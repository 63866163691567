import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Link
} from 'react-router-dom';
import {
  Button,
  Table,
  Tag
} from 'antd';
import {
  SubTitle,
} from './styles';

IncomingInventoryList.defaultProps = {
  updateState: (key, value) => console.log(key, value),
  selectedInventory: []
};

function IncomingInventoryList({
  data,
  loading,
  updateState,
  acceptInventory,
  declineInventory,
  userData,
  disableRemoteColumn,
  disableCreatedOnColumn,
  disableWithdrawalLocationName,
  disableLocationName,
  disablePayout,
  acceptText,
  declineText,
  disableOrderDateColumn,
  disableOrderColumn,
  disableItemIdColumn,
  disableOwnerColumn,
  disablePriceColumn,
  type,
  openNewTab,
  showCheckbox = false
}) {

  let columns = [
    !disableRemoteColumn && userData && userData.allowRemote && userData.allowDropoff ? {
      title: 'Remote',
      dataIndex: '',
      key: '',
      render: (item) => item.isRemote ? <Tag color='red'>Remote</Tag> : <Tag>Dropoff</Tag>
    } : null,
    {
      title: 'Title',
      dataIndex: '',
      key: '',
      render: (item) => <Link
          to={`/${type||'inventory'}/${item.id}`}
          target={openNewTab ? '_blank' : ''}
        >
        {item.title} {item.productSku} {item.brand} {item.option1} {item.option2} {item.option3}
        </Link>
    },
    !disableLocationName ? {
      title: 'Location',
      dataIndex: '',
      key: 'locationName',
      render: (item) => <SubTitle>{item.locationName} {item.subLocationName}</SubTitle>
    } : null,
    !disableWithdrawalLocationName ? {
      title: 'Withdrawal Location',
      dataIndex: 'withdrawalLocationName',
      key: 'withdrawalLocationName',
    } : null,

    !disableItemIdColumn ? {
      title: 'Item Id',
      dataIndex: 'shortCode',
      key: 'shortCode',
    } : null,
    !disablePriceColumn ? {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    } : null,
    !disablePayout ? {
      title: 'Payout',
      dataIndex: '',
      key: '',
      render: (item) => <SubTitle>{(item.price - (item.price * (item.fee/100)) - item.dollarfee).toFixed(2)}</SubTitle>
    } : null,
    !disableOwnerColumn ? {
      title: 'Owner',
      dataIndex: '',
      key: '',
      render: (item) => item.consignerName && item.consignerId ? <div><Link to={`/consigners/${item.consignerId}`}>{item.consignerName}</Link></div> : null
    } : null,
    !disableOrderColumn ? {
      title: 'Order',
      dataIndex: '',
      key: '',
      render: (item) => <Link to={`/orders/${item.orderId}`}>#{item.orderNumber}</Link>
    } : null,
    !disableCreatedOnColumn ? {
      title: 'Created On',
      dataIndex: '',
      key: '',
      render: (item) => <SubTitle>Created On: {moment(item.createdAt).format('llll')}</SubTitle>
    } : null,
    !disableOrderDateColumn ? {
      title: 'Order Date',
      dataIndex: '',
      key: '',
      render: (item) => <SubTitle>{moment(item.orderDate).format('llll')}</SubTitle>
    } : null,
    acceptInventory || declineInventory ? {
      title: 'Action',
      render: (item) =>
        <div style={{ display: 'flex' }}>
          {acceptInventory ? <Button
            type='primary'
            onClick={() => acceptInventory(item.id, item.shortCode, item)}
          >
            {acceptText || 'Accept'}
          </Button> : null}
          {declineInventory ?<Button
            type='danger'
            onClick={() => declineInventory(item.id, item.shortCode)}
          >
            {declineText || 'Decline'}
          </Button> : null}
        </div>
    }: null
  ];
  columns = columns.filter(function(e){return e});

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      updateState('selectedInventory', selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      rowSelection={showCheckbox ? rowSelection : null}
    />
  )
}

export default IncomingInventoryList;
