import axios from 'axios';
import { message } from 'antd';
import {
  CUSTOMER_API
} from '../../../constants/API';
import {
  SEARCH_CUSTOMERS_LOADING,
  SEARCH_CUSTOMERS
} from '../types';

function searchCustomers(query, page, order) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_CUSTOMERS_LOADING,
      loadingSearchCustomers: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const searchCustomerResponse = await axios.post(
        `${CUSTOMER_API}/search/${userData.id}`,
        { query, page, order },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(searchCustomerResponse)
      dispatch({
        type: SEARCH_CUSTOMERS,
        loadingSearchCustomers: false,
        searchCustomers: !searchCustomerResponse.data.isError ? searchCustomerResponse.data.results : [],
        searchCustomersCount: !searchCustomerResponse.data.isError ? searchCustomerResponse.data.count : 0,
      });
      return searchCustomerResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: SEARCH_CUSTOMERS_LOADING,
        loadingSearchCustomers: false
      });
    }
  };
}
export default searchCustomers;
