import axios from 'axios';
import { message } from 'antd';
import {
  EMPLOYEE_API
} from '../../../constants/API';
import {
  SEARCH_EMPLOYEES_LOADING,
  SEARCH_EMPLOYEES
} from '../types';

function searchEmployees(query, page, order) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_EMPLOYEES_LOADING,
      loadingSearchEmployees: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const searchEmployeeResponse = await axios.post(
        `${EMPLOYEE_API}/search/${userData.id}`,
        { query, page, order },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(searchEmployeeResponse)
      dispatch({
        type: SEARCH_EMPLOYEES,
        loadingSearchEmployees: false,
        searchEmployees: !searchEmployeeResponse.data.isError ? searchEmployeeResponse.data.results : [],
        searchEmployeesCount: !searchEmployeeResponse.data.isError ? searchEmployeeResponse.data.count : 0,
      });
      return searchEmployeeResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: SEARCH_EMPLOYEES_LOADING,
        loadingSearchEmployees: false
      });
    }
  };
}
export default searchEmployees;
