import React from 'react';
import {
  Modal,
  Input,
  Form,
  Switch,
  InputNumber
} from 'antd';
import PropTypes from 'prop-types';
import PlacesInput from '../../../algolia/PlacesInput';
import Tag from '../../Tag';
import SettingsAppointmentsTime from '../../forms/settings/SettingsAppointmentsTime';
import SettingsAppointmentsRestriction from '../../forms/settings/SettingsAppointmentsRestriction';
import AddAppointmentTime from '../AddAppointmentTime';
import AddAppointmentRule from '../AddAppointmentRule';

EditLocation.propTypes = {
  updatingLocation: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  visible: PropTypes.bool,
  updateState: PropTypes.func,
  name: PropTypes.string,
  addressQuery: PropTypes.string,
  address: PropTypes.object
};
EditLocation.defaultProps = {
  updatingLocation: false,
  handleOk: (...props) => console.log(props),
  handleCancel: (...props) => console.log(props),
  visible: false,
  updateState: (...props) => console.log(props),
  name: '',
  addressQuery: '',
  address: {}
};


function EditLocation(props) {
  const {
    updatingLocation,
    handleOk,
    handleCancel,
    visible,
    updateState,
    updateStateObj,
    values,
    handleAddAppointmentTimesModal,
    deleteAppointmentTime,
    creatingAppointmentTime,
    handleAppointmentTimeOk,
    deleteAppointmentRule,
    handleAddAppointmentRulesModal,
    handleAppointmentRuleOk,
    addAppointmentRuleVisible,
    creatingAppointmentRule
  } = props;
  const {
    id,
    address,
    name,
    addressQuery,
    allowPickup,
    subLocations,
    enableAppointments,
    enableSameDayAppointments,
    appointmentInterval,
    AppointmentTimes,
    addAppointmentTimeVisible,
    close,
    open,
    day,
    AppointmentRules,
    rule,
    ruleFormat
  } = values;

  return (
    <Modal
      title='Edit Location'
      visible={visible}
      onOk={() => handleOk()}
      okText='Update'
      onCancel={() => handleCancel()}
      confirmLoading={updatingLocation}
      width={'60%'}
    >
      <Form.Item label='Name'>
        <Input
          placeholder='Name'
          onChange={(e) => updateState('name', e.target.value)}
          value={name}
          data-test='EditLocationModalName'
        />
      </Form.Item>
      <Form.Item label='Sub Locations'>
        <Tag
          title='New Sub Location'
          updateState={updateState}
          stateKey='subLocations'
          value={subLocations || []}
        />
      </Form.Item>
      <Form.Item label='Allow Pickup'>
        <Switch
          onChange={(value) => updateState('allowPickup', value)}
          checked={allowPickup}
        />
      </Form.Item>
      <Form.Item label='Enable Appointments'>
        <Switch
          onChange={(value) => updateState('enableAppointments', value)}
          checked={enableAppointments}
        />
      </Form.Item>
      <Form.Item label='Accept Same Day Appointments'>
        <Switch
          onChange={(value) => updateState('enableSameDayAppointments', value)}
          checked={enableSameDayAppointments}
        />
      </Form.Item>
      <Form.Item label='Time interval (in minutes)'>
        <InputNumber
          placeholder='Time interval'
          onChange={(value) => updateState('appointmentInterval', value)}
          value={appointmentInterval}
        />
      </Form.Item>
      <Form.Item label='Appointment Times'>
        <SettingsAppointmentsTime
          appointmentTimes={AppointmentTimes}
          deleteAppointmentTime={(id) => deleteAppointmentTime(id)}
          handleAddAppointmentTimesModal={handleAddAppointmentTimesModal}
        />
      </Form.Item>
      <Form.Item label='Appointment Restrictions'>
        <SettingsAppointmentsRestriction
          appointmentRules={AppointmentRules}
          deleteAppointmentRule={(id) => deleteAppointmentRule(id)}
          handleAddAppointmentRulesModal={handleAddAppointmentRulesModal}
        />
      </Form.Item>
      <Form.Item label='Address'>
        <PlacesInput
          updateState={(key, data) => updateState(key, data)}
          queryKey='addressQuery'
          query={addressQuery}
        />
        <Input
          placeholder='Address'
          value={address.address}
          onChange={(e) => updateStateObj('address', 'address', e.target.value)}

        />
        <Input
          placeholder='City'
          value={address.city}
          onChange={(e) => updateStateObj('address', 'city', e.target.value)}
        />
        <Input
          placeholder='State/Province'
          value={address.province}
          onChange={(e) => updateStateObj('address', 'province', e.target.value)}
        />
        <Input
          placeholder='Country'
          value={address.country}
          onChange={(e) => updateStateObj('address', 'country', e.target.value)}

        />
        <Input
          placeholder='Country Code'
          value={address.countryCode}
          onChange={(e) => updateStateObj('address', 'countryCode', e.target.value)}

        />
        <Input
          placeholder='Postcode'
          value={address.postcode}
          onChange={(e) => updateStateObj('address', 'postcode', e.target.value)}

        />
      </Form.Item>
      <AddAppointmentTime
        creatingAppointmentTime={creatingAppointmentTime}
        handleOk={() => handleAppointmentTimeOk(id)}
        handleCancel={() => handleAddAppointmentTimesModal()}
        visible={addAppointmentTimeVisible}
        updateState={(key, value) => updateState(key, value)}
        close={close}
        open={open}
        day={day}
      />
      <AddAppointmentRule
        creatingAppointmentRule={creatingAppointmentRule}
        handleOk={() => handleAppointmentRuleOk(id)}
        handleCancel={() => handleAddAppointmentRulesModal()}
        visible={addAppointmentRuleVisible}
        updateState={(key, value) => updateState(key, value)}
        rule={rule}
        ruleFormat={ruleFormat}
      />
    </Modal>
  );
}

export default EditLocation;
