import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API
} from '../../../constants/API';
import {
  GET_CONSIGNERS_LOADING,
  GET_CONSIGNERS
} from '../types';

function getConsigners() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CONSIGNERS_LOADING,
      loadingConsigners: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const consignersResponse = await axios.get(
        `${CONSIGNER_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(consignersResponse)
      dispatch({
        type: GET_CONSIGNERS,
        loadingConsigners: false,
        consigners: consignersResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_CONSIGNERS_LOADING,
        loadingConsigners: false
      });
    }
  };
}
export default getConsigners;
