import axios from 'axios';
import { message } from 'antd';
import {
  APPOINTMENT_RULES_API,
} from '../../../constants/API';
import {
  DELETE_APPOINTMENT_RULE_LOADING
} from '../types';
import getLocations from '../location/getLocations';

function deleteAppointmentRules(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: DELETE_APPOINTMENT_RULE_LOADING,
        deletingAppointmentRule: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await axios.delete(`${APPOINTMENT_RULES_API}/${id}`, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: DELETE_APPOINTMENT_RULE_LOADING,
          deletingAppointmentRule: false
        });

        await dispatch(getLocations());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: DELETE_APPOINTMENT_RULE_LOADING,
          deletingAppointmentRule: false
        });
        console.log(e);
      }
  }
}
export default deleteAppointmentRules;
