import React from 'react';
import AsyncSelect from 'react-select/async';

import {
  Select

} from 'antd';
const { Option } = Select;

function ProductSearchInput(props) {
  const {
    data,
    onChange,
    value,
    viewOnly,
    onViewOnlyClick,
    onSearch,
    loading,
    defaultValue
  } = props;

  const customStyles = {
          control: base => ({
              ...base,
              minHeight: 30
          }),
          dropdownIndicator: base => ({
              ...base,
              padding: 4
          }),
          clearIndicator: base => ({
              ...base,
              padding: 4
          }),
          multiValue: base => ({
              ...base,
          }),
          menu: base => ({
              ...base,
              zIndex: 99999999,
          }),
          valueContainer: base => ({
              ...base,
              padding: '0px 6px'
          }),
          input: base => ({
              ...base,
              margin: 0,
              padding: 0
          })
  };
  return (
    <div
      style={{ width: '100%'}}
    >
    <AsyncSelect
      cacheOptions
      loadOptions={onSearch}
      defaultOptions
      defaultValue={defaultValue}
      isDisabled={viewOnly}
      placeholder="Select Product"
      onChange={onChange}
      isClearable
      custom
      styles={customStyles}

    />
    </div>
  )
}
export default ProductSearchInput;
