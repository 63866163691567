import React from 'react';
import {
  Link
} from 'react-router-dom';


class OrderViewCustomer extends React.Component {

  render() {
    const {
      values,
    } = this.props;
    const {
      customerName,
      customerId
    } = values;
    return (
      <Link
        to={`/customers/${customerId}`}
        target='_blank'
      >
        {customerName}
      </Link>
    );
  }
}
export default OrderViewCustomer;
