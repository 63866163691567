import { message } from 'antd';
import {
    SEARCH_PRODUCTS_LOADING,
    SEARCH_STOCKX_PRODUCTS
} from '../types';

import algoliasearch from 'algoliasearch/lite';
import axios from "axios";
import {
    PRODUCTS_API
} from "../../../constants/API";

const searchClient = algoliasearch('XW7SBCT9V6', '6bfb5abee4dcd8cea8f0ca1ca085c2b3');
const productsIndex = searchClient.initIndex('products');


function convertHitToProduct(hit) {
    return {
        key: hit.objectID,
        id: hit.objectID + '-' + hit.product_category + '-sx',
        title: hit.name,
        brand: hit.brand,
        sku: hit.style_id,
        images: [hit.thumbnail_url],
        category: hit.product_category.charAt(0).toUpperCase() + hit.product_category.slice(1),
        integrationName: null,
        // description: hit.description,
        description: null,
        metadata: null,
        tags: [],
        option1: "Size",
        option2: "Color",
        option3: "Condition",
        option1FixedValues: [],
        option2FixedValues: [],
        option3FixedValues: [],
        option1Switch: false,
        option2Switch: false,
        option3Switch: false,
        template: null,
        consignerCreated: false,
        draftProduct: false,
        updatedAt: "2020-01-03T10:18:21.970Z",
        createdAt: "2020-01-03T10:18:21.970Z",
        catalogId: 99999,
        catalogProductId: hit.objectID,
        integrationId: null,
        productTemplateId: null,
        userId: "",
        rawHit: hit
    };
}

function searchStockXProducts(query, page, order) {
    return async (dispatch, getState) => {
        dispatch({
            type: SEARCH_PRODUCTS_LOADING,
            loadingSearchProducts: true
        });
        try {
            const accessToken = await localStorage.getItem('accessToken');
            const { userData } = getState().AuthReducer;
            const catalogIds = userData.subscribedCatalogIds;
            const subscribedCatalogNames = userData.subscribedCatalogNames;

            // Get user products w/o catalog products
            const actualSearchProductResponse = await axios.post(
                `${PRODUCTS_API}/search/${userData.id}`,
                { query, catalogIds: [], page, order },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            let searchProductResponse = {};
            searchProductResponse['data'] = {};
            searchProductResponse['data']['isError'] = actualSearchProductResponse.data.isError;
            searchProductResponse['data']['results'] = !actualSearchProductResponse.data.isError ? actualSearchProductResponse.data.results : [];
            searchProductResponse['data']['count'] = !actualSearchProductResponse.data.isError ? actualSearchProductResponse.data.count : 0;

            let shouldFallbackToRegularQuery = false;

            // Get catalog products from stockx if subscribed to any catalogs
            if (subscribedCatalogNames.length > 0) {
                try {
                    const searchProductSkus = searchProductResponse['data']['results'].filter(result => result.sku !== null && result.sku !== "").map(result => String(result.sku).toUpperCase());
                    const searchProductNames = searchProductResponse['data']['results'].filter(result => result.title !== null && result.title !== "").map(result => String(result.title).toUpperCase());
                    const stockXCatalogProductSearchResponse = await productsIndex.search(query, {
                        facets: '*',
                        page: Math.max(0, page - (1 + (Math.floor(searchProductResponse['data']['count'] / 10)))),
                        hitsPerPage: 10 - searchProductResponse['data']['results'].length,
                        facetFilters: [
                            subscribedCatalogNames.map(catalogName => 'product_category:' + catalogName.toLowerCase())
                        ]
                    });

                    stockXCatalogProductSearchResponse.hits = stockXCatalogProductSearchResponse.hits.filter(hit => !searchProductSkus.includes(hit.style_id) && !searchProductNames.includes(String(hit.name).toUpperCase()));

                    shouldFallbackToRegularQuery = stockXCatalogProductSearchResponse.nbHits === 0;

                    searchProductResponse['data']['isError'] &= false;
                    searchProductResponse['data']['results'] = searchProductResponse['data']['results'].concat(stockXCatalogProductSearchResponse.hits.map(convertHitToProduct));
                    searchProductResponse['data']['count'] = Math.min(1000 - 10, stockXCatalogProductSearchResponse.nbHits); // Limit to max of first 1000 hits
                } catch (e) {
                    shouldFallbackToRegularQuery = true;
                }
            }

            // Fallback to regular query with catalog products from db if StockX fails or returns 0 hits
            if (shouldFallbackToRegularQuery) {
                searchProductResponse = await axios.post(
                    `${PRODUCTS_API}/search/${userData.id}`,
                    { query, catalogIds, page, order },
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                );
            }

            dispatch({
                type: SEARCH_STOCKX_PRODUCTS,
                loadingSearchProducts: false,
                searchProducts: !searchProductResponse.data.isError ? searchProductResponse.data.results : [],
                searchProductsCount: !searchProductResponse.data.isError ? searchProductResponse.data.count : 0,
            });

            return searchProductResponse.data;
        } catch (e) {
            console.log(e);
            message.error(String(e));
            dispatch({
                type: SEARCH_PRODUCTS_LOADING,
                loadingSearchProducts: false
            });
        }
    };
}
export default searchStockXProducts;
