import styled from 'styled-components';
import { Icon } from 'antd'
const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: 15px;
  position: relative;
`
const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`
const CloseIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 0;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Handle = styled.span`
  cursor: grab;
`
export {
  Image,
  CloseIcon,
  ImageContainer,
  Container,
  Handle
}
