import React from 'react';
import {
  Table
} from 'antd';
import {
  Container
} from './styles';

const TableUI = ({
  data,
  columns,
  loading,
  id,
  emptyText,
  footer,
  pagination,
  onChange,
  pageSize
}) => {
  return (
    <Container>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        id={id}
        locale={{ emptyText }}
        footer={footer}
        pagination={pagination}
        onChange={onChange}
      />
    </Container>
  );
}
export default TableUI;
