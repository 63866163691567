import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API,
} from '../../../constants/API';
import {
  BULK_UPSERTING_INVENTORY
} from '../types';


function bulkUpsertInventory(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: BULK_UPSERTING_INVENTORY,
        bulkUpsertingInventory: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const postData = {
          data,
        };
        await axios.post(`${INVENTORY_API}/bulkUpsert`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: BULK_UPSERTING_INVENTORY,
          bulkUpsertingInventory: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: BULK_UPSERTING_INVENTORY,
          bulkUpsertingInventory: false
        });
        console.log(e);
      }
  }
}
export default bulkUpsertInventory;
