import React from 'react';
import { Empty } from 'antd';
import LoadingSpinner from '../../../LoadingSpinner';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove,
  Edit
} from './styles';

class SettingsLocation extends React.Component {

  render() {
    const {
      locations,
      loadingLocations,
      deleteLocation,
      handleAddLocationModal,
      updateLocationDataToState,
      handleEditLocationModal,
      setCurrentLocation
    } = this.props;
    if(loadingLocations) {
      return <LoadingSpinner />
    }
    if(!locations || locations.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddLocationModal()}
              data-test='SettingsAddLocationButton'

            >
              Add Location
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        locations.map((item) => {
          return (
            <ItemContainer>
              <Title>
              {item.name}
              </Title>
              <SubTitle>
              {item.addressQuery}
              </SubTitle>
              <Edit
                onClick={async () => {
                  await updateLocationDataToState(item)
                  await handleEditLocationModal()
                }}
              >
                EDIT
              </Edit>
              <Remove
                onClick={() => {
                  deleteLocation(item.id)
                }}
              >
                REMOVE
              </Remove>

            </ItemContainer>
          )
        })
      }
      <a
        onClick={() => handleAddLocationModal()}
        data-test='SettingsAddLocationButton'

      >
        Add Location
      </a>
      </Container>
    );
  }
}
export default SettingsLocation;
