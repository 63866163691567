import React from 'react';
import {
  Form,
} from 'antd';
import DropdownSearch from '../../../../algolia/DropdownSearch';

class ConsignerCustomerAccount extends React.Component {

  render() {
    const {
      updateState,
      values,
      queryKey,
      query,
      userId,
      disabled
    } = this.props;
    const {
      customerName
    } = values;
    return (
      <div>
          <Form.Item >
            <DropdownSearch
              searchPlaceholder='Search Customers'
              index='customers'
              keyName='name'
              updateState={updateState}
              stateKey='customerId'
              stateValue='objectID'
              stateKey2='customerName'
              stateValue2='name'
              value={customerName}
              query={query}
              queryKey={queryKey}
              userId={userId}
              viewOnly={disabled}

            />
          </Form.Item>
      </div>
    );
  }
}
export default ConsignerCustomerAccount;
