import React from 'react';
import DropdownSearch from '../../../../algolia/DropdownSearch';
class InventoryConsigner extends React.Component {

  render() {
    const {
      updateState,
      values,
      query,
      queryKey,
      userId,
      viewOnly,
      onViewOnlyClick
    } = this.props;
    const {
      consignerName,
    } = values;
    return (
        <DropdownSearch
          searchPlaceholder='Search Consigners'
          index='consigners'
          keyName='name'
          updateState={updateState}
          stateKey='consignerId'
          stateValue='objectID'
          stateKey2='consignerName'
          stateValue2='name'
          value={consignerName}
          query={query}
          queryKey={queryKey}
          userId={userId}
          viewOnly={viewOnly}
          onViewOnlyClick={onViewOnlyClick}
          filters='AND NOT isStoreAccount:true'

        />
    );
  }
}
export default InventoryConsigner;
