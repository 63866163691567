import React from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker
} from 'antd';
import PlacesInput from '../../../../algolia/PlacesInput';

class EmployeeName extends React.Component {

  render() {
    const {
      updateState,
      updateStateObj,
      values,
      queryKey,
      query,
      disabled
    } = this.props;
    const {
      name,
      birthday,
      address
    } = values;
    const { 
      address: addressString,
      city,
      province,
      country,
      countryCode,
      postcode
     } = address || {};

    return (
      <div>
        <Form.Item label='Name'>
          <Input
            placeholder='Employee name'
            onChange={(e) => updateState('name', e.target.value)}
            value={name}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label='Birthday' >
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select Date"
            style={{ width: '100%'}}
            onChange={(date) => updateState('birthday', date)}
            value={birthday ? moment(birthday) : ''}
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Address'>
          <PlacesInput
            updateState={updateState}
            queryKey={queryKey}
            query={query}
            disabled={disabled}

          />
          <Input
            placeholder='Address'
            value={addressString}
            onChange={(e) => updateStateObj('address', 'address', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='City'
            value={city}
            onChange={(e) => updateStateObj('address', 'city', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='State/Province'
            value={province}
            onChange={(e) => updateStateObj('address', 'province', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='Country'
            value={country}
            onChange={(e) => updateStateObj('address', 'country', e.target.value)}
            disabled={disabled}


          />
          <Input
            placeholder='Country Code'
            value={countryCode}
            onChange={(e) => updateStateObj('address', 'countryCode', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='Postcode'
            value={postcode}
            onChange={(e) => updateStateObj('address', 'postcode', e.target.value)}
            disabled={disabled}

          />
        </Form.Item>
      </div>
    );
  }
}
export default EmployeeName;
