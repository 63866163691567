import React from 'react';
import {
  connectInfiniteHits
} from 'react-instantsearch-dom';
import {
  Container,
  Image,
  Title,
  Active,
  ConnectButton,
  UnsubscribeContainer
} from './styles';

const CatalogCard = connectInfiniteHits(props => {
  const {
    hits,
    subscribedCatalogIds,
    onSubscribePress,
    onUnsubcribePress
  } = props;
  return (
    <div>
    {
      hits.map((hit, index) => {
        const subscribed = subscribedCatalogIds.includes(hit.objectID);
        return (
          <Container
            span={4}
          >
            <Image
              src={hit.image}
            />
            <Title>
              {hit.title}
            </Title>
            {
              subscribed ?
                <UnsubscribeContainer>
                  <Active onClick={() => onUnsubcribePress(hit)}>
                    You are already subscribed.
                  </Active>
                  <ConnectButton
                    onClick={() => onUnsubcribePress(hit)}
                    type='danger'
                  >
                    Unsubscribe
                  </ConnectButton>
                </UnsubscribeContainer>
                :
                <ConnectButton
                  onClick={() => onSubscribePress(hit)}
                >
                  Subscribe
                </ConnectButton>
            }
          </Container>
        )
      }

      )
    }
    </div>
  );
});

export default CatalogCard;
