import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {
  PageHeader,
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';
import GridPicker from '../../components/ui/GridPicker';
import SettingsGrid from '../../constants/SettingsGrid';
import Version from '../../constants/Version';

class Settings extends React.Component {

  render() {
      return (
        <DashboardContainer>
          <PageHeader
            title={<PageTitle>Settings</PageTitle>}
            subTitle={`v${Version.version} at ${Version.releaseDate}`}
            style={{ background: 'transparent', marginBottom: 10 }}
          />
          <GridPicker
            data={SettingsGrid}
          />
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
