import {
  GET_LOCATIONS_LOADING,
  GET_LOCATIONS,
  CREATE_LOCATION_LOADING,
  DELETE_LOCATION_LOADING,
  UPDATE_LOCATION_LOADING,
  GET_LOCATION_ITEM,
  GET_LOCATION_ITEM_LOADING
} from '../actions/types';

const defaultState = {
  loadingLocations: false,
  locations: [],
  creatingLocation: false,
  deletingLocation: false,
  updatingLocation: false,
  loadingLocationItem: false,
  locationItem: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_LOCATIONS_LOADING:
          return {
            ...state,
            loadingLocations: action.loadingLocations
          };
        case GET_LOCATIONS:
          return {
            ...state,
            loadingLocations: action.loadingLocations,
            locations: action.locations
          };
        case CREATE_LOCATION_LOADING:
          return {
            ...state,
            creatingLocation: action.creatingLocation
          }
        case DELETE_LOCATION_LOADING:
          return {
            ...state,
            deletingLocation: action.deletingLocation
          }
        case UPDATE_LOCATION_LOADING:
          return {
            ...state,
            updatingLocation: action.updatingLocation
          }
        case GET_LOCATION_ITEM:
          return {
          ...state,
            loadingLocationItem: action.loadingLocationItem,
            locationItem: action.locationItem
          }
        case GET_LOCATION_ITEM_LOADING:
          return {
          ...state,
            loadingLocationItem: action.loadingLocationItem,
          }
        default:
            return state;
    }
};
