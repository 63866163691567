import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import SearchBar from '../../components/ui/search/SearchBar';
import SearchTable from '../../components/ui/search/SearchTable';
import SearchSortTitle from '../../components/ui/search/SearchSortTitle';
import {
  PageHeader
} from 'antd';
import CustomerSortBy from '../../constants/CustomerSortBy';

import {
  DashboardContainer,
  PageTitle
} from '../styles';

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCatalog: false,
      query: '',
      pagination: { current: 1 },
      selectedOrder: 0
    }
  }
  componentWillMount() {
    this.props.searchCustomers('', 1, CustomerSortBy[0])
  }

  selectTableColumns() {
    let columns = [
      {
        title: () =>
        <SearchSortTitle
          title='Name'
          updateState={(key, value) => this.updateState(key, value)}
          selectedOrder={this.state.selectedOrder}
          upIndex={0}
          downIndex={1}
        />,
        dataIndex: '',
        key: '',
        render: (data) => <Link to={`/customers/${data.id}`}>{data.name}</Link>,

      },
    ]

    //Table columns
    if(window.outerWidth > 992) {
      columns = [
        {
          title: () =>
          <SearchSortTitle
            title='Name'
            updateState={(key, value) => this.updateState(key, value)}
            selectedOrder={this.state.selectedOrder}
            upIndex={0}
            downIndex={1}
          />,
          dataIndex: '',
          key: '',
          render: (data) => <Link to={`/customers/${data.id}`}>{data.name}</Link>,

        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt'
        },
      ];
    }

    return columns

  }
  updateState(key, value) {
    if(value === '') {
      this.props.searchCustomers(value, 1, CustomerSortBy[this.state.selectedOrder])
    } else if(key === 'selectedOrder') {
      this.props.searchCustomers(this.state.query, this.state.pagination.current, CustomerSortBy[value])
    }
    this.setState({
      [key]: value,
      pagination: { current: 1 }
    })
  }

  onTableChange(pagination) {
    const { query } = this.state;
    this.setState({
      pagination
    })
    this.props.searchCustomers(query, pagination.current, CustomerSortBy[this.state.selectedOrder])

  }

  render() {
    //Table columns
    const columns = this.selectTableColumns()
      return (
        <DashboardContainer>
          <PageHeader
            title={<PageTitle>Customers</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
          />
          <SearchBar
            updateState={(key, value) => this.updateState(key, value)}
            query={this.state.query}
            searchPlaceholder='Search Customers By Email or Name'
            onSearch={() => this.props.searchCustomers(this.state.query, this.state.pagination.current,CustomerSortBy[this.state.selectedOrder])}
            loading={this.props.customerData.loadingSearchCustomers}
          />
          <SearchTable
            data={this.props.customerData.searchCustomers}
            columns={columns}
            loading={this.props.customerData.loadingSearchCustomers}
            pagination={{ total: this.props.customerData.searchCustomersCount, defaultCurrent: 1, current: this.state.pagination.current }}
            onChange={(pagination) => this.onTableChange(pagination)}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        customerData: state.CustomerReducer,
        inventoryData: state.InventoryReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customers));
