import React from 'react';
import {
  connectInfiniteHits
} from 'react-instantsearch-dom';
import {
  Container,
  Image,
  Title,
  Category,
  Active,
  ConnectButton
} from './styles';
import doesExistInArrayOfObj from '../../../../helpers/data/doesExistInArrayOfObj';

const IntegrationCard = connectInfiniteHits(props => {
  const {
    hits,
    connections,
    onPress
  } = props;
  return (
    <div>
    {
      hits.map((hit, index) => {
        const connection = doesExistInArrayOfObj(connections, hit.objectID);
        return (
          <Container
            span={4}
          >
            <Image
              src={hit.image}
            />
            <Title>
              {hit.name}
            </Title>
            <Category>
              {hit.category}
            </Category>
            {
              connection ?
                <Active>
                  Connected
                </Active> :
                <ConnectButton
                  onClick={() => onPress(hit)}
                >
                  Connect
                </ConnectButton>
            }
            {
              connection && hit.allowMultipleConnect ?
              <ConnectButton
                onClick={() => onPress(hit)}
              >
                Connect
              </ConnectButton> : null
            }
          </Container>
        )
      }

      )
    }
    </div>
  );
});

export default IntegrationCard;
