import React from 'react';
import {
  Form,
  InputNumber,
  Input,
  Radio
} from 'antd';

class InventoryPricing extends React.Component {

  render() {
    const {
      updateState,
      values,
      disabled,
      priceDisabled,
      feeDisabled,
      defaultCurrency
    } = this.props;
    const {
      lowestPrice,
      price,
      fee,
      feeType,
      taxable,
      dollarfee,
      isStoreInventory,
      compareAt
    } = values;
    const { symbol } = defaultCurrency;
    return (
      <div>
          <Form.Item label='Pricing'>
            <Input
              defaultValue={1000}
              type="number"
              suffix={symbol}
              style={{ width: '100%' }}
              onChange={(e) => updateState('price', e.target.value)}
              value={price}
              disabled={disabled || priceDisabled}
            />
          </Form.Item>
          <Form.Item label='Compare at'>
            <Input
              defaultValue={1000}
              type="number"
              suffix={symbol}
              style={{ width: '100%' }}
              onChange={(e) => updateState('compareAt', e.target.value)}
              value={compareAt}
              disabled={disabled || priceDisabled}
            />
          </Form.Item>

          {
            !isStoreInventory ?
            <Form.Item label='Consignment Fee'>
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
                  onChange={(value) => updateState('fee', value)}
                  value={fee}
                  disabled={disabled || feeDisabled}


                />
                <Input
                  max='Infinity'
                  type="number"
                  suffix={symbol}
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={(e) => updateState('dollarfee', e.target.value)}
                  value={dollarfee}
                  disabled={disabled || feeDisabled}

                />
            </Form.Item> :
            <Form.Item label='Cost'>
              <Input
                max='Infinity'
                type="number"
                suffix={symbol}
                style={{ width: '100%' }}
                onChange={(e) => updateState('dollarfee', e.target.value)}
                value={dollarfee}
                disabled={disabled }

              />
            </Form.Item>
          }

          <Form.Item label='Taxable'>
            <Radio.Group
              value={taxable}
              disabled={disabled}
              onChange={
                (e) => {
                  updateState('taxable', e.target.value)
                }
              }
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
      </div>
    );
  }
}
export default InventoryPricing;
