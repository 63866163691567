import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Title
} from './styles';

AvatarUI.propTypes = {
  name: PropTypes.string
};

function AvatarUI(props) {
  const {
    name
  } = props;
  if(!name) {
    return null;
  }
  return (
      <Container>
        <Title>
          {name}
        </Title>
      </Container>
  )
}
AvatarUI.displayName = 'AvatarUI';

export default AvatarUI;
