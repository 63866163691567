import styled from 'styled-components';
import { Button } from 'antd';
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const CardContainer = styled(Button)`
  border: 1px #e7e7e7 solid;
  margin-top: 10px
`
const Title = styled.h2`

`

const Subtitle = styled.p`
  margin-bottom: 0px;
`
export {
  Container,
  CardContainer,
  Title,
  Subtitle
}
