import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Link
} from 'react-router-dom';
import {
  Tag,
  Table
} from 'antd';
import BulkEditInventory from '../BulkInventoryEditor';
import {
  SubTitle,
} from './styles';

InventoryList.defaultProps = {
  updateState: (key, value) => console.log(key, value),
  selectedInventory: []
};

function InventoryList({
  data,
  loading,
  updateState,
  selectedInventory,
  hideCheckbox,
  compact,
  bulkEditMode,
  bulkEditData,
  updateStateArrayKey,
  consigners,
  defaultCurrency
}) {
  let columns = [
    {
      title: 'Owned by',
      dataIndex: '',
      key: '',
      render: (item) => item.isStoreInventory ? <Tag color='red'>Store Owned</Tag> : <Tag>Consigner</Tag>
    },
    {
      title: 'Title',
      dataIndex: '',
      key: '',
      render: (item) => <Link to={`/inventory/${item.id}`}>{item.title} {item.brand} {item.option1} {item.option2} {item.option3}</Link>
    },
    {
      title: 'SKU',
      dataIndex: 'productSku',
      key: 'productSku',
    },
    {
      title: 'Location',
      dataIndex: '',
      key: 'locationName',
      render: (item) => <SubTitle>{item.locationName} {item.subLocationName}</SubTitle>
    },

    {
      title: 'Item Id',
      dataIndex: 'shortCode',
      key: 'shortCode',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Owner',
      dataIndex: '',
      key: '',
      render: (item) => item.consignerName && item.consignerId ? <div><Link to={`/consigners/${item.consignerId}`}>{item.consignerName}</Link></div> : null
    },
    {
      title: 'Date',
      dataIndex: '',
      key: '',
      render: (item) => {
        if(item.status === 'Active' && !item.isStoreInventory && item.receivedAt) {
          return <SubTitle>Received On: {moment(item.receivedAt).format('llll')}</SubTitle>
        } else if(item.status === 'Active' && item.isStoreInventory && item.createdAt) {
          return <SubTitle>Bought On: {moment(item.createdAt).format('llll')}</SubTitle>
        } else if(item.status === 'Withdrawn' && item.withdrawalDate) {
          return <SubTitle>Withdrawn On: {moment(item.withdrawalDate).format('llll')}</SubTitle>
        }
        else if (item.status === 'Sold' && item.orderDate) {
          return (
            <div>
              <Link to={`/orders/${item.orderId}`}>Order #{item.orderNumber}</Link>
              <SubTitle>Ordered On: {moment(item.orderDate).format('llll')}</SubTitle>
            </div>
          )
        } else if (item.createdAt) {
          return <SubTitle>Created On: {moment(item.createdAt).format('llll')}</SubTitle>
        }
      }
    },
  ];

  let rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        const selectedInventory = selectedRows.map(a => a.id);
        updateState('selectedInventory', selectedInventory)
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

  if(compact || window.outerWidth < 992) {
    rowSelection=null;
    columns = [
      {
        title: 'Title',
        dataIndex: '',
        key: '',
        render: (item) => <Link to={`/inventory/${item.id}`}>{item.title} {item.brand} {item.option1} {item.option2} {item.option3}</Link>
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ];
  }
  if(bulkEditMode) {
    return (
      <BulkEditInventory
        data={bulkEditData}
        updateStateArrayKey={updateStateArrayKey}
        consigners={consigners}
        defaultCurrency={defaultCurrency}
      />
    )
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowSelection={rowSelection}
      loading={loading}
    />
  );
}

export default InventoryList;
