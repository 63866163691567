import styled from 'styled-components';
import React from 'react';
import { Card, Button } from 'antd';
import {
  PRIMARY_FONT
} from '../constants/Fonts';
import {
  Tabs,
} from 'antd';
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 102px;
  max-width: 100vw;
`
const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-contenr: center;
`
const SyncText = styled.span`
  margin-right: 24px;
`
const TabContainer = styled(Tabs)`
  .ant-tabs-bar {
    margin: 0;
    background-color: white;
  }
`
const TabPaneContainer = styled(Tabs.TabPane)`

`
const LaunchContainer= styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: center;
  align-items: center;
  flex: 1;
`
const Title = styled.h2`
  font-size: 36px;
`
const SubTitle = styled.p`
  font-size: 16px;
`
const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`
const PageTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  font-family: ${PRIMARY_FONT}
  margin: 0;
  @media (max-width: 992px) {
    font-size: 18px;
  }
`

const ScanButton = styled(props => <Button {...props} />)`
  display: none !important;
  margin-left: 20px;
  @media (max-width: 992px) {
    display: flex !important;
  }
`
const AddContainer = styled.div`
  padding: 140px;
  padding-top: 90px;
  @media (max-width: 992px) {
    font-size: 18px;
    padding: 10px;
    padding-top: 90px;
  }
`
const FormCard = styled((props: NativeButtonProps) => <Card {...props} hoverable/>)`
   padding: 0px;
   margin: 10px !important;
   .ant-card-body{
     padding: 20px;
   }
`
const BarcodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  page-break-after:always;
  p {
    margin-bottom: 0px;
  }
  @media print {
    width: 100%;
    height: 100vh;
    padding: 40px;
    p {
      font-size: 2em;
      text-align: center;
      margin-bottom: 0px;
    }
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MobileBar = styled.div`
  display: none !important;
  @media (max-width: 992px) {
    display: flex !important;
    position: fixed;
    border-top: 1px solid #e2e2e2;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    z-index: 999999;
    height: 100px;
    right: 0px;
    background: white;
  }
`
const MobileContainer = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`
const MobileBarText = styled.p`
  margin-bottom: 0px;
`
const TitleContainer = styled.div`
  display: flex;
`

const ProductImage = styled.img`
  max-width: 100px;
  height: auto;
  object-fit: cover;
  @media (max-width: 992px) {
    max-width: 50px;

  }
`
const Logo = styled.img`
  width: auto;
  max-height: 100px;
  resize-mode: contain;
`
const SortableItemContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-radius: 6px;
  margin: 5px auto;
  padding: 10px;
  height: 70px;
`
const Label = styled.h2`
  font-size: 18px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
`
const QRCodeImage = styled.img`
  width: 100%;
  height: 200px;
  @media print {
    height: 70%;
  }
`
const DashboardCSVCard = styled.div`
  .react-grid-Container {
    width: 100% !important;
  }
`
export {
  DashboardContainer,
  SyncContainer,
  SyncText,
  LandingPageContainer,
  TabContainer,
  TabPaneContainer,
  LaunchContainer,
  Title,
  Image,
  PageTitle,
  FormCard,
  AddContainer,
  SubTitle,
  BarcodeContainer,
  LoadingContainer,
  ScanButton,
  MobileBar,
  MobileContainer,
  MobileBarText,
  TitleContainer,
  ProductImage,
  Logo,
  SortableItemContainer,
  Label,
  QRCodeImage,
  DashboardCSVCard
};
