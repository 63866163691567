import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";

import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Card,
  Empty,
  Button,
  Modal
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';

class RefreshShopifyCSV extends React.Component {

  confirm() {
    Modal.confirm({
     title: 'Confirm',
     content: 'Please confirm you have successfuly uploaded the CSV into Shopify, we will now turn on inventory to be pushed in.',
     onOk: () => this.props.updateUser({ shopifyRefreshed: true, shopifyInventoryPushOn: true }),
     onOkText: 'Confirm'
   });
  }

  render() {
    const {
      shopifyCSV,
      loadingShopifyCSV
    } = this.props.auth;
      return (
        <DashboardContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Refresh Shopify CSV</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              this.props.button || null

            ]}
          />
          <Card>
            <Empty
              image={require('../../images/shopify.svg')}
              imageStyle={{
                 height: 60,
               }}
              description=''
            >
              <h2>Refresh Your Shopify Products</h2>
              <p>Convert to Frontflip Data to Shopify CSV</p>
              {
                shopifyCSV === '' ?
                <Button
                  type='primary'
                  onClick={() => this.props.getShopifyCSV()}
                  loading={loadingShopifyCSV}
                >
                  Convert to CSV
                </Button> :
                <Button
                  type='primary'
                >
                  <CSVLink
                    data={shopifyCSV}
                    filename={"shopify.csv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              }
              <br />
              <br />
              {
                shopifyCSV !== '' ?

                <Button
                  type='danger'
                  onClick={() => this.confirm()}
                >
                  Confirm that Shopify Has Successfully Refreshed
                </Button> : null
              }

            </Empty>
          </Card>

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        fee: state.FeeReducer,
        productTemplate: state.ProductTemplateReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RefreshShopifyCSV));
