import {
    CREATE_LEDGER_LOADING,
  } from "../actions/types"
  
  let defaultState = {
    creatingLedger: false,
  };
  
  export default (state = defaultState, action) => {
      switch (action.type) {
          case CREATE_LEDGER_LOADING:
            return {
              ...state,
              creatingLedger: action.creatingLedger
            };
          default:
              return state;
      }
  };
  