import {
  GET_CATALOGS,
  GET_CATALOGS_LOADING,
  SUBSCRIBE_CATALOG_LOADING,
  UNSUBSCRIBE_CATALOG_LOADING
} from "../actions/types"

let defaultState = {
  loadingCatalogs: false,
  catalogs: [],
  subscribingCatalog: false,
  unsubscribingCatalog: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_CATALOGS:
          return {
            ...state,
            loadingCatalogs: action.loadingCatalogs,
            catalogs: action.catalogs
          };
        case GET_CATALOGS_LOADING:
          return {
            ...state,
            loadingCatalogs: action.loadingCatalogs,
          };
        case SUBSCRIBE_CATALOG_LOADING:
          return {
            ...state,
            subscribingCatalog: action.subscribingCatalog
          }
        case UNSUBSCRIBE_CATALOG_LOADING:
          return {
            ...state,
            unsubscribingCatalog: action.unsubscribingCatalog
          }
        default:
            return state;
    }
};
