import React from 'react';
import queryString from 'query-string';
//components
import { 
    Card,
    Steps,
    Button,
    message
} from 'antd';
import GettingStarted from './gettingStarted';
import ConnectShopify from './connectStore';
import UpdateData from './updateDate';
//Redux imports
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Redirect
} from 'react-router-dom';
import * as actions from '../../redux/actions';
//styles
import {
    DashboardContainer,
} from '../styles';
const { Step } = Steps;



const steps = [
    {
        title: 'Getting Started',
        content: ({updateState, company, logo, uploadImage}) => 
            <GettingStarted 
                updateState={(key, value) => updateState(key, value)}
                uploadImage={(key) => uploadImage(key)}
                logo={logo}
                company={company}
            />,
    },
    {
        title: 'Connect Store',
        content: () => <ConnectShopify />
    },
    {
        title: 'Update Data',
        content: ({ uploadImage, csv, csvFileName, updateState, acceptedTerms }) => 
            <UpdateData 
                updateState={(key, value) => updateState(key, value)}
                uploadCSV={(key) => uploadImage(key)}
                csv={csv}
                csvFileName={csvFileName}
                acceptedTerms={acceptedTerms}
            />,
    }
]
class Onboarding extends React.Component {
    constructor(props) {
        super(props);
        const { userData } = this.props.auth;

        const parsed = queryString.parse(this.props.location.search);
        const { current } = parsed;

        this.state = {
          current: current || 0,
          company: userData.company || '',
          logo: userData.logo || '',
          acceptedTerms: false
        };
    }
    
    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    updateState(key, value) {
        console.log('update state')
        console.log({key, value})
        this.setState({
          [key]: value
        })
    }
    uploadImage(key) {
        window.cloudinary.openUploadWidget({
            cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
            if (result && result.event === "success") {
                //Get Image uploaded
                console.log(result.info);
                const { secure_url, original_filename } = result.info;
                //Add images to state
                this.setState({
                    [key]: secure_url,
                    [`${key}FileName`]: original_filename
                })
            }
        });
    }
    render() {
        const { 
            current,
            logo,
            company,
            csv,
            csvFileName,
            acceptedTerms
        } = this.state;
        const { userData } = this.props.auth;
        if(userData.didOnboard || userData.onboardingData ) {
            return <Redirect to='dashboard' />
        }
        return (
            <DashboardContainer>
                <Card>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">
                        {
                            steps[current].content({
                                updateState: (key, value) => this.updateState(key, value), 
                                uploadImage: (key) => this.uploadImage(key), 
                                company, 
                                logo,
                                csv,
                                csvFileName,
                                acceptedTerms
                            })
                        }
                        </div>
                    <div className="steps-action">
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                            Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            Done
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                            Previous
                            </Button>
                        )}
                    </div>
                </Card>
            </DashboardContainer>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Onboarding));