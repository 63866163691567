import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API,
} from '../../../constants/API';
import {
  CREATE_INVENTORY_LOADING
} from '../types';


function createInventory(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_INVENTORY_LOADING,
        creatingInventory: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id,
          lastChangedSource: userData.employee.id,
          lastChangedModel: 'Employee',
          lastChangedName: userData.employee.name
        };
        const inventoryResponse = await axios.post(INVENTORY_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(inventoryResponse)
        const { id } = inventoryResponse.data

        dispatch({
          type: CREATE_INVENTORY_LOADING,
          creatingInventory: false
        });
        return id;
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_INVENTORY_LOADING,
          creatingInventory: false
        });
        console.log(e);
      }
  }
}
export default createInventory;
