import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCT_RESTRICTIONS_API,
} from '../../../constants/API';
import {
  DELETE_PRODUCT_RESTRICTION_LOADING
} from '../types';
import getProductRestrictions from './getProductRestrictions';

function deleteProductRestriction(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: DELETE_PRODUCT_RESTRICTION_LOADING,
        deletingProductRestrictions: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const hide = message.loading('Deleting ProductRestriction', 0);

        await axios.delete(`${PRODUCT_RESTRICTIONS_API}/${id}`, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: DELETE_PRODUCT_RESTRICTION_LOADING,
          deletingProductRestrictions: false
        });
        hide();
        await dispatch(getProductRestrictions());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: DELETE_PRODUCT_RESTRICTION_LOADING,
          deletingProductRestrictions: false
        });
        console.log(e);
      }
  }
}
export default deleteProductRestriction;
