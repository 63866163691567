import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import Places from './widget';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

const PlacesInput = ({ updateState, value, query, queryKey, disabled }) => {
  return (
    <InstantSearch
      searchClient={searchClient}
    >
      <Places
        updateState={updateState}
        defaultRefinement={{
          lat: 37.7793,
          lng: -122.419
        }}
        query={query}
        queryKey={queryKey}
        data-test='PlacesInput'
        disabled={disabled}

      />
    </InstantSearch>
  )
}

export default PlacesInput;
