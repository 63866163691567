import React from 'react';
import {
  Form,
  InputNumber,
  Radio
} from 'antd';

class ConsignerFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPercentageFee: true
    }
  }

  render() {

    const { isPercentageFee } = this.state;
    const { updateState, values } = this.props;
    const {
      fee,
      feeType,
    } = values;
    return (
      <div>
          <Form.Item>
            <Radio.Group
              defaultValue={feeType === '$' ? false : true}
              onChange={
                (e) => {
                  this.setState({ isPercentageFee: e.target.value})
                  if(e.target.value) {
                    updateState('feeType', '%')
                  } else {
                    updateState('feeType', '$')
                  }
                }
              }
            >
              <Radio.Button value={true}>%</Radio.Button>
              <Radio.Button value={false}>$</Radio.Button>
            </Radio.Group>
            {
              isPercentageFee ?
                <InputNumber
                  defaultValue={20}
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  style={{ width: 200 }}
                  onChange={(value) => updateState('fee', value)}
                  value={fee}
                />
                :
                <InputNumber
                  defaultValue={1000}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: 200 }}
                  onChange={(value) => updateState('fee', value)}
                  value={fee}
                />
            }
          </Form.Item>
      </div>
    );
  }
}
export default ConsignerFee;
