import React from 'react';
import { Empty } from 'antd';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  Image,
  CloseIcon,
  ImageContainer,
  Container,
  Handle
} from './styles';

const DragHandle = sortableHandle(() => <Handle>::</Handle>);

const SortableItem = SortableElement(({image, itemIndex, onDelete, isEditable}) => {
  return (
    <ImageContainer>
      {isEditable ? <DragHandle /> : null}
      <Image
        src={image}
        alt={image}
      />
      {
        isEditable ?
        <CloseIcon
          type='close-circle'
          onClick={() => onDelete(itemIndex)}
        /> : null
      }
    </ImageContainer>
  )
})

const SortableList = SortableContainer(({images, onDelete, disabled}) => {

  return (
    <Container>
      {images.map((image, index) => (
        <SortableItem
          key={`item-${image}`}
          image={image}
          itemIndex={index}
          index={index}
          onDelete={onDelete}
          isEditable={!disabled}
          disabled={disabled}
        />
      ))}
    </Container>
  )
})

const PhotoUploader = ({images, onDelete, title, disabled, onImageSort}) => {
  const onSortEnd = ({oldIndex, newIndex}) => {
    onImageSort(oldIndex, newIndex)
  };

  
  if(images && images.length === 0) {
    return (
      <Empty description={title} />
    )
  }

  return (
    <SortableList
      axis="x"
      useDragHandle
      images={images}
      onSortEnd={onSortEnd}
      onDelete={onDelete}
      disabled={disabled}
    />
  )
}

export default PhotoUploader;
