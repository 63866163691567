import React from 'react';
import { Editors } from "react-data-grid-addons";
const { DropDownEditor } = Editors;

export default (templates) => {
  console.log(templates)
  return {
    products: [
      { key: 'title', name: 'Title' },
      { key: 'category', name: 'Category' },
      { key: 'brand', name: 'Brand' },
      { key: 'sku', name: 'SKU'},
      {
        key: 'template',
        name: 'Templates',
        editor: <DropDownEditor options={templates} />
      }
    ],
    consigners: [
      { key: 'name', name: 'Display Name', editable: true  },
      { key: 'firstName', name: 'First Name', editable: true  },
      { key: 'lastName', name: 'Last Name', editable: true  },
      { key: 'email', name: 'Email', editable: true },
      { key: 'password', name: 'Temporary Password', editable: true }
    ]
  }
}
