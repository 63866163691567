import {
  GET_ANALYTICS,
  GET_ANALYTICS_LOADING,
} from "../actions/types"

let defaultState = {
  loadingAnalytics: false,
  analytics: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_ANALYTICS:
          return {
            ...state,
            loadingAnalytics: action.loadingAnalytics,
            analytics: action.analytics
          };
        case GET_ANALYTICS_LOADING:
          return {
            ...state,
            loadingAnalytics: action.loadingAnalytics,
          };
        default:
            return state;
    }
};
