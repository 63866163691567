import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import CSVEditor from '../../components/ui/CSVEditor';
import CSVcolumns from '../../constants/CSVcolumns';
import history from '../../navigation/history';
import validateRows from '../../helpers/data/validateRows';

import {
  PageHeader,
  Card,
  Button,
  message
} from 'antd'
import {
  DashboardContainer,
  PageTitle,
  DashboardCSVCard
} from '../styles';


class EditProducts extends React.Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    const { productTemplates } = userData;
    const templates = productTemplates.map(template => { return {id: template.id, value: template.title}})
    const columns = CSVcolumns(templates).products
    this.state = {
      columns,
      rows: []
    }
  }
  componentWillMount() {
    this.props.getProducts();
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { productsData } = this.props;
    const newProducts = productsData.products;
    //Old Inventory Item
    const oldProducts = oldProps.productsData.products;
    if(newProducts) {
      if(oldProducts !== newProducts) {
        this.setState({ rows: newProducts });
      }
    }
  }
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(state => {
      const rows = state.rows.slice();
      if(updated.template) {
        const { userData } = this.props.auth;
        const { productTemplates } = userData;
        const template = productTemplates.find(obj => {
          return obj.title === updated.template
        })
        updated.productTemplateId = template.id;
      }
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    });
  };
  async next() {
    const { rows } = this.state;
    const columnsToValidate = ['productTemplateId', 'sku'];
    await this.props.bulkUpsertProducts(this.state.rows)
    const isValidated = validateRows(rows, columnsToValidate);
    if(isValidated) {
      history.push('/onboarding/mergeDuplicateProducts')
    } else {
      message.error('Please fill sku and template id for all products');
    }
  }
  render() {
    const {
      bulkUpsertingProducts
    } = this.props.productsData;
    return (
      <DashboardContainer>
        <PageHeader
          title={<PageTitle>Attach Templates To Products</PageTitle>}
          style={{ background: 'transparent', marginBottom: 10 }}
          extra={[
            <Button
              onClick={() => this.props.bulkUpsertProducts(this.state.rows)}
              loading={bulkUpsertingProducts}
            >
              Save
            </Button>
          ]}
        />

        <DashboardCSVCard>
          <CSVEditor
            columns={this.state.columns}
            rows={this.state.rows}
            onGridRowsUpdated={(data) => this.onGridRowsUpdated(data)}
            inDashboard
          />
        </DashboardCSVCard>

      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProducts));
