import {
  SHOPIFY
} from '../../constants/Integrations';

export default function selectIntegrationUrl(data) {
  //Selects the url to send the integration install to, takes in the data of the integration form, user id and integration id
  const {
    fieldValues,
    integrationId,
    userId
  } = data;
  console.log(SHOPIFY, integrationId);
  switch (integrationId) {
    case SHOPIFY:
      return `https://frontflip-server.herokuapp.com/shopify?shop=${fieldValues['Shop Url']}&user=${userId}`;
    default:
      return `/integrations`;
  }
}
