import axios from 'axios';
import { message } from 'antd';
import {
  EMPLOYEE_API,
} from '../../../constants/API';
import {
  UPDATE_EMPLOYEE_LOADING
} from '../types';


function updateEmployee(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_EMPLOYEE_LOADING,
        updatingEmployee: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        console.log(accessToken)
        await axios.put(`${EMPLOYEE_API}/${data.id}`, data, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_EMPLOYEE_LOADING,
          updatingEmployee: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_EMPLOYEE_LOADING,
          updatingEmployee: false
        });
        console.log(e);
      }
  }
}
export default updateEmployee;
