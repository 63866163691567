export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPLOAD_IMAGE_LOADING = 'UPLOAD_IMAGE_LOADING';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const CREATE_MEMBERSHIP_LOADING = 'CREATE_MEMBERSHIP_LOADING';
export const EDITING_CARD_LOADING = 'EDITING_CARD_LOADING';
export const CANCEL_MEMBERSHIP_LOADING = 'CANCEL_MEMBERSHIP_LOADING';
export const RENEW_MEMBERSHIP_LOADING = 'RENEW_MEMBERSHIP_LOADING';
export const UPDATE_MEMBERSHIP_LOADING = 'UPDATE_MEMBERSHIP_LOADING';
export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_LOADING = 'GET_INVENTORY_LOADING';
export const CREATE_INVENTORY_LOADING = 'CREATE_INVENTORY_LOADING';
export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM';
export const GET_INVENTORY_ITEM_LOADING = 'GET_INVENTORY_ITEM_LOADING';
export const UPDATE_INVENTORY_LOADING = 'UPDATE_INVENTORY_LOADING';
export const CREATE_CONSIGNER_LOADING = 'CREATE_CONSIGNER_LOADING';
export const GET_CONSIGNER_ITEM_LOADING = 'GET_CONSIGNER_ITEM_LOADING';
export const GET_CONSIGNER_ITEM = 'GET_CONSIGNER_ITEM';
export const UPDATE_CONSIGNER_LOADING = 'UPDATE_CONSIGNER_LOADING';
export const CREATE_CUSTOMER_LOADING = 'CREATE_CUSTOMER_LOADING';
export const GET_CUSTOMER_ITEM_LOADING = 'GET_CUSTOMER_ITEM_LOADING';
export const GET_CUSTOMER_ITEM = 'GET_CUSTOMER_ITEM';
export const UPDATE_CUSTOMER_LOADING = 'UPDATE_CUSTOMER_LOADING';
export const CREATE_DISCOUNT_LOADING = 'CREATE_DISCOUNT_LOADING';
export const GET_DISCOUNT_ITEM_LOADING = 'GET_DISCOUNT_ITEM_LOADING';
export const GET_DISCOUNT_ITEM = 'GET_DISCOUNT_ITEM';
export const UPDATE_DISCOUNT_LOADING = 'UPDATE_DISCOUNT_LOADING';
export const CREATE_EMPLOYEE_LOADING = 'CREATE_EMPLOYEE_LOADING';
export const GET_EMPLOYEE_ITEM_LOADING = 'GET_EMPLOYEE_ITEM_LOADING';
export const GET_EMPLOYEE_ITEM = 'GET_EMPLOYEE_ITEM';
export const UPDATE_EMPLOYEE_LOADING = 'UPDATE_EMPLOYEE_LOADING';
export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING';
export const GET_TAXES_LOADING = 'GET_TAXES_LOADING';
export const GET_TAXES = 'GET_TAXES';
export const CREATE_TAX_LOADING = 'CREATE_TAX_LOADING';
export const GET_ORDER_ITEM = 'GET_ORDER_ITEM';
export const GET_ORDER_ITEM_LOADING = 'GET_ORDER_ITEM_LOADING';
export const UPDATE_ORDER_LOADING = 'UPDATE_ORDER_LOADING';
export const UPDATE_REFUND_LOADING = 'UPDATE_REFUND_LOADING';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_LOADING = 'GET_LOCATIONS_LOADING';
export const CREATE_LOCATION_LOADING = 'CREATE_LOCATION_LOADING';
export const DELETE_LOCATION_LOADING = 'DELETE_LOCATION_LOADING';
export const GET_ANALYTICS_LOADING = 'GET_ANALYTICS_LOADING';
export const GET_ANALYTICS = 'GET_ANALYTICS';
export const GET_CONNECTION = 'GET_CONNECTION';
export const GET_CONNECTION_LOADING = 'GET_CONNECTION_LOADING';
export const GET_INTEGRATION = 'GET_INTEGRATION';
export const GET_INTEGRATION_LOADING = 'GET_INTEGRATION_LOADING';
export const CREATE_INTEGRATION_LOADING = 'CREATE_INTEGRATION_LOADING';
export const CREATE_CONNECTION_LOADING = 'CREATE_CONNECTION_LOADING';
export const CREATE_PRODUCT_LOADING = 'CREATE_PRODUCT_LOADING';
export const GET_PRODUCT_ITEM = 'GET_PRODUCT_ITEM';
export const GET_PRODUCT_ITEM_LOADING = 'GET_PRODUCT_ITEM_LOADING';
export const UPDATE_PRODUCT_LOADING = 'UPDATE_PRODUCT_LOADING';
export const CREATE_PAYOUT_LOADING = 'CREATE_PAYOUT_LOADING';
export const GET_INVENTORY_FOR_FILTER = 'GET_INVENTORY_FOR_FILTER';
export const SEND_EMPLOYEE_INVITE = 'SEND_EMPLOYEE_INVITE';
export const SETUP_SHOPIFY_ONBOARDING = 'SETUP_SHOPIFY_ONBOARDING';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_LOADING = 'GET_APPOINTMENTS_LOADING'
export const CREATE_FEE_LOADING = 'CREATE_FEE_LOADING';
export const DELETE_FEE_LOADING = 'DELETE_FEE_LOADING';
export const GET_FEES_LOADING = 'GET_FEES_LOADING';
export const GET_FEES = 'GET_FEES'
export const GET_APPOINTMENT_RULES_LOADING = 'GET_APPOINTMENT_RULES_LOADING';
export const GET_APPOINTMENT_RULES = 'GET_APPOINTMENT_RULES';
export const DELETE_APPOINTMENT_RULE_LOADING = 'DELETE_APPOINTMENT_RULE_LOADING';
export const CREATE_APPOINTMENT_RULE_LOADING = 'CREATE_APPOINTMENT_RULE_LOADING';
export const GET_APPOINTMENT_TIMES_LOADING = 'GET_APPOINTMENT_TIMES_LOADING';
export const GET_APPOINTMENT_TIMES = 'GET_APPOINTMENT_TIMES';
export const DELETE_APPOINTMENT_TIME_LOADING = 'DELETE_APPOINTMENT_TIME_LOADING';
export const CREATE_APPOINTMENT_TIME_LOADING = 'CREATE_APPOINTMENT_TIME_LOADING';
export const UPDATE_LOCATION_LOADING = 'UPDATE_LOCATION_LOADING';
export const GET_LOCATION_ITEM = 'GET_LOCATION_ITEM';
export const GET_LOCATION_ITEM_LOADING = 'GET_LOCATION_ITEM_LOADING';
export const SEND_INVENTORY_INVITE = 'SEND_INVENTORY_INVITE';
export const GET_USERS_SHIPPING_CARRIERS = 'GET_USERS_SHIPPING_CARRIERS';
export const GET_USERS_SHIPPING_CARRIERS_LOADING = 'GET_USERS_SHIPPING_CARRIERS_LOADING';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const GET_CATALOGS = 'GET_CATALOGS';
export const GET_CATALOGS_LOADING = 'GET_CATALOGS_LOADING';
export const SUBSCRIBE_CATALOG_LOADING = 'SUBSCRIBE_CATALOG_LOADING'
export const UNSUBSCRIBE_CATALOG_LOADING = 'UNSUBSCRIBE_CATALOG_LOADING';
export const GET_INVENTORY_PAYOUT_LOADING = 'GET_INVENTORY_PAYOUT_LOADING';
export const GET_INVENTORY_PAYOUT = 'GET_INVENTORY_PAYOUT';
export const GET_ORDER_FOR_FILTER = 'GET_ORDER_FOR_FILTER';
export const SAVE_FILTER = 'SAVE_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CREATE_PRODUCT_RESTRICTION_LOADING = 'CREATE_PRODUCT_RESTRICTION_LOADING';
export const  DELETE_PRODUCT_RESTRICTION_LOADING = 'DELETE_PRODUCT_RESTRICTION_LOADING';
export const GET_PRODUCT_RESTRICTIONS_LOADING = 'GET_PRODUCT_RESTRICTIONS_LOADING';
export const GET_PRODUCT_RESTRICTIONS = 'GET_PRODUCT_RESTRICTIONS'
export const SEND_WITHDRAWAL_TRANSFERED = 'SEND_WITHDRAWAL_TRANSFERED';
export const CREATE_PRODUCT_TEMPLATE_LOADING = 'CREATE_PRODUCT_TEMPLATE_LOADING'
export const GET_PRODUCT_TEMPLATES_LOADING = 'GET_PRODUCT_TEMPLATES_LOADING';
export const GET_PRODUCT_TEMPLATES = 'GET_PRODUCT_TEMPLATES';
export const DELETE_PRODUCT_TEMPLATE_LOADING = 'DELETE_PRODUCT_TEMPLATE_LOADING';
export const UPDATE_PRODUCT_TEMPLATE_LOADING = 'UPDATE_PRODUCT_TEMPLATE_LOADING';
export const MERGE_PRODUCT_LOADING = 'MERGE_PRODUCT_LOADING';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const BULK_UPSERT_PRODUCTS_LOADING = 'BULK_UPSERT_PRODUCTS_LOADING';
export const GET_PRODUCTS_DUPLICATE_SKUS_LOADING = 'GET_PRODUCTS_DUPLICATE_SKUS_LOADING';
export const GET_PRODUCTS_DUPLICATE_SKUS = 'GET_PRODUCTS_DUPLICATE_SKUS';
export const GET_CONSIGNERS_LOADING = 'GET_CONSIGNERS_LOADING';
export const GET_CONSIGNERS = 'GET_CONSIGNERS';
export const BULK_UPSERT_CONSIGNERS_LOADING = 'BULK_UPSERT_CONSIGNERS_LOADING';
export const GET_SHOPIFY_CSV_LOADING = 'GET_SHOPIFY_CSV_LOADING';
export const GET_SHOPIFY_CSV = 'GET_SHOPIFY_CSV';
export const SEARCH_PRODUCTS_LOADING = 'SEARCH_PRODUCTS_LOADING';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_STOCKX_PRODUCTS = 'SEARCH_STOCKX_PRODUCTS';
export const SEARCH_CONSIGNERS_LOADING = 'SEARCH_CONSIGNERS_LOADING';
export const SEARCH_CONSIGNERS = 'SEARCH_CONSIGNERS';
export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS';
export const SEARCH_CUSTOMERS_LOADING = 'SEARCH_CUSTOMERS_LOADING';
export const SEARCH_EMPLOYEES_LOADING = 'SEARCH_EMPLOYEES_LOADING';
export const SEARCH_EMPLOYEES = 'SEARCH_EMPLOYEES';
export const GET_PRODUCT_ATTRIBUTES_LOADING = 'GET_PRODUCT_ATTRIBUTES_LOADING';
export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES';
export const BULK_UPSERTING_INVENTORY= 'BULK_UPSERTING_INVENTORY';
export const UPDATE_USER_SUBDOMAIN_LOADING = 'UPDATE_USER_SUBDOMAIN_LOADING';
export const GET_STORE_LOADING = 'GET_STORE_LOADING';
export const GET_STORE = 'GET_STORE';
export const SET_PRODUCTS_LAST_QUERY = 'SET_PRODUCTS_LAST_QUERY';
export const SET_INVETORY_FILTER = 'SET_INVETORY_FILTER';
export const TURN_PRODUCT_RETAIL_LOADING = 'TURN_PRODUCT_RETAIL_LOADING';
export const TURN_PRODUCT_CONSIGNMENT_LOADING = 'TURN_PRODUCT_CONSIGNMENT_LOADING';
export const GET_ONBOARDING_CSV_LOADING = 'GET_ONBOARDING_CSV_LOADING';
export const UPLOAD_ONBOARDING_CSV_LOADING = 'UPLOAD_ONBOARDING_CSV_LOADING';
export const SET_CSV_LOADING = 'SET_CSV_LOADING';
export const SET_CSV_UPLOADING = 'SET_CSV_UPLOADING';
export const CHECK_REAUTH_LOADING = 'CHECK_REAUTH_LOADING';
export const CHECK_REAUTH = 'CHECK_REAUTH';
export const RESYNC_PRODUCT_INVENTORY_LOADING = 'RESYNC_PRODUCT_INVENTORY_LOADING';
export const GET_CONSIGNER_LEDGER = 'GET_CONSIGNER_LEDGER';
export const GET_CONSIGNER_LEDGER_LOADING = 'GET_CONSIGNER_LEDGER_LOADING';
export const CREATE_LEDGER_LOADING = 'CREATE_LEDGER_LOADING';