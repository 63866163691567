import {
  CREATE_ORDER_LOADING,
  GET_ORDER_ITEM,
  GET_ORDER_ITEM_LOADING,
  UPDATE_ORDER_LOADING,
  UPDATE_REFUND_LOADING,
  GET_ORDER_FOR_FILTER
} from '../actions/types';

const defaultState = {
  creatingOrder: false,
  loadingOrderItem: false,
  orderItem: null,
  upatingOrder: false,
  creatingRefund: false,
  filteringOrder: false,
  filteredOrder: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_ORDER_LOADING:
          return {
            ...state,
            creatingOrder: action.creatingOrder
          };
        case GET_ORDER_ITEM:
          return {
            ...state,
            loadingOrderItem: action.loadingOrderItem,
            orderItem: action.orderItem
          }
        case GET_ORDER_ITEM_LOADING:
          return {
            ...state,
            loadingOrderItem: action.loadingOrderItem
          }
        case UPDATE_ORDER_LOADING:
          return {
            ...state,
            updatingOrder: action.updatingOrder
          }
        case UPDATE_REFUND_LOADING:
          return {
            ...state,
            creatingRefund: action.creatingRefund
          }
        case GET_ORDER_FOR_FILTER:
          return {
            ...state,
            filteringOrder: action.filteringOrder,
            filteredOrder: action.filteredOrder
          }
        default:
            return state;
    }
};
