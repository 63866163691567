import React from 'react';
import {
  Image
} from './styles';
import logoImage from '../../../images/logo.png';
function Logo(props) {
  const {
    logo
  } = props
  return (
      <Image
        src={logo || logoImage}
      />
  )
}
Logo.displayName = 'Logo';

export default Logo;
