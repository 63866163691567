import axios from 'axios';
import {
  CONNECTIONS_API
} from '../../../constants/API';
import {
  GET_CONNECTION,
  GET_CONNECTION_LOADING
} from '../types';

function loadConnections() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CONNECTION_LOADING,
      loadingConnection: true,
      connections: []
    });
    const {
      userData
    } = getState().AuthReducer;
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const getConnectionResponse =
        await axios.get(
          `${CONNECTIONS_API}/${userData.id}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
      if (!getConnectionResponse.data.isError) {
        const connectionsData = getConnectionResponse.data;
        console.log(connectionsData);
        dispatch({
          type: GET_CONNECTION,
          connections: connectionsData,
          loadingConnection: false
        });
      } else {
        console.log(getConnectionResponse);
        dispatch({
          type: GET_CONNECTION_LOADING,
          loadingConnection: false,
          connections: []
        });
      }
    } catch (e) {
      dispatch({
        type: GET_CONNECTION_LOADING,
        loadingConnection: false,
        connections: []
      });
    }
  };
}

export default loadConnections;
