import {
  CREATE_CUSTOMER_LOADING,
  GET_CUSTOMER_ITEM,
  GET_CUSTOMER_ITEM_LOADING,
  UPDATE_CUSTOMER_LOADING,
  SEARCH_CUSTOMERS,
  SEARCH_CUSTOMERS_LOADING
} from "../actions/types"

let defaultState = {
  creatingCustomer: false,
  loadingCustomerItem: false,
  customerItem: null,
  updatingCustomer: false,
  loadingSearchCustomers: false,
  searchCustomers: [],
  searchCustomersCount: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_CUSTOMER_LOADING:
          return {
            ...state,
            creatingCustomer: action.creatingCustomer
          };
        case GET_CUSTOMER_ITEM:
          return {
            ...state,
            loadingCustomerItem: action.loadingCustomerItem,
            customerItem: action.customerItem
          };
        case GET_CUSTOMER_ITEM_LOADING:
          return {
            ...state,
            loadingCustomerItem: action.loadingCustomerItem
          };
        case UPDATE_CUSTOMER_LOADING:
          return {
            ...state,
            updatingCustomer: action.updatingCustomer
          }
        case SEARCH_CUSTOMERS_LOADING:
          return {
            ...state,
            loadingSearchCustomers: action.loadingSearchCustomers,
          }
        case SEARCH_CUSTOMERS:
          return {
            ...state,
            loadingSearchCustomers: action.loadingSearchCustomers,
            searchCustomers: action.searchCustomers,
            searchCustomersCount: action.searchCustomersCount
          }
        default:
            return state;
    }
};
