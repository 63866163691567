import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  TURN_PRODUCT_RETAIL_LOADING
} from '../types';


function turningProductRetail(id) {
  return async(dispatch) => {
      dispatch({
        type: TURN_PRODUCT_RETAIL_LOADING,
        turningProductRetail: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await axios.post(`${PRODUCTS_API}/setProductAsRetail/${id}`, {}, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        dispatch({
          type: TURN_PRODUCT_RETAIL_LOADING,
          turningProductRetail: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: TURN_PRODUCT_RETAIL_LOADING,
          turningProductRetail: false
        });
        console.log(e);
      }
  }
}
export default turningProductRetail;
