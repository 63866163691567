import {
  SETUP_SHOPIFY_ONBOARDING
} from '../types';

function setAuthState(shop, shopifyAccessToken) {
  return async (dispatch, getState) => {
    dispatch({
      type: SETUP_SHOPIFY_ONBOARDING,
      shop,
      shopifyAccessToken
    });
  };
}
export default setAuthState;
