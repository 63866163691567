import React from 'react';
import ReactDataGrid from 'react-data-grid';

function CSVEditor(props) {
  const {
    rows,
    columns,
    onGridRowsUpdated,
    inDashboard
  } = props;
  return (
    <ReactDataGrid
      columns={columns}
      rowGetter={i => rows[i]}
      rowsCount={rows.length}
      minHeight={'90vh'}
      onGridRowsUpdated={onGridRowsUpdated}
      enableCellSelect={true}
    />
  )
}

export default CSVEditor;
