import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API
} from '../../../constants/API';

function getInventoryItem(shortCode) {
  return async (dispatch, getState) => {
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const inventoryItemResponse = await axios.get(
        `${INVENTORY_API}/shortCode/${shortCode}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(inventoryItemResponse.data)
      if(inventoryItemResponse.data.isError) {
        console.log(inventoryItemResponse.data.errorMessage);
        message.error(String(inventoryItemResponse.data.errorMessage));
        return null
      }
      return inventoryItemResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      return null
    }
  };
}
export default getInventoryItem;
