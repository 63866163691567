import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';

import {
  Col,
  Empty
} from 'antd';
import {
  withRouter,
  Link
} from 'react-router-dom';
import moment from 'moment';
import ConsignerName from '../../components/ui/forms/consigners/ConsignerName';
import ConsignerContact from '../../components/ui/forms/consigners/ConsignerContact';
import ConsignerFee from '../../components/ui/forms/consigners/ConsignerFee';
import ConsignerStatus from '../../components/ui/forms/consigners/ConsignerStatus';
import ConsignerCustomerAccount from '../../components/ui/forms/consigners/ConsignerCustomerAccount';
import ConsignerStats from '../../components/ui/forms/consigners/ConsignerStats';
import ConsignerInventory from '../../components/ui/forms/consigners/ConsignerInventory';
import ConsignerPayouts from '../../components/ui/forms/consigners/ConsignerPayouts';
import ConsignerTimeline from '../../components/ui/forms/consigners/ConsignerTimeline';

import FileUploader from '../../components/ui/FileUploader';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  AddContainer,
  FormCard
} from '../styles';

class ViewConsigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      tags: [],
      notes: '',
      locationName: '',
      locationId: null,
      status: '',
      name: '',
      birthday: moment(),
      fee: '20',
      feeType: '%',
      email: '',
      phone: '',
      contracts: [],
      customerId: null,
      customerQuery: '',
      customerName: '',
      locationQuery: '',
      addressQuery: '',
      payoutModalVisible: false,
      selectedMethod: { objectID: '' },
      payoutEmail: '',
      payoutName: '',
      payoutAmount: '',
      firstName: '',
      lastName: '',
      verificationId: '',
      address: {},
      editMode: false
    }
  }
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getConsignerItem(id);
    this.props.loadConnections();
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { consignerData } = this.props;
    const newConsignerItem = consignerData.consignerItem;
    //Old Inventory Item
    const oldConsignerItem = oldProps.consignerData.consignerItem;
    const {
      id
    } = this.props.auth.userData
    if(newConsignerItem) {
      if(oldConsignerItem !== newConsignerItem) {
        let address = '';
        try {
          address = newConsignerItem.address ? JSON.parse(CryptoJS.AES.decrypt(newConsignerItem.address, id).toString(CryptoJS.enc.Utf8)) : '';
        } catch (error) {
          console.error('=== Address error ===', error);
          console.log( '=== Address ===', newConsignerItem.address)
        }
        this.setState({
          ...newConsignerItem,
          contracts: newConsignerItem.contracts || [],
          locationQuery: newConsignerItem.locationName,
          customerQuery: newConsignerItem.customerName,
          address,
          addressQuery: address ? address.name : '',
          payoutEmail: newConsignerItem.email,
          payoutName: newConsignerItem.name,
          payoutAmount: newConsignerItem.balance,
          verificationId: newConsignerItem.verificationId ?  CryptoJS.AES.decrypt(newConsignerItem.verificationId, id).toString(CryptoJS.enc.Utf8) : '',
          firstName: newConsignerItem.firstName ? CryptoJS.AES.decrypt(newConsignerItem.firstName, id).toString(CryptoJS.enc.Utf8) : '',
          lastName: newConsignerItem.lastName ? CryptoJS.AES.decrypt(newConsignerItem.lastName, id).toString(CryptoJS.enc.Utf8) : '',
          birthday: newConsignerItem.birthday ? moment(CryptoJS.AES.decrypt(newConsignerItem.birthday, id).toString(CryptoJS.enc.Utf8)) : '',
          phone: newConsignerItem.phone ? CryptoJS.AES.decrypt(newConsignerItem.phone, id).toString(CryptoJS.enc.Utf8) : '',
        })
      }
    }
  }
  handlePayoutModal() {
    const { payoutModalVisible } = this.state;
    this.setState({
      payoutModalVisible: !payoutModalVisible
    })
  }
  uploadImage() {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            console.log(result)
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add contracts to state
            this.setState({
              contracts: [...this.state.contracts, {url: secure_url}]
            })
        }
    });
  }
  deleteImage(index) {
    //State data
    const contracts = this.state.contracts;
    //Remove index
    contracts.splice(index, 1);
    this.setState({ contracts });
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }

  async updateConsigner() {
    const { updateConsigner } = this.props;
    await updateConsigner(this.state);
    window.location.reload();
  }

  async createPayout() {
    const {
      payoutName,
      payoutEmail,
      payoutAmount,
      selectedMethod,
      name,
      email,
      id
    } = this.state;

    const payoutData = {
      payoutName,
      payoutEmail,
      payoutAmount,
      integrationId: selectedMethod.objectID
    };

    const data = {
      payoutData,
      name,
      email,
      consignerId: id,
      amount: payoutAmount
    }

    await this.props.createPayout(data);
    window.location.reload();

  }

  cancelEdit() {
    const id = this.props.match.params.id
    this.props.getConsignerItem(id);
    this.props.loadConnections();
    this.setState({ editMode: false });
  }

  render() {
    const { contracts } = this.state;
    const {
      consignerData,
    } = this.props;
    const {
      loadingConnection,
      connections
    } = this.props.connection;
    const {
      creatingPayout
    } = this.props.payout;
    const {
      loadingConsignerItem,
      consignerItem,
      updatingConsigner
    } = consignerData;

    if(loadingConsignerItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!consignerItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const {
      inventoryData,
      payoutsData,
      timelineData,
      balance
    } = consignerItem;
    console.log(consignerItem);

      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>{consignerItem.name}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              !this.state.isStoreAccount ?
              <Button
                key="4"
                onClick={() => this.setState({ editMode: true })}
              >
                  <Link 
                    to={{ pathname: `/payout/consigners/${consignerItem.id}`, state: { consigner: consignerItem }}}
                  >
                    View Ledger 
                  </Link>
                  
              </Button> : null,
              this.state.editMode ?
              <Button
                key="1"
                onClick={() => this.cancelEdit()}
                loading={updatingConsigner}
              >
                Cancel
              </Button> : null,
              this.state.editMode ?
              <Button
                key="2"
                type="primary"
                onClick={() => this.updateConsigner()}
                loading={updatingConsigner}
              >
                Update
              </Button> : null,
              !this.state.editMode ?
              <Button
                key="3"
                type="primary"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button> : null,
              
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard onClick={() => history.push(`/inventory?consignerId=${consignerItem.id}&consignerName=${consignerItem.name}`)}>
                <ConsignerStats
                  values={this.state}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}
                />
              </FormCard>
              <FormCard>
                <ConsignerName
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.addressQuery}
                  queryKey='addressQuery'
                  updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
                  disabled={!this.state.editMode}
                />
              </FormCard>
              <FormCard
                title="Contact"
              >
                <ConsignerContact
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  disabled={!this.state.editMode}

                />
              </FormCard>
              <FormCard
                title="Inventory"
                extra={
                  <Button onClick={() => history.push(`/inventory?consignerId=${consignerItem.id}&consignerName=${consignerItem.name}`)}>
                  Filter
                  </Button>
                }
              >
                <ConsignerInventory
                  data={inventoryData}
                  hideCheckbox
                  compact
                />
              </FormCard>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard
                title="Status"
              >
                <ConsignerStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}

                />
              </FormCard>
              <FormCard
                title="Connect Customer Account"
              >
                <ConsignerCustomerAccount
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.customerQuery}
                  queryKey='customerQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}

                />
              </FormCard>
              {
                !this.state.isStoreAccount ?
                <FormCard
                  title="Consignment Contract"
                  extra={this.state.editMode ? <a onClick={() => this.uploadImage()}>Add New</a> : null}
                >
                  <FileUploader
                    data={contracts}
                    onDelete={(index) => this.deleteImage(index)}
                    title='Click edit and add new to upload consigners'
                    disabled={!this.state.editMode}
                  />
                </FormCard> : null
              }
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        consignerData: state.ConsignerReducer,
        connection: state.ConnectionReducer,
        payout: state.PayoutReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewConsigner));
