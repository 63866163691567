import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  Table,
  Input,
  Switch,
  InputNumber
} from 'antd';
import ConsignerSearchInput from '../ConsignerSearchInput';
import SelectDropdown from '../SelectDropdown';
import InventoryStatus from '../../../constants/InventoryStatus';

function BulkEditInventory(props) {
  const {
    data,
    loading,
    updateStateArrayKey,
    consigners,
    defaultCurrency
  } = props;

  const columns = [
    {
      title: 'Title',
      dataIndex: '',
      key: '',
      render: (item) =>
        <Link
          to={`/inventory/${item.id}`}
          target='_blank'
        >
        {item.title} {item.brand} {item.option1} {item.option2} {item.option3}
        </Link>
    },
    {
      title: 'Options',
      dataIndex: '',
      key: '',
      render: (item) => {  
        console.log(item)
        const option1 = item['Product.ProductTemplate.option1'];
        const option2 = item['Product.ProductTemplate.option2'];
        const option3 = item['Product.ProductTemplate.option3'];
        const option1FixedValues = item['Product.ProductTemplate.option1FixedValues'];
        const option2FixedValues = item['Product.ProductTemplate.option2FixedValues'];
        const option3FixedValues = item['Product.ProductTemplate.option3FixedValues'];
        const option1Switch = item['Product.ProductTemplate.option1Switch'];
        const option2Switch = item['Product.ProductTemplate.option2Switch'];
        const option3Switch = item['Product.ProductTemplate.option3Switch'];
        
        const optionFixedValues = {
          option1FixedValues,
          option2FixedValues,
          option3FixedValues
        };
        const optionSwitchs = [option1Switch, option2Switch, option3Switch];
        const options = [option1, option2, option3];
        const optionsComp = options.map((option, index) => {
          if(option && option !== '') {
            const optionSwitch = optionSwitchs[index];
            const optionFixedValue = Object.values(optionFixedValues)[index];
            if(optionSwitch !== 'false' && (optionSwitch || optionSwitch === 'true') ) {
              return (
                  <SelectDropdown
                    placeholder={option}
                    data={optionFixedValue}
                    onChange={(value) => updateStateArrayKey('bulkEditData',`option${index + 1}`, item.id, value)}
                    value={item[`option${index + 1}`]}
                  />
              )
            } else {
              return (
                  <Input
                    placeholder={option}
                    onChange={(e) => updateStateArrayKey('bulkEditData',`option${index + 1}`,item.id, e.target.value)}
                    value={item[`option${index + 1}`]}
                  />
              )
            }
      
          } else return null
        })
        return (
          <div>
          {optionsComp}
        </div>
        )
      }
    },
    {
      title: `Fee(${defaultCurrency.symbol})`,
      dataIndex: '',
      key: '',
      render: (item, record, index) =>
        <Input
          max='Infinity'
          type="number"
          value={item.dollarfee}
          placeholder='Enter Fee($)'
          onChange={(e) => updateStateArrayKey('bulkEditData', 'dollarfee', item.id, e.target.value)}
        />
    },
    {
      title: 'Fee(%)',
      dataIndex: '',
      key: '',
      render: (item, record, index) =>
        <InputNumber
          value={item.fee}
          placeholder='Enter Fee(%)'
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          onChange={(value) => updateStateArrayKey('bulkEditData', 'fee', item.id, value)}

        />
    },
    {
      title: 'Price',
      dataIndex: '',
      key: '',
      render: (item, record, index) =>
        <Input
          value={item.price}
          placeholder='Enter Price'
          max='Infinity'
          type="number"
          onChange={(e) => updateStateArrayKey('bulkEditData', 'price', item.id, e.target.value)}

        />
    },
    {
      title: 'Taxable',
      dataIndex: '',
      key: '',
      render: (item, record, index) =>
        <Switch
          checked={item.taxable}
          onChange={(checked) => updateStateArrayKey('bulkEditData', 'taxable', item.id, checked)}
        />
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (item, record, index) =>
        <SelectDropdown
          data={InventoryStatus}
          onChange={(value) => updateStateArrayKey('bulkEditData', 'status', item.id, value)}
          value={item.status}
        />
    },
    {
      title: 'Consigner',
      dataIndex: '',
      key: '',
      render: (item) =>
      <ConsignerSearchInput
        data={consigners}
        value={JSON.stringify({consignerName:item.consignerName,consignerId:item.consignerId,isStoreAccount:item.isStoreInventory})}
        onChange={async(value) => {
          const { consignerName, consignerId, isStoreAccount } = JSON.parse(value);
          await updateStateArrayKey('bulkEditData', 'consignerName', item.id, consignerName)
          await updateStateArrayKey('bulkEditData', 'consignerId', item.id, consignerId)
          await updateStateArrayKey('bulkEditData', 'isStoreInventory', item.id, isStoreAccount)
        }}
      />
    }
  ]
  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
    />
  )
}

export default BulkEditInventory;
