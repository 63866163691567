import React from 'react';
import {
  Form,
  Input,
  Button
} from 'antd';

class FormComponent extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      fields,
      handleSubmit,
      form,
      isLoading
    } = this.props;
    return (
      <Form onSubmit={(e) => handleSubmit(e, form)} className="login-form">
        {fields.map(field => {
          return (
            <Form.Item>
              {getFieldDecorator(field, {
                rules: [{ required: true, message: `Please input your ${field}` }],
              })(
                <Input placeholder={field} />
              )}
            </Form.Item>
          );
        })}
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Connect
        </Button>
      </Form>
    );
  }
}
const DynamicForm = Form.create({ name: 'addForm' })(FormComponent);
export default DynamicForm;
