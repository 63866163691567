import React from 'react';
import {
  SettingsCardContainer,
  Title,
  SubTitle,
  RightArrowIcon,
  TextContainer
} from './styles'
function SettingsCard(props) {
  const {
    data
  } = props;
  const {
    title,
    subTitle,
    onClick
  } = data;

  return (
    <SettingsCardContainer
      hoverable
      onClick={onClick}
    >
      <TextContainer>
        <Title>
          {title}
        </Title>
        <SubTitle>
          {subTitle}
        </SubTitle>
      </TextContainer>
      <RightArrowIcon type="arrow-right" />
    </SettingsCardContainer>
  );

}

export default SettingsCard;
