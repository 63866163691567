import axios from 'axios';
import {
  APPOINTMENT_RULES_API
} from '../../../constants/API';
import {
  GET_APPOINTMENT_RULES_LOADING,
  GET_APPOINTMENT_RULES
} from '../types';

function getAppointmentRules(locationId) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_APPOINTMENT_RULES,
      appointmentRules: [],
      loadingAppointmentRules: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const appointmentRulesResponse = await axios.get(
        `${APPOINTMENT_RULES_API}?userId=${userData.id}&locationId=${locationId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(appointmentRulesResponse)
      dispatch({
        type: GET_APPOINTMENT_RULES,
        loadingAppointmentRules: false,
        appointmentRules: appointmentRulesResponse.data ? appointmentRulesResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_APPOINTMENT_RULES,
        appointmentRules: [],
        loadingAppointmentRules: false
      });
    }
  };
}
export default getAppointmentRules;
