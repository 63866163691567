import auth0 from 'auth0-js';
import {
  message
} from 'antd';
const Auth0 = new auth0.WebAuth({
  // the following three lines MUST be updated
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  grant_type:"client_credentials",
  theme: {
    logo: 'https://example.com/logo.png',
    primaryColor: '#000'
  }
});

function changePassword(email) {
  return async(dispatch) => {
    try {
      await Auth0
      .changePassword({
        email,
        connection: "Username-Password-Authentication",
      }, function (err, resp) {
        if(err){
          message.error(err.message);
        }else{
          console.log(resp);
          message.success(resp);
        }
      })
    } catch (e) {
      console.log(e)
    }
  };
}

export default changePassword;
