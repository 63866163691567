import React from 'react';
import {
  Form,
  Input,
} from 'antd';

class EmployeeContact extends React.Component {

  render() {
    const {
      updateState,
      values,
      disabled,
      emailDisabled
    } = this.props;
    const {
      email,
      phone
    } = values;
    return (
      <div>
        <Form.Item label='Email'>
          <Input
            placeholder='Employee email'
            onChange={(e) => updateState('email', e.target.value)}
            value={email}
            disabled={emailDisabled || disabled}
          />
        </Form.Item>
        <Form.Item label='Phone' >
          <Input
            placeholder='Employee phone'
            onChange={(e) => updateState('phone', e.target.value)}
            value={phone}
            disabled={disabled}
          />
        </Form.Item>
      </div>
    );
  }
}
export default EmployeeContact;
