import axios from 'axios';
import { message } from 'antd';
import {
  LOCATIONS_API
} from '../../../constants/API';
import {
  GET_LOCATION_ITEM_LOADING,
  GET_LOCATION_ITEM
} from '../types';

function getLocationItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LOCATION_ITEM_LOADING,
      loadingLocationItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const locationItemResponse = await axios.get(
        `${LOCATIONS_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_LOCATION_ITEM,
        loadingLocationItem: false,
        locationItem: locationItemResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_LOCATION_ITEM_LOADING,
        loadingLocationItem: false
      });
    }
  };
}
export default getLocationItem;
