import React from 'react';
import {
  Modal,
  Form,
  Input
} from 'antd';
import DropdownSearch from '../../../algolia/DropdownSearch';
import SelectDropdown from '../../SelectDropdown';
import {
  Container,
  Title,
  SubTitle
} from './styles';

function SetLocation(props) {
  const {
    visible,
    handleOk,
    handleCancel,
    loading,
    title,
    updateState,
    userId,
    query,
    queryKey,
    dataKey,
    values,
    showInventory
  } = props;
  const {
    locationName,
    subLocationName,
    locationData,
    selectedInventory,
    notes
  } = values;
  return (
    <Modal
      title='Confirm'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={loading}
    >
      <Title>{title}</Title>
      {
        showInventory ?
        <Container>
          <SubTitle>
            Inventory: {selectedInventory.title} {selectedInventory.sku} {selectedInventory.option1} {selectedInventory.option2} {selectedInventory.option3}
          </SubTitle>
        </Container>
          : null
      }
      { !selectedInventory.isRemote ?
        <Form.Item label='Location'>
          <DropdownSearch
            searchPlaceholder='Search Locations'
            index='locations'
            keyName='name'
            updateState={updateState}
            stateKey='locationId'
            stateValue='objectID'
            stateKey2='locationName'
            stateValue2='name'
            value={locationName}
            query={query}
            queryKey={queryKey}
            userId={userId}
            dataKey={dataKey}

          />
          <SelectDropdown
            data={locationData && locationData.subLocations ? locationData.subLocations : []}
            onChange={(value) => updateState('subLocationName', value)}
            value={subLocationName}
            placeholder='Select Sub Location'
          />

        </Form.Item> : null
      }
      <Form.Item label='Notes' autoresize>
        <Input.TextArea
          placeholder='Notes'
          onChange={(e) => updateState('notes', e.target.value)}
          value={notes}
        />
      </Form.Item>
    </Modal>
  );
}
export default SetLocation;
