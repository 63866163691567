import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  Empty,
  Tag,
  message
} from 'antd';
import moment from 'moment';
import {
  withRouter
} from 'react-router-dom';
import EmployeeName from '../../components/ui/forms/employees/EmployeeName';
import EmployeeContact from '../../components/ui/forms/employees/EmployeeContact';
import EmployeeRestrictedRoutes from '../../components/ui/forms/employees/EmployeeRestrictedRoutes';
import EmployeeStatus from '../../components/ui/forms/employees/EmployeeStatus';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

import history from '../../navigation/history';
import RestrictedRoutes from '../../constants/RestrictedRoutes';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  AddContainer,
  FormCard,
  TitleContainer
} from '../styles';

class ViewEmployeeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      birthday: moment(),
      address: {},
      email: '',
      phone: '',
      fee: 20,
      feeType: '%',
      status: '',
      notes: '',
      tags: [],
      locationId: null,
      locationName: '',
      locationQuery: '',
      pin: 0,
      sales: 0,
      restrictedRoutes: [],
      editMode: false
    }
  }
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getEmployeeItem(id);
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { employeeData } = this.props;
    const newEmployeeData = employeeData.employeeItem;
    //Old Inventory Item
    const oldEmployeeItem = oldProps.employeeData.employeeItem;
    if(newEmployeeData) {
      if(oldEmployeeItem !== newEmployeeData) {
        this.setState({
          ...newEmployeeData,
          locationQuery: newEmployeeData.locationName,
          addressQuery: newEmployeeData.address && newEmployeeData.address.name
        })
      }
    }
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }

  async updateEmployee() {
    const {
      name,
      email
    } = this.state;
    if(name === '' || email === '') {
      message.error('Please enter a email and name')
    } else {
      const { updateEmployee } = this.props;
      await updateEmployee(this.state);
      window.location.reload(); //refresh screen
    }

 }

 cancelEdit() {
   const id = this.props.match.params.id
   this.props.getEmployeeItem(id);
   this.setState({ editMode: false });
 }
  render() {
    const { employeeData } = this.props;
    const { employeeItem, loadingEmployeeItem, sendingEmployeeInvite } = employeeData;
    if(loadingEmployeeItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!employeeItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const {
      name,
      auth0_id,
      email,
    } = employeeItem;
    const {
      company,
      id
    } = this.props.auth.userData;
      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>{name}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              auth0_id ?
                <Tag> Connected </Tag> :
                <Button
                  onClick={() => this.props.sendEmployeeInvite({
                    to: this.state.email,
                    company,
                    name: this.state.name,
                    employee: employeeItem.id,
                    store: id
                  })}
                  loading={sendingEmployeeInvite}
                >
                  Send Invite
                </Button>,
                this.state.editMode ?
                <Button
                  key="1"
                  onClick={() => this.cancelEdit()}
                  loading={employeeData.updatingEmployee}
                >
                  Cancel
                </Button> : null,
              this.state.editMode ?
              <Button
                key="2"
                type="primary"
                onClick={() => this.updateEmployee()}
                loading={employeeData.updatingEmployee}
              >
                Update
              </Button> : null,
              !this.state.editMode ?
              <Button
                key="3"
                type="primary"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button> : null,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard>
                <EmployeeName
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
                  disabled={!this.state.editMode}
                />

              </FormCard>
              <FormCard
                title='Contact'
              >
                <EmployeeContact
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  disabled={!this.state.editMode}
                  emailDisabled={auth0_id ? true : false}
                />

              </FormCard>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
            <FormCard title="Restricted Routes" >
              <EmployeeRestrictedRoutes
                values={this.state}
                updateState={(key, value) => this.updateState(key, value)}
                routes={RestrictedRoutes}
                disabled={!this.state.editMode}


              />
            </FormCard>
              <FormCard
                title="Status"
              >
                <EmployeeStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}


                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        employeeData: state.EmployeeReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewEmployeeItem));
