import styled from 'styled-components';
import { Icon } from 'antd'
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
`
const CloseIcon = styled(Icon)`
  position: absolute;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
`
const FileIcon = styled(Icon)`
font-size: 60px;
`
const Title = styled.p`
  margin-top: 20px;

`
export {
  CloseIcon,
  ImageContainer,
  Container,
  FileIcon,
  Title
}
