import React from 'react';
import {
  Select

} from 'antd';
const { Option } = Select;

function ConsignerSearchInput(props) {
  const {
    data,
    onChange,
    value
  } = props;
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Select Consigner"
      optionFilterProp="children"
      onChange={onChange}
      value={value}
      filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {
      data.map(consigner =>
        <Option
          value={JSON.stringify({consignerName:consigner.name,consignerId:consigner.id,isStoreAccount:consigner.isStoreAccount})}
          key={consigner.id}
        >
          {consigner.name}
        </Option>
      )
    }
  </Select>
  )
}
export default ConsignerSearchInput;
