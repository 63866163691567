import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Link
} from 'react-router-dom';
import {
  List,
  Checkbox,
  Tag,
  Table
} from 'antd';
import {
  Image,
  TextContainer,
  SubTitle,
  Container
} from './styles';
import OrderCartList from '../OrderCartList';
OrderFilterList.defaultProps = {
  updateState: (key, value) => console.log(key, value),
  selectedInventory: []
};

function OrderFilterList({
  data,
  loading,
  updateState,
  selectedInventory,
  hideCheckbox,
  compact,
  defaultCurrency
}) {
  let columns = [
    {
      title: 'Order',
      dataIndex: '',
      key: '',
      render: (data) => <Link to={`/orders/${data.id}`}>#{data.orderNumber}</Link>,
    },
    {
      title: 'Customer',
      dataIndex: '',
      key: '',
      render: (data) => <Link to={`/customers/${data.customerId}`}>{data.customerName}</Link>,
    },
    {
      title: 'Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Order Date',
      dataIndex: '',
      key: '',
      render: (data) => <SubTitle>{moment(data.createdAt).format('llll')}</SubTitle>,
    },
  ];
  if(compact || window.outerWidth < 992) {
    columns = [
      {
        title: 'Order',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
      },
      {
        title: 'Total',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ];
  }
  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      expandedRowRender={item => {
        const { refundIds, products } = item;
        return (
          <OrderCartList
            data={products}
            refundIds={refundIds}
            defaultCurrency={defaultCurrency}
          />
        )
      }}


    />
  );
}

export default OrderFilterList;
