import React from 'react';
import moment from 'moment-timezone';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import PayoutModal from '../../components/ui/modals/Payout';

import {
    DashboardContainer,
    PageTitle,
    FormCard
  } from '../styles';
import { 
    PageHeader,
    Table,
    Typography,
    Button,
    message,
    Input,
    Form,
    Modal
} from 'antd';
const { Title } = Typography;

class Ledger extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            payoutModalVisible: false,
            payoutData: null,
            showAdjustmentModal: false,
            payoutAmount: 0,
            selectedMethod: null,
            desc: this.props.auth.userData && this.props.auth.userData.employee &&  this.props.auth.userData.employee.name ? `${this.props.auth.userData.employee.name} adjustment` : 'Store adjustment'
        }
    }
    componentWillMount() {
        this.getLedgerData();
        this.props.loadConnections();
    }
    getLedgerData() {
        const id = this.props.match.params.id;
        this.props.getConsignerLedgerData(id);
    }
    getColumns() {
        return [
            {
                title: 'Date',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (createdAt, item) => <p>{moment(createdAt).format('lll')}</p>
            },
            {
                title: 'Desc',
                key: 'desc',
                dataIndex: 'desc',
            },
            {
                title: 'Change',
                key: 'credit',
                dataIndex: 'credit',
                render: (credit, item) => {
                    if(credit) {
                        return <p style={{ color: 'red' }}>-{this.props.auth.userData.defaultCurrency.symbol}{credit}</p>
                    }
                    return <p style={{ color: 'green' }}>+{this.props.auth.userData.defaultCurrency.symbol}{item.debit}</p>
                }
            }
        ]
    }
    handlePayoutModal() {
        this.setState({
            showPaymentModal: !this.state.showPaymentModal
        })
    }
    selectPayoutMethod(id, data) {
        this.setState({
            selectedMethod: id,
            payoutMethod: data
        })
    }
    
    async createPayout() {
        const { selectedMethod, payoutAmount, payoutMethod } = this.state;
        if(selectedMethod === null) {
          message.error('Please select a payout method');
        } else if(payoutAmount <= 0) {
            message.error('You must send more the $0 to create a payout');
        } else {
          const hide = message.loading(`Creating Payout`);
          const defaultCurrency = this.props.auth.userData.defaultCurrency.symbol;
          const data = {
            desc: this.props.auth.userData && this.props.auth.userData.employee &&  this.props.auth.userData.employee.name ? `Payout made by ${this.props.auth.userData.employee.name} of ${defaultCurrency}${payoutAmount} via ${payoutMethod.name}` : `Payout made of ${defaultCurrency}${payoutAmount} via ${payoutMethod.name}`,
            credit: payoutAmount,
            consignerId: this.props.match.params.id,
            selectedMethod
          }
          console.log(data)
          await this.props.createPayout(data);
          this.handlePayoutModal()
          this.getLedgerData()
          hide();
        }
    }
    async addAdjustment() {
        const { desc, credit, debit } = this.state;
        const id = this.props.match.params.id;
        await this.props.createLedger({
            desc,
            debit,
            credit,
            consignerId: id
        })
        this.handleAdjustmentModal();
        this.getLedgerData();
    } 
    handleAdjustmentModal() {
        this.setState({
            showAdjustmentModal: !this.state.showAdjustmentModal
        })
    }
    render() {
        const { loadingConsignerLedger, consignerLedgerData } = this.props.consignerData;
        const {
            creatingPayout
        } = this.props.payout;
        const {
            loadingConnection,
            connections
        } = this.props.connection;
        const { consigner } = this.props.location.state
        if(loadingConsignerLedger) return <DashboardContainer><LoadingSpinner /></DashboardContainer>
        return (
            <DashboardContainer>
                <PageHeader
                    title={
                        <Link to={`/consigners/${consigner.id}`}>
                            <PageTitle>{consigner.name} Ledger</PageTitle>
                        </Link>
                    }
                    breadcrumb={null}
                    style={{ background: 'transparent' }}
                    subTitle={`Available Balance: ${this.props.auth.userData.defaultCurrency.symbol}${consignerLedgerData.availableBalance}`}
                    extra={[
                        <Button 
                            key={0}
                            onClick={() => this.handleAdjustmentModal()}
                        >
                            Adjust Balance
                        </Button>,
                        <Button 
                            key={1} 
                            type='primary'
                            onClick={() => this.handlePayoutModal()}
                        >
                            Create Payout
                        </Button>
                    ]}
                />
                <FormCard>
                    <Table 
                        columns={this.getColumns()} 
                        dataSource={consignerLedgerData.ledgerData}
                        pagination={false}
                    />
                </FormCard>
                <Modal
                    title="Add adjustment"
                    visible={this.state.showAdjustmentModal}
                    onOk={() => this.addAdjustment()}
                    onCancel={() => this.handleAdjustmentModal()}
                >
                    <Form.Item label='Description'>
                        <Input
                            placeholder='Add Description Of Adjustment'
                            value={this.state.desc}
                            onChange={(e) => this.setState({ desc: e.target.value })}
                        />
                    </Form.Item>
                    <Form.Item label='Debit'>
                        <Input
                            type="number"
                            suffix={this.props.auth.userData.defaultCurrency.symbol}
                            onChange={(e) => this.setState({ debit: e.target.value, credit: null })}
                            value={this.state.debit}
                        />
                    </Form.Item>
                    <Form.Item label='Credit'>
                        <Input
                            type="number"
                            suffix={this.props.auth.userData.defaultCurrency.symbol}
                            onChange={(e) => this.setState({ credit: e.target.value, debit: null })}
                            value={this.state.credit}
                        />
                    </Form.Item>
                </Modal>

                <PayoutModal
                    visible={this.state.showPaymentModal}
                    handleCancel={() => this.handlePayoutModal()}
                    loadingConnection={loadingConnection}
                    connections={connections}
                    onConnect={() => history.push('/settings')}
                    selectedMethod={this.state.selectedMethod}
                    selectPayoutMethod={(id, data) => this.selectPayoutMethod(id, data)}
                    handleOk={() => this.createPayout()}
                    creatingPayout={creatingPayout}
                    defaultCurrency={this.props.auth.userData.defaultCurrency.symbol}
                    payoutAmount={this.state.payoutAmount}
                    updatePayoutAmount={(value) => this.setState({ payoutAmount: value})}
                    maxPayout={consignerLedgerData.availableBalance}
                    creditBonus={this.props.auth.userData.creditBonus}
                />
                
            </DashboardContainer>
        )
    }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        consignerData: state.ConsignerReducer,
        inventoryData: state.InventoryReducer,
        membership: state.MembershipReducer,
        productData: state.ProductReducer,
        connection: state.ConnectionReducer,
        payout: state.PayoutReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ledger));
