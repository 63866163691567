import React from 'react';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  DatePicker,
  List,
} from 'antd'
import DropdownSearch from '../../components/algolia/DropdownSearch';
import {
  DashboardContainer,
  PageTitle,
  FormCard,
  SubTitle
} from '../styles';

class Appointments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      locationName: '',
      locationId: null,
      locationQuery: '',
      datetime: moment()
    }
  }

  componentWillMount() {
    this.props.getAppointments({ datetime: moment() })
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
    if(key === 'locationId') {
      this.props.getAppointments({ [key]: value });
      this.setState({ datetime: null })
    }

    if(key === 'locationQuery' && value === '') {
      this.props.getAppointments({ locationId: null });
      this.setState({ datetime: null })

    }
  }

  onDateChange(datetime) {
    const { locationId } = this.state;
    this.setState({
      datetime
    })
    this.props.getAppointments({ datetime,locationId });

  }
  render() {
    const {
      appointments,
      loadingAppointments
    }  = this.props.appointments
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Appointments</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
            subTitle={
              <DropdownSearch
                searchPlaceholder='Search Locations'
                index='locations'
                keyName='name'
                updateState={(key, value) => this.updateState(key, value)}
                stateKey='locationId'
                stateValue='objectID'
                stateKey2='locationName'
                stateValue2='name'
                value={this.state.locationName}
                query={this.state.locationQuery}
                queryKey='locationQuery'
                userId={this.props.auth.userData.id}
                filters='AND enableAppointments:true'

              />
            }
            extra={[
              <DatePicker
                onChange={(date) => this.onDateChange(date)}
                value={this.state.datetime}
              />,
            ]}
          />
          <FormCard>
          <List
            dataSource={appointments}
            loading={loadingAppointments}
            renderItem={item => (
              <List.Item key={item.id}>
               <List.Item.Meta
                 title={
                   <SubTitle>
                   { moment(item.datetime).format('llll')} at {item.locationName} - {item.reason}
                   </SubTitle>
                 }
                 description={item.notes}
               />
               <Link to={`/consigners/${item.consignerId}`}>{item.consignerName}</Link>
             </List.Item>
            )}
          />
          </FormCard>

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        appointments: state.AppointmentReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointments));
