import React from 'react';
import {
  Redirect,
  withRouter
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

import LoadingSpinner from '../components/ui/LoadingSpinner';

class Callback extends React.Component {

  componentWillMount(){
    this.props.onCallback();
  }

  render() {
    const {
      isLoggedIn,
      isMember,
      shop,
      shopifyAccessToken
    } = this.props.auth;

    if(isLoggedIn && isMember) {
      if(shop && shopifyAccessToken) {
        //if shopify redirects
        return <Redirect to={`/shopify?shop=${shop}&accessToken=${shopifyAccessToken}`} />
      }
      return <Redirect to='/' />
    } else if (isLoggedIn) {
      if(shop && shopifyAccessToken) {
        //if shopify redirects
        return <Redirect to={`/membership?shop=${shop}&accessToken=${shopifyAccessToken}`} />
      }
      return <Redirect to='/membership' />
    } else
    return (
        <LoadingSpinner />
    );
  }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Callback));
