import React from 'react';
import { Empty } from 'antd';
import LoadingSpinner from '../../../LoadingSpinner';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove,
  Edit
} from './styles';

class SettingsProductRestrictions extends React.Component {

  render() {
    const {
      productRestrictions,
      deleteProductRestriction,
      handleAddProductRestrictionsModal
    } = this.props;
    if(!productRestrictions || productRestrictions.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddProductRestrictionsModal()}
            >
              Add Selling Restrictions
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        productRestrictions.map((item) => {
          return (
            <ItemContainer>
              <Title>
              {item.type} equals {item.trigger}
              </Title>
              <SubTitle>
              {item.restrictionType} equals {item.restrictionValue}
              </SubTitle>
              <Remove
                onClick={() => {
                  deleteProductRestriction(item.id)
                }}
              >
                REMOVE
              </Remove>

            </ItemContainer>
          )
        })
      }
      <a
        onClick={() => handleAddProductRestrictionsModal()}
      >
        Add Selling Restrictions
      </a>
      </Container>
    );
  }
}
export default SettingsProductRestrictions;
