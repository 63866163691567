import React from 'react';
import {
  Icon
} from 'antd';
import {
  Container,
  Title,
  IconContainer
} from './styles';
import {
  PRIMARY_COLOR
} from '../../../../constants/Colors';

const SortBy = ({ updateState, selectedOrder, title, upIndex, downIndex }) => (
  <Container>
    <Title
      onClick={event => {
        event.preventDefault();
        const nextOrder = selectedOrder === upIndex ? downIndex : upIndex;
        updateState('selectedOrder', nextOrder);
      }}
    >
      {title}
    </Title>
    <IconContainer>
        <Icon
          style={{
            color: selectedOrder === upIndex ? PRIMARY_COLOR : '#bfbfbf',
            fontSize: 11,
            marginBottom: '-0.18em'
          }}
          onClick={event => {
            event.preventDefault();
            updateState('selectedOrder', upIndex);
          }}
          type="caret-up"
        />
        <Icon
          type="caret-down"
          style={{
            color: selectedOrder === downIndex ? PRIMARY_COLOR : '#bfbfbf',
            fontSize: 11,
            marginTop: '-0.18em'
          }}
          onClick={event => {
            event.preventDefault();
            updateState('selectedOrder', downIndex);
          }}
        />
    </IconContainer>

  </Container>
);

export default SortBy;
