import {
  GET_PRODUCT_RESTRICTIONS_LOADING,
  GET_PRODUCT_RESTRICTIONS,
  CREATE_PRODUCT_RESTRICTION_LOADING,
  DELETE_PRODUCT_RESTRICTION_LOADING,
} from '../actions/types';

const defaultState = {
  loadingProductRestrictions: false,
  productRestrictions: [],
  creatingProductRestrictions: false,
  deletingProductRestrictions: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_PRODUCT_RESTRICTIONS_LOADING:
          return {
            ...state,
            loadingProductRestrictions: action.loadingProductRestrictions
          };
        case GET_PRODUCT_RESTRICTIONS:
          return {
            ...state,
            loadingProductRestrictions: action.loadingProductRestrictions,
            productRestrictions: action.productRestrictions
          };
        case CREATE_PRODUCT_RESTRICTION_LOADING:
          return {
            ...state,
            creatingProductRestrictions: action.creatingProductRestrictions
          }
        case DELETE_PRODUCT_RESTRICTION_LOADING:
          return {
            ...state,
            deletingProductRestrictions: action.deletingProductRestrictions
          }


        default:
            return state;
    }
};
