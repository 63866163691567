import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import {
  CloseIcon,
  ImageContainer,
  Container,
  FileIcon,
  Title
} from './styles';

FileUploader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string
  })),
  onDelete: PropTypes.func,
  title: PropTypes.string
};

function FileUploader(props) {
  const {
    data,
    onDelete,
    title,
    disabled
  } = props;
  if(!data) {
    return null;
  }
  if(data.length === 0) {
      return (
        <Empty
          description={title}
          data-test='FileUploaderEmptyContainer'
        />
      )
  }
  return (
    <Container
      data-test='FileUploaderContainer'
    >
    {
      data.map((data, index) => {
        let { url } = data;
        url = url.substring(url.lastIndexOf('/')+1);
        return (
          <ImageContainer
            data-test='FileUploaderContainerItem'
            key={index}
          >
            <FileIcon
              onClick={() => window.open(data.url)}
              type="file"
              data-test='FileUploaderItemIcon'
            />
            <Title
              data-test='FileUploaderItemTitle'
            >
            {url}
            </Title>
            {!disabled ? <CloseIcon
              type='close-circle'
              onClick={() => onDelete(index)}
              data-test='FileUploaderItemCloseIcon'
            /> : null}
          </ImageContainer>
        )
      })
    }
    </Container>
  )
}

export default FileUploader;
