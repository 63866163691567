import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API,
} from '../../../constants/API';
import {
  UPDATE_USER_SUBDOMAIN_LOADING
} from '../types';


function updateUserSubdomain(type, url) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_USER_SUBDOMAIN_LOADING,
        updatingUserSubdomain: true
      });
      try {
        const hide = message.loading('Updating custom domains');
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          url
        };
        console.log(putData)
        await axios.put(`${USERS_API}/updateSubdomain/${userData.id}/${type}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_USER_SUBDOMAIN_LOADING,
          updatingUserSubdomain: false
        });
        hide()
        window.location.reload();
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_USER_SUBDOMAIN_LOADING,
          updatingUserSubdomain: false
        });
        console.log(e);
      }
  }
}
export default updateUserSubdomain;
