import styled from 'styled-components';
import {
  Button
} from 'antd';
import {
  PRIMARY_COLOR
} from '../../../../constants/Colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: #ededed;
    background-color: #f3f3f3;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
`

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
`
export const Image = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`
export const Category = styled.p`
  font-size: 16px;
`
export const Active = styled.p`
  font-size: 12px;
  color: ${PRIMARY_COLOR}
`

export const ConnectButton = styled(Button)`

`
export const UnsubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
`
