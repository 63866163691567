import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  List,
  Tag
} from 'antd';
import {
  Container,
  Image,
  TextContainer,
  SubTitle,
  ActionContainer,
} from './styles';
import InventoryContentList from '../InventoryContentList';
OrderCardList.defaultProps = {
  refundedIds: []
};
function OrderCardList({
  data,
  refundedIds,
  defaultCurrency

}) {
  const { symbol } = defaultCurrency
  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        pageSize: 8,
      }}
      dataSource={data}
      renderItem={(item, index) => (
        <Container>
          <Container>
            <TextContainer>
              <Link to={`/inventory/${item.id}`}>{item.title} {item.productSku}</Link>
              <SubTitle>
              {item.brand} {item.option1} {item.option2} {item.option3} {item.shortCode}
              </SubTitle>
              <SubTitle>
              {item.locationName} {item.subLocationName}
              </SubTitle>
              <Link
                to={`/consigners/${item.consignerId}`}
                target='_blank'
              >
                Consigner: {item.consignerName}
              </Link>
              <InventoryContentList
                data={item.productContents}
              />
            </TextContainer>
          </Container>
          <ActionContainer>
            <SubTitle>{symbol}{item.price}</SubTitle>
            {
              refundedIds.includes(item.id) ?
                <Tag
                  color="red"
                  style={{ marginRight: 0, marginTop: 5}}
                >
                  Refunded
                </Tag> : null
            }
          </ActionContainer>
        </Container>
      )}
    />
  )
}

export default OrderCardList;
