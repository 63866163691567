import styled from 'styled-components';

const SwitchContainers = styled.div`
  display: flex;
  align-items: center;
`
const SwitchTitle = styled.p`
  margin-bottom: 0px;
  margin-right: 10px;
`
export {
  SwitchContainers,
  SwitchTitle
}
