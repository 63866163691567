import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import * as actions from '../../redux/actions';
import {
  Empty,
  Button,
  Card,
  List,
} from 'antd'
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import doesExistInArrayOfObj from '../../helpers/data/doesExistInArrayOfObj';
import history from '../../navigation/history';
import { SHOPIFY } from '../../constants/Integrations';
import ConnectStoreRoutes from '../../constants/ConnectStoreRoutes';
const Container = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`
class ConnectProducts extends React.Component {

  componentWillMount() {
    this.props.loadConnections();
  }

  render() {
    const {
      loadingConnection,
      connections
    } = this.props.connection;

    if(loadingConnection) {
      return <LoadingSpinner />
    }
    const connection = doesExistInArrayOfObj(connections, SHOPIFY);
    const { userData } = this.props.auth;
    return (
      <Container>
        {
          connection ?
            <Empty
              image={require('../../images/shopify.svg')}
              imageStyle={{
                 height: 60,
               }}
              description=''
            >
              <h2>Shopify Connected</h2>
              <p>Product, variant, orders, inventory, customers are synced.</p>
            </Empty>
            :
            <Empty
              image={require('../../images/shopify.svg')}
              description=''
              imageStyle={{
                 height: 60,
               }}
            >

              <h2>Do you have a Shopify Account?</h2>
              <p>Connect Shopify to pull in product and variant, and sync product, variant orders, inventory, customers are synced.</p>
              <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={ConnectStoreRoutes}
                renderItem={item => (
                  <List.Item >
                    <Card
                      hoverable
                      onClick={() => window.open(item.link(userData.email, userData.company))}
                    >
                        {item.title}
                    </Card>
                  </List.Item>
               )}
              />
            </Empty>
        }
      </Container>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectProducts));
