import React from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker
} from 'antd';
import DropdownSearch from '../../../../algolia/DropdownSearch';

class InventoryName extends React.Component {
  render() {
    const {
      updateState,
      values,
      query,
      queryKey,
      userId,
      onProductClick
    } = this.props;
    const {
      title,
      receivedAt,
      description
    } = values;
    return (
      <div>
          <Form.Item label='Title' >
            {/*  <DropdownSearch
                searchPlaceholder='Type Product Name'
                index='inventory'
                keyName='title'
                updateState={updateState}
                stateKey='title'
                stateValue='title'
                value={title}
                query={query}
                queryKey={queryKey}
                userId
              />*/}
              <div onClick={onProductClick}>
                <Input
                  placeholder='Title'
                  disabled
                  value={title}
                />
              </div>
          </Form.Item>
      </div>
    );
  }
}
export default InventoryName;
