import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import CatalogList from '../../components/algolia/CatalogList';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Modal,
  message
} from 'antd';
import {
  DashboardContainer,
  PageTitle
} from '../styles';
class Catalog extends React.Component {
  unsubscribeCatalog(catalog) {
    const { unsubscribeCatalog } = this.props;

    Modal.confirm({
      title: `Unsubscribe from the ${catalog.title} Catalog`,
      content: 'Are sure you want unsubscribe? This will hide this catalog from the consigner products?',
      async onOk() {
        const hide = message.loading(`Unsubscribing...`, 0);
        await unsubscribeCatalog(catalog.objectID)
        hide();
        window.location.reload()

      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  subscribeCatalog(catalog) {
    const { subscribeCatalog } = this.props;

    Modal.confirm({
      title: `Subscribe to the ${catalog.title} Catalog`,
      content: 'Are sure you want subscribe? This will let consigners view products from the sneaker catalog to sell',
      async onOk() {
        const hide = message.loading(`Subscribing...`, 0);
        await subscribeCatalog(catalog.objectID)
        hide();
        window.location.reload()
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  render() {
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Catalogs</PageTitle>}
          breadcrumb={null}
          style={{ background: 'transparent' }}
        />
        <CatalogList
          subscribedCatalogIds={this.props.auth.userData.subscribedCatalogIds || []}
          onUnsubcribePress={(catalog) => this.unsubscribeCatalog(catalog)}
          onSubscribePress={(catalog) => this.subscribeCatalog(catalog)}
        />

      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalog));
