import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API
} from '../../../constants/API';
import {
  SEARCH_PRODUCTS_LOADING,
  SEARCH_PRODUCTS
} from '../types';

function searchProducts(query, page, order, searchCatalog = true) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_PRODUCTS_LOADING,
      loadingSearchProducts: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const catalogIds = searchCatalog ? userData.subscribedCatalogIds : [];
      const searchProductResponse = await axios.post(
        `${PRODUCTS_API}/search/${userData.id}`,
        { query, catalogIds, page, order },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(searchProductResponse)
      dispatch({
        type: SEARCH_PRODUCTS,
        loadingSearchProducts: false,
        searchProducts: !searchProductResponse.data.isError ? searchProductResponse.data.results : [],
        searchProductsCount: !searchProductResponse.data.isError ? searchProductResponse.data.count : 0,
      });
      return searchProductResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: SEARCH_PRODUCTS_LOADING,
        loadingSearchProducts: false
      });
    }
  };
}
export default searchProducts;
