import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Empty,
  Button,
  Card,
  Row,
  Col,
  message
} from 'antd'
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import doesExistInArrayOfObj from '../../helpers/data/doesExistInArrayOfObj';
import history from '../../navigation/history';
import { SHOPIFY } from '../../constants/Integrations';
import ShopifyIntegrationInstructions from '../../constants/ShopifyIntegrationInstructions';
import {
  DashboardContainer,
  PageTitle
} from '../styles';


class AddInventory extends React.Component {

  componentWillMount() {
    this.props.loadConnections();
  }

  render() {
    const {
      loadingConnection,
      connections
    } = this.props.connection;

    if(loadingConnection) {
      return <LoadingSpinner />
    }
    const connection = doesExistInArrayOfObj(connections, SHOPIFY);
    return (
      <DashboardContainer>
        <PageHeader
          title={<PageTitle>Add Inventory</PageTitle>}
          style={{ background: 'transparent', marginBottom: 10 }}
          extra={[
            !connection ?
              <Button onClick={() => {
                message.info('If you have already connected, your data may still be importing please try again in a few minutes.')
                this.props.loadConnections()
              }}>Refresh</Button>
            : null
          ]}
        />

        <Row gutter={16}>
          <Col span={12}>
            <Card title='New Shopify Store or okay to delete variants?'>
              <p>Add inventory via Frontflip manually and have it push into your store as you add.</p>
              <Button
                type='primary'
                onClick={() => {
                  this.props.updateUser({ shopifyRefreshed: true })
                  history.push('/onboarding/finalStep')
                }}
                loading={this.props.auth.updatingUser}
              >
              Select Option 1
              </Button>
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Existing Shopify Store?'>
              <p>Add inventory via Frontflip manually and download a csv to overwrite your current Shopify Variants.</p>
              <Button
                type='primary'
                onClick={() => {
                  this.props.updateUser({ shopifyRefreshed: false })
                  history.push('/onboarding/finalStep')
                }}
                loading={this.props.auth.updatingUser}
              >
                Select Option 2
              </Button>
            </Card>
          </Col>
        </Row>



      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddInventory));
