import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  withRouter,
} from 'react-router-dom';
import history from '../../navigation/history';
import BarcodeReader from 'react-barcode-reader';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button,
  message
} from 'antd'
import IncomingInventoryList from '../../components/ui/IncomingInventoryList';
import InventorySearchFilter from '../../components/ui/InventorySearchFilter';
import ProductSortBy from '../../constants/ProductSortBy';

import {
  DashboardContainer,
  PageTitle,
  FormCard,
  ScanButton
} from '../styles';


class Inventory extends React.Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    const {
      consignerName,
      consignerId,
      productId
    } = parsed;
    if(productId) {
      this.props.getProduct(productId);
    }
    this.state = {
      id: null || productId,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      inventoryFilterVisible: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: consignerName || '',
      filterConsignerId: consignerId || null,
      filterConsignerQuery: consignerName || '',
      productQuery: '',
      title: '',
      filterStatus: 'Active',
      filterShortCode: '',
      selectedInventory: {},
      bulkEditVisible: false,
      bulkEditKey: '',
      bulkEditKeyValue: '',
      filterReceivedAt: null,
      filterOrderDate: null,
      selectedSortBy: 0,
      filterTags: '',
      selectedMethod: { objectID: '' },
      sortByOptions: [
        {
          label: 'Order Date (Old to New)',
          value: [['orderDate', 'ASC']]
        },
        {
          label: 'Order Date (New to Old)',
          value: [['orderDate', 'DESC']]
        },
        {
          label: 'Consigner',
          value: [['consignerName', 'ASC']]
        },
      ],
      
    }
  }
  componentDidMount() {
    this.loadInventory();
    this.props.loadConnections()
    this.props.getProducts()
  }
  componentDidUpdate(oldProps) {
    //New Product Item
    const { productData } = this.props;
    const newProductItem = productData.productItem;
    //Old Product Item
    const oldProductItem = oldProps.productData.productItem;
    if(newProductItem) {
      if(oldProductItem !== newProductItem) {
        this.setState({
          ...newProductItem,
          locationQuery: newProductItem.locationName,
          consignerQuery: newProductItem.consignerName,
          productQuery: newProductItem.title
        })
      }
    }
  }
  handleInventoryFilter() {
    this.setState({
      inventoryFilterVisible: !this.state.inventoryFilterVisible
    })
  }
  handleBulkEdit() {
    this.setState({
      bulkEditVisible: !this.state.bulkEditVisible
    })
  }
  updateState(key, value) {
    if(key === 'id') {
      this.props.getProduct(value);
    }
    this.setState({
      [key]: value
    })
  }
  loadInventory() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      filterShortCode,
      filterReceivedAt,
      filterOrderDate,
      selectedSortBy,
      filterTags,
      sortByOptions,
      isRemote,
      filterDateRange,
      filterDateRangeKey
    } = this.state;
    console.log(filterLocationQuery)
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      status: null,
      shortCode: filterShortCode !== '' ? filterShortCode : null,
      receivedAt: filterReceivedAt !== '' ? filterReceivedAt : null,
      orderDate: filterOrderDate !== '' ? filterOrderDate : null,
      sortBy: selectedSortBy !== '' &&  sortByOptions[selectedSortBy] ? sortByOptions[selectedSortBy].value : null,
      tags: filterTags !== '' ? filterTags.split(',') : null,
      isRemote: isRemote !== '' ? isRemote : null,
      dateRangeKey: filterDateRangeKey !== '' ? filterDateRangeKey : null,
      dateRange: filterDateRange !== '' ? filterDateRange : null,
      equalAcceptedSales: true,
      isPaid: false,
      isStoreInventory: false
    }
    this.props.getInventoryForFilter(data);
    this.setState({ inventoryFilterVisible: false });
    console.log(data)
  }
  clearProduct() {
    this.setState({
      id: null,
      productQuery: '',
      title: '',
      option1: null,
      option2: null,
      option3: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
    });
  }

  
  async searchProducts(value, callback) {
    await this.props.searchProducts(value, 1, ProductSortBy[0], false)
    const { searchProducts } = this.props.productData;
    return searchProducts.map(obj => {return {
      value: obj.id,
      label: obj.title
    }})
  }

  async setAsAvailablePayout() {
    const { selectedInventory } = this.state;
    console.log(selectedInventory)
    const amountOfInventory = selectedInventory.length;
    const hide = message.loading('Marking inventory, please wait...', 0);
    for(let i = 0; i < amountOfInventory; i++) {
      const inventory = selectedInventory[i];
      await this.props.updateInventory({ ...inventory, status: 'Available Payout' });
      await this.props.createLedger({
            desc: `Sold ${inventory.title} ${inventory.option1} ${inventory.option2} ${inventory.option3} Order #${inventory.orderNumber}`,
            debit: (inventory.price - (inventory.price * (inventory.fee/100)) - inventory.dollarfee).toFixed(2),
            credit: null,
            consignerId: inventory.consignerId
      })
    }
    hide();
    this.loadInventory();
  }


  render() {
    const {
      filteredInventory,
      filteringInventory
    } = this.props.inventoryData;
    
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Inventory Ready For Payout</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
            extra={[
              <Button onClick={() => this.setAsAvailablePayout()}>Mark As Available</Button>
            ]}
          />
          <FormCard
          >
            <InventorySearchFilter
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              loadInventory={() => this.loadInventory()}
              query={this.state.filterLocationQuery}
              queryKey='filterLocationQuery'
              query2={this.state.filterConsignerQuery}
              queryKey2='filterConsignerQuery'
              query3={this.state.productQuery}
              queryKey3='productQuery'
              userId={this.props.auth.userData.id}
              canSelectProduct
              clearProduct={() => this.clearProduct()}
              disableStatus
              disableWithdrawalLocation
              userData={this.props.auth.userData}
              products={this.props.productData.searchProducts}
              loadingProducts={this.props.productData.loadingSearchProducts}
              onSearchProducts={(value) => this.searchProducts(value)}

            />
            <IncomingInventoryList
              userData={this.props.auth.userData}
              data={filteredInventory}
              loading={filteringInventory}
              updateState={(key, value) => this.updateState(key, value)}
              selectedInventory={this.state.selectedInventory}
              disableWithdrawalLocationName
              disableLocationName
              disableCreatedOnColumn
              showCheckbox
            />
          </FormCard>
          <BarcodeReader
            onScan={(data) => history.push(`inventory/${data}`)}
          />
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
        productData: state.ProductReducer,
        connection: state.ConnectionReducer,
        payout: state.PayoutReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory));
