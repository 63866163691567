import {
  CREATE_MEMBERSHIP_LOADING,
  EDITING_CARD_LOADING,
  CANCEL_MEMBERSHIP_LOADING,
  RENEW_MEMBERSHIP_LOADING,
  UPDATE_MEMBERSHIP_LOADING,
} from '../actions/types';

const defaultState = {
  creatingMembership: false,
  editingCard: false,
  cancellingMembership: false,
  renewingMembership: false,
  updatingMembership: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_MEMBERSHIP_LOADING:
          return {
            ...state,
            creatingMembership: action.creatingMembership
          };
        case EDITING_CARD_LOADING:
          return {
            ...state,
            editingCard: action.editingCard
          };
        case CANCEL_MEMBERSHIP_LOADING:
          return {
            ...state,
            cancellingMembership: action.cancellingMembership
          };
        case RENEW_MEMBERSHIP_LOADING:
          return {
            ...state,
            renewingMembership: action.renewingMembership
          };
        case UPDATE_MEMBERSHIP_LOADING:
          return {
            ...state,
            updatingMembership: action.updatingMembership
          };
        default:
            return state;
    }
};
