import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API
} from '../../../constants/API';
import {
  UPLOAD_ONBOARDING_CSV_LOADING,
} from '../types';

function uploadOnboardingCSV(csv) {
  return async (dispatch, getState) => {
    dispatch({
      type: UPLOAD_ONBOARDING_CSV_LOADING,
      uploadingOnboardingCSV: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      await axios.post(
        `${USERS_API}/uploadOnboardingCSV`,
        { csv, userId: userData.id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: UPLOAD_ONBOARDING_CSV_LOADING,
        uploadingOnboardingCSV: false,
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: UPLOAD_ONBOARDING_CSV_LOADING,
        uploadingOnboardingCSV: false
      });
    }
  };
}
export default uploadOnboardingCSV;
