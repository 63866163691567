import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API
} from '../../../constants/API';
import {
  GET_INVENTORY_PAYOUT_LOADING,
  GET_INVENTORY_PAYOUT
} from '../types';

function getInventoryPayout(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INVENTORY_PAYOUT_LOADING,
      loadingInventoryPayout: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const inventoryPayoutResponse = await axios.get(
        `${INVENTORY_API}/payout/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_INVENTORY_PAYOUT,
        loadingInventoryPayout: false,
        inventoryPayout: !inventoryPayoutResponse.isError ? inventoryPayoutResponse.data : null
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_INVENTORY_PAYOUT_LOADING,
        loadingInventoryPayout: false
      });
    }
  };
}
export default getInventoryPayout;
