import React from 'react';

import moment from 'moment';
import {
  TimelineContainer,
  TimelineItem,
  TimelineTextContainer,
  Title,
  SubTitle
} from './styles'
class CustomerTimeline extends React.Component {
  render() {
    const {
      data
    } = this.props;
    if(!data || data.length <= 0) {
      return null;
    }
    return (
      <TimelineContainer>
        {
          data.map((timeline) =>
              <TimelineItem>
                <TimelineTextContainer>
                  <div>
                    <Title> {timeline.title} </Title>
                    <SubTitle>{moment(timeline.createdAt).format('llll')}</SubTitle>
                  </div>
                </TimelineTextContainer>
              </TimelineItem>
        )}
      </TimelineContainer>
    );
  }
}
export default CustomerTimeline;
