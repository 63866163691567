import {
  CREATE_DISCOUNT_LOADING,
  GET_DISCOUNT_ITEM_LOADING,
  GET_DISCOUNT_ITEM,
  UPDATE_DISCOUNT_LOADING
} from "../actions/types"

let defaultState = {
  creatingDiscount: false,
  discountItem: null,
  loadingDiscountItem: false,
  updatingDiscount: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_DISCOUNT_LOADING:
          return {
            ...state,
            creatingDiscount: action.creatingDiscount
          };
        case GET_DISCOUNT_ITEM_LOADING:
          return {
            ...state,
            loadingDiscountItem: action.loadingDiscountItem
          };
        case GET_DISCOUNT_ITEM:
          return {
            ...state,
            loadingDiscountItem: action.loadingDiscountItem,
            discountItem: action.discountItem
          };
        case UPDATE_DISCOUNT_LOADING:
          return {
            ...state,
            updatingDiscount: action.updatingDiscount
          };
        default:
            return state;
    }
};
