import {
  CREATE_PRODUCT_LOADING,
  GET_PRODUCT_ITEM_LOADING,
  GET_PRODUCT_ITEM,
  UPDATE_PRODUCT_LOADING,
  MERGE_PRODUCT_LOADING,
  GET_PRODUCTS,
  GET_PRODUCTS_LOADING,
  BULK_UPSERT_PRODUCTS_LOADING,
  GET_PRODUCTS_DUPLICATE_SKUS,
  GET_PRODUCTS_DUPLICATE_SKUS_LOADING,
  SEARCH_PRODUCTS,
  SEARCH_STOCKX_PRODUCTS,
  SEARCH_PRODUCTS_LOADING,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_ATTRIBUTES_LOADING,
  SET_PRODUCTS_LAST_QUERY,
  TURN_PRODUCT_RETAIL_LOADING,
  TURN_PRODUCT_CONSIGNMENT_LOADING,
  RESYNC_PRODUCT_INVENTORY_LOADING
} from '../actions/types';

const defaultState = {
  creatingProduct: false,
  loadingProductItem: false,
  productItem: null,
  updatingProduct: false,
  mergingProducts: false,
  products: [],
  loadingProducts: false,
  bulkUpsertingProducts: false,
  loadingProductsWithDuplicateSkus: false,
  productsWithDuplicateSkus: [],
  searchProducts: [],
  searchStockXProducts: [],
  loadingSearchProducts: false,
  loadingProductAttributes: false,
  productAttributes: [],
  productsLastQuery: "",
  turningProductRetail: false,
  turningProductConsignment: false,
  resyncingProductInventory: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_LOADING:
          return {
            ...state,
            creatingProduct: action.creatingProduct
          };
        case GET_PRODUCT_ITEM:
          return {
            ...state,
            loadingProductItem: action.loadingProductItem,
            productItem: action.productItem
          }
        case GET_PRODUCT_ITEM_LOADING:
          return {
            ...state,
            loadingProductItem: action.loadingProductItem,
          }
        case UPDATE_PRODUCT_LOADING:
          return {
            ...state,
            updatingProduct: action.updatingProduct
          }
        case MERGE_PRODUCT_LOADING:
          return {
            ...state,
            mergingProducts: action.mergingProducts
          }
        case GET_PRODUCTS:
          return {
            ...state,
            loadingProducts: action.loadingProducts,
            products: action.products
          }
        case GET_PRODUCTS_LOADING:
          return {
            ...state,
            loadingProducts: action.loadingProducts,
          }
        case BULK_UPSERT_PRODUCTS_LOADING:
          return {
            ...state,
            bulkUpsertingProducts: action.bulkUpsertingProducts
          }
        case GET_PRODUCTS_DUPLICATE_SKUS:
          return {
            ...state,
            loadingProductsWithDuplicateSkus: action.loadingProductsWithDuplicateSkus,
            productsWithDuplicateSkus: action.productsWithDuplicateSkus
          }
        case GET_PRODUCTS_DUPLICATE_SKUS_LOADING:
          return {
            ...state,
            loadingProductsWithDuplicateSkus: action.loadingProductsWithDuplicateSkus,
          }
        case SEARCH_PRODUCTS:
          return {
            ...state,
            loadingSearchProducts: action.loadingSearchProducts,
            searchProducts: action.searchProducts,
            searchProductsCount: action.searchProductsCount
          }
        case SEARCH_STOCKX_PRODUCTS:
          return {
            ...state,
            loadingSearchProducts: action.loadingSearchProducts,
            searchProducts: action.searchProducts,
            searchProductsCount: action.searchProductsCount
          }
        case SEARCH_PRODUCTS_LOADING:
          return {
            ...state,
            loadingSearchProducts: action.loadingSearchProducts,
          }
        case GET_PRODUCT_ATTRIBUTES:
          return {
            ...state,
            loadingProductAttributes: action.loadingProductAttributes,
            productAttributes: action.productAttributes
          }
        case GET_PRODUCT_ATTRIBUTES_LOADING:
          return {
            ...state,
            loadingProductAttributes: action.loadingProductAttributes,
          }
          case SET_PRODUCTS_LAST_QUERY:
            return {
              ...state,
              productsLastQuery: action.query,
            }
          case TURN_PRODUCT_RETAIL_LOADING:
            return {
              ...state,
              turningProductRetail: action.turningProductRetail,
              turningProductConsignment: action.turningProductConsignment
            }
          case RESYNC_PRODUCT_INVENTORY_LOADING:
              return {
                ...state,
                resyncingProductInventory: action.resyncingProductInventory
              }
        default:
            return state;
    }
};
