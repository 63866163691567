import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import {
  withRouter,
} from 'react-router-dom';
import history from '../../navigation/history';
import BarcodeReader from 'react-barcode-reader';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button,
  message,
  Modal
} from 'antd'
import BulkEdit from '../../components/ui/modals/BulkEdit';
import IncomingInventoryList from '../../components/ui/IncomingInventoryList';
import InventorySearchFilter from '../../components/ui/InventorySearchFilter';
import ProductSortBy from '../../constants/ProductSortBy';
import withLocalState from '../../HOC/withLocalState'

import {
  DashboardContainer,
  PageTitle,
  FormCard,
  ScanButton
} from '../styles';


class Inventory extends React.Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    const {
      consignerName,
      consignerId,
      productId
    } = parsed;
    if(productId) {
      this.props.getProduct(productId);
    }
    this.state = {
      id: null || productId,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      inventoryFilterVisible: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: consignerName || '',
      filterConsignerId: consignerId || null,
      filterConsignerQuery: consignerName || '',
      productQuery: '',
      title: '',
      filterStatus: 'Withdrawal Requested',
      filterShortCode: '',
      selectedInventory: [],
      bulkEditVisible: false,
      bulkEditKey: '',
      bulkEditKeyValue: '',
      filterReceivedAt: null,
      filterOrderDate: null,
      selectedSortBy: 0,
      filterTags: '',
      sortByOptions: [
        {
          label: 'Withdrawal Requested Date ASC',
          value: [['withdrawalRequestedDate', 'ASC']]
        },
        {
          label: 'Withdrawal Requested Date DESC',
          value: [['withdrawalRequestedDate', 'DESC']]
        },
        {
          label: 'Withdrawal Location ASC',
          value: [['withdrawalLocationName', 'ASC']]
        },
        {
          label: 'Withdrawal Location DESC',
          value: [['withdrawalLocationName', 'DESC']]
        },
        {
          label: 'Location ASC',
          value: [['locationName', 'ASC']]
        },
        {
          label: 'Location DESC',
          value: [['locationName', 'DESC']]
        },
      ],
      ...props.inventoryData.lastWithdrawalsTransferInventoryFilter
    }
  }
  componentDidMount() {
    this.loadInventory();
    this.props.getProducts()
  }  
  componentDidUpdate(oldProps) {
    //New Product Item
    const { productData } = this.props;
    const newProductItem = productData.productItem;
    //Old Product Item
    const oldProductItem = oldProps.productData.productItem;
    if(newProductItem) {
      if(oldProductItem !== newProductItem) {
        this.setState({
          ...newProductItem,
          locationQuery: newProductItem.locationName,
          consignerQuery: newProductItem.consignerName,
          productQuery: newProductItem.title
        })
      }
    }
  }
  componentWillUnmount() {
    this.props.saveLastInvetoryFilter('lastWithdrawalsTransferInventoryFilter', this.state);
  }
  handleInventoryFilter() {
    this.setState({
      inventoryFilterVisible: !this.state.inventoryFilterVisible
    })
  }
  handleBulkEdit() {
    this.setState({
      bulkEditVisible: !this.state.bulkEditVisible
    })
  }
  updateState(key, value) {
    if(key === 'id') {
      this.props.getProduct(value);
    }
    this.setState({
      [key]: value
    })
  }
  loadInventory() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      filterShortCode,
      filterReceivedAt,
      filterOrderDate,
      selectedSortBy,
      filterTags,
      sortByOptions,
      isRemote,
      filterWithdrawalLocationId,
      filterWithdrawalLocationQuery,
      filterDateRange,
      filterDateRangeKey
    } = this.state;
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      withdrawalLocationId: filterWithdrawalLocationQuery !== '' ? filterWithdrawalLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      status: 'Withdrawal Requested',
      shortCode: filterShortCode !== '' ? filterShortCode : null,
      receivedAt: filterReceivedAt !== '' ? filterReceivedAt : null,
      orderDate: filterOrderDate !== '' ? filterOrderDate : null,
      sortBy: selectedSortBy !== '' &&  sortByOptions[selectedSortBy] ? sortByOptions[selectedSortBy].value : null,
      tags: filterTags !== '' ? filterTags.split(',') : null,
      notEqualWithdrawalLocation: true,
      notEqualToInTransit: true,
      dateRangeKey: filterDateRangeKey !== '' ? filterDateRangeKey : null,
      dateRange: filterDateRange !== '' ? filterDateRange : null,
    }
    this.props.getInventoryForFilter(data);
    this.setState({ inventoryFilterVisible: false });
    console.log(data)
  }
  clearProduct() {
    this.setState({
      id: null,
      productQuery: '',
      title: '',
      option1: null,
      option2: null,
      option3: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
    });
  }
  async acceptInventory(id, shortCode, item) {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are sure you to mark this item as transferred?',
      onOk: async() => {
        const hide = message.loading(`Marking as transferred...`)
        await this.props.updateInventory({
          id,
          transferStatus: 'Transferred',
          withdrawalStatus: 'Ready For Pickup',
          locationName: item.withdrawalLocationName,
          locationId: item.withdrawalLocationId,
        })
        await this.props.sendWithdrawalTransfered({ id })
        await this.loadInventory();
        hide();
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  async searchProducts(value, callback) {
    await this.props.searchProducts(value, 1, ProductSortBy[0], false)
    const { searchProducts } = this.props.productData;
    return searchProducts.map(obj => {return {
      value: obj.id,
      label: obj.title
    }})
  }


  render() {
    const {
      filteredInventory,
      filteringInventory,
      updatingInventory
    } = this.props.inventoryData;
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Withdrawal Transfer Requests</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
          />
          <FormCard
          >
            <InventorySearchFilter
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              loadInventory={() => this.loadInventory()}
              query={this.state.filterLocationQuery}
              queryKey='filterLocationQuery'
              query2={this.state.filterConsignerQuery}
              queryKey2='filterConsignerQuery'
              query3={this.state.productQuery}
              queryKey3='productQuery'
              query4={this.state.filterWithdrawalLocationQuery}
              queryKey4='filterWithdrawalLocationQuery'
              userId={this.props.auth.userData.id}
              canSelectProduct
              clearProduct={() => this.clearProduct()}
              disableStatus
              disableSellType
              userData={this.props.auth.userData}
              products={this.props.productData.searchProducts}
loadingProducts={this.props.productData.loadingSearchProducts}
onSearchProducts={(value) => this.searchProducts(value)}

            />
            <IncomingInventoryList
              userData={this.props.auth.userData}
              data={filteredInventory}
              loading={filteringInventory}
              updateState={(key, value) => this.updateState(key, value)}
              selectedInventory={this.state.selectedInventory}
              acceptInventory={(id, shortCode, item) => this.acceptInventory(id, shortCode, item)}
              acceptText='Mark as Transferred'
              declineText='Mark in Transit'
              disableRemoteColumn
              disableCreatedOnColumn
              disablePayout
              disableOrderDateColumn
              disableOrderColumn
            />
          </FormCard>
          <BarcodeReader
            onScan={(data) => history.push(`inventory/${data}`)}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
        productData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory));
