import React from 'react';
import { Select } from 'antd';

const { Option } = Select;


function SortBy(props) {
  const {
    data,
    updateState,
    selectedSortBy
  } = props;
  return (
    <Select
      style={{ width: 120 }}
      onChange={(value) => updateState(value)}
      value={selectedSortBy}
      style={{ width: '100%' }}
    >
      {
        data.map((option, index) => {
          return <Option value={index}>Sort By {option.label}</Option>
        })
      }
    </Select>
  )
}
export default SortBy;
