import Membership from '../screens/Membership';
import Dashboard from '../screens/Dashboard';
import Inventory from '../screens/Inventory';
import IncomingInventory from '../screens/Inventory/incoming';
import ScanInventory from '../screens/Inventory/scan';
import Products from '../screens/Products';
import Consigners from '../screens/Consigners';
import Customers from '../screens/Customers';
import Orders from '../screens/Orders';
import Employees from '../screens/Employees';
import AddInventory from '../screens/Inventory/add';
import ViewInventory from '../screens/Inventory/view';
import AddProduct from '../screens/Products/add';
import ViewProduct from '../screens/Products/view';
import AddConsigners from '../screens/Consigners/add';
import ViewConsigner from '../screens/Consigners/view';
import ViewCustomer from '../screens/Customers/view';
import AddEmployee from '../screens/Employees/add';
import ViewEmployeee from '../screens/Employees/view';
import ViewOrder from '../screens/Orders/view';
import Settings from '../screens/Settings';
import Appointments from '../screens/Appointments';
import AddIntegration from '../screens/Settings/Integration/Add';
import SoldRemoteInventory from '../screens/Inventory/soldRemote';
import WithdrawalInventory from '../screens/Inventory/withdrawal';
import WithdrawalTransferRequests from '../screens/Inventory/withdrawalTransferRequests';
import PayoutInventory from '../screens/Inventory/payout';
import Catalog from '../screens/Catalog';
import SlackSignup from '../screens/SlackSignup';
import SettingsCustomDomain from '../screens/Settings/customDomain';
import SettingsBasic from '../screens/Settings/basic';
import SettingsLocation from '../screens/Settings/locations';
import SettingsIntegrations from '../screens/Settings/Integration';
import SettingsConsigner from '../screens/Settings/consigners';
import SettingsFees from '../screens/Settings/fees';
import SettingsInventoryPriority from '../screens/Settings/inventoryPriority';
import SettingsNotifications from '../screens/Settings/notifications';
import SettingsProductTemplates from '../screens/Settings/productTemplates';
import ConnectProducts from '../screens/Onboarding/connectProducts';
import SetupProductTemplates from '../screens/Onboarding/setupProductTemplates';
import EditProducts from '../screens/Onboarding/editProducts';
import EditConsigners from '../screens/Onboarding/editConsigners';
import MergeDuplicateProducts from '../screens/Onboarding/mergDuplicateProducts';
import FinalStep from '../screens/Onboarding/finalStep';
import MergeInventory from '../screens/Onboarding/addInventory';
import Onboarding from '../screens/Onboarding';
import RefreshShopifyCSV from '../screens/Settings/refreshShopifyCSV';
import Support from '../screens/Support';
import ReAuth from '../screens/ReAuth';
import PayoutConsigner from '../screens/Consigners/payout';
import Ledger from '../screens/Consigners/ledger';

export default [
  {
    path: '/',
    component: Dashboard,
    exact: true
  },
  {
    path: '/onboarding',
    component: Onboarding,
    exact: true
  },
  {
    path: '/onboarding/connectProducts',
    component: ConnectProducts,
    exact: true
  },
  {
    path: '/onboarding/setupProductTemplates',
    component: SetupProductTemplates,
    exact: true
  },
  {
    path: '/onboarding/editConsigners',
    component: EditConsigners,
    exact: true
  },
  {
    path: '/onboarding/mergeDuplicateProducts',
    component: MergeDuplicateProducts,
    exact: true
  },
  {
    path: '/onboarding/addInventory',
    component: MergeInventory,
    exact: true
  },
  {
    path: '/onboarding/finalStep',
    component: FinalStep,
    exact: true
  },
  {
    path: '/onboarding/products/:id',
    component: ViewProduct,
    exact: true
  },
  {
    path: '/onboarding/integrations/add/:integrationId',
    component: AddIntegration,
    exact: true
  },
  {
    path: '/inventory',
    component: Inventory,
    exact: true
  },
  {
    path: '/products',
    component: Products,
    exact: true
  },
  {
    path: '/consigners',
    component: Consigners,
    exact: true
  },
  {
    path: '/customers',
    component: Customers,
    exact: true
  },
  {
    path: '/orders',
    component: Orders,
    exact: true
  },
  {
    path: '/employees',
    component: Employees,
    exact: true
  },
  {
    path: '/inventory/add',
    component: AddInventory,
    exact: true
  },
  {
    path: '/products/add',
    component: AddProduct,
    exact: true
  },
  {
    path: '/products/:id',
    component: ViewProduct,
    exact: true
  },
  {
    path: '/inventory/:id',
    component: ViewInventory,
    exact: true
  },
  {
    path: '/incoming/inventory',
    component: IncomingInventory,
    exact: true
  },
  {
    path: '/scan',
    component: ScanInventory,
    exact: true
  },
  {
    path: '/consigners/add',
    component: AddConsigners,
    exact: true
  },
  {
    path: '/consigners/:id',
    component: ViewConsigner,
    exact: true
  },
  {
    path: '/customers/:id',
    component: ViewCustomer,
    exact: true
  },
  {
    path: '/employees/add',
    component: AddEmployee,
    exact: true
  },
  {
    path: '/employees/:id',
    component: ViewEmployeee,
    exact: true
  },
  {
    path: '/orders/:id',
    component: ViewOrder,
    exact: true
  },
  {
    path: '/settings/domains',
    component: SettingsCustomDomain,
    exact: true
  },
  {
    path: '/settings/basic',
    component: SettingsBasic,
    exact: true
  },
  {
    path: '/settings/locations',
    component: SettingsLocation,
    exact: true
  },
  {
    path: '/settings/integrations',
    component: SettingsIntegrations,
    exact: true
  },
  {
    path: '/settings/consigners',
    component: SettingsConsigner,
    exact: true
  },
  {
    path: '/settings/fees',
    component: SettingsFees,
    exact: true
  },
  {
    path: '/settings/inventoryPriority',
    component: SettingsInventoryPriority,
    exact: true
  },
  {
    path: '/settings/notifications',
    component: SettingsNotifications,
    exact: true
  },
  {
    path: '/settings/productTemplates',
    component: SettingsProductTemplates,
    exact: true
  },
  {
    path: '/settings/refreshShopifyCSV',
    component: RefreshShopifyCSV,
    exact: true
  },
  {
    path: '/settings',
    component: Settings,
    exact: true
  },
  {
    path: '/integrations/add/:integrationId',
    component: AddIntegration,
    exact: true
  },
  {
    path: '/appointments',
    component: Appointments,
    exact: true
  },
  {
    path: '/soldRemote/inventory',
    component: SoldRemoteInventory,
    exact: true
  },
  {
    path: '/withdrawal/inventory',
    component: WithdrawalInventory,
    exact: true
  },
  {
    path: '/withdrawalTransferRequests/inventory',
    component: WithdrawalTransferRequests,
    exact: true
  },
  {
    path: '/payout/inventory',
    component: PayoutInventory,
    exact: true
  },
  {
    path: '/catalogs',
    component: Catalog,
    exact: true
  },
  {
    path: '/slack',
    component: SlackSignup,
    exact: true
  },
  {
    path: '/support',
    component: Support,
    exact: true
  },
  {
    path: '/reauth',
    component: ReAuth,
    exact: true
  },
  {
    path: '/payout/consigners',
    component: PayoutConsigner,
    exact: true
  },
  {
    path: '/payout/consigners/:id',
    component: Ledger,
    exact: true
  },
]
