import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { message } from 'antd';
import * as actions from '../redux/actions';

class Signup extends React.Component {
  componentWillMount() {
    const parsed = queryString.parse(this.props.location.search);
    const { employee, store } = parsed;
    if(employee && store) {
      this.props.login({
        employee,
        store,
        isEmployee: true
      });

    } else {
      message.error('Expired URL')
    }
  }

  render() {
    return <div />;
  }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
