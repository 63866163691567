import styled from 'styled-components';
import { CardElement } from 'react-stripe-elements';
import { Button } from 'antd';

export const CardInput = styled(CardElement)`

`

export const SubmitButton = styled(Button)`
  margin-top: 20px;
`
