import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API,
} from '../../../constants/API';
import {
  BULK_UPSERT_CONSIGNERS_LOADING
} from '../types';


function bulkUpsertConsigners(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: BULK_UPSERT_CONSIGNERS_LOADING,
        bulkUpsertingConsigners: true
      });
      try {
        console.log(data)
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;

        data = data.map(row => { return { ...row, userId: userData.id }})
        const postData = {
          data
        };
        const consignerResponse = await axios.post(`${CONSIGNER_API}/bulkUpsert`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(consignerResponse)
        dispatch({
          type: BULK_UPSERT_CONSIGNERS_LOADING,
          bulkUpsertingConsigners: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: BULK_UPSERT_CONSIGNERS_LOADING,
          bulkUpsertingConsigners: false
        });
        console.log(e);
      }
  }
}
export default bulkUpsertConsigners;
