export default (rows, columnsToValidate) => {
  let isFilled = true;
  for (let i = 0; i < columnsToValidate.length; i++) {
    const columnToValidate = columnsToValidate[i];
    for (let j = 0; j < rows.length; j++) {
        const row = rows[j];
        if(!row[columnToValidate] || row[columnToValidate] === '') {
          isFilled = false
          return isFilled;
        }
    }
  }
  return isFilled;
}
