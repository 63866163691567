import styled from 'styled-components';
import {
  PRIMARY_FONT
} from '../../../constants/Fonts';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Title = styled.p`
  font-size: 12px;
  font-family: ${PRIMARY_FONT};
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 10px;
  line-height: 16px;
`
export {
  Container,
  Title
};
