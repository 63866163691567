import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API
} from '../../../constants/API';
import {
  GET_PRODUCTS_LOADING,
  GET_PRODUCTS
} from '../types';

function getProducts() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS_LOADING,
      loadingProducts: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const productsResponse = await axios.get(
        `${PRODUCTS_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(productsResponse)
      dispatch({
        type: GET_PRODUCTS,
        loadingProducts: false,
        products: productsResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_PRODUCTS_LOADING,
        loadingProducts: false
      });
    }
  };
}
export default getProducts;
