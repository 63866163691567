import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import CsvParse from '@vtex/react-csv-parse'
import CSVEditor from '../../components/ui/CSVEditor';
import CSVcolumns from '../../constants/CSVcolumns';
import history from '../../navigation/history';

import {
  PageHeader,
  Card,
  Button,
  message
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';


class EditConsigners extends React.Component {
  constructor(props) {
    super(props);
    const columns = CSVcolumns().consigners
    this.state = {
      columns,
      rows: []
    }
  }
  componentWillMount() {
    this.props.getConsigners();
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { consignerData } = this.props;
    const newConsigners = consignerData.consigners;
    //Old Inventory Item
    const oldConsigners = oldProps.consignerData.consigners;
    if(newConsigners) {
      if(oldConsigners !== newConsigners) {
        this.setState({ rows: newConsigners });
      }
    }
  }
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(state => {
      const rows = state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    });
  };
  async next() {
    await this.props.bulkUpsertConsigners(this.state.rows)
    history.push('/onboarding/finalStep')
  }
  async handleCSVUploaded(data) {
    console.log(data)
    this.setState({
      rows: [...this.state.rows, ...data]
    })
  }
  handleCSVUploadFailed(err) {
    message.error(err)
  }
  render() {
    const {
      bulkUpsertingConsigners
    } = this.props.consignerData;
    const keys = [
      'name',
      'firstName',
      'lastName',
      'email',
      'password'
    ]
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Add Consigners</PageTitle>}
          subTitle={<a
              target='_blank'
              href='https://frontflip.kwiki.io/docs/consigners-csv'
            >
              CSV Instructions
            </a>
          }
          style={{ background: 'transparent', marginBottom: 10 }}
          extra={[
            <Button>
            <CsvParse
                keys={keys}
                onDataUploaded={(data) => this.handleCSVUploaded(data)}
                onError={(err) => this.handleCSVUploadFailed(err)}
                render={onChange => <input type="file" onChange={onChange} />}
              />
            </Button>,
            <Button
              onClick={() => this.props.bulkUpsertConsigners(this.state.rows)}
              loading={bulkUpsertingConsigners}
            >
              Save
            </Button>,
            <Button
              onClick={() => this.next()}
              type='primary'
              loading={bulkUpsertingConsigners}

            >
              Next
            </Button>
          ]}
        />

        <Card>
          <CSVEditor
            columns={this.state.columns}
            rows={this.state.rows}
            onGridRowsUpdated={(data) => this.onGridRowsUpdated(data)}
          />


        </Card>

      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        consignerData: state.ConsignerReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditConsigners));
