import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API,
} from '../../../constants/API';
import {
  GET_INVENTORY_FOR_FILTER
} from '../types';


function getInventoryForFilter(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: GET_INVENTORY_FOR_FILTER,
        filteringInventory: true,
        filteredInventory: []

      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const filteredInventory = await axios.post(`${INVENTORY_API}/filter`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(filteredInventory)
        if(filteredInventory.data.isError) {
          dispatch({
            type: GET_INVENTORY_FOR_FILTER,
            filteringInventory: false,
            filteredInventory: []
          });
        } else {
          dispatch({
            type: GET_INVENTORY_FOR_FILTER,
            filteringInventory: false,
            filteredInventory: filteredInventory.data
          });
        }

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: GET_INVENTORY_FOR_FILTER,
          filteringInventory: false,
          filteredInventory: []
        });
        console.log(e);
      }
  }
}
export default getInventoryForFilter;
