import React from 'react';
import {
  Link
} from 'react-router-dom';
export default function sideBarRoutes(MenuItem, changePassword, logout, userData) {
  return [
    {
      path: '/',
      key: 0,
      title: 'Dashboard',
      icon: 'home',
    },
    {
      path: '/products',
      key: 1,
      title: 'Products',
      icon: 'database',
    },
    {
      path: '/inventory',
      key: 2,
      title: 'Inventory',
      icon: 'unordered-list',
      subMenu: true,
      subMenuItems:[
        <MenuItem key="2">
          <Link to='/inventory'>
            <span>
              Find
            </span>
          </Link>
        </MenuItem>,
        <MenuItem key="10">
          <Link to='/incoming/inventory'>
            <span>
              Incoming
            </span>
          </Link>
        </MenuItem>,
        userData && userData.allowDropoff ? <MenuItem key="12">
          <Link to='/withdrawal/inventory'>
            <span>
              Withdrawals
            </span>
          </Link>
        </MenuItem> :null,
        userData && userData.allowDropoff ? <MenuItem key="13">
          <Link to='/withdrawalTransferRequests/inventory'>
            <span>
              Withdrawal Transfer Requests
            </span>
          </Link>
        </MenuItem> :null,
      ]

    },
    {
      path: '/consigners',
      key: 3,
      title: 'Consigner',
      icon: 'solution',
    },
    {
      path: '/customers',
      key: 4,
      title: 'Customers',
      icon: 'smile',
    },
    {
      path: '/orders',
      key: 5,
      title: 'Orders',
      icon: 'dollar',
      subMenu: true,
      subMenuItems: [
        <MenuItem key="5">
          <Link to='/orders'>
            <span>
              Orders
            </span>
          </Link>
        </MenuItem>,
        userData && userData.allowRemote ? <MenuItem key="11">
          <Link to='/soldRemote/inventory'>
            <span>
              Remote Orders
            </span>
          </Link>
        </MenuItem> :null,
        <MenuItem key="14">
          <Link to='/payout/inventory'>
            <span>
              Ready for Payout
            </span>
          </Link>
        </MenuItem>,
        <MenuItem key="15">
          <Link to='/payout/consigners'>
            <span>
              Payout
            </span>
          </Link>
        </MenuItem>,
      ]
    },
    {
      path: '/employees',
      key: 6,
      title: 'Employees',
      icon: 'robot',
    },
    {
      path: '/appointments',
      key: 7,
      title: 'Appointments',
      icon: 'calendar',
    },
    {
      path: '/settings',
      key: 8,
      title: 'Your Account',
      icon: 'user',
      subMenu: true,
      subMenuItems:[
        <MenuItem key="9">
          <Link to='/settings'>
            <span>
              Settings
            </span>
          </Link>
        </MenuItem>,
        <MenuItem >
        <Link to='/support'>
          <span>
            Support
          </span>
        </Link>
        </MenuItem>,
        <MenuItem
          onClick={changePassword}
        >
          Change Password
        </MenuItem>,
        <MenuItem
          onClick={logout}
        >
        Logout
        </MenuItem>
      ]
    }
  ]
}
