import axios from 'axios';
import {
  INTEGRATION_API
} from '../../../constants/API';
import {
  GET_INTEGRATION,
  GET_INTEGRATION_LOADING
} from '../types';

function getIntegration(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INTEGRATION_LOADING,
      loadingIntegration: true,
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const getIntegrationResponse =
        await axios.get(
          `${INTEGRATION_API}/${id}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
      console.log(getIntegrationResponse.data);
      const integration = getIntegrationResponse.data;
      dispatch({
        type: GET_INTEGRATION,
        integration: integration,
        loadingIntegration: false
      });
    } catch (e) {
      dispatch({
        type: GET_INTEGRATION_LOADING,
        loadingIntegration: false
      });
    }
  };
}

export default getIntegration;
