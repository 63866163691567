import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import * as actions from '../../redux/actions';
import history from '../../navigation/history';

import {
  PageHeader,
  Card,
  Table,
  Button,
  message
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';


class MergeDuplicateProducts extends React.Component {
  componentWillMount() {
    this.props.getProductsWithDuplicateSkus();
  }

  async next() {
    await this.props.getProductsWithDuplicateSkus();
    const {
      productsWithDuplicateSkus
    } = this.props.productsData;
    if(productsWithDuplicateSkus.length > 0) {
      message.error('Please merge all duplicate skus')
    } else {
      history.push('/onboarding/editConsigners')
    }
  }

  render() {
    const {
      productsWithDuplicateSkus,
      loadingProductsWithDuplicateSkus
    } = this.props.productsData;

    if(loadingProductsWithDuplicateSkus) {
      return <LoadingSpinner />
    }
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
      },
      {
        title: 'Actions',
        dataIndex: '',
        key: '',
        render: (data) =>  <Link to={`/onboarding/products/${data.id}`}>Merge</Link>
      }
    ];
    console.log(productsWithDuplicateSkus)
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Merge Duplicate Products</PageTitle>}
          style={{ background: 'transparent', marginBottom: 10 }}
          extra={[
            <Button
              onClick={() => this.props.getProductsWithDuplicateSkus()}
            >
              Refresh
            </Button>,
            <Button
              onClick={() => this.next()}
              type='primary'
            >
              Next
            </Button>
          ]}
        />

        <Card>
          <Table
            dataSource={productsWithDuplicateSkus}
            columns={columns}
            locale={{ emptyText: 'Products are all unique, click next to continue!' }}
          />
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MergeDuplicateProducts));
