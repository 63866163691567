import React from 'react';
import {
  connectInfiniteHits
} from 'react-instantsearch-dom';
import {
  Container,
  Image,
  Title,
  Category,
  Active,
  ConnectButton,
  ListContainer
} from './styles';
import doesExistInArrayOfObj from '../../../../helpers/data/doesExistInArrayOfObj';

const PayoutCard = connectInfiniteHits(props => {
  const {
    hits,
    connections,
    selectPayoutMethod,
    selectedMethod
  } = props;
  return (
    <ListContainer>
      <Container
        span={4}
        style={{ border: (selectedMethod === '' ? '1px solid blue' : null) }}
      >
        <Title>
          Manual
        </Title>
        <Category>
          Payout
        </Category>
        <ConnectButton onClick={() => selectPayoutMethod('', { name: 'Manual Payment' })}>
          Select
        </ConnectButton>
      </Container>
      <Container
        span={4}
        style={{ border: (selectedMethod === 'shopify-credit' ? '1px solid blue' : null) }}
      >
        <Title>
          Shopify Store Credit
        </Title>
        <Category>
          Payout
        </Category>
        <ConnectButton onClick={() => selectPayoutMethod('shopify-credit', { name: 'Shopify Store Credit' })}>
          Select
        </ConnectButton>
      </Container>
    {
      hits.map((hit, index) => {
        const connection = doesExistInArrayOfObj(connections, hit.objectID);
        if(!connection) {
          return null;
        }
        return (
          <Container
            span={4}
            style={{ border: selectedMethod === hit.objectID ? '1px solid blue' : null }}
          >
            <Image
              src={hit.image}
            />
            <Title>
              {hit.name}
            </Title>
            <Category>
              {hit.category}
            </Category>
            <ConnectButton onClick={() => selectPayoutMethod(hit.objectID, hit)}>
              Select
            </ConnectButton>
          </Container>
        )
      })
    }
    </ListContainer>
  );
});

export default PayoutCard;
