import React, {Component} from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Card } from 'antd';
import {
  SubmitButton
} from './styles';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
    const {
      stripe,
      createMembership
    } = this.props;
    try {
      let { token } = await stripe.createToken();
      console.log(token);
      if(token && token.id) {
        createMembership(token.id);
      }
    } catch (e) {
      alert(String(e));
    }
  }

  render() {
    const {
      isLoading
    } = this.props;
    return (
      <Card
        title='Payments are powered by Stripe. No card information is store by Frontflip.'
      >
        <CardElement />
        <SubmitButton
          onClick={this.submit}
          type='primary'
          loading={isLoading}
        >
          Start free 30 day trial
        </SubmitButton>
      </Card>
    );
  }
}

export default injectStripe(CheckoutForm);
