import axios from 'axios';
import { message } from 'antd';
import {
  LOCATIONS_API,
} from '../../../constants/API';
import {
  CREATE_LOCATION_LOADING
} from '../types';
import getLocations from './getLocations';

function createLocation(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_LOCATION_LOADING,
        creatingLocation: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const hide = message.loading('Creating Location', 0);

        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        await axios.post(LOCATIONS_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        hide()
        dispatch({
          type: CREATE_LOCATION_LOADING,
          creatingLocation: false
        });
        await dispatch(getLocations());
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_LOCATION_LOADING,
          creatingLocation: false
        });
        console.log(e);
      }
  }
}
export default createLocation;
