import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  Empty,
  message,
  Alert,
  Card,
  Modal,
  Form
} from 'antd';
import {
  withRouter,
  Link
} from 'react-router-dom';
import arrayMove from 'array-move';
import history from '../../navigation/history';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import ProductName from '../../components/ui/forms/product/ProductName';
import ProductOption from '../../components/ui/forms/product/ProductOption';
import ProductTemplate from '../../components/ui/forms/product/ProductTemplate';
import IncomingInventoryList from '../../components/ui/IncomingInventoryList';
import ConsignerInventory from '../../components/ui/forms/consigners/ConsignerInventory';
import PhotoUploader from '../../components/ui/PhotoUploader';

import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  FormCard,
  AddContainer
} from '../styles';

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      sku: '',
      images: [],
      brand: '',
      category: '',
      tags: [],
      description: '',
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      inventoryFilterVisible: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: '',
      filterConsignerId: null,
      filterConsignerQuery: '',
      productQuery: '',
      filterShortCode: '',
      editMode: false,
      draftProduct: false
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getProduct(id);

  }
  componentDidUpdate(oldProps) {
    //New Product Item
    const { productData } = this.props;
    const newProductItem = productData.productItem;
    //Old Product Item
    const oldProductItem = oldProps.productData.productItem;
    if(newProductItem) {
      if(oldProductItem !== newProductItem) {
        this.setState({
          ...newProductItem,
          locationQuery: newProductItem.locationName,
          consignerQuery: newProductItem.consignerName,
          productQuery: newProductItem.title,
        }, () => this.loadInventory())
      }
    }
  }
  uploadImage() {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add images to state
            this.setState({
              images: [...this.state.images, secure_url]
            })
        }
    });
  }
  deleteImage(index) {
    const images = this.state.images;
    images.splice(index, 1);
    this.setState({ images });
  }

  onImageSort = (oldIndex, newIndex) => {
    this.setState(({images}) => ({
      images: arrayMove(images, oldIndex, newIndex),
    }));
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

  async updateProduct(values) {
    const {
      title,
      option1,
      sku,
      brand,
      category,
      draftProduct,
      productTemplateId
    } = this.state;
      const { updateProduct } = this.props;
      await updateProduct({ ...this.state, ...values, draftProduct: false });
      window.location.reload();
  }

  handleInventoryFilter() {
    this.setState({
      inventoryFilterVisible: !this.state.inventoryFilterVisible
    })
  }

  loadInventory() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      filterShortCode
    } = this.state;
    console.log(filterLocationQuery)
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      status: filterStatus !== '' ? filterStatus : null,
      shortCode: filterShortCode !== '' ? filterShortCode : null,
      sortBy: [
        ['status', 'ASC'],
        ['option1', 'ASC'],
        ['option2', 'ASC'],
        ['option3', 'ASC'],
        ['price', 'ASC'],
      ]
    }
    this.props.getInventoryForFilter(data);
    this.setState({ inventoryFilterVisible: false });
    console.log(data)
  }
  cancelEdit() {
    const id = this.props.match.params.id
    this.props.getProduct(id);
    this.loadInventory();
    this.setState({
      editMode: false
    })
  }
  onProductTemplateSelected(data) {
    const newData = { ...data };
    delete newData.title;
    delete newData.id;
    delete newData.createdAt;
    delete newData.updatedAt;
    const didProductTemplateChange = this.props.productData.productItem.productTemplateId !== data.id
    if(didProductTemplateChange) {
      this.resyncTemplateWarning()
    }
    this.setState({
      ...newData,
      productTemplateId: data.id,
      didProductTemplateChange
    });
  }
  mergeProducts(id, shortCode, item) {
    const { mergeProducts } = this.props;
    const { productData } = this.props;
    const { productItem } = productData;
    if(productItem.draftProduct) {
      Modal.error({
        content: 'This product is a draft, either make it live then merge or merge only into live products',
        title: `Can't merge into draft products`
      });
    } else {
      Modal.confirm({
        title: 'Are you sure you want to merge?',
        content: 'Doing so will transfer inventory to the current product from the product your selected in the duplicate skus list and delete the product.',
        async onOk() {
          const hide = message.loading(`Merging Product In`)
          mergeProducts({
            productId: productItem.id,
            mergingProductId: id
          })
          hide();
        },
        okType: 'danger',
        okText: 'Merge & Delete',
        onCancel() {
          console.log('Cancel');
        },
      })
    }

  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.updateProduct(values);
      }
    });
  };

  handleChangeProductType() {
    const { productItem } = this.props.productData;
    const { isConsignment, id } = productItem;
    Modal.confirm({
      title: `Are you sure you want to turn this product to ${isConsignment ? 'Retail' : 'Consignment'}`,
      content: isConsignment ? 
        'Turning a product retail, will delete all inventory information and stop tracking in frontflip. You can control Retail Products using Shopify.'
        : 'Turning a product consignment will pull down all inventory from Shopify. Managining of consignment invenotry must happen in Frontflip.'
      ,
      okButtonProps: () => { return {
        loading: isConsignment ? 
          this.props.productData.turningProductRetail 
          : this.props.productData.turningProductConsignment    
        ,
      }},
      onOk: async() => {
        if(isConsignment) {
          //Turn retail 
          await this.props.turnProductRetail(id);
          window.location.reload();
        } else {
          // turn consignment
          this.props.turnProductConsignment(id)
        }
        console.log('OK');
      },
    });
    
  }

  getShopifyProductUrl() {
    const { productData, auth } = this.props;
    const { connectionsData } = auth.userData;
    const { productItem } = productData;
    if(
      connectionsData && 
      connectionsData[0].fieldValues && 
      connectionsData[0].fieldValues.shop &&
      productItem &&
      productItem.metadata &&
      productItem.metadata.productId
    ) {
      const shop = connectionsData[0].fieldValues.shop;
      const productId = productItem.metadata.productId;
      const shopifyProductUrl = `https://${shop}/admin/products/${productId}`
      return shopifyProductUrl;
    } 
    return '';

  }

  resyncTemplateWarning() {
    const { setState } = this;
    const { productTemplateId } = this.state;
    Modal.confirm({
      title: 'Are you sure you want to change template?',
      content: 'Changing Template will resync this product in Shopify. This means this product will be temporarily down (approx 2 seconds per inventory item long). If the new template has additional option - you can bulk edit the option from the bulk editor in the find inventory tab. Otherwise, the blank options will appear as Default in Shopify.',
      okType: 'danger',
      okText: 'Okay, I understand',
      closable: false,
      cancelButtonProps: { style: { display: 'none' } }

    })
  }

  resyncInventory() {
    const { resyncProductInventory, productData } = this.props;
    const { productItem } = productData
    Modal.confirm({
      title: 'Are you sure you want to resync inventory?',
      content: 'Depending on the amount of active inventory, this could take a few minutes to an hour.',
      async onOk() {
        resyncProductInventory(productItem.id);
        message.success('Resync started')
      },
      okType: 'danger',
      okText: 'Resync',
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  render() {
    const { images } = this.state;
    const { productData, form } = this.props;
    console.log(productData)
    const {
      loadingProductItem,
      productItem
    } = productData;
    if(loadingProductItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!productItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const {
      id,
      title,
      option1,
      option2,
      option3,
      option1Switch,
      option2Switch,
      option3Switch,
      option1FixedValues,
      option2FixedValues,
      option3FixedValues,
      inventoryCount,
      sku,
      draftProduct,
      duplicateSkus,
      isConsignment
    } = productItem;
    const {
      filteredInventory,
      filteringInventory
    } = this.props.inventoryData;


    return (
      <Form onSubmit={this.handleSubmit}>
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>{productItem.title}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              this.state.editMode ?
              <Button
                key="1"
                onClick={() => this.cancelEdit()}
                loading={productData.updatingProduct}
              >
                Cancel
              </Button> : null,
                this.state.editMode ?
                <Button
                  key="2"
                  type="primary"
                  htmlType="submit"
                  loading={productData.updatingProduct}
                >
                  {draftProduct ? 'Make Live' : 'Update'}
                </Button> : null,
                !this.state.editMode ?
                <Button
                  key="3"
                  onClick={() => this.handleChangeProductType()}
                >
                  {isConsignment ? 'Turn to Retail' : 'Turn to Consignment'}
                </Button> : null,
                !this.state.editMode ?
                <Button
                  key="4"
                  type="primary"
                  onClick={() => this.setState({ editMode: true })}
                >
                  {draftProduct ? 'Review' : 'Edit'}
                </Button> : null
            ]}
          />



            { draftProduct ?
              <Alert
                  message="IMPORTANT: Draft Product"
                  description='This a draft product, review and make it live.'
                  type="info"
                /> : null
            }
            { !isConsignment ?
              <a
                href={`${this.getShopifyProductUrl()}`} 
                target='_blank'
              >
              <Alert
                  message="IMPORTANT: Retail Product"
                  description='This a retail product - inventory is controlled by Shopify. Click this card to navigate to the Shopify Product Page.'
                  type="info"
                /> 
              </a>  
                
                
              : null
            }
            {
              duplicateSkus ?
                <Card
                  title='Duplicate SKUs Found'
                  style={{ marginTop: 20 }}
                >
                  <IncomingInventoryList
                    userData={this.props.auth.userData}
                    data={duplicateSkus}
                    updateState={(key, value) => this.updateState(key, value)}
                    selectedInventory={this.state.selectedInventory}
                    acceptInventory={(id, shortCode, item) => this.mergeProducts(id, shortCode, item)}
                    acceptText='Merge'
                    disableWithdrawalLocationName
                    disableLocationName
                    disablePayout
                    disableOrderDateColumn
                    disableOrderColumn
                    disableRemoteColumn
                    disableItemIdColumn
                    disableOwnerColumn
                    disablePriceColumn
                    disableCreatedOnColumn
                    type='products'
                    openNewTab
                  />
                </Card>
                : null
            }

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard >
                <ProductName
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  form={form}
                  query={this.state.productQuery}
                  queryKey='productQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}
                />
              </FormCard>

              {
              isConsignment ?
              <FormCard
                title="Inventory"
                extra={
                  [
                    <Button
                      onClick={() => this.resyncInventory()}
                      style={{ marginRight: 10 }}
                      key={0}
                    >
                      Resync Inventory
                    </Button>,
                    <Button
                      onClick={() => history.push(`/inventory?productId=${id}`)}
                      style={{ marginRight: 10 }}
                      key={1}
                    >
                      Filters
                    </Button>,
                    <Link
                      key={3}
                      to={{
                        pathname: `/inventory/add`,
                        state: { product: productItem }
                      }}
                    >
                      Add New
                    </Link>
                  ]
                }
              >
                <ConsignerInventory
                  data={filteredInventory}
                  loading={filteringInventory}
                  hideCheckbox
                  compact
                />
              </FormCard>
            : null }
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
            {
              this.props.auth.userData.productTemplates && this.state.editMode ?
                <FormCard
                  title='Templates'
                  style={{ height: '100%' }}
                >
                  <ProductTemplate
                    selectedTemplate={this.state.productTemplateId}
                    productTemplates={this.props.auth.userData.productTemplates}
                    onProductTemplateSelected={(data) => this.onProductTemplateSelected(data)}
                  />
                </FormCard> : null
            }
            <FormCard
              title='Options'
              style={{ height: '100%' }}
              id='productImages'
            >
              <ProductOption
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                query={this.state.productQuery}
                queryKey='productQuery'
                userId={this.props.auth.userData.id}
                disabled={true}
              />
            </FormCard>
            <FormCard
              title='Images'
              extra={ this.state.editMode ? <a onClick={() => this.uploadImage()}>Add New</a> : null}
              style={{ height: '100%' }}
            >
              <PhotoUploader
                images={images}
                onDelete={(index) => this.deleteImage(index)}
                onImageSort={this.onImageSort}
                disabled={!this.state.editMode}
              />
            </FormCard>

          </Col>
        </AddContainer>
      </Form>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        productData: state.ProductReducer,
        inventoryData: state.InventoryReducer,
        connectionsData: state.ConnectionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default  Form.create({ name: 'ViewProduct' })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProduct)));
