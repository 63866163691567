import React from 'react';
import moment from 'moment';
import {
   Link
} from 'react-router-dom'
import {
  Modal,
  Card,
  Form,
  Input,
  InputNumber
} from 'antd';
import PayoutList from '../../../algolia/PayoutList';
import LoadingSpinner from '../../LoadingSpinner';
import {
  TextLine
} from './styles';
function PayoutModal(props) {
  const {
    visible,
    handleCancel,
    connections,
    loadingConnection,
    selectedMethod,
    onConnect,
    handleOk,
    creatingPayout,
    selectPayoutMethod,
    defaultCurrency,
    updatePayoutAmount,
    payoutAmount,
    maxPayout,
    creditBonus
  } = props;
  if(loadingConnection) {
    return <LoadingSpinner />
  }
  return (
    <Modal
      title='Choose Payout Method'
      visible={visible}
      onOk={() => handleOk()}
      okText={`Pay ${defaultCurrency}${selectedMethod === 'shopify-credit' && creditBonus > 0 ? `${(payoutAmount * (1 + ( creditBonus / 100))).toFixed(2)} (includes ${creditBonus}% bonus)` : payoutAmount}`}
      onCancel={() => handleCancel()}
      okButtonProps={{ loading: creatingPayout }}
    >
      <PayoutList
        connections={connections}
        onConnect={onConnect}
        filters={`category:Payouts`}
        selectedMethod={selectedMethod}
        selectPayoutMethod={selectPayoutMethod}
      />
      <Form.Item label='Payout Amount'>
          <InputNumber
              type="number"
              suffix={defaultCurrency}
              onChange={(value) => updatePayoutAmount(value)}
              value={payoutAmount}
              max={maxPayout}
              style={{ width: '100%'}}
          />
      </Form.Item>
      
    </Modal>
  )
}

export default PayoutModal;
