import React from 'react';
import {
  Modal,
  Input,
} from 'antd';

function SaveFilter(props) {
  const {
    handleOk,
    visible,
    handleCancel,
    loading,
    updateState,
    saveFilterTitle
  } = props;
  return (
    <Modal
      title='Save Filter'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={loading}
    >
      <Input
        placeholder='Enter Filter Title'
        onChange={(e) => updateState('saveFilterTitle', e.target.value)}
        value={saveFilterTitle}
      />
    </Modal>
  )
}

export default SaveFilter;
