import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  message,
  Modal,
  Switch
} from 'antd';
import moment from 'moment';
import {
  withRouter,
  Link
} from 'react-router-dom';
import queryString from 'query-string';

import history from '../../navigation/history';
import InventoryName from '../../components/ui/forms/inventory/InventoryName';
import InventoryConsigner from '../../components/ui/forms/inventory/InventoryConsigner';
import InventoryPricing from '../../components/ui/forms/inventory/InventoryPricing';
import InventoryDescription from '../../components/ui/forms/inventory/InventoryDescription';
import InventoryIntake from '../../components/ui/forms/inventory/InventoryIntake';
import InventoryLocation from '../../components/ui/forms/inventory/InventoryLocation';
import InventoryStatus from '../../components/ui/forms/inventory/InventoryStatus';

import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  FormCard,
  AddContainer
} from '../styles';

class AddInventory extends React.Component {
  constructor(props) {
    super(props);
    const { location, auth } = this.props
    const product = location.state && location.state.product ? location.state.product : {};
    const {
      id,
      title,
      option1,
      option2,
      option3,
      option1FixedValues,
      option2FixedValues,
      option3FixedValues,
      option1Switch,
      option2Switch,
      option3Switch,
      sku
    } = product;
    const {
      defaultDollarfee,
      defaultFee,
      defaultTaxable
    } = auth.userData
    this.state = {
      productId: id,
      title,
      productSku: sku,
      sku,
      receivedAt: moment(),
      description: '',
      images: [],
      price: '1000',
      compareAt: '',
      lowestPrice: '1000',
      fee: defaultFee || '0',
      dollarfee: defaultDollarfee || 0,
      feeType: '%',
      taxable: defaultTaxable,
      productContents: [],
      locationId: null,
      quantity: 1,
      consignerId: null,
      sourceConsignerId: null,
      option1: '',
      option2: '',
      option3: '',
      brand: '',
      category: '',
      tags: [],
      status: 'Pending',
      expirationDate: moment(),
      returnPolicy: '',
      consignerName: '',
      consignerQuery: '',
      sourceConsignerName: '',
      sourceConsignerQuery: '',
      locationQuery: '',
      productQuery: '',
      options: [option1, option2, option3],
      optionFixedValues: {
        option1FixedValues,
        option2FixedValues,
        option3FixedValues
      },
      optionSwitchs: [option1Switch, option2Switch, option3Switch],
      source: '',
      notes: '',
      isStoreInventory: false,
      locationData: {},
      grams: 0
    }
  }

  componentWillMount() {
    this.handleStoreInventory(false);
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

  async createInventoryForQuantiy() {
    const { createInventory } = this.props;
    for (let i = 0; i < this.state.quantity; i++) {
      console.log(i);
      const id = await createInventory({...this.state, bulkIndex: i});
      console.log(id)
    }
    history.goBack();
  }

  async createInventory() {
    const { createInventory } = this.props;
    const { options, status } = this.state;
    let optionsSelected = true
    options.forEach((option, index) => {
      console.log(option, index)
      if(option && option !== '') {
        console.log(this.state[`option${index + 1}`])
        if(!this.state[`option${index + 1}`] || this.state[`option${index + 1}`] === '') {
          optionsSelected = false;
        }
      }
    })
    if(optionsSelected) {
      if(status && status !== '') {
        if(this.state.quantity > 1) {
          await Modal.confirm({
            content: `Are you sure you want to add ${this.state.quantity} amount of this inventory?`,
            okText: `Add ${this.state.quantity}`,
            onOk: async() => await this.createInventoryForQuantiy()
          })

        } else {
          const id = await createInventory(this.state);
          history.push(`/inventory/${id}`)
        }
      } else {
        message.error('You must enter in a status');
      }
    } else {
      message.error('You must enter in the options for this inventory');
    }
  }

  handleStoreInventory(checked) {
    if(!checked) {
      const {
        consignerAccount
      } = this.props.auth.userData
      this.setState({
        consignerName: consignerAccount && consignerAccount.name ? consignerAccount.name : '',
        consignerQuery: consignerAccount && consignerAccount.name ? consignerAccount.name : '',
        consignerId: consignerAccount && consignerAccount.id ? consignerAccount.id : '',
        isStoreInventory: true
      })
    } else {
      this.setState({
        consignerName: '',
        consignerQuery: '',
        consignerId: null,
        isStoreInventory: false
      })
    }

  }

  render() {
    const { images, title } = this.state;
    const { inventoryData } = this.props;
      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Add Inventory - {title}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => this.createInventory()}
                loading={inventoryData.creatingInventory}
              >
                Create
              </Button>,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard id='inventoryName'>
                <InventoryName
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  query={this.state.productQuery}
                  queryKey='productQuery'
                  userId={this.props.auth.userData.id}

                />
              </FormCard>
              <FormCard
                title="Description"
                id='inventoryDescription'
              >
                <InventoryDescription
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}

                />
              </FormCard>
              <FormCard
                title="Pricing"
                id='inventoryPricing'
              >
                <InventoryPricing
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}
                />
              </FormCard>

            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard
                title="Consigner"
                extra={
                  <Switch
                    value={!this.state.isStoreInventory}
                    onChange={(checked) => this.handleStoreInventory(checked)}
                  />
                }
                id='inventoryConsigner'
              >
                <InventoryConsigner
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  query={this.state.consignerQuery}
                  queryKey='consignerQuery'
                  userId={this.props.auth.userData.id}
                  viewOnly={this.state.isStoreInventory}
                />
              </FormCard>
              {
              this.props.auth.userData.allowRemote
              && this.props.auth.userData.allowDropoff
              && !this.state.isStoreInventory
              ?
              <FormCard
                title="Sell Type"
              >
                <InventoryIntake
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  disabled={this.state.editMode}
                />
              </FormCard>
              : null
            }


              <FormCard
                title="Status"
                id='inventoryStatus'
              >
                <InventoryStatus
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}

                />
              </FormCard>
              <FormCard
                title="Location"
                id='inventoryLocation'
              >
                <InventoryLocation
                  updateState={(key, value) => this.updateState(key, value)}
                  values={this.state}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}
                  query2={this.state.sourceConsignerQuery}
                  queryKey2='sourceConsignerQuery'
                  dataKey='locationData'
                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddInventory));
