import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Card
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';
import SettingsFees from '../../components/ui/forms/settings/SettingsFees';
import AddFee from '../../components/ui/modals/AddFee';
class FeesSetting extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addLocationModalVisible: false,
      name: '',
      addressQuery: '',
      address: {},
      allowPickup: false,
      subLocations: [],
      editLocationModalVisible: false,
      addProductRestrictionsModalVisible: false,
      restrictionType: 'option1FixedValues',
      type: 'consignerTag',
      option1: '',
      option2: '',
      option3: '',
      dollarFeeOperator: 'Always',
      dollarFeeAlert: {'Always': 'Always Apply: Flat fee will apply on top of the %.', 'Minimum': 'Minimum Fee: Flat fee will apply only if greater than % commission.'}
    }
  }

  componentWillMount() {
    this.props.getFees();
  }

  handleAddFeeModal() {
    const {addFeeModalVisible} = this.state;
    this.setState({
      addFeeModalVisible: !addFeeModalVisible
    });
  }
  handleAddFee() {
    this.handleAddFeeModal();
    const {
      consignerTag,
      consignerCountry,
      productCategory,
      dollarfee,
      dollarFeeOperator,
      fee,
      option1,
      option2,
      option3
    } = this.state;
    this.props.createFee(
      {
        productCategory,
        consignerCountry,
        consignerTag,
        dollarfee,
        dollarFeeOperator,
        fee,
        option1,
        option2,
        option3
      }
    );
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

// updateDollarFeeAlert(key, value){
//   if (key == 'dollarFeeOperator'){
//
//   }
// }


  render() {
    const {
      consignerTag,
      consignerCountry,
      productCategory,
      dollarfee,
      dollarFeeOperator,
      dollarFeeAlert,
      fee,
      addFeeModalVisible,
      option1,
      option2,
      option3
    } = this.state;

      return (
        <DashboardContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Fees Settings</PageTitle>}
            style={{ background: 'transparent' }}
          />
          <Card>
            <SettingsFees
              fees={this.props.fee.fees}
              loadingFees={this.props.fee.loadingFees}
              deleteFee={(id) => this.props.deleteFee(id)}
              handleAddFeeModal={() => this.handleAddFeeModal()}
              userData={this.props.auth.userData}
            />
          </Card>

          <AddFee
            creatingLocation={this.props.fee.creatingFee}
            handleOk={() => this.handleAddFee()}
            handleCancel={() => this.handleAddFeeModal()}
            visible={addFeeModalVisible}
            updateState={(key, value) => this.updateState(key, value)}
            consignerTag={consignerTag}
            consignerCountry={consignerCountry}
            productCategory={productCategory}
            option1={option1}
            option2={option2}
            option3={option3}
            fee={fee}
            dollarfee={dollarfee}
            dollarFeeOperator={dollarFeeOperator}
            dollarFeeAlert={dollarFeeAlert}
            defaultCurrency={this.props.auth.userData.defaultCurrency}
          />
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        fee: state.FeeReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeesSetting));
