import React from 'react';
import {
  Statistic
} from 'antd';
import { Container } from './styles';
class ConsignerName extends React.Component {

  render() {
    const {
      values,
      defaultCurrency
    } = this.props;
    const {
      sales,
      balance,
      items,
      isStoreAccount
    } = values;
    const { symbol } = defaultCurrency;
    return (
      <Container>
        <Statistic
          title='Items'
          value={items ? items : 0}
          valueStyle={{ textAlign: 'center' }}
        />
        <Statistic
          title={isStoreAccount ? 'Cost' : 'Balance'}
          value={balance ? balance : 0}
          precision={2}
          prefix={symbol}
          valueStyle={{ textAlign: 'center' }}

        />
        <Statistic
          title='Sales'
          value={sales ? sales : 0}
          precision={2}
          prefix={symbol}
          valueStyle={{ textAlign: 'center' }}

        />
      </Container>
    );
  }
}
export default ConsignerName;
