import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import {
  AutoComplete
} from 'antd';
const { Option } = AutoComplete;
const Menu = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  updateState,
  value,
  disabled
}) => {
  const children = items.map((item, index) => <Option key={index.value} value={item.value}>{item.value}</Option>);

  return (
    <AutoComplete
      disabled={disabled}

      style={{ width: '100%' }}
      dataSource={items}
      onSearch={(value) => {
        updateState(value);
      }}
      value={value}
      onChange={(value) => {
        updateState(value);
      }
    }
    >
    {children}
    </AutoComplete>
  )
};

export default connectMenu(Menu);
