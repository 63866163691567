import axios from 'axios';
import { message } from 'antd';
import {
  LOCATIONS_API,
} from '../../../constants/API';
import {
  UPDATE_LOCATION_LOADING
} from '../types';
import getLocations from './getLocations';


function updateLocation(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_LOCATION_LOADING,
        updatingLocation: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data,
          userId: userData.id
        };
        const hide = message.loading('Updating Location', 0);

        await axios.put(`${LOCATIONS_API}/${data.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_LOCATION_LOADING,
          updatingLocation: false
        });
        hide()
        await dispatch(getLocations());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_LOCATION_LOADING,
          updatingLocation: false
        });
        console.log(e);
      }
  }
}
export default updateLocation;
