import React from 'react';
import {
  Modal,
  Input,
  Form,
  InputNumber,
  Select,
  Typography,
  Button,
  Icon
} from 'antd';


import PropTypes from 'prop-types';
const { Text } = Typography;

const { Option } = Select;

function AddFee(props) {
  const {
    creatingLocation,
    handleOk,
    handleCancel,
    visible,
    updateState,
    consignerTag,
    consignerCountry,
    productCategory,
    fee,
    dollarfee,
    dollarFeeOperator,
    dollarFeeAlert,
    defaultCurrency,
    option1,
    option2,
    option3
  } = props;
  const { symbol } = defaultCurrency;
  return (
    <Modal
      title='Add Fee'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingLocation}
    >
    <Form.Item label='Consigner Tag'>
      <Input
        placeholder='Consigner Tag'
        onChange={(e) => updateState('consignerTag', e.target.value)}
        value={consignerTag}
      />
    </Form.Item>
      <Form.Item label='Consigner Country'>
        <Input
          placeholder='Consigner Country'
          onChange={(e) => updateState('consignerCountry', e.target.value)}
          value={consignerCountry}
        />
      </Form.Item>

      <Form.Item label='Product Catgory'>
        <Input
          placeholder='Product Catgory'
          onChange={(e) => updateState('productCategory', e.target.value)}
          value={productCategory}
        />
      </Form.Item>
      <Form.Item label='Option 1'>
        <Input
          placeholder='Option 1'
          onChange={(e) => updateState('option1', e.target.value)}
          value={option1}
        />
      </Form.Item>
      <Form.Item label='Option 2'>
        <Input
          placeholder='Option 2'
          onChange={(e) => updateState('option2', e.target.value)}
          value={option2}
        />
      </Form.Item>
      <Form.Item label='Option 3'>
        <Input
          placeholder='Option 3'
          onChange={(e) => updateState('option3', e.target.value)}
          value={option3}
        />
      </Form.Item>

      <Form.Item label='Fee'>
        <InputNumber
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
          onChange={(value) => updateState('fee', value)}
          value={fee}
        />
      </Form.Item>

      <Form.Item label='Flat Fee'>
        <Input
          max='Infinity'
          type="number"
          suffix={symbol}
          style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
          onChange={(e) => updateState('dollarfee', e.target.value)}
          value={dollarfee}
        />

        <Select
          value={dollarFeeOperator}
          onChange={(value) => updateState('dollarFeeOperator', value)}
          style={{ width: 'calc(50% - 10px)' }}
        >
          <Option value="Always">Always Apply</Option>
          <Option value="Minimum">Minimum Fee</Option>
        </Select>
      </Form.Item>
      <Text>
      <Button
        shape="circle"
        icon="info"
        theme="filled"
        style={{ marginRight: '10px'}}
      />
      {dollarFeeAlert[dollarFeeOperator]}
      </Text>
    </Modal>
  );
}

export default AddFee;
