import auth0 from 'auth0-js';




//CUSTOM WHITE LABEL (TEMP SOLUTION FOR CUSTOMER)
function getLogo() {
  const domain = window.location.hostname;
  console.log(domain)
  if(domain === 'admin.courtorder.co.za') {
    return 'https://cdn.shopify.com/s/files/1/0003/2689/3632/files/Cologowhite_282x140.jpg?v=1570970471'
  } else {
    return 'https://res.cloudinary.com/frontflipco/image/upload/v1563402781/New_Project_1_jf8jes.png'
  }
}
function getTitle() {
  const domain = window.location.hostname;
  if(domain === 'admin.courtorder.co.za') {
    return 'Court Order'
  } else {
    return 'Frontflip'
  }
}
const Auth0 = new auth0.WebAuth({
  // the following three lines MUST be updated
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email app_metadata',
  grant_type:"client_credentials",
  theme: {
    logo: 'https://example.com/logo.png',
    primaryColor: '#000'
  }
});

function login(appState = {}){
  return async(dispatch) => {
    try {
      await Auth0
      .authorize({
        scope: 'openid profile email app_metadata',
        audience: 'https',
        prompt: 'login',
        appState,
        redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
        logo: getLogo(),
        title: getTitle() // TODO: Dynamically set this and logo
      });
    } catch (e) {
      console.log(e)
    }
  };
}
export default login;
