import styled from 'styled-components';

const Container = styled.div`

`
const Title = styled.h4`
`
const SubTitle = styled.p`
`
export {
  Container,
  Title,
  SubTitle
}
