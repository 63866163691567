import { SET_PRODUCTS_LAST_QUERY } from '../types';

function saveProductsLastQuery(query) {
  return dispatch => {
    dispatch({
      type: SET_PRODUCTS_LAST_QUERY,
      query
    });
  }
}

export default saveProductsLastQuery;
