import styled from 'styled-components';
const Container = styled.div`
  display: flex;
`

const Title = styled.a`
  margin-bottom: 0px;
  color: #000;
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`
export {
  Container,
  Title,
  IconContainer
};
