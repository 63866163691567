import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  UPDATE_PRODUCT_LOADING
} from '../types';


function updateProduct(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_PRODUCT_LOADING,
        updatingProduct: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data,
          userId: userData.id
        };
        console.log(data)
        await axios.put(`${PRODUCTS_API}/${data.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_PRODUCT_LOADING,
          updatingProduct: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_PRODUCT_LOADING,
          updatingProduct: false
        });
        console.log(e);
      }
  }
}
export default updateProduct;
