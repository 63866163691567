import axios from 'axios';
import { message } from 'antd';
import {
  ORDER_API,
} from '../../../constants/API';
import {
  GET_ORDER_FOR_FILTER
} from '../types';


function getOrderForFilter(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: GET_ORDER_FOR_FILTER,
        filteringOrder: true,
        filteredOrder: []

      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const filteredOrder = await axios.post(`${ORDER_API}/filter`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(filteredOrder)
        dispatch({
          type: GET_ORDER_FOR_FILTER,
          filteringOrder: false,
          filteredOrder: !filteredOrder.data.isError ? filteredOrder.data : []
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: GET_ORDER_FOR_FILTER,
          filteringOrder: false,
          filteredOrder: []
        });
        console.log(e);
      }
  }
}
export default getOrderForFilter;
