import styled from 'styled-components';

const Image = styled.img`
  max-height: 48px;
  min-height: 48px;
  width: auto;
  object-fit: contain;
  background: rgba(255,255,255,0.2);
  padding: 0;
  margin-right: 10px;
`
export {
  Image
};
