import React from 'react';
import {
  Modal,
  Input,
  Form,
  Select,
  TimePicker
} from 'antd';
const { Option } = Select;

function AddAppointmentTime(props) {
  const {
    creatingAppointmentTime,
    handleOk,
    handleCancel,
    visible,
    updateState,
    day,
    close,
    open
  } = props;
  return (
    <Modal
      title='Add Appointment Time'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingAppointmentTime}
    >
    <Form.Item label='Day'>
      <Select
        value={day}
        onChange={(value) => updateState('day', value)}
        style={{ width: '100%' }}
      >
        <Option value="Sunday">Sunday</Option>
        <Option value="Monday">Monday</Option>
        <Option value="Tuesday">Tuesday</Option>
        <Option value="Wednesday">Wednesday</Option>
        <Option value="Thursday">Thursday</Option>
        <Option value="Friday">Friday</Option>
        <Option value="Saturday">Saturday</Option>
      </Select>
    </Form.Item>
    <Form.Item label='Open Time'>
      <TimePicker
        defaultValue={open}
        format={'HH:mm'}
        onChange={(value) => updateState('open', value)}
      />
    </Form.Item>
    <Form.Item label='Close Time'>
      <TimePicker
        defaultValue={close}
        format={'HH:mm'}
        onChange={(value) => updateState('close', value)}
      />
    </Form.Item>
    </Modal>
  );
}

export default AddAppointmentTime;
