import React from 'react';
import {
  Modal,
  Form,
  Input,
  Switch
} from 'antd';
import ProductOption from '../../forms/product/ProductOption';

function AddProductTemplate(props) {
  const {
    creatingProductTemplate,
    handleOk,
    handleCancel,
    visible,
    updateState,
    values
  } = props;

  return (
    <Modal
      title='Add Product Template'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingProductTemplate}
    >
      <Form.Item label='Title'>
        <Input
          placeholder='Title'
          onChange={(e) => updateState('title', e.target.value)}
          value={values.title}
        />
      </Form.Item>
      <Form.Item label='Set as default template'>
        <Switch
          onChange={(checked) => updateState('isDefault', checked)}
          checked={values.isDefault}
        />
      </Form.Item>
      <ProductOption
        updateState={updateState}
        values={values}
        disabled={false}
      />

    </Modal>
  );
}

export default AddProductTemplate;
