import React from 'react';
import { Tag } from 'antd';

const { CheckableTag } = Tag;

class InventoryContentList extends React.Component {
  state = {
    selectedTags: [],
  };

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    this.setState({ selectedTags: nextSelectedTags });
  }

  render() {
    const { selectedTags } = this.state;
    const { data } = this.props;

    if((data && data.length <= 0) || !data) {
      return null;
    }
    return (
      <div>
        <h6 style={{ marginRight: 2, display: 'inline' }}>Contents:</h6>
        {data.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </div>
    );
  }
}

export default InventoryContentList;
