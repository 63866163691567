import styled from 'styled-components';
import { Spin } from 'antd';


const Container = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Spinner = styled(Spin)`

`
export {
  Container,
  Spinner
};
