import React from 'react';
import {
  Form,
  Input,
} from 'antd';
import Tag from '../../../Tag';
import DropdownSearch from '../../../../algolia/DropdownSearch';
import SelectDropdown from '../../../SelectDropdown';
import EmployeeStatus from '../../../../../constants/EmployeeStatus';
class EmployeeStatusComponent extends React.Component {

  render() {
    const {
      updateState,
      values,
      queryKey,
      query,
      userId,
      disabled
    } = this.props;
    const {
      status,
      notes,
      tags,
      locationName
    } = values;
    return (
      <div>
          <Form.Item label='Status'>
            <SelectDropdown
              data={EmployeeStatus}
              onChange={(value) => updateState('status', value)}
              value={status}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item label='Notes' autoresize>
            <Input.TextArea
              placeholder='Notes'
              onChange={(e) => updateState('notes', e.target.value)}
              value={notes}
              disabled={disabled}

            />
          </Form.Item>
          <Form.Item label='Location'>
            <DropdownSearch
              searchPlaceholder='Search Locations'
              index='locations'
              keyName='name'
              updateState={updateState}
              stateKey='locationId'
              stateValue='objectID'
              stateKey2='locationName'
              stateValue2='name'
              value={locationName}
              query={query}
              queryKey={queryKey}
              userId={userId}
              viewOnly={disabled}

            />
          </Form.Item>
          <Form.Item label='Tags'>
            <Tag
              title='New Tag'
              updateState={updateState}
              stateKey='tags'
              value={tags}
              disabled={disabled}

            />
          </Form.Item>
      </div>
    );
  }
}
export default EmployeeStatusComponent;
