import React from 'react';
import {
  Container,
  CardContainer,
} from './styles';
function ProductTemplates(props) {
  const {
    productTemplates,
    onProductTemplateSelected,
    selectedTemplate
  } = props
  if(!productTemplates) {
    return null
  }
  return (
    <Container>
      {
        productTemplates.map(template => {
          return (
            <CardContainer
              onClick={() => onProductTemplateSelected(template)}
              type={selectedTemplate === template.id ? 'primary' : 'default' }
            >
              {template.title}
            </CardContainer>
          )
        })
      }

    </Container>
  )
}
export default ProductTemplates;
