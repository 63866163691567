import React, { Component } from 'react';
import places from 'places.js';
import connect from './connector';

class Places extends Component {
  createRef = c => (this.element = c);

  componentDidMount() {
    const { refine, defaultRefinement } = this.props;

    const autocomplete = places({
      container: this.element,
      // Algolia Places options
    });

    autocomplete.on('change', event => {
      refine(event.suggestion.latlng);
      console.log(event.suggestion)
      const {
        suggestion
      } = event;
      const address = {
        country: suggestion.country,
        province: suggestion.administrative,
        address: suggestion.name,
        postcode: suggestion.postcode,
        latlng: suggestion.latlng,
        name: suggestion.value,
        city: suggestion.city,
        countryCode: suggestion.countryCode
      }
      this.props.updateState('address', address)
      this.props.updateState('addressQuery', suggestion.value)
    });

    autocomplete.on('clear', () => {
      refine(defaultRefinement);
    });
  }

  render() {
    const {
      updateState,
      queryKey,
      query,
      disabled
    } = this.props;
    return (
      <div style={{ marginBottom: 20 }}>
        <input
          ref={this.createRef}
          type="search"
          id="address-input"
          placeholder="Search Address"
          value={query}
          disabled={disabled}
          onChange={(e) => {
            updateState(queryKey, e.target.value)
          }}
        />
      </div>
    );
  }
}

export default connect(Places);
