import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  RESYNC_PRODUCT_INVENTORY_LOADING
} from '../types';

function resyncProductInventory(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: RESYNC_PRODUCT_INVENTORY_LOADING,
        resyncingProductInventory: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const data = {
            productId: id,
            userId: userData.id
        }
        await axios.post(`${PRODUCTS_API}/resyncProductInventory`, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        dispatch({
          type: RESYNC_PRODUCT_INVENTORY_LOADING,
          resyncingProductInventory: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: RESYNC_PRODUCT_INVENTORY_LOADING,
          resyncingProductInventory: false
        });
        console.log(e);
      }
  }
}
export default resyncProductInventory;
