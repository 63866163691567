import styled from 'styled-components';
import { Card } from 'antd';
import {
  SearchBox,
} from 'react-instantsearch-dom';

export const Container = styled(Card)`
  margin-top: 10px;
`
export const SearchInput= styled(SearchBox)`
  width: 100%;
  form {
    display: flex;
  }
  input {
    flex-grow: 1;
    outline: none;
    border: 1px solid #f3f3f3;
    padding: 10px;
  }
  button {
    margin-left: 10px;
    padding: 10px;
    width: 40px;
    background-color: #5C6AC4;
    fill: #F9FAFB;
    border: none;
    border-radius: 3px;
  }
`
