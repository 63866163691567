import React, { Component } from 'react'
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { message } from 'antd';

import * as actions from '../../redux/actions';
import history from '../../navigation/history';

class ScanInventory extends Component {
  state = {
    loading: false
  }

  handleScan = async data => {
    if (data && !this.state.loading) {
      this.setState({ loading: true })
      const inventory = await this.props.getInventoryByShortCode(data);

      if (!inventory) {
        this.setState({ loading: false })
        message.error('Invalid QR code');
      } else {
        history.push(`/inventory/${inventory.id}`)
      }
    }
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    return (
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100vw', position: 'fixed', height: '100vh', zIndex: 1000 }}
        />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(null, mapDispatchToProps)(ScanInventory);
