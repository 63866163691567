import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import SearchBar from '../../components/ui/search/SearchBar';
import SearchTable from '../../components/ui/search/SearchTable';
import SearchSortTitle from '../../components/ui/search/SearchSortTitle';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';
import ConsignerSortBy from '../../constants/ConsignerSortBy';

class Consigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCatalog: false,
      query: '',
      pagination: { current: 1 },
      selectedOrder: 0
    }
  }
  componentWillMount() {
    this.props.searchConsigners('', 1, ConsignerSortBy[0])
  }

  selectTableColumns() {
    let columns = [
      {
        title: () =>
        <SearchSortTitle
          title='Name'
          updateState={(key, value) => this.updateState(key, value)}
          selectedOrder={this.state.selectedOrder}
          upIndex={0}
          downIndex={1}
        />,
        dataIndex: '',
        key: '',
        render: (data) => <Link to={`/consigners/${data.id}`}>{data.name}</Link>,

      }
    ]

    //Table columns
    if(window.outerWidth > 992) {
      columns = [
        {
          title: () =>
          <SearchSortTitle
            title='Name'
            updateState={(key, value) => this.updateState(key, value)}
            selectedOrder={this.state.selectedOrder}
            upIndex={0}
            downIndex={1}
          />,
          dataIndex: '',
          key: '',
          render: (data) => <Link to={`/consigners/${data.id}`}>{data.name}</Link>,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Handle',
          dataIndex: 'shortCode',
          key: 'shortCode',
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt'
        },
      ];
    }

    return columns

  }
  updateState(key, value) {
    if(value === '') {
      this.props.searchConsigners(value, 1, ConsignerSortBy[this.state.selectedOrder])
    } else if(key === 'selectedOrder') {
      this.props.searchConsigners(this.state.query, this.state.pagination.current, ConsignerSortBy[value])
    }
    this.setState({
      [key]: value,
      pagination: { current: 1 }
    })
  }

  onTableChange(pagination) {
    const { query } = this.state;
    this.setState({
      pagination
    })
    this.props.searchConsigners(query, pagination.current, ConsignerSortBy[this.state.selectedOrder])

  }
  render() {
    //Table columns
    const columns = this.selectTableColumns()
    const { consignerPortalUrl } = this.props.auth.userData;
      return (
        <DashboardContainer>
          <PageHeader
            title={<PageTitle>Consigners</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
            extra={
              <Button
                onClick={() => window.open(consignerPortalUrl, '_blank')}
              >
                Go to Consigner Portal
              </Button>
            }
          />
          <SearchBar
            updateState={(key, value) => this.updateState(key, value)}
            query={this.state.query}
            searchPlaceholder='Search consigners by email or name'
            onSearch={() => this.props.searchConsigners(this.state.query, this.state.pagination.current,ConsignerSortBy[this.state.selectedOrder])}
            loading={this.props.consignerData.loadingSearchConsigners}
          />
          <SearchTable
            data={this.props.consignerData.searchConsigners}
            columns={columns}
            loading={this.props.consignerData.loadingSearchConsigners}
            pagination={{ total: this.props.consignerData.searchConsignersCount, defaultCurrent: 1, current: this.state.pagination.current }}
            onChange={(pagination) => this.onTableChange(pagination)}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        consignerData: state.ConsignerReducer,
        inventoryData: state.InventoryReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Consigner));
