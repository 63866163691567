import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API
} from '../../../constants/API';
import {
  CHECK_REAUTH_LOADING,
  CHECK_REAUTH
} from '../types';

function checkReauth() {
  return async (dispatch, getState) => {
    dispatch({
      type: CHECK_REAUTH_LOADING,
      checkingReauth: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      console.log(userData)
      const reauthDataResponse = await axios.get(
        `${USERS_API}/checkReauth/${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: CHECK_REAUTH,
        checkingReauth: false,
        reauthData: reauthDataResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: CHECK_REAUTH_LOADING,
        checkingReauth: false
      });
    }
  };
}
export default checkReauth;
