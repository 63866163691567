import React from 'react';
import {
  Modal,
  Form,
  Input
} from 'antd';
import SelectDropdown from '../../SelectDropdown';

function BulkEdit(props) {
  const {
    visible,
    handleCancel,
    handleOk,
    values,
    updateState,
    loading
  } = props;
  const {
    selectedInventory,
    bulkEditKey,
    bulkEditValue
  } = values;

  return (
    <Modal
      title='Bulk Editor'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      okText={`Update ${selectedInventory.length} Inventory`}
      okButtonProps={{ loading }}
    >
      <Form.Item label='Key'>
        <SelectDropdown
          data={['price']}
          onChange={(value) => updateState('bulkEditKey', value)}
          value={bulkEditKey}
        />
      </Form.Item>
      <Form.Item label='Value'>
        <Input
          placeholder='Value'
          onChange={(e) => updateState('bulkEditValue', e.target.value)}
          value={bulkEditValue}
        />
      </Form.Item>
    </Modal>
  );
}

export default BulkEdit;
