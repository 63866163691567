import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API
} from '../../../constants/API';
import {
  GET_SHOPIFY_CSV_LOADING,
  GET_SHOPIFY_CSV
} from '../types';

function getShopifyCSV() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SHOPIFY_CSV_LOADING,
      loadingShopifyCSV: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const csvResponse = await axios.get(
        `${USERS_API}/getShopifyCSV/${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(csvResponse)
      if(!csvResponse.data.isError) {
        dispatch({
          type: GET_SHOPIFY_CSV,
          loadingShopifyCSV: false,
          shopifyCSV: csvResponse.data
        });
      } else {
        message.error('Error - No products or inventory in Frontflip');
        dispatch({
          type: GET_SHOPIFY_CSV_LOADING,
          loadingShopifyCSV: false
        });
      }

    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_SHOPIFY_CSV_LOADING,
        loadingShopifyCSV: false
      });
    }
  };
}
export default getShopifyCSV;
