import React from 'react';
import {
  Form,
  Input,
  Switch
} from 'antd';
import Tag from '../../../Tag';
import {
  SwitchContainers,
  SwitchTitle
} from './styles';
class ProductOption extends React.Component {
  render() {
    const {
      updateState,
      values,
      disabled
    } = this.props;
    const {
      option1,
      option2,
      option3,
      option1Switch,
      option1FixedValues,
      option2Switch,
      option2FixedValues,
      option3Switch,
      option3FixedValues
    } = values;
    return (
      <div>
          <Form.Item label='Option1'>
              <Input
                placeholder='Enter Name For Option 1 (Example Size)'
                onChange={(e) => updateState('option1', e.target.value)}
                value={option1}
                disabled={disabled}
              />
              <SwitchContainers>
                <SwitchTitle>Fixed Values?</SwitchTitle>
                <Switch
                  checked={option1Switch}
                  onChange={(checked) => updateState('option1Switch', checked)}
                  disabled={disabled}
                />
              </SwitchContainers>
              {option1Switch ?
                <Tag
                  title='Add Fixed Value'
                  updateState={updateState}
                  stateKey='option1FixedValues'
                  value={option1FixedValues || []}
                  disabled={disabled}
                /> : null
              }
          </Form.Item>
          <Form.Item label='Option2'>
              <Input
                placeholder='Enter Name For Option 2 (Example Color)'
                onChange={(e) => updateState('option2', e.target.value)}
                value={option2}
                disabled={disabled}
              />
              <SwitchContainers>
                <SwitchTitle>Fixed Values?</SwitchTitle>
                <Switch
                  checked={option2Switch}
                  onChange={(checked) => updateState('option2Switch', checked)}
                  disabled={disabled}

                />
              </SwitchContainers>
              {option2Switch ?
                <Tag
                  title='Add Fixed Value'
                  updateState={updateState}
                  stateKey='option2FixedValues'
                  value={option2FixedValues || []}
                  disabled={disabled}

                /> : null
              }
          </Form.Item>
          <Form.Item label='Option3'>
              <Input
                placeholder='Enter Name For Option 3 (Example Condition)'
                onChange={(e) => updateState('option3', e.target.value)}
                value={option3}
                disabled={disabled}
              />
              <SwitchContainers>
                <SwitchTitle>Fixed Values?</SwitchTitle>
                <Switch
                  checked={option3Switch}
                  onChange={(checked) => updateState('option3Switch', checked)}
                  disabled={disabled}

                />
              </SwitchContainers>
              {option3Switch ?
                <Tag
                  title='Add Fixed Value'
                  updateState={updateState}
                  stateKey='option3FixedValues'
                  value={option3FixedValues || []}
                  disabled={disabled}
                /> : null
              }
          </Form.Item>
      </div>
    );
  }
}
export default ProductOption;
