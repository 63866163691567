import React from 'react';
import {
  Input,
  Form,
  Select,
  DatePicker
} from 'antd';
import DropdownSearch from '../../algolia/DropdownSearch';
import ProductSearchInput from '../../ui/ProductSearchInput';
import SelectDropdown from '../SelectDropdown';
import SortBy from '../SortBy';
import InventoryStatus from '../../../constants/InventoryStatus';
import InventoryDateRangeFilterOption from '../../../constants/InventoryDateRangeFilterOptions';
import {
  Container,
  SearchFilterContainer
} from './styles';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function InventorySearchFilter(props) {
  const {
    values,
    updateState,
    loadInventory,
    userId,
    query,
    queryKey,
    query2,
    queryKey2,
    canSelectProduct,
    query3,
    queryKey3,
    clearProduct,
    disableStatus,
    userData,
    disableSellType,
    query4,
    queryKey4,
    disableWithdrawalLocation,
    products,
    loadingProducts,
    onSearchProducts
  } = props;
  const {
    filterShortCode,
    title,
    id,
    option1,
    option2,
    option3,
    option1Switch,
    option2Switch,
    option3Switch,
    option1FixedValues,
    option2FixedValues,
    option3FixedValues,
    filterLocationName,
    filterConsignerName,
    filterStatus,
    sortByOptions,
    selectedSortBy,
    filterTags,
    isRemote,
    filterWithdrawalLocationName,
    filterDateRangeKey,
    filterDateRange,
    ProductTemplate
  } = values;
  console.log(values)
  console.log(ProductTemplate)
  const options = [option1, option2, option3];
  const optionSwitchs = [option1Switch, option2Switch, option3Switch];
  const optionFixedValues = {option1FixedValues, option2FixedValues, option3FixedValues};
  console.log({options, optionSwitchs, optionFixedValues})
  return (
    <SearchFilterContainer>
      <Container>
        <ProductSearchInput
          data={products}
          defaultValue={id && title && {value: id, label: title}}
          loading={loadingProducts}
          onSearch={onSearchProducts}
          onChange={async (obj) => {
            if(obj) {
              const { label, value } = obj;
              await updateState('id', value)
              await updateState('title', label)
              loadInventory()
            } else {
              await clearProduct()
              loadInventory()
            }

          }}
        />
          {
            options.map((option, index) => {
              if(option && option !== '') {
                const optionSwitch = optionSwitchs[index];
                const optionFixedValue = Object.values(optionFixedValues)[index];
                if(optionSwitch || optionSwitch === 'true' ) {
                  return (
                      <SelectDropdown
                        data={optionFixedValue}
                        placeholder={option}
                        onChange={async(value) => {
                          await updateState(`filterOption${index + 1}`, value)
                          loadInventory()
                        }}
                        value={values[`filterOption${index + 1}`]}
                      />
                  )
                } else {
                  return (
                      <Input
                        placeholder={option}
                        onChange={(e) => updateState(`filterOption${index + 1}`, e.target.value)}
                        value={values[`filterOption${index + 1}`]}
                        onPressEnter={() => loadInventory()}
                      />
                  )
                }

              } else return null
            })
          }
      </Container>
      <Container>

        <Input
          placeholder='QR Code Id'
          onChange={(e) => updateState('filterShortCode', e.target.value)}
          onPressEnter={() => loadInventory()}
          value={filterShortCode}
        />
        <Input
          placeholder='Tags (Comma Seperated)'
          onChange={(e) => updateState('filterTags', e.target.value)}
          onPressEnter={() => loadInventory()}
          value={filterTags}
        />
        {
          !disableStatus ?
            <SelectDropdown
              data={InventoryStatus}
              onChange={async(value) => {
                await updateState('filterStatus', value)
                loadInventory()
              }}
              value={filterStatus}
            /> : null
        }
        {
          userData.allowRemote && userData.allowDropoff && !disableSellType  ?
          <Select
            value={isRemote || null}
            allowClear
            style={{ width: 120 }}
            onChange={async(value) => {
              await updateState('isRemote', value)
              loadInventory()
            }}
          >
            <Option value={null}>All</Option>
            <Option value="false">Drop Off</Option>
            <Option value="true">Remote</Option>
          </Select> : null
        }

        <DropdownSearch
          searchPlaceholder='Search Locations'
          index='locations'
          keyName='name'
          updateState={async (queryKey, value) => {
            await updateState(queryKey, value)
            if(queryKey === 'filterLocationId' || value === '') {
              loadInventory()
            }
          }}
          stateKey='filterLocationId'
          stateValue='objectID'
          stateKey2='filterLocationName'
          stateValue2='name'
          value={filterLocationName}
          query={query}
          queryKey={queryKey}
          userId={userId}
        />
      {
        !disableWithdrawalLocation ?
        <DropdownSearch
          searchPlaceholder='Search Pickup Locations'
          index='locations'
          keyName='name'
          updateState={async (queryKey4, value) => {
            await updateState(queryKey4, value)
            if(queryKey === 'filterWithdrawalLocationId' || value === '') {
              loadInventory()
            }
          }}
          stateKey='filterWithdrawalLocationId'
          stateValue='objectID'
          stateKey2='filterWithdrawalLocationName'
          stateValue2='name'
          value={filterWithdrawalLocationName}
          query={query4}
          queryKey={queryKey4}
          userId={userId}
          filters='AND allowPickup:true'

        /> : null
      }
        <DropdownSearch
          searchPlaceholder='Search Consigners'
          index='consigners'
          keyName='name'
          updateState={async (queryKey, value) => {
            await updateState(queryKey, value)
            if(queryKey === 'filterConsignerId' || value === '') {
              loadInventory()
            }
          }}
          stateKey='filterConsignerId'
          stateValue='objectID'
          stateKey2='filterConsignerName'
          stateValue2='name'
          value={filterConsignerName}
          query={query2}
          queryKey={queryKey2}
          userId={userId}
        />
      </Container>
      <Container>
        <SelectDropdown
          data={InventoryDateRangeFilterOption}
          placeholder='Select date to filter'
          value={filterDateRangeKey}
          onChange={(value) => {
             updateState('filterDateRangeKey', value)
          }}
        />
        <RangePicker
          value={filterDateRange}
          onChange={async(value) => {
             if(value.length === 0) {
               await updateState('filterDateRange', null)
               loadInventory()
             } else {
               await updateState('filterDateRange', value)
               loadInventory()
             }

          }}
        />
      </Container>
      <Container>
        <SortBy
          data={sortByOptions}
          selectedSortBy={selectedSortBy}
          updateState={async(value) => {
            await updateState('selectedSortBy', value)
            loadInventory()
          }}
        />
      </Container>

    </SearchFilterContainer>
  );
}
