import axios from 'axios';
import { message } from 'antd';
import {
  FEES_API,
} from '../../../constants/API';
import {
  DELETE_FEE_LOADING
} from '../types';
import getFees from './getFees';

function deleteFee(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: DELETE_FEE_LOADING,
        deletingFee: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await axios.delete(`${FEES_API}/${id}`, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: DELETE_FEE_LOADING,
          deletingFee: false
        });
        await dispatch(getFees());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: DELETE_FEE_LOADING,
          deletingFee: false
        });
        console.log(e);
      }
  }
}
export default deleteFee;
