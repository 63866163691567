import React from 'react';
import {
  Elements,
  StripeProvider
} from 'react-stripe-elements';
import {
  Redirect
} from 'react-router-dom';
import {
  withRouter
} from 'react-router';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import CheckoutForm from '../components/CheckoutForm';
import queryString from 'query-string';

import {
  DashboardContainer
} from './styles';
class Subscription extends React.Component {
  render() {
    const {
      creatingMembership
    } = this.props.membership;
    const {
      isMember
    } = this.props.auth;

    const parsed = queryString.parse(this.props.location.search);
    const { shop, accessToken } = parsed;

    console.log(this.props.auth)

    if(isMember) {
      if(shop && accessToken) {
        //if shopify redirects
        return <Redirect to={`/shopify?shop=${shop}&accessToken=${accessToken}`} />
      }
      return <Redirect to='/' />
    }
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <DashboardContainer>
          
           <h1>Add a card to continue</h1>
           <Elements>
            <CheckoutForm
              createMembership={(token) => this.props.createMembership(token)}
              isLoading={creatingMembership}
            />
           </Elements>
          </DashboardContainer>
     </StripeProvider>
    );
  }
}


function mapStateToProps(state, props) {
    return {
        membership: state.MembershipReducer,
        auth: state.AuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscription));
