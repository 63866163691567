import React from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker
} from 'antd';
class CustomerName extends React.Component {
  render() {
    const {
      updateState,
      values,
      disabled
    } = this.props;
    const {
      name,
      phone,
      email,
      birthday
    } = values;
    return (
      <div>
        <Form.Item label='Name'>
          <Input
            placeholder='Customer name'
            onChange={(e) => updateState('name', e.target.value)}
            value={name}
            name='customerName'
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label='Email'>
          <Input
            placeholder='Email'
            onChange={(e) => updateState('email', e.target.value)}
            value={email}
            name='customerEmail'
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Phone'>
          <Input
            placeholder='Customer Phone'
            onChange={(e) => updateState('phone', e.target.value)}
            value={phone}
            name='customerPhone'
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Birthday' >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select Date"
            style={{ wnameth: '100%'}}
            onChange={(date) => updateState('birthday', date)}
            value={moment(birthday)}
            name='customerBirthday'
            disabled={disabled}

          />
        </Form.Item>
      </div>
    );
  }
}
export default CustomerName;
