import React, { Component } from 'react';
import {
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import firebase from 'firebase';
import history from '../navigation/history';
import {
  Layout,
  notification
} from 'antd';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import routes from './routes';
import secretRoutes from './secretRoutes';

import Sidebar from '../components/ui/Sidebar';
import TopBar from '../components/ui/TopBar';

import SecretRoute from './SecretRoute';


class Navigation extends Component {
  componentWillMount() {
    this.props.setAuthState();
    window.Canny('initChangelog', {
      appID: '5da90b3424827d3c797cc428',
      position: 'bottom',
      align: 'right',
    });
  }
  
  render() {
    const {
      isLoggedIn,
      userData,
      csvLoading,
      csvUploading
    } = this.props.auth;
    return (
      <div style={styles.container}>
        {isLoggedIn  ?
        <Sidebar
          logout={() => this.props.logout()}
          changePassword={() => this.props.changePassword(userData.email)}
          userData={userData}
        /> : null
        }
        <Layout>
            <TopBar
              isLoggedIn={isLoggedIn}
              userData={userData}
              csvLoading={csvLoading}
              csvUploading={csvUploading}
              pathname={this.props.location.pathname}
              logout={() => this.props.logout()}
            />
            <Switch>
            {
              routes.map((route) => {
                const {
                  exact,
                  path,
                  component
                } = route;
                return (
                  <Route
                    exact={exact}
                    path={path}
                    component={component}
                  />
                )
              }
            )}
            {
              secretRoutes.map((secretRoute) => {
                const {
                  exact,
                  path,
                  component
                } = secretRoute;
                //redirect to reauth route
                return (
                  <SecretRoute
                    auth={this.props.auth}
                    exact={exact}
                    path={path}
                    component={component}
                  />
                )
              }
            )}
              <Route render={() => (<div> Sorry, this page does not exist. </div>)} />
            </Switch>
        </Layout>
      </div>
    );
  }
}
const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden'
  },
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
