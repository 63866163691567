export default [
  {
    title: 'Drop off',
    subtitle: 'Drop off inventory to our store',
    isRemote: false,
    remoteStatus: 'false'
  },
  {
    title: 'Remote sell',
    subtitle: 'Sell inventory remotely, ship when sold',
    isRemote: true,
    remoteStatus: 'Pending'
  },
]
