import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Card,
  Modal,
  Button
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';
import SettingsProductTemplates from '../../components/ui/forms/settings/SettingsProductTemplates';
import AddProductTemplate from '../../components/ui/modals/AddProductTemplate';
import EditProductTemplate from '../../components/ui/modals/EditProductTemplate';

class ProductTemplatesSettings extends React.Component {
  getDefaultState() {
    return {
      title: '',
      option1: '',
      option2: '',
      option3: '',
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getDefaultState()
  }

  componentWillMount() {
    this.props.getProductTemplates();
  }

  handleAddProductTemplate() {
    const {addProductModalVisible} = this.state;
    this.setState({
      addProductModalVisible: !addProductModalVisible
    });
  }
  async handleEditProductTemplate() {
    const { editProductTemplateModalVisible } = this.state;
    this.setState({
      editProductTemplateModalVisible: !editProductTemplateModalVisible
    }, () => {
      if(editProductTemplateModalVisible) {
          this.setState(this.getDefaultState());
      }
    });
  }
  handleOkAddProductTemplate() {
    this.handleAddProductTemplate();
    const {
      title,
      option1,
      option2,
      option3,
      option1FixedValues,
      option1Switch,
      option2FixedValues,
      option2Switch,
      option3FixedValues,
      option3Switch,
      isDefault
    } = this.state;
    this.setState(this.getDefaultState(), () => {
      this.props.createProductTemplate(
        {
          title,
          option1,
          option2,
          option3,
          option1FixedValues,
          option1Switch,
          option2FixedValues,
          option2Switch,
          option3FixedValues,
          option3Switch,
          isDefault
        }
      );
    })
  }
  warnProductTemplate() {
    const { productTemplates } = this.props.productTemplate;
    const { id, option1, option2, option3 } = this.state;
    const selectedProductTemplate = productTemplates.find(template => template.id === id);
    if(
      option1 === selectedProductTemplate.option1 &&
      option2 === selectedProductTemplate.option2 &&
      option3 === selectedProductTemplate.option3
    ) {
      this.handleOkEditProductTemplate();
    } else {
      console.log(selectedProductTemplate);
      Modal.confirm({
        title: 'IMPORTANT: Changes will need to resync Shopify',
        content: 'All products using this template, will temporarily be unavailable as we resync the inventory into your Shopify. This could take 2 seconds per inventory item being resynced.',
        onOk: () => {
          this.handleOkEditProductTemplate(true);
        },
        okType: 'danger',
        okText: 'Update Anyways'
       })
    }
  }
  handleOkEditProductTemplate(shouldResync = false) {
    this.handleEditProductTemplate();
    const {
      id,
      title,
      option1,
      option2,
      option3,
      option1FixedValues,
      option1Switch,
      option2FixedValues,
      option2Switch,
      option3FixedValues,
      option3Switch,
      isDefault
    } = this.state;
    this.setState(this.getDefaultState(), () => {
      this.props.updateProductTemplate(
        {
          id,
          title,
          option1,
          option2,
          option3,
          option1FixedValues,
          option1Switch,
          option2FixedValues,
          option2Switch,
          option3FixedValues,
          option3Switch,
          isDefault,
          shouldResync
        }
      );
    })

  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateProductTemplateDataToState(data) {
    this.setState({
      ...data
    })
  }
  render() {
    const {
      addProductModalVisible,
      editProductTemplateModalVisible
    } = this.state;

      return (
        <DashboardContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Setup Product Templates</PageTitle>}
            style={{ background: 'transparent' }}
            subTitle={<a
                target='_blank'
                href='https://frontflip.kwiki.io/docs/product-templates'
              >
                Template Instructions
              </a>
            }
            extra={[
              this.props.button
            ]}
          />
          <Card>

            {this.props.header || null }
            <SettingsProductTemplates
              productTemplates={this.props.productTemplate.productTemplates}
              loadingProductTemplates={this.props.productTemplate.loadingProductTemplates}
              deleteProductTemplate={(id) => this.props.deleteProductTemplate(id)}
              handleAddProductTemplate={() => this.handleAddProductTemplate()}
              handleEditProductTemplate={() => this.handleEditProductTemplate()}
              updateProductTemplateDataToState={(data) => this.updateProductTemplateDataToState(data)}
            />
          </Card>
          <AddProductTemplate
            creatingProductTemplate={this.props.productTemplate.creatingProductTemplate}
            handleOk={() => this.handleOkAddProductTemplate()}
            handleCancel={() => this.handleAddProductTemplate()}
            visible={addProductModalVisible}
            updateState={(key, value) => this.updateState(key, value)}
            values={this.state}
          />
          <EditProductTemplate
            updatingProductTemplate={this.props.productTemplate.updatingProductTemplate}
            handleOk={() => this.warnProductTemplate()}
            handleCancel={() => this.handleEditProductTemplate()}
            visible={editProductTemplateModalVisible}
            updateState={(key, value) => this.updateState(key, value)}
            values={this.state}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        fee: state.FeeReducer,
        productTemplate: state.ProductTemplateReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductTemplatesSettings));
