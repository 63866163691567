import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API,
} from '../../../constants/API';
import {
  SAVE_FILTER
} from '../types';

function saveFilter(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: SAVE_FILTER,
        savingFilter: true,
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const filteredOrder = await axios.post(`${USERS_API}/saveFilter`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(filteredOrder)
        dispatch({
          type: SAVE_FILTER,
          savingFilter: false,
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: SAVE_FILTER,
          savingFilter: false,
        });
        console.log(e);
      }
  }
}
export default saveFilter;
