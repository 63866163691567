import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API
} from '../../../constants/API';
import {
  GET_CONSIGNER_ITEM_LOADING,
  GET_CONSIGNER_ITEM
} from '../types';

function getConsignerItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CONSIGNER_ITEM_LOADING,
      loadingConsignerItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const consignerItemResponse = await axios.get(
        `${CONSIGNER_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(consignerItemResponse)
      dispatch({
        type: GET_CONSIGNER_ITEM,
        loadingConsignerItem: false,
        consignerItem: consignerItemResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_CONSIGNER_ITEM_LOADING,
        loadingConsignerItem: false
      });
    }
  };
}
export default getConsignerItem;
