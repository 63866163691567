import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API
} from '../../../constants/API';
import {
  GET_INVENTORY_LOADING,
  GET_INVENTORY
} from '../types';

function getInventory(id, subscription_id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INVENTORY_LOADING,
      loadingInventory: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const inventoryResponse = await axios.get(
        `${INVENTORY_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_INVENTORY,
        loadingInventory: false,
        inventory: inventoryResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_INVENTORY_LOADING,
        loadingInventory: false
      });
    }
  };
}
export default getInventory;
