import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  List,
} from 'antd';
import {
  Container,
  TextContainer,
  SubTitle
} from './styles';

function OrderList({
  data,
  defaultCurrency
}) {
  const { symbol } = defaultCurrency;
  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        pageSize: 8,
      }}
      dataSource={data}
      renderItem={item => (
        <Container>
          <Container>
            <TextContainer>
              <Link to={`/orders/${item.id}`}>Order #{item.orderNumber}</Link>
            </TextContainer>
          </Container>
            <SubTitle>{symbol}{item.totalPrice}</SubTitle>
        </Container>
      )}
    />
  )
}

export default OrderList;
