import React from 'react';
import {
  Transfer,
  Button,
  PageHeader,
  Card,
  Form,
  Switch,
  Select,
  Input,
  Modal,
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import history from '../../navigation/history';
import AddProductRestriction from '../../components/ui/modals/AddProductRestriction';
import SettingsProductRestrictions from '../../components/ui/forms/settings/SettingsProductRestrictions';
import DropdownSearch from '../../components/algolia/DropdownSearch';
import ConsignerFields from '../../constants/ConsignerFields';
import {
  DashboardContainer,
  PageTitle
} from '../styles';
const mockData = [];
for (let i = 0; i < 20; i++) {
  mockData.push({
    key: i.toString(),
    title: `content${i + 1}`
  });
}
class SettingsConsigner extends React.Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    this.state = {
      requiredConsignerFields: userData && userData.requiredConsignerFields ? userData.requiredConsignerFields : [],
      selectedKeys: [],
      disabled: false,
      allowDropoff: userData && userData.allowDropoff ? userData.allowDropoff : false,
      allowRemote: userData && userData.allowRemote ? userData.allowRemote : false,
      defaultCarrierAccount: userData && userData.defaultCarrierAccount ? userData.defaultCarrierAccount : '',
      defaultCarrierServiceLevel: userData && userData.defaultCarrierServiceLevel? userData.defaultCarrierServiceLevel : '',
      defaultRemoteReceivingLocation: userData && userData.defaultRemoteReceivingLocation ? userData.defaultRemoteReceivingLocation : null,
      defaultRemoteParcel: userData && userData.defaultRemoteParcel ? userData.defaultRemoteParcel : {
        length: "",
        width: "",
        height: "",
        distance_unit: "",
        weight: "",
        mass_unit: ""
      },
      defaultRemoteReceivingLocationName: userData && userData.defaultRemoteReceivingLocationName ? userData.defaultRemoteReceivingLocationName : '',
      locationQuery: userData && userData.defaultRemoteReceivingLocationName ? userData.defaultRemoteReceivingLocationName : '',

    }
  }
  componentWillMount() {
    this.props.getShippingCarriers()
    this.props.getProductRestrictions()
  }
  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ requiredConsignerFields: nextTargetKeys });
  };
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };
  handleAddProductRestrictionsModal() {
    const {addProductRestrictionsModalVisible} = this.state;
    this.setState({
      addProductRestrictionsModalVisible: !addProductRestrictionsModalVisible
    });
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }
  deleteProductRestriction(id) {
    Modal.confirm({
      title: 'Are you sure you want to delete this product restriction?',
      okText: 'Delete Product Restriction',
      onOk: () => this.props.deleteProductRestriction(id),
      cancelText: 'Cancel'
    })
  }
  handleProductRestrictionOk() {
    this.handleAddProductRestrictionsModal();
    const {
      type,
      trigger,
      productIds,
      restrictionType,
      restrictionValue,
    } = this.state;
    this.props.createProductRestriction({
      type,
      trigger,
      productIds,
      restrictionType,
      restrictionValue
    });
  }
  save() {
    const { requiredConsignerFields } = this.state;
    let { userData } = this.props.auth;
    userData = {
      ...this.state,
      requiredConsignerFields
    }
     console.log(requiredConsignerFields)
  this.props.updateUser(userData);
  }
  render() {
    const {
      requiredConsignerFields,
      selectedKeys,
      allowDropoff,
      allowRemote,
      defaultCarrierAccount,
      defaultCarrierServiceLevel,
      defaultRemoteParcel,
      addProductRestrictionsModalVisible
    } = this.state;
    const { userData, shippingCarriers } = this.props.auth;
    console.log('HERE')
    console.log(shippingCarriers)
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Consigners Settings</PageTitle>}
          style={{ background: 'transparent' }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => this.save()}
              loading={this.props.auth.updatingUser}
            >
              Save
            </Button>,
          ]}
        />
        <Card>
          <Form.Item label='Consigners Required Fields'>
            <Transfer
              dataSource={ConsignerFields}
              titles={['Not Required', 'Required']}
              targetKeys={requiredConsignerFields}
              selectedKeys={selectedKeys}
              onChange={this.handleChange}
              onSelectChange={this.handleSelectChange}
              render={item => item.title}
              style={{ display: 'flex' }}
              listStyle={{ flex: 1 }}
            />
          </Form.Item>
          <Form.Item label='Selling Restrictions'>
            <SettingsProductRestrictions
              productRestrictions={this.props.productRestrictions.productRestrictions}
              handleAddProductRestrictionsModal={() => this.handleAddProductRestrictionsModal()}
              deleteProductRestriction={(id) => this.deleteProductRestriction(id)}
            />
          </Form.Item>
          <Form.Item label='Drop off inventory'>
            <Switch
              onChange={(value) => this.updateState('allowDropoff', value)}
              checked={allowDropoff}
            />
          </Form.Item>
          <Form.Item label='Remote inventory'>
            <Switch
              onChange={(value) => this.updateState('allowRemote', value)}
              checked={allowRemote}
            />
          </Form.Item>
          {
            allowRemote ?
            <div>
              <Form.Item label='Default Shipping For Remote Inventory'>
                <Select
                  style={{ width: '100%' }}
                  value={defaultCarrierAccount}
                  onChange={(value) => this.updateState('defaultCarrierAccount', value)}
                >
                  {
                    shippingCarriers.map(carrier => {
                      return (
                        <Select.Option value={carrier.object_id}>{carrier.title || carrier.tile}</Select.Option>
                      )
                    })
                  }
                </Select>
                {
                  shippingCarriers && shippingCarriers.find(x => x.object_id === defaultCarrierAccount) && shippingCarriers.find(x => x.object_id === defaultCarrierAccount).serviceLevels ?
                  <Select
                    style={{ width: '100%' }}
                    value={defaultCarrierServiceLevel}
                    onChange={(value) => this.updateState('defaultCarrierServiceLevel', value)}
                  >
                    {
                      Object.keys(shippingCarriers.find(x => x.object_id === defaultCarrierAccount).serviceLevels).map(serviceLevel => {
                        return (
                          <Select.Option value={serviceLevel}>{serviceLevel}</Select.Option>
                        )
                      })
                    }
                  </Select> : null
                }
              </Form.Item>
              <Form.Item label='Remote Inventory Receiving Address'>
                <DropdownSearch
                  searchPlaceholder='Search Locations'
                  index='locations'
                  keyName='name'
                  updateState={(key, value) => this.updateState(key, value)}
                  stateKey='defaultRemoteReceivingLocation'
                  stateValue='objectID'
                  stateKey2='defaultRemoteReceivingLocationName'
                  stateValue2='name'
                  value={this.state.defaultRemoteReceivingLocationName}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={userData.id}
                />
              </Form.Item>
              <Form.Item label='Default Remote Parcel'>
                <Input
                  placeholder='Length'
                  value={defaultRemoteParcel.length}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'length', e.target.value)}
                />
                <Input
                  placeholder='Width'
                  value={defaultRemoteParcel.width}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'width', e.target.value)}
                />
                <Input
                  placeholder='Height'
                  value={defaultRemoteParcel.height}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'height', e.target.value)}
                />
                <Input
                  placeholder='Distance Unit (in)'
                  value={defaultRemoteParcel.distance_unit}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'distance_unit', e.target.value)}
                />
                <Input
                  placeholder='Weight'
                  value={defaultRemoteParcel.weight}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'weight', e.target.value)}
                />
                <Input
                  placeholder='Mass Unit(lb)'
                  value={defaultRemoteParcel.mass_unit}
                  onChange={(e) => this.updateStateObj('defaultRemoteParcel', 'mass_unit', e.target.value)}
                />
              </Form.Item>
            </div> : null
          }
        </Card>
        <AddProductRestriction
          creatingProductRestriction={this.props.productRestrictions.creatingProductRestriction}
          handleOk={() => this.handleProductRestrictionOk()}
          handleCancel={() => this.handleAddProductRestrictionsModal()}
          visible={addProductRestrictionsModalVisible}
          updateState={(key, value) => this.updateState(key, value)}
          updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
          values={this.state}
        />
      </DashboardContainer>
    );
  }
}

function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        productRestrictions: state.ProductRestrictionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsConsigner));
