import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API,
} from '../../../constants/API';
import {
  UPDATE_CONSIGNER_LOADING
} from '../types';


function updateInventory(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_CONSIGNER_LOADING,
        updatingConsigner: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data,
          userId: userData.id
        };
        await axios.put(`${CONSIGNER_API}/${data.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_CONSIGNER_LOADING,
          updatingConsigner: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_CONSIGNER_LOADING,
          updatingConsigner: false
        });
        console.log(e);
      }
  }
}
export default updateInventory;
