import React from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;
function SavedFilterTabs(props) {
  const {
    savedFilters,
    onTabSelect,
    handleSavedFilter
  } = props;
  return (
    <Tabs
      defaultActiveKey="Find"
      onChange={onTabSelect}
      onEdit={handleSavedFilter}
      type="editable-card"
    >
      <TabPane
        tab='Find'
        key='Find'
        closable={false}
      />

      {savedFilters.map((filters, index) =>
        <TabPane
          tab={JSON.parse(filters).saveFilterTitle}
          key={index}
        />
      )}
    </Tabs>
);
}
export default SavedFilterTabs;
