import {
  CREATE_PAYOUT_LOADING,
} from "../actions/types"

let defaultState = {
  creatingPayout: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_PAYOUT_LOADING:
          return {
            ...state,
            creatingPayout: action.creatingPayout,
          };
        default:
            return state;
    }
};
