import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  Empty
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import moment from 'moment';
import CustomerName from '../../components/ui/forms/customers/CustomerName';
import CustomerStatus from '../../components/ui/forms/customers/CustomerStatus';
import CustomerConsignerAccount from '../../components/ui/forms/customers/CustomerConsignerAccount';
import CustomerStats from '../../components/ui/forms/customers/CustomerStats';
import CustomerTimeline from '../../components/ui/forms/customers/CustomerTimeline';
import CustomerOrder from '../../components/ui/forms/customers/CustomerOrder';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  AddContainer,
  FormCard
} from '../styles';

class ViewCustomerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      birthday: moment(),
      status: '',
      notes: '',
      tags: [],
      consignerId: null,
      consignerName: '',
      consignerQuery: '',
      locationId: null,
      locationName: '',
      locationQuery: '',
      editMode: false
    }
  }
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getCustomerItem(id);
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { customerData } = this.props;
    const newCustomerItem = customerData.customerItem;
    //Old Inventory Item
    const oldCustomerItem = oldProps.customerData.customerItem;
    if(newCustomerItem) {
      if(oldCustomerItem !== newCustomerItem) {
        this.setState({
          ...newCustomerItem,
          locationQuery: newCustomerItem.locationName,
          consignerQuery: newCustomerItem.consignerName,
        })
      }
    }
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

  async updateCustomer() {
    const { updateCustomer } = this.props;
    await updateCustomer(this.state);
    window.location.reload()
  }

  cancelEdit() {
    const id = this.props.match.params.id
    this.props.getCustomerItem(id);
    this.setState({ editMode: false })
  }

  render() {
    const { customerData } = this.props;
    const { customerItem, loadingCustomerItem } = customerData;
    if(loadingCustomerItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!customerItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const { name, timelineData, orderData } = customerItem;
      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>{name}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              this.state.editMode ?
              <Button
                key="1"
                onClick={() => this.cancelEdit()}
                loading={customerData.updatingCustomer}
              >
                Cancel
              </Button> : null,
              this.state.editMode ?
              <Button
                key="2"
                type="primary"
                onClick={() => this.updateCustomer()}
                loading={customerData.updatingCustomer}
              >
                Update
              </Button> : null,

              !this.state.editMode ?
              <Button
                key="3"
                type="primary"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button> : null,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard>
                <CustomerStats
                  values={this.state}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}
                />
              </FormCard>
              <FormCard>
                <CustomerName
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  disabled={!this.state.editMode}
                />
              </FormCard>
              <FormCard
                title="Orders"
              >
                <CustomerOrder
                  data={orderData}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}
                />
              </FormCard>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard
                title="Status"
              >
                <CustomerStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}


                />
              </FormCard>
              <FormCard
                title="Connect Consigner Account"
              >
                <CustomerConsignerAccount
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.consignerQuery}
                  queryKey='consignerQuery'
                  userId={this.props.auth.userData.id}
                  disabled={!this.state.editMode}


                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        customerData: state.CustomerReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCustomerItem));
