import React from 'react';
import {
  Modal,
  Input,
  Form,
  Switch,
  InputNumber
} from 'antd';
import PropTypes from 'prop-types';
import PlacesInput from '../../../algolia/PlacesInput';
import Tag from '../../Tag';

AddLocation.propTypes = {
  creatingLocation: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  visible: PropTypes.bool,
  updateState: PropTypes.func,
  name: PropTypes.string,
  addressQuery: PropTypes.string,
  address: PropTypes.object
};
AddLocation.defaultProps = {
  creatingLocation: false,
  handleOk: (...props) => console.log(props),
  handleCancel: (...props) => console.log(props),
  visible: false,
  updateState: (...props) => console.log(props),
  name: '',
  addressQuery: '',
  address: {}
};


function AddLocation(props) {
  const {
    creatingLocation,
    handleOk,
    handleCancel,
    visible,
    updateState,
    updateStateObj,
    values
  } = props;
  const {
    address,
    name,
    addressQuery,
    allowPickup,
    subLocations,
    enableAppointments,
    enableSameDayAppointments,
    appointmentInterval
  } = values;
  return (
    <Modal
      title='Add Location'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingLocation}
      width={'60%'}

    >
      <Form.Item label='Name'>
        <Input
          placeholder='Name'
          onChange={(e) => updateState('name', e.target.value)}
          value={name}
          data-test='AddLocationModalName'
        />
      </Form.Item>
      <Form.Item label='Sub Locations'>
        <Tag
          title='New Sub Location'
          updateState={updateState}
          stateKey='subLocations'
          value={subLocations || []}
        />
      </Form.Item>
      <Form.Item label='Allow Pickup'>
        <Switch
          onChange={(value) => updateState('allowPickup', value)}
          value={allowPickup}
        />
      </Form.Item>
      <Form.Item label='Enable Appointments'>
        <Switch
          onChange={(value) => updateState('enableAppointments', value)}
          checked={enableAppointments}
        />
      </Form.Item>
      <Form.Item label='Accept Same Day Appointments'>
        <Switch
          onChange={(value) => updateState('enableSameDayAppointments', value)}
          checked={enableSameDayAppointments}
        />
      </Form.Item>
      <Form.Item label='Time interval (in minutes)'>
        <InputNumber
          placeholder='Time interval'
          onChange={(value) => updateState('appointmentInterval', value)}
          value={appointmentInterval}
        />
      </Form.Item>
      <Form.Item label='Address'>
        <PlacesInput
          updateState={(key, data) => updateState(key, data)}
          queryKey='addressQuery'
          query={addressQuery}
        />
        <Input
          placeholder='Address'
          value={address.address}
          onChange={(e) => updateStateObj('address', 'address', e.target.value)}

        />
        <Input
          placeholder='City'
          value={address.city}
          onChange={(e) => updateStateObj('address', 'city', e.target.value)}
        />
        <Input
          placeholder='State/Province'
          value={address.province}
          onChange={(e) => updateStateObj('address', 'province', e.target.value)}
        />
        <Input
          placeholder='Country'
          value={address.country}
          onChange={(e) => updateStateObj('address', 'country', e.target.value)}

        />
        <Input
          placeholder='Country Code'
          value={address.countryCode}
          onChange={(e) => updateStateObj('address', 'countryCode', e.target.value)}

        />
        <Input
          placeholder='Postcode'
          value={address.postcode}
          onChange={(e) => updateStateObj('address', 'postcode', e.target.value)}

        />
      </Form.Item>
    </Modal>
  );
}

export default AddLocation;
