import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Empty,
  Button,
  Card,
  List,
  message
} from 'antd'
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import doesExistInArrayOfObj from '../../helpers/data/doesExistInArrayOfObj';
import history from '../../navigation/history';
import { SHOPIFY } from '../../constants/Integrations';
import ShopifyIntegrationInstructions from '../../constants/ShopifyIntegrationInstructions';
import {
  DashboardContainer,
  PageTitle
} from '../styles';


class ConnectProducts extends React.Component {

  componentWillMount() {
    this.props.loadConnections();
  }

  render() {
    const {
      loadingConnection,
      connections
    } = this.props.connection;

    if(loadingConnection) {
      return <LoadingSpinner />
    }
    const connection = doesExistInArrayOfObj(connections, SHOPIFY);
    return (
      <DashboardContainer>
        <PageHeader
          title={<PageTitle>Connect Shopify</PageTitle>}
          style={{ background: 'transparent', marginBottom: 10 }}
          extra={[
            !connection ?
              <Button onClick={() => {
                message.info('If you have already connected, your data may still be importing please try again in a few minutes.')
                this.props.loadConnections()
              }}>Refresh</Button>
            : null
          ]}
        />

        <Card>
        {/*
        !connection ?
          <Empty
            image={require('../../images/shopify.svg')}
            imageStyle={{
               height: 60,
             }}
            description=''
          >
            <h2>Connecting Shopify..</h2>
            <p>Please try to refresh in a few minutes</p>
            <LoadingSpinner />

          </Empty> :
          */
          connection ?
            <Empty
              image={require('../../images/shopify.svg')}
              imageStyle={{
                 height: 60,
               }}
              description=''
            >
              <h2>Shopify Connected</h2>
              <p>Product, variant, orders, inventory, customers are synced.</p>
              <Button
                onClick={() => history.push(`/onboarding/editConsigners`)}
                type='primary'
              >
              Next
              </Button>
            </Empty>
            :
            <Empty
              image={require('../../images/shopify.svg')}
              description=''
              imageStyle={{
                 height: 60,
               }}
            >

              <h2>Connect Shopify</h2>
              <p>Connect shopify to pull in product and variant, and sync product, variant orders, inventory, customers are synced.</p>
              <List
                header={<h1>What happens when you connect?</h1>}
                grid={{ gutter: 16, column: 4 }}
                dataSource={ShopifyIntegrationInstructions}
                renderItem={item => (
                  <List.Item >
                    <Card
                      title={item.title}
                      hoverable
                    >
                      {item.desc}
                    </Card>
                  </List.Item>
               )}
              />
              <Button
                type='primary'
                onClick={() => history.push(`/onboarding/integrations/add/${SHOPIFY}`)}
              >
                Connect
              </Button>
              <Button
                onClick={() => history.push(`/onboarding/editConsigners`)}
              >
                Skip
              </Button>
            </Empty>
        }
        </Card>

      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectProducts));
