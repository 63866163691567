import React from 'react';
import { Empty } from 'antd';
import LoadingSpinner from '../../../LoadingSpinner';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove
} from './styles';

class SettingsFees extends React.Component {

  render() {
    const {
      fees,
      loadingFees,
      deleteFee,
      handleAddFeeModal,
      userData
    } = this.props;
    const { defaultCurrency } = userData;
    const { symbol } = defaultCurrency
    if(loadingFees) {
      return <LoadingSpinner />
    }
    if(!fees || fees.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddFeeModal()}
            >
              Add Fee
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        fees.map((item) => {
          return (
            <ItemContainer>
              <Title>
              Category - {item.productCategory} <br />
              Consigner Country - {item.consignerCountry} <br />
              Consigner Tag - {item.consignerTag} <br />
              Option 1 - {item.option1} <br />
              Option 2 - {item.option2} <br />
              Option 3 - {item.option3}
              </Title>
              <SubTitle>
              {item.fee ? `${item.fee}%` : ''}{item.dollarfee ? ` and ${symbol}${item.dollarfee}` : ''}
              </SubTitle>
              <Remove
                onClick={() => {
                  deleteFee(item.id)
                }}
              >
                REMOVE
              </Remove>
            </ItemContainer>
          )
        })
      }
      <a
        onClick={() => handleAddFeeModal()}
        data-test='SettingsAddLocationButton'

      >
        Add Fee
      </a>
      </Container>
    );
  }
}
export default SettingsFees;
