import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  MERGE_PRODUCT_LOADING
} from '../types';
import getProduct from './getProduct';

function mergeProducts(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: MERGE_PRODUCT_LOADING,
        mergingProducts: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const productResponse = await axios.post(`${PRODUCTS_API}/merge`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(productResponse)
        await dispatch(getProduct(data.productId));


        dispatch({
          type: MERGE_PRODUCT_LOADING,
          mergingProducts: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: MERGE_PRODUCT_LOADING,
          mergingProducts: false
        });
        console.log(e);
      }
  }
}
export default mergeProducts;
