import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  withRouter
} from 'react-router-dom';
import * as actions from '../redux/actions';
import { PageHeader, DatePicker } from 'antd'
import AnalyticCards from '../components/ui/AnalyticCards';
import {
  DashboardContainer,
} from './styles';
const { RangePicker } = DatePicker;

class Dashboard extends React.Component {
  componentWillMount() {
    const date = [
      moment().subtract(1, 'day'),
      moment()
    ]
    this.props.getAnalytics(date)
  }
  render() {
    const { loadingAnalytics, analytics } = this.props.analyticsData;
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title='Dashboard'
            style={{ background: 'transparent' }}
            breadcrumb={null}
            footer={[
              <RangePicker
                defaultValue={[moment().subtract(1, 'day'), moment()]}
                onChange={(date) => this.props.getAnalytics(date)}
                id='datepicker'
              />
            ]}
          />
          <AnalyticCards
            loadingAnalytics={loadingAnalytics}
            analytics={analytics}
            id='cards'
            defaultCurrency={this.props.auth.userData.defaultCurrency}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        analyticsData: state.AnalyticsReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
