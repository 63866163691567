import {
  CREATE_CONSIGNER_LOADING,
  GET_CONSIGNER_ITEM,
  GET_CONSIGNER_ITEM_LOADING,
  UPDATE_CONSIGNER_LOADING,
  GET_CONSIGNERS,
  GET_CONSIGNERS_LOADING,
  BULK_UPSERT_CONSIGNERS_LOADING,
  SEARCH_CONSIGNERS,
  SEARCH_CONSIGNERS_LOADING,
  GET_CONSIGNER_LEDGER,
  GET_CONSIGNER_LEDGER_LOADING
} from "../actions/types"

let defaultState = {
  creatingConsigner: false,
  loadingConsignerItem: false,
  consignerItem: null,
  updatingConsigner: false,
  consigners: [],
  loadingConsigners: false,
  bulkUpsertingConsigners: false,
  loadingSearchConsigners: false,
  searchConsigners: [],
  searchConsignersCount: 0,
  consignerLedgerData: {},
  loadingConsignerLedger: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_CONSIGNER_LOADING:
          return {
            ...state,
            creatingConsigner: action.creatingConsigner
          };
        case GET_CONSIGNER_ITEM:
          return {
            ...state,
            consignerItem: action.consignerItem,
            loadingConsignerItem: action.loadingConsignerItem
          }
        case GET_CONSIGNER_ITEM_LOADING:
          return {
            ...state,
            loadingConsignerItem: action.loadingConsignerItem
          }
        case UPDATE_CONSIGNER_LOADING:
          return {
            ...state,
            updatingConsigner: action.updatingConsigner
          }
        case GET_CONSIGNERS:
          return {
            ...state,
            consigners: action.consigners,
            loadingConsigners: action.loadingConsigners
          }
        case GET_CONSIGNERS_LOADING:
          return {
            ...state,
            loadingConsigners: action.loadingConsigners
          }
        case BULK_UPSERT_CONSIGNERS_LOADING:
          return {
            ...state,
            bulkUpsertingConsigners: action.bulkUpsertingConsigners
          }
        case SEARCH_CONSIGNERS:
          return {
            ...state,
            loadingSearchConsigners: action.loadingSearchConsigners,
            searchConsigners: action.searchConsigners,
            searchConsignersCount: action.searchConsignersCount
          }
        case SEARCH_CONSIGNERS_LOADING:
          return {
            ...state,
            loadingSearchConsigners: action.loadingSearchConsigners,
          }
        case GET_CONSIGNER_LEDGER_LOADING:
          return {
            ...state,
            loadingConsignerLedger: action.loadingConsignerLedger
          }
        case GET_CONSIGNER_LEDGER:
          return {
            ...state,
            loadingConsignerLedger: action.loadingConsignerLedger,
            consignerLedgerData: action.consignerLedgerData
          }
        default:
            return state;
    }
};
