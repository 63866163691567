import React from 'react';
import {
  Checkbox
} from 'antd';

class EmployeeRestrictedRoutes extends React.Component {

  render() {
    const {
      updateState,
      values,
      routes,
      disabled
    } = this.props;
    const {
      restrictedRoutes
    } = values;
    return (
      <Checkbox.Group
        options={routes}
        value={restrictedRoutes}
        onChange={(value) => updateState('restrictedRoutes', value)}
        disabled={disabled}

      />

    );
  }
}
export default EmployeeRestrictedRoutes;
