import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

`
const ItemContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
`

const Title = styled.p`

`
const SubTitle = styled.p`

`
export {
  Container,
  ItemContainer,
  Title,
  SubTitle
}
