import React from 'react';
import {
  InstantSearch,
  Configure
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import Menu from '../../ui/algolia/Menu';
import {
  Container,
} from './styles';
const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

export default function AlgoliaSearch(props) {
  const {
    index,
    attribute,
    updateState,
    value,
    disabled,
    placeholder,
    userId,
    filters
  } = props;
  return (
    <Container>
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
      >
        <Configure filters={`userId:${userId} ${filters || ''}`} />
        <Menu
          attribute={attribute}
          updateState={updateState}
          value={value}
          searchable
          disabled={disabled}
          placeholder={placeholder}
        />
      </InstantSearch>
    </Container>
  );
}
