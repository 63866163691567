import axios from 'axios';
import { message } from 'antd';
import {
  ORDER_API
} from '../../../constants/API';
import {
  GET_ORDER_ITEM_LOADING,
  GET_ORDER_ITEM
} from '../types';

function getOrderItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ORDER_ITEM_LOADING,
      loadingOrderItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const orderItemResponse = await axios.get(
        `${ORDER_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_ORDER_ITEM,
        loadingOrderItem: false,
        orderItem: orderItemResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_ORDER_ITEM_LOADING,
        loadingOrderItem: false
      });
    }
  };
}
export default getOrderItem;
