import {
  CREATE_PRODUCT_TEMPLATE_LOADING,
  DELETE_PRODUCT_TEMPLATE_LOADING,
  GET_PRODUCT_TEMPLATES_LOADING,
  GET_PRODUCT_TEMPLATES,
  UPDATE_PRODUCT_TEMPLATE_LOADING
} from "../actions/types"

let defaultState = {
  creatingProductTemplate: false,
  deletingProductTemplate: false,
  loadingProductTemplates: false,
  productTemplates: [],
  updatingProductTemplate: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_TEMPLATE_LOADING:
          return {
            ...state,
            creatingProductTemplate: action.creatingProductTemplate
          };
        case DELETE_PRODUCT_TEMPLATE_LOADING:
          return {
            ...state,
            deletingProductTemplate: action.deletingProductTemplate
          }
        case GET_PRODUCT_TEMPLATES_LOADING:
          return {
            ...state,
            loadingProductTemplates: action.loadingProductTemplates
          }
        case GET_PRODUCT_TEMPLATES:
          return {
            ...state,
            loadingProductTemplates: action.loadingProductTemplates,
            productTemplates: action.productTemplates
          }
        case UPDATE_PRODUCT_TEMPLATE_LOADING:
          return {
            ...state,
            updatingProductTemplate: action.updatingProductTemplate
          }
        default:
            return state;
    }
};
