import styled from 'styled-components';
import {
  Col,
  Button
} from 'antd';
import {
  PRIMARY_COLOR
} from '../../../../constants/Colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: #ededed;
    background-color: #f3f3f3;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 50% !important;
    flex-wrap: wrap;
`

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`
export const Image = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`
export const Category = styled.p`
  font-size: 12px;
`
export const Active = styled.p`
  font-size: 12px;
  color: ${PRIMARY_COLOR}
`

export const ConnectButton = styled(Button)`

`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

`
