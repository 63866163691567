import React from 'react';
import { 
    Input,
    Form,
    Button
} from 'antd';
import styled from 'styled-components';
import {
    Logo
} from '../styles'
const Container = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`
class GettingStarted extends React.Component {
    render() {
        const {
            updateState,
            company,
            logo,
            uploadImage
        } = this.props;
        return (
            <Container>
                <Form.Item label='Add your logo'>
                    {
                    !logo || logo === '' ?
                    <Button
                        onClick={() => uploadImage('logo')}
                    >
                    Upload
                    </Button> :
                        <Logo
                            src={logo}
                            onClick={() => uploadImage('logo')}
                        />
                    }
                </Form.Item>
                <Form.Item label='What is your company name?'>
                    <Input
                        placeholder='Company name'
                        onChange={(e) => updateState('company', e.target.value)}
                        value={company}
                    />
                </Form.Item>
            </Container>
        )
    }
}
export default GettingStarted;