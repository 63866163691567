import axios from 'axios';
import { message } from 'antd';
import {
  EMPLOYEE_API
} from '../../../constants/API';
import {
  SEND_EMPLOYEE_INVITE
} from '../types';


function sendEmployeeInvite(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: SEND_EMPLOYEE_INVITE,
        sendingEmployeeInvite: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const inviteResponse = await axios.post(`${EMPLOYEE_API}/invite`, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(inviteResponse)
        if(inviteResponse.data.isError) {
          message.error(String(inviteResponse.data.errorMessage));

        } else {
          message.success('Sent Invite')
        }
        dispatch({
          type: SEND_EMPLOYEE_INVITE,
          sendingEmployeeInvite: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: SEND_EMPLOYEE_INVITE,
          sendingEmployeeInvite: false
        });
        console.log(e);
      }
  }
}
export default sendEmployeeInvite;
