import React from 'react';
import {
  Form,
  Input,
} from 'antd';
import Tag from '../../../Tag';
import AttributeSearch from '../../../../algolia/AttributeSearch';

class ProductName extends React.Component {
  render() {
    const {
      updateState,
      values,
      disabled,
      userId,
      form
    } = this.props;
    const {
      title,
      sku,
      brand,
      category,
      tags,
      description
    } = values;
    const {
      getFieldDecorator
    } = form;

    return (
      <div>
          <Form.Item label='Title'>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Please input product title!' }],
              initialValue: title
            })(
              <Input
                placeholder='Title'
                disabled={disabled}
              />
            )}
          </Form.Item>
          <Form.Item label='Sku'>
            {getFieldDecorator('sku', {
              rules: [{ required: true, message: 'Please input product sku!' }],
              initialValue: sku
            })(
              <Input
                placeholder='Sku'
                disabled={disabled}

              />
            )}
          </Form.Item>
            <Form.Item label='Description' autoresize>
              {getFieldDecorator('description', {initialValue: description})(

                <Input.TextArea
                placeholder='Description'
                disabled={disabled}
                
                />
                )}
            </Form.Item>

            <Form.Item
              label='Brand'
            >
            {getFieldDecorator('brand', {
              rules: [{ required: true, message: 'Please input product brand!' }],
              initialValue: brand
            })(
              <AttributeSearch
                index='products'
                attribute='brand'
                updateState={(value) => this.props.form.setFieldsValue({'brand': value})}
                disabled={disabled}
                userId={userId}
              />
            )}
            </Form.Item>
            <Form.Item 
              label='Category'
              required
              >
              {getFieldDecorator('category', {
                rules: [{ required: true, message: 'Please input product category!' }],
                initialValue: category
              })(
                <AttributeSearch
                  index='products'
                  attribute='category'
                  updateState={(value) => this.props.form.setFieldsValue({'category': value})}
                  disabled={disabled}
                  userId={userId}

                />
              )}
            </Form.Item>

          <Form.Item label='Tags'>
            <Tag
              title='New Tag'
              updateState={updateState}
              stateKey='tags'
              value={tags}
              disabled={disabled}

            />
          </Form.Item>
      </div>
    );
  }
}
export default ProductName;
