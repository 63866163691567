import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    Card,
    Button,
    Tooltip,
    Checkbox,
    message
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
const Container = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`
class UpdateData extends React.Component {
    uploadCSV(csv) {
        if(this.props.acceptedTerms) {
            this.props.uploadOnboardingCSV(csv);
            this.props.updateState('csv', null);
            this.props.updateState('csvFileName', null);
        } else {
            message.error('You must accept the terms to continue');
        }
        
    }
    render() {
        const { csv, csvFileName, acceptedTerms } = this.props;
        return (
            <Container>
                <Card 
                    style={{ marginBottom: 10 }}
                    title='Step 1) Export your csv'
                    extra={
                        <a 
                            onClick={() => this.props.getOnboardingCSV()}
                        >
                            Export CSV
                        </a>
                    }
                >
                <p>
                    Click export csv to get your Shopify Inventory Data. This CSV will have columns to add in consigner, and retail information. The export CSV will be emailed to you. View the video below on how to update the CSV correctly.   
                </p>

                    
                </Card>
                <Card
                    style={{ marginBottom: 10 }}
                    title='Step 2) Update and import your csv'
                    extra={
                        csv && csvFileName ?
                            <div>   
                                <Tooltip 
                                    title="Uploading CSV will overwrite your barcodes in Shopify for all consignment products. All consignment products should now be controlled via Frontflip. Retail products are still controlled via Shopify."
                                >
                                    <Checkbox 
                                        onChange={(e) => this.props.updateState('acceptedTerms', e.target.checked)}
                                        checked={acceptedTerms}
                                    > 
                                    I acknowledge the importing terms. - <a href='https://www.notion.so/frontflipco/What-happens-after-uploading-my-CSV-0a59b811e671494897735998e2c28fe0?v=b39f8b172d7f48f6b5db51e3488dc225' target='_blank'>learn more</a>
                                    </Checkbox>
                                </Tooltip>
                                <Button 
                                    type='primary'
                                    onClick={() => this.uploadCSV(csv)}
                                >
                                    Start Importing '{csvFileName}' into Frontflip
                                </Button>
                            </div>
                             : 
                            <a 
                                onClick={() => this.props.uploadCSV('csv')}
                            >
                                Upload CSV
                            </a>                        
                    }
                >
                    <div 
                        style={{ padding: "68.7% 0 0 0", position: "relative" }}
                    >
                        <iframe 
                            src="https://player.vimeo.com/video/397438263?title=0&byline=0&portrait=0" 
                            style={{ 
                                position:"absolute", 
                                top: 0, 
                                left: 0, 
                                width: "100%", 
                                height: "100%" 
                            }}
                            frameborder="0" 
                            allow="autoplay; fullscreen" 
                            allowfullscreen
                        >
                        </iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </Card>
            </Container>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateData)); 