import { SHOPIFY } from './Integrations';

export default [
  {
    title: 'Yes, click here to connect',
    link: () => `/onboarding/integrations/add/${SHOPIFY}`
  },
  {
    title: 'No, but I want one',
    link: () => 'https://www.shopify.com/?ref=radar2'
  },
  {
    title: 'No, I use another POS / Online Store',
    link: (email, company) => `https://share.hsforms.com/1pdqMPazYQtamllb61gUhgg49ap7?company=${company}&email=${email}&subject=company%20submit`
  },
]
