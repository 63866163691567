import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  PageHeader,
  Card,
  InputNumber
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import {
  DashboardContainer,
  Logo,
  PageTitle
} from '../styles';
import * as actions from '../../redux/actions';
import SelectDropdown from '../../components/ui/SelectDropdown';
import ConsignerStatus from '../../constants/ConsignerStatus';
import Currencies from '../../constants/Currencies';
const { Option } = Select;

class SettingsBasic extends React.Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    this.state = {
      company: userData ? userData.company : '',
      phone: userData ? userData.phone : '',
      email: userData ? userData.email : '',
      defaultFee: userData ? userData.defaultFee : '',
      timezone: userData ? userData.timezone : '',
      logo: userData ? userData.logo : '',
      favicon: userData ? userData.favicon : '',
      defaultConsignerStatus: userData ? userData.defaultConsignerStatus : 'Active',
      defaultCurrency: userData ? userData.defaultCurrency : {},
      enablePayoutRequests: userData ? userData.enablePayoutRequests : false,
      enableAppointments: userData ? userData.enableAppointments  : false,
      disableShopifyOrderNotes: userData ? userData.disableShopifyOrderNotes  : false,
      disableSellTab: userData ? userData.disableSellTab : false,
      disableSellSearch: userData ? userData.disableSellSearch : false,
      disableConsignerPricingChanges: userData ? userData.disableConsignerPricingChanges : false,
      defaultTaxable: userData ? userData.defaultTaxable : false,
      enableStoreCredit: userData ? userData.enableStoreCredit : false,
      creditBonus: userData ? userData.creditBonus : 0

    }
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  uploadImage(key) {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add images to state
            this.setState({
              [key]: secure_url
            })
        }
    });
  }
  render() {
    const {
      company,
      phone,
      email,
      timezone,
      logo,
      defaultConsignerStatus,
      defaultCurrency,
      enablePayoutRequests,
      enableAppointments,
      favicon,
      disableShopifyOrderNotes,
      disableSellTab,
      disableConsignerPricingChanges,
      disableSellSearch,
      defaultTaxable,
      enableStoreCredit,
      creditBonus
    } = this.state;
    const {
      updateUser
    } = this.props;
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Basic Settings</PageTitle>}
          style={{ background: 'transparent' }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => updateUser(this.state)}
              loading={this.props.auth.updatingUser}
            >
              Save
            </Button>,
          ]}
        />
      <Card>
      <Form.Item label='Logo'>

        {
          !logo || logo === '' ?
          <Button
            onClick={() => this.uploadImage('logo')}
          >
          Upload Logo
          </Button> :
            <Logo
              src={logo}
              onClick={() => this.uploadImage('logo')}
            />
        }
        </Form.Item>

        <Form.Item label='Favicon'>

        {
          !favicon || favicon === '' ?
          <Button
            onClick={() => this.uploadImage('favicon')}
          >
          Upload Favicon
          </Button> :
            <Logo
              src={favicon}
              onClick={() => this.uploadImage('favicon')}
            />
        }
        </Form.Item>

          <Form.Item label='Company Name'>
            <Input
              placeholder='Company name'
              onChange={(e) => this.updateState('company', e.target.value)}
              value={company}
            />
          </Form.Item>
          <Form.Item label='Email'>
            <Input
              placeholder='email'
              onChange={(e) => this.updateState('email', e.target.value)}
              value={email}
            />
          </Form.Item>
          <Form.Item label='Phone'>
            <Input
              placeholder='Phone'
              onChange={(e) => this.updateState('phone', e.target.value)}
              value={phone}
            />
          </Form.Item>
          <Form.Item label='Default Currency'>
            <Select
              showSearch
              style={{ width: '100%' }}
              value={JSON.stringify(defaultCurrency)}
              onChange={(value) => this.updateState('defaultCurrency', JSON.parse(value))}
            >
              {Currencies.map(currency => <Option value={JSON.stringify(currency)}>{currency.name} ({currency.symbol})</Option>)}

            </Select>

          </Form.Item>
          <Form.Item label='Default Timezone'>
            <Input
              placeholder='Enter Timezone'
              onChange={(e) => this.updateState('timezone', e.target.value)}
              value={timezone}
            />
          </Form.Item>
          <Form.Item label='Default Consigner Status'>
            <SelectDropdown
              data={ConsignerStatus}
              onChange={(value) => this.updateState('defaultConsignerStatus', value)}
              value={defaultConsignerStatus}
            />
          </Form.Item>
          <Form.Item label='Default Taxable'>
            <Switch
              onChange={(checked) => this.updateState('defaultTaxable', checked)}
              checked={defaultTaxable}
            />
          </Form.Item>
          <Form.Item label='Disable Sell Tab in Consigner Portal'>
            <Switch
              onChange={(checked) => this.updateState('disableSellTab', checked)}
              checked={disableSellTab}
            />
          </Form.Item>
          <Form.Item label='Disable Sell Search'>
            <Switch
              onChange={(checked) => this.updateState('disableSellSearch', checked)}
              checked={disableSellSearch}
            />
          </Form.Item>
          <Form.Item label='Disable Consigner Pricing Changes'>
            <Switch
              onChange={(checked) => this.updateState('disableConsignerPricingChanges', checked)}
              checked={disableConsignerPricingChanges}
            />
          </Form.Item>

          <Form.Item label='Disable Shopify Order Notes'>
            <Switch
              onChange={(checked) => this.updateState('disableShopifyOrderNotes', checked)}
              checked={disableShopifyOrderNotes}
            />
          </Form.Item>
          <Form.Item label='Allow Consigner Payout Requests'>
            <Switch
              onChange={(checked) => this.updateState('enablePayoutRequests', checked)}
              checked={enablePayoutRequests}
            />
          </Form.Item>
          <Form.Item label='Enable Appointments'>
            <Switch
              onChange={(value) => this.updateState('enableAppointments', value)}
              checked={enableAppointments}
            />
          </Form.Item>
          <Form.Item label='Enable Store Credit as Payout'>
            <Switch
              onChange={(value) => this.updateState('enableStoreCredit', value)}
              checked={enableStoreCredit}
            />
          </Form.Item>

          <Form.Item label='Enable Store Credit as Payout'>
            <InputNumber
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              onChange={(value) => this.updateState('creditBonus', value)}
              value={creditBonus}
            />
          </Form.Item>
          
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsBasic));
