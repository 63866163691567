import React from 'react';
import {
  Redirect
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Button,
  Select,
  Result,
  message
} from 'antd';
import * as actions from '../redux/actions';
import history from '../navigation/history';
import {
  SLACK
} from '../constants/Integrations';

import {
  DashboardContainer,
  Title,
  SubTitle
} from './styles';
const { Option } = Select;

class SlackSignup extends React.Component {

  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    const {
      access_token,
      scope,
      team_name,
      team_id,
      url,
      channel
    } = parsed;
    console.log(parsed)
    this.state = {
      access_token,
      scope,
      team_name,
      team_id,
      url,
      channel,
      trigger: 'payoutRequest'
    }
  }

  async createConnection() {
    const {
      access_token,
      scope,
      team_name,
      team_id,
      url,
      channel,
      trigger
    } = this.state;
    await this.props.createConnection({
      fieldValues: {
        access_token,
        scope,
        team_name,
        team_id,
        url,
        channel,
        trigger
      },
      integrationId: SLACK
    });
    message.success('Slack Notification Created')
    history.push('/');
  }

  render() {

    return (
      <DashboardContainer>
      <Result
        title="Confirm when to send Slack Notifications!"
        subTitle="Before you start getting notifications confirm when you would like to receive them."
        extra={[
          <Button
            onClick={() => this.createConnection()}
            style={{ width: 300 }}

          >
            Confirm Slack Notification
          </Button>
        ]}
      >
        <SubTitle>
          Name: {this.state.team_name}
        </SubTitle>
        <SubTitle>
          Channel: {this.state.channel}
        </SubTitle>
        <Select
          value={this.state.trigger}
          style={{ width: 300, marginBottom: 20 }}
          onChange={(value) => this.setState({ trigger: value})}>
          <Option value="payoutRequest">Payout Request</Option>
          <Option value="orderNotification">Order Notification</Option>
        </Select>
      </Result>

      </DashboardContainer>
    )
  }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connections: state.ConnectionReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SlackSignup);
