import {
  SET_AUTH,
  SET_AUTH_LOADING,
  UPDATE_USER,
  UPDATE_USER_LOADING,
  UPLOAD_IMAGE_LOADING,
  UPLOAD_IMAGE,
  SETUP_SHOPIFY_ONBOARDING,
  GET_USERS_SHIPPING_CARRIERS,
  GET_USERS_SHIPPING_CARRIERS_LOADING,
  SET_CURRENT_LOCATION,
  SAVE_FILTER,
  REMOVE_FILTER,
  GET_SHOPIFY_CSV_LOADING,
  GET_SHOPIFY_CSV,
  UPDATE_USER_SUBDOMAIN_LOADING,
  GET_STORE_LOADING,
  GET_STORE,
  GET_ONBOARDING_CSV_LOADING,
  UPLOAD_ONBOARDING_CSV_LOADING,
  SET_CSV_LOADING,
  SET_CSV_UPLOADING,
  CHECK_REAUTH_LOADING,
  CHECK_REAUTH,
} from "../actions/types"

let defaultState = {
  isLoggedIn: false,
  isLoadingAuthState: true,
  userData: {},
  updatingUser: false,
  uploadingImage: false,
  isMember: false,
  shop: null,
  shopifyAccessToken: null,
  loadingShippingCarriers: false,
  shippingCarriers: [],
  currentLocation: null,
  savingFilter: false,
  removingFilter: false,
  loadingShopifyCSV: false,
  shopifyCSV: '',
  updatingUserSubdomain: false,
  loadingStore: false,
  storeData: null,
  loadingOnboardingCSV: false,
  uploadingOnboardingCSV: false,
  csvLoading: {
    isLoading: false,
    current: 0,
    total: 0
  },
  csvUploading: {
    isLoading: false,
    current: 0,
    total: 0
  },
  checkingReauth: false,
  reauthData: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
          return {
            ...state,
            isLoggedIn: action.isLoggedIn,
            isLoadingAuthState: action.isLoadingAuthState,
            userData: action.userData,
            isMember: action.isMember
          };
        case SET_AUTH_LOADING:
          return {
            ...state,
            isLoadingAuthState: action.isLoadingAuthState
          };
        case UPDATE_USER:
          return {
            ...state,
            updatingUser: action.updatingUser,
            userData: action.userData
          };
        case UPDATE_USER_LOADING:
          return {
            ...state,
            updatingUser: action.updatingUser,
          };
        case UPLOAD_IMAGE_LOADING:
          return {
            ...state,
            uploadingImage: action.uploadingImage,
          };
        case UPLOAD_IMAGE:
          return {
            ...state,
            uploadingImage: action.uploadingImage,
            userData: action.userData
          };
        case SETUP_SHOPIFY_ONBOARDING:
          return {
            ...state,
            shop: action.shop,
            shopifyAccessToken: action.shopifyAccessToken
          }
        case GET_USERS_SHIPPING_CARRIERS:
          return {
            ...state,
            loadingShippingCarriers: action.loadingShippingCarriers,
            shippingCarriers: action.shippingCarriers
          }
        case GET_USERS_SHIPPING_CARRIERS_LOADING:
          return {
            ...state,
            loadingShippingCarriers: action.loadingShippingCarriers,
          }
        case SET_CURRENT_LOCATION:
          return {
            ...state,
            currentLocation: action.currentLocation
          }
        case SAVE_FILTER:
          return {
            ...state,
            savingFilter: action.savingFilter
          }
        case REMOVE_FILTER:
          return {
            ...state,
            removingFilter: action.removingFilter
          }
        case GET_SHOPIFY_CSV_LOADING:
          return {
            ...state,
            loadingShopifyCSV: action.loadingShopifyCSV
          }
        case GET_SHOPIFY_CSV:
          return {
            ...state,
            loadingShopifyCSV: action.loadingShopifyCSV,
            shopifyCSV: action.shopifyCSV
          }
        case UPDATE_USER_SUBDOMAIN_LOADING:
          return {
            ...state,
            updatingUserSubdomain: action.updatingUserSubdomain
          }
        case GET_STORE:
          return {
            ...state,
            loadingStore: action.loadingStore,
            storeData: action.storeData
          }
        case GET_STORE_LOADING:
          return {
            ...state,
            loadingStore: action.loadingStore
          }
        case GET_ONBOARDING_CSV_LOADING:
          return {
            ...state,
            loadingOnboardingCSV: action.loadingOnboardingCSV
          }
        case UPLOAD_ONBOARDING_CSV_LOADING:
          return {
            ...state,
            uploadingOnboardingCSV: action.uploadingOnboardingCSV
          }
        case SET_CSV_LOADING:
            return {
              ...state,
              csvLoading: action.csvLoading
            }
        case SET_CSV_UPLOADING:
          return {
            ...state,
            csvUploading: action.csvUploading
          }
        case CHECK_REAUTH_LOADING:
          return {
            ...state,
            checkingReauth: action.checkingReauth
          }
        case CHECK_REAUTH:
            return {
              ...state,
              checkingReauth: action.checkingReauth,
              reauthData: action.reauthData
            }
        default:
            return state;
    }
};
