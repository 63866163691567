import React from 'react';
import {
  Route,
  Redirect,
  withRouter
} from 'react-router-dom';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import ReAuth from '../screens/ReAuth';

const SecretRoute = ({ component: Component, path, auth, ...rest }) => {
  if(auth.isLoadingAuthState) {
    return (
      <LoadingSpinner />
    )
  }
  console.log(path.split('/'))
  const routePaths = path.split('/')
  const routePath = routePaths[1] || path;
  console.log(routePath);
  return (
    <Route {...rest} render={(props) => (
        auth.isLoggedIn ?
          auth.isMember || path === '/membership' ?
              auth.userData.employee.restrictedRoutes && !auth.userData.employee.restrictedRoutes.includes(`${routePath}`) ?
                !auth.userData.reAuthNeeded ?
                  <Component {...props}/>
                : <ReAuth {...props} />
              : <Redirect to='/access-denied' />
          : <Redirect to='/membership' />
        : <Redirect to='/signup' />
    )} />
  )
}

export default withRouter(SecretRoute);
