import React from 'react';
import {bindActionCreators} from 'redux'
import {
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
  PageHeader,
} from 'antd';
import IntegrationList from '../../../components/algolia/IntegrationList';
import LoadingSpinner from '../../../components/ui/LoadingSpinner';
import history from '../../../navigation/history';

import {
  DashboardContainer,
  PageTitle
} from '../../styles';


class Integration extends React.Component {

  componentWillMount() {
    this.props.loadConnections();
  }
  createConnection(connection) {
    const { is_url, url, objectID } = connection;
    history.push(`/integrations/add/${objectID}`)
  }


  render() {

    const {
      loadingConnection,
      connections
    } = this.props.connection;

    if(loadingConnection) {
      return <LoadingSpinner />
    }
      return (
        <DashboardContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Integrations</PageTitle>}
            style={{ background: 'transparent' }}
          />
          <IntegrationList
            connections={connections}
            onPress={(connection) => this.createConnection(connection)}
          />
        </DashboardContainer>
      );
  }
}

function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Integration));
