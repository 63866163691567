import selectIntegrationUrl from '../../../helpers/integrations/selectIntegrationUrl';
import createConnection from '../connections/createConnection';
import history from '../../../navigation/history';

function createIntegration(values, integrationId, is_url) {
  return async (dispatch, getState) => {
    try {
      const { userData } = getState().AuthReducer;
      const data = { fieldValues: values, userId: userData.id, integrationId };
      if(is_url) {
        const installUrl = selectIntegrationUrl(data);
        window.open(installUrl);
      } else {
        console.log(data);
        await dispatch(createConnection(data));
        history.goBack();
      }
    } catch (e) {
      console.log(e);
      alert(String(e.errorMessage));
      return false;
    }
  };
}

export default createIntegration;
