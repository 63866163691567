import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Modal
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';
import SettingsLocation from '../../components/ui/forms/settings/SettingsLocation';
import AddLocation from '../../components/ui/modals/AddLocation';
import EditLocation from '../../components/ui/modals/EditLocation';
import AddAppointmentTime from '../../components/ui/modals/AddAppointmentTime';

class LocationSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addLocationModalVisible: false,
      name: '',
      addressQuery: '',
      addAppointmentRuleVisible: false,
      address: {},
      allowPickup: false,
      subLocations: [],
      editLocationModalVisible: false,
      enableAppointments: false,
      enableSameDayAppointments: false,
      appointmentInterval: 15
    }
  }
  componentWillMount() {
    this.props.getLocations();
  }
  handleAddLocationModal() {
    const {addLocationModalVisible} = this.state;
    this.setState({
      addLocationModalVisible: !addLocationModalVisible
    });
  }
  handleEditLocationModal() {
    const { editLocationModalVisible } = this.state;
    this.setState({
      editLocationModalVisible: !editLocationModalVisible
    });
  }
  handleCancelEditLocation() {
    this.setState({
      name: '',
      addressQuery: '',
      addAppointmentRuleVisible: false,
      address: {},
      allowPickup: false,
      subLocations: [],
      editLocationModalVisible: false,
      enableAppointments: false,
      enableSameDayAppointments: false,
      appointmentInterval: 15
    })
  }
  handleOk() {
    this.handleAddLocationModal();
    const {
      name,
      addressQuery,
      address,
      allowPickup,
      subLocations,
      enableAppointments,
      enableSameDayAppointments,
      appointmentInterval
    } = this.state;
    this.props.createLocation({
      name,
      address,
      addressQuery,
      allowPickup,
      subLocations,
      enableAppointments,
      enableSameDayAppointments,
      appointmentInterval
    });
    this.setState({
      name: '',
      addressQuery: '',
      addAppointmentRuleVisible: false,
      address: {},
      allowPickup: false,
      subLocations: [],
      editLocationModalVisible: false,
      enableAppointments: false,
      enableSameDayAppointments: false,
      appointmentInterval: 15
    })
  }
  async handleOkEditLocation() {
    this.handleEditLocationModal();

    const {
      id,
      name,
      addressQuery,
      address,
      allowPickup,
      subLocations,
      enableAppointments,
      enableSameDayAppointments,
      appointmentInterval
    } = this.state;
    this.props.updateLocation({
      id,
      name,
      address,
      addressQuery,
      allowPickup,
      subLocations,
      enableAppointments,
      enableSameDayAppointments,
      appointmentInterval
    });
    this.handleCancelEditLocation()
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }
  updateLocationDataToState(data) {
    this.setState({
      ...data
    })
  }
  deleteLocation(id) {
    Modal.confirm({
      title: 'Are you sure you want to delete this location?',
      content: 'References to this location will also be deleted',
      okText: 'Delete Location',
      onOk: () => this.props.deleteLocation(id),
      cancelText: 'Cancel'
    })
  }
  handleAddAppointmentTimesModal() {
    const {addAppointmentTimeVisible} = this.state;
    this.setState({
      addAppointmentTimeVisible: !addAppointmentTimeVisible
    });
  }
  async handleAppointmentTimeOk(id) {
    this.handleAddAppointmentTimesModal();
    const { day, open ,close } = this.state;
    await this.props.createAppointmentTime(
      {
        open: moment(open).format('LT'),
        close: moment(close).format('LT'),
        day,
        locationId: id
      }
    );
    const obj = this.props.locationData.locations.find(o => o.id === id);
    this.updateLocationDataToState(obj)
  }
  async handleAppointmentRuleOk(id) {
    this.handleAddAppointmentRulesModal();
    const { rule, ruleFormat } = this.state;
    await this.props.createAppointmentRule(
      {
        rule: moment(rule).format(ruleFormat),
        ruleFormat,
        locationId: id
      }
    );
    const obj = this.props.locationData.locations.find(o => o.id === id);
    this.updateLocationDataToState(obj)
  }

  async deleteAppointmentTime(id) {
    await this.props.deleteAppointmentTime(id);
    const obj = this.props.locationData.locations.find(o => o.id === this.state.id);
    this.updateLocationDataToState(obj);
  }

  handleAddAppointmentRulesModal() {
    const {addAppointmentRuleVisible} = this.state;
    this.setState({
      addAppointmentRuleVisible: !addAppointmentRuleVisible
    });
  }
  async deleteAppointmentRule(id) {
    await this.props.deleteAppointmentRule(id);
    const obj = this.props.locationData.locations.find(o => o.id === this.state.id);
    this.updateLocationDataToState(obj);
  }

  render() {
    const {
      addLocationModalVisible,
      editLocationModalVisible,
      addAppointmentTimeVisible,
      addAppointmentRuleVisible
    } = this.state;
      return (
        <DashboardContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Locations</PageTitle>}
            style={{ background: 'transparent' }}
          />
          <SettingsLocation
            locations={this.props.locationData.locations}
            loadingLocations={this.props.locationData.loadingLocations}
            deleteLocation={(id) => this.deleteLocation(id)}
            handleAddLocationModal={() => this.handleAddLocationModal()}
            handleEditLocationModal={() => this.handleEditLocationModal()}
            updateLocationDataToState={(data) => this.updateLocationDataToState(data)}
            setCurrentLocation={(location) => this.props.setCurrentLocation(location)}
          />

          <AddLocation
            creatingLocation={this.props.locationData.creatingLocation}
            handleOk={() => this.handleOk()}
            handleCancel={() => this.handleAddLocationModal()}
            visible={addLocationModalVisible}
            updateState={(key, value) => this.updateState(key, value)}
            updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
            values={this.state}
          />
          <EditLocation
            updatingLocation={this.props.locationData.updatingLocation}
            handleOk={() => this.handleOkEditLocation()}
            handleCancel={() => this.handleCancelEditLocation()}
            visible={editLocationModalVisible}
            updateState={(key, value) => this.updateState(key, value)}
            updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
            values={this.state}
            deleteAppointmentRule={(id) => this.deleteAppointmentRule(id)}
            handleAddAppointmentRulesModal={() => this.handleAddAppointmentRulesModal()}
            deleteAppointmentTime={(id) => this.deleteAppointmentTime(id)}
            handleAddAppointmentTimesModal={() => this.handleAddAppointmentTimesModal()}
            creatingAppointmentTime={this.props.appointmentTime.creatingAppointmentTime}
            handleAppointmentTimeOk={(id) => this.handleAppointmentTimeOk(id)}
            addAppointmentTimeVisible={addAppointmentTimeVisible}
            creatingAppointmentRule={this.props.appointmentRule.creatingAppointmentRule}
            handleAppointmentRuleOk={(id) => this.handleAppointmentRuleOk(id)}
            addAppointmentRuleVisible={addAppointmentRuleVisible}
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        locationData: state.LocationReducer,
        appointmentTime: state.AppointmentTimeReducer,
        appointmentRule: state.AppointmentRuleReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationSettings));
