import styled from 'styled-components';
import { Switch } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`
const TextContainer = styled.div`
  margin-left: 20px;
`

const SubTitle = styled.p`
  margin: 0;
`

const TaxableContainer = styled.div`
  display: flex;
`
const TaxableSwitch = styled(Switch)`
  margin-left: 10px !important;
`
const Remove = styled.p`
  color: red;
  margin: 0;
  margin-top: 10px;
  font-size: 10px;
`
export {
  Container,
  Image,
  TextContainer,
  SubTitle,
  ActionContainer,
  TaxableContainer,
  TaxableSwitch,
  Remove
}
