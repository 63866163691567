import styled from 'styled-components';
import { Card, Icon } from 'antd';
const SettingsCardContainer = styled(Card)`
  margin-bottom: 10px !important;
  .ant-card-body {
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
  }

`
const Title = styled.h2`
`
const SubTitle = styled.p`
  margin-bottom: 0px;

`
const TextContainer = styled.div`
  width: calc(100% - 32px);

`
const RightArrowIcon = styled(Icon)`
  font-size: 32px;
  color: blue;

`
export {
  SettingsCardContainer,
  Title,
  SubTitle,
  RightArrowIcon,
  TextContainer
}
