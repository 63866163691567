import React from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { AutoComplete } from 'antd';
const { Option } = AutoComplete;
function DropdownSearchBar ({
  hits,
  refine,
  searchPlaceholder,
  keyName,
  updateState,
  stateKey,
  stateValue,
  stateKey2,
  stateValue2,
  value,
  query,
  queryKey,
  viewOnly,
  onViewOnlyClick,
  dataKey
}) {
  const children = hits.map((hit, index) => <Option key={hit[keyName]} value={`${index}&${hit[stateValue]}&${hit[stateValue2]}`}>{hit[keyName]}</Option>);

  return (
    <div onClick={() => {
      if(viewOnly && onViewOnlyClick) {
        onViewOnlyClick()
      }
      return
    }}>
    <AutoComplete
      dataSource={hits}
      onSearch={(value) => {
        refine(value);
        updateState(queryKey, value)
      }}
      placeholder={searchPlaceholder}
      style={{ width: '100%' }}
      value={query}
      disabled={viewOnly}
      allowClear
      onChange={(value) => {
        if(value) {

          updateState(queryKey, value)
          const values = value.split('&');
          if(values.length > 1) {
            console.log(dataKey)
            console.log(hits[values[0]])
            updateState(dataKey, hits[values[0]])
            updateState(stateKey, values[1])
            if(stateKey2  && values[2]) {
              updateState(stateKey2, values[2])
            }
          }
        }
      }
    }
    >
    {children}
    </AutoComplete>
    </div>
  );
};

export default connectAutoComplete(DropdownSearchBar);
