import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
const { Option } = Select;
DropdownSelectDropdown.defaultProps = {
  placeholder: 'Status'
}
function DropdownSelectDropdown(props) {
  let {
    onChange,
    data,
    value,
    placeholder,
    disabled
  } = props;
  value = value || undefined
  return (
    <Select
      showSearch
      allowClear
      style={{ width: '100%' }}
      placeholder={placeholder}
      optionFilterProp="children"
      value={value}
      disabled={disabled}
      onChange={onChange}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {
        data.map(option =>
          <Option value={option}>{option}</Option>
        )
      }
    </Select>
  );
}

export default DropdownSelectDropdown
