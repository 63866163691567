import history from '../navigation/history';

export default [
  {
    title: 'Basic',
    subTitle: 'Manage basic store information such as name, logo, email and more',
    onClick: () => history.push('/settings/basic')
  },
  {
    title: 'Custom Domain',
    subTitle: 'Add a custom domain for your consigner and admin portal',
    onClick: () => history.push('/settings/domains')
  },
  {
    title: 'Locations',
    subTitle: 'Manage your stores locations',
    onClick: () => history.push('/settings/locations')
  },
  {
    title: 'Integrations',
    subTitle: 'Integrate Frontflip to other services',
    onClick: () => history.push('/settings/integrations')
  },
  {
    title: 'Products Templates',
    subTitle: 'Setup product templates to easily add new products',
    onClick: () => history.push('/settings/productTemplates')
  },
  {
    title: 'Consigners',
    subTitle: 'Manage how consigners are allowed to sell',
    onClick: () => history.push('/settings/consigners')
  },
  {
    title: 'Fees',
    subTitle: 'Manage rules on how consignment fee are calculated',
    onClick: () => history.push('/settings/fees')
  },
  {
    title: 'Inventory Priority',
    subTitle: 'Set what order inventory is sold',
    onClick: () => history.push('/settings/inventoryPriority')
  },
  {
    title: 'Email Notifications',
    subTitle: 'Setup Sendgrid email templates for events in Frontflip',
    onClick: () => history.push('/settings/notifications')
  },
  // {
  //   title: 'Refresh Shopify',
  //   subTitle: 'Need to refresh your Shopify Products, convert Frontflip Data to Shopify CSV',
  //   onClick: () => history.push('/settings/refreshShopifyCSV')
  // }
]
