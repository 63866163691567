import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API
} from '../../../constants/API';
import {
  GET_PRODUCT_ITEM_LOADING,
  GET_PRODUCT_ITEM
} from '../types';

function getProductItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT_ITEM_LOADING,
      loadingProductItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const productItemResponse = await axios.get(
        `${PRODUCTS_API}/${userData.id}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(productItemResponse)
      dispatch({
        type: GET_PRODUCT_ITEM,
        loadingProductItem: false,
        productItem: productItemResponse.data
      });
      return productItemResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_PRODUCT_ITEM_LOADING,
        loadingProductItem: false
      });
    }
  };
}
export default getProductItem;
