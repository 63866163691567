import axios from 'axios';
import login from '../auth/login';
import setAuthState from '../auth/setAuthState';
import history from '../../../navigation/history';

import {
  CARDS_API,
  SUBSCRIPTION_API
} from '../../../constants/API';
import {
  CREATE_MEMBERSHIP_LOADING
} from '../types';
function createMembership(token) {
  return async(dispatch, getState) => {
    dispatch({
      type: CREATE_MEMBERSHIP_LOADING,
      creatingMembership: true
    });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        if(accessToken && userData) {
          //has accesstoken and userdata
          const data = {
            customer_id: userData.customer_id,
            token: token,
            userId: userData.id
          } // data to add card api
          const addCardsResponse = await axios.post(CARDS_API, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
          if(addCardsResponse.data.isError) {
            //error while adding a card
            dispatch({
              type: CREATE_MEMBERSHIP_LOADING,
              creatingMembership: false
            });
            alert(addCardsResponse.data.errorMessage)
          } else {
            //add card success ful
            //update membership
            const subscriptionData = {
              status: 'pending',
              userId: userData.id
            } // data to send membership api
            const updatingSubResponse = await axios.put(SUBSCRIPTION_API, subscriptionData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
            if(updatingSubResponse.data.isError) {
              //error while creating membership
              dispatch({
                type: CREATE_MEMBERSHIP_LOADING,
                creatingMembership: false
              });
              alert(updatingSubResponse.data.errorMessage)
            } else {
              //SUCCESS FULL MEMBERSHIP
              dispatch({
                type: CREATE_MEMBERSHIP_LOADING,
                creatingMembership: false
              });
              dispatch(setAuthState());
            }
          }
        } else {
          //User has no accesstoken or user data
          dispatch(login());
        }
      } catch (e) {
        alert(String(e))
        console.log(e);
      }
  }
}
export default createMembership;
