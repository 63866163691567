export default [
  {
    label: 'Dashboard',
    value: '/',
  },
  {
    label: 'Inventory',
    value: '/inventory',
  },
  {
    label: 'Products',
    value: '/products',
  },
  {
    label: 'Consigners',
    value: '/consigners',
  },
  {
    label: 'Customers',
    value: '/customers',
  },
  {
    label: 'Orders',
    value: '/orders',
  },
  {
    label: 'Employees',
    value: '/employees',

  },
  {
    label: 'Appointments',
    value: '/appointments',
  },
  {
    label: 'Scan Inventory',
    value: '/scan',
  },
  {
    label: 'Settings',
    value: '/settings',
  },
  {
    label: 'Change Consigner Price',
    value: 'disableConsignerPriceControl'
  },
  {
    label: 'Change Consigner Fee',
    value: 'disableConsignerFeeControl'
  }
]
