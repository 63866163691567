import React from 'react';
import {
  InstantSearch,
  Configure
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import CatalogCard from './CatalogCard';
import {
  Container,
  SearchInput
} from './styles';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

export default function AlgoliaSearch(props) {
  const {
    subscribedCatalogIds,
    onSubscribePress,
    onUnsubcribePress,
    filters
  } = props;
  return (
    <Container>
      <InstantSearch
        searchClient={searchClient}
        indexName='catalogs'
      >
        <Configure filters={filters} />
        <SearchInput />
        <CatalogCard
          subscribedCatalogIds={subscribedCatalogIds}
          onSubscribePress={onSubscribePress}
          onUnsubcribePress={onUnsubcribePress}
        />
      </InstantSearch>
    </Container>
  );
}
