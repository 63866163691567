import {
  CREATE_FEE_LOADING,
  DELETE_FEE_LOADING,
  GET_FEES_LOADING,
  GET_FEES
} from "../actions/types"

let defaultState = {
  creatingFee: false,
  deletingFee: false,
  loadingFees: false,
  fees: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_FEE_LOADING:
          return {
            ...state,
            creatingFee: action.creatingFee
          };
        case DELETE_FEE_LOADING:
          return {
            ...state,
            deletingFee: action.deletingFee
          }
        case GET_FEES_LOADING:
          return {
            ...state,
            loadingFees: action.loadingFees
          }
        case GET_FEES:
          return {
            ...state,
            loadingFees: action.loadingFees,
            fees: action.fees
          }
        default:
            return state;
    }
};
