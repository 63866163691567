import React from 'react';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Configure
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import DropdownSearchBar from '../../ui/algolia/DropdownSearchBar';
import {
  Container
} from './styles';
const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);
DropdownSearch.propTypes = {
  index: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  keyName: PropTypes.string,
  updateState: PropTypes.func,
  stateKey: PropTypes.string,
  stateValue: PropTypes.string,
  stateKey2: PropTypes.string,
  stateValue2: PropTypes.string,
  value: PropTypes.string,
  query: PropTypes.string,
  queryKey: PropTypes.string,
  userId: PropTypes.string
};
export default function DropdownSearch(props) {
  const {
    index,
    searchPlaceholder,
    keyName,
    updateState,
    stateKey,
    stateValue,
    stateKey2,
    stateValue2,
    value,
    query,
    queryKey,
    userId,
    viewOnly,
    onViewOnlyClick,
    filters,
    dataKey
  } = props;
  return (
    <Container>
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
      >
        <Configure filters={`userId:${userId} ${filters || ''}`} />
        <DropdownSearchBar
          searchPlaceholder={searchPlaceholder}
          keyName={keyName}
          updateState={updateState}
          stateKey={stateKey}
          stateValue={stateValue}
          stateKey2={stateKey2}
          stateValue2={stateValue2}
          value={value}
          query={query}
          queryKey={queryKey}
          viewOnly={viewOnly}
          onViewOnlyClick={onViewOnlyClick}
          dataKey={dataKey}
        />
      </InstantSearch>
    </Container>
  );
}
