import axios from 'axios';
import setAuthState from '../auth/setAuthState';
import {
  SUBSCRIPTION_API
} from '../../../constants/API';
import {
  RENEW_MEMBERSHIP_LOADING
} from '../types';

function renewMembership(id, subscription_id) {
  return async (dispatch) => {
    dispatch({
      type: RENEW_MEMBERSHIP_LOADING,
      renewingMembership: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const data = { subscription_id, id };
      await axios.post(
        `${SUBSCRIPTION_API}/renew`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      await dispatch(setAuthState(false));
      dispatch({
        type: RENEW_MEMBERSHIP_LOADING,
        renewingMembership: false
      });
    } catch (e) {
      console.log(e);
      alert(String(e));
      dispatch({
        type: RENEW_MEMBERSHIP_LOADING,
        renewingMembership: false
      });
    }
  };
}
export default renewMembership;
