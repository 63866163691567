import React from 'react';
import moment from 'moment';
import {
  Link
} from 'react-router-dom';
import {
  Form,
  Input,
  DatePicker
} from 'antd';
import SelectDropdown from '../../../SelectDropdown';
import InventoryStatus from '../../../../../constants/InventoryStatus';
class InventoryStatusComponent extends React.Component {

  render() {
    const { updateState, values, disabled } = this.props;
    const {
      status,
      expirationDate,
      returnPolicy,
      notes,
      isRemote,
      remoteStatus,
      orderId,
      orderNumber
    } = values;
    return (
      <div>
        {
          orderId ?
            <Form.Item label='Order'>
              <Link
                to={`/orders/${orderId}`}
              >
                #{orderNumber}
              </Link>
            </Form.Item> : null
        }
        {
          isRemote ?
            <Form.Item label='Remote Status'>
              <Input
                value={remoteStatus}
                disabled={true}
              />
            </Form.Item> : null
        }


          <Form.Item label='Status'>
            <SelectDropdown
              data={InventoryStatus}
              onChange={(value) => updateState('status', value)}
              value={status}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item label='Notes' autoresize>
            <Input.TextArea
              placeholder='Notes'
              onChange={(e) => updateState('notes', e.target.value)}
              value={notes}
              disabled={disabled}
            />
          </Form.Item>
      </div>
    );
  }
}
export default InventoryStatusComponent;
