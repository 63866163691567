import auth0 from 'auth0-js';
import { message } from 'antd';
import history from '../../../navigation/history';
import saveUserCredentials from './saveUserCredentials';
import setupShopifyOnboarding from './setupShopifyOnboarding';
import setAuthState from './setAuthState';
import updateEmployee from '../employees/updateEmployee';
import {
  SET_AUTH_LOADING,
} from '../types';


const Auth0 = new auth0.WebAuth({
  // the following three lines MUST be updated
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  grant_type:"client_credentials",
});

 function onCallback(){
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_AUTH_LOADING,
        isLoadingAuthState: true
      });
      Auth0.parseHash(async(err, authResult) => {
        if(!err) {
          console.log(authResult)
          const { idTokenPayload, appState } = authResult;
          console.log(appState);
          const {
            isEmployee,
            isShopify,
            employee,
            store,
            shop,
            shopifyAccessToken
          } = appState;
          if(isEmployee) {
            //EMPLOYEE FLOW
            console.log("EMPLOYEE FLOW")
            await saveUserCredentials(authResult, idTokenPayload);
            console.log("SAVED CREDNITALS")
            const employeeAuthId = idTokenPayload.sub;
            console.log({
              employeeAuthId,
              employee,
              store
            })
            dispatch(await updateEmployee({
              auth0_id: employeeAuthId,
              id: employee,
              userId: store
            }));
            message.success('Account Created - Redirected to Dashboard');
            dispatch(setAuthState(idTokenPayload));
          } else if(isShopify) {
            //SHOPIFY FLOW
            console.log("SHOPIFY FLOW")
            await saveUserCredentials(authResult, idTokenPayload);
            dispatch(setupShopifyOnboarding(shop, shopifyAccessToken));
            dispatch(setAuthState(idTokenPayload));

          } else {
            console.log("NORMAL FLOW")

            await saveUserCredentials(authResult, idTokenPayload);
            dispatch(setAuthState(idTokenPayload));
          }

        }
      });
    } catch (e) {
      console.log(e)
    }
  };
}
export default onCallback;
