import React from 'react';
import {
  Form,
  Input,
  Button,
  PageHeader,
  Card
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import history from '../../navigation/history';
import {
  DashboardContainer,
  PageTitle
} from '../styles';
class SettingsTriggers extends React.Component {

  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    this.state = {
      triggers: userData && userData.triggers ? userData.triggers : {},
    }
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }
  render() {
    const {
      triggers
    } = this.state;
    const {
      updateUser
    } = this.props;
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Notifications</PageTitle>}
          style={{ background: 'transparent' }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => updateUser(this.state)}
              loading={this.props.auth.updatingUser}
            >
              Save
            </Button>,
          ]}
        />
        <Card>
          <Form.Item label='From Email'>
            <Input
              placeholder='From email'
              onChange={(e) => this.updateStateObj('triggers', 'fromEmail', e.target.value)}
              value={triggers.fromEmail}
            />
          </Form.Item>
          <Form.Item label='Consigner Reset Password '>
            <Input
              placeholder='Email sent to consigners to reset password'
              onChange={(e) => this.updateStateObj('triggers', 'consignerChangePassword', e.target.value)}
              value={triggers.consignerChangePassword}
            />
          </Form.Item>
          <Form.Item label='After Sale Email'>
            <Input
              placeholder='Email sent after drop off inventory sold'
              onChange={(e) => this.updateStateObj('triggers', 'sale', e.target.value)}
              value={triggers.sale}
            />
            <Input
              placeholder='Email sent after remote inventory sold'
              onChange={(e) => this.updateStateObj('triggers', 'remoteSale', e.target.value)}
              value={triggers.remoteSale}
            />
          </Form.Item>
          <Form.Item label='Employee Invite'>
            <Input
              placeholder='Employee Invite Email'
              onChange={(e) => this.updateStateObj('triggers', 'employeeInvite', e.target.value)}
              value={triggers.employeeInvite}
            />
          </Form.Item>
          <Form.Item label='Remote Sale Declined'>
            <Input
              placeholder='Customer Email'
              onChange={(e) => this.updateStateObj('triggers', 'remoteDeclineSaleCustomer', e.target.value)}
              value={triggers.remoteDeclineSaleCustomer}
            />
            <Input
              placeholder='Consigner Email'
              onChange={(e) => this.updateStateObj('triggers', 'remoteDeclineSaleConsigner', e.target.value)}
              value={triggers.remoteDeclineSaleConsigner}
            />
          </Form.Item>
          <Form.Item label='Payout'>
            <Input
              placeholder='After payout is sent'
              onChange={(e) => this.updateStateObj('triggers', 'payoutSent', e.target.value)}
              value={triggers.payoutSent}
            />
          </Form.Item>
          <Form.Item label='Withdrawal Transferred'>
            <Input
              placeholder='After item is transferred to withdrawal location'
              onChange={(e) => this.updateStateObj('triggers', 'withdrawalTransferred', e.target.value)}
              value={triggers.withdrawalTransferred}
            />
          </Form.Item>
          <Form.Item label='Consigner Credit'>
            <Input
              placeholder='Email sent to consigner with there discount code to use as store credit'
              onChange={(e) => this.updateStateObj('triggers', 'consignerCredit', e.target.value)}
              value={triggers.consignerCredit}
            />
          </Form.Item>
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsTriggers));
