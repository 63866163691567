import {
  GET_INTEGRATION,
  GET_INTEGRATION_LOADING,
} from "../actions/types"

let defaultState = {
  integration: null,
  loadingIntegration: true
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_INTEGRATION:
          return {
            ...state,
            loadingIntegration: action.loadingIntegration,
            integration: action.integration
          };
        case GET_INTEGRATION_LOADING:
          return {
            ...state,
            loadingIntegration: action.loadingIntegration,
          };
        default:
            return state;
    }
};
