import styled from 'styled-components';

const Container = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemContainer = styled.div`
  padding: 10px;
  width: 100%;

`
const Title = styled.p`
  font-weight: bold;
`
const SubTitle = styled.p`
`

const Remove = styled.p`
  color: red;
  margin: 0;
  margin-top: 10px;
  font-size: 10px;
`
const Edit = styled.p`
  color: blue;
  margin: 0;
  margin-top: 10px;
  font-size: 10px;
`

export {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove,
  Edit
}
