import styled from 'styled-components';
import {
  Layout,
  Icon,
  Button,
  Dropdown
} from 'antd';
const { Header } = Layout;

const Container = styled(Header)`
  height: 70px;
  padding-left: 0px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left:0;
  width: 100vw;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);


`
const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
`
const AvatarTutorialContainer = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`
export {
  Container,
  LogoContainer,
  AvatarTutorialContainer
};
