import styled from 'styled-components';
import {
  Timeline
} from 'antd';

const TimelineContainer = styled(Timeline)`
`
const TimelineItem = styled(Timeline.Item)`
`
const TimelineTextContainer = styled.div`
  background: #f7f7f7;
  display: flex;
  padding: 10px;
  border-radius: 5px;
`

const Title = styled.h2`
  font-size: 14px;
`
const SubTitle = styled.p`
  margin-bottom: 1px;

`
export {
  TimelineContainer,
  TimelineItem,
  TimelineTextContainer,
  Title,
  SubTitle
};
