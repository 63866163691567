import axios from 'axios';
import { message } from 'antd';
import {
  APPOINTMENT_TIMES_API,
} from '../../../constants/API';
import {
  CREATE_APPOINTMENT_TIME_LOADING
} from '../types';
import getLocations from '../location/getLocations';

function createAppointmentTime(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_APPOINTMENT_TIME_LOADING,
        creatingAppointmentTime: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        await axios.post(APPOINTMENT_TIMES_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        dispatch({
          type: CREATE_APPOINTMENT_TIME_LOADING,
          creatingAppointmentTime: false
        });
        await dispatch(getLocations());
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_APPOINTMENT_TIME_LOADING,
          creatingAppointmentTime: false
        });
        console.log(e);
      }
  }
}
export default createAppointmentTime;
