export default [
  {
    key: 'name',
    title: 'Name (Display Name)'
  },
  {
    key: 'firstName',
    title: 'First Name'
  },
  {
    key: 'lastName',
    title: 'Last Name'
  },
  {
    key: 'address',
    title: 'Address'
  },
  {
    key: 'phone',
    title: 'Phone'
  },
  {
    key: 'birthday',
    title: 'Birthday'
  },
]
