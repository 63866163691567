import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API,
} from '../../../constants/API';
import {
  UPDATE_INVENTORY_LOADING
} from '../types';


function updateInventory(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_INVENTORY_LOADING,
        updatingInventory: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data,
          userId: userData.id,
          lastChangedSource: userData.employee.id,
          lastChangedModel: 'Employee',
          lastChangedName: userData.employee.name
        };
        console.log(putData)
        await axios.put(`${INVENTORY_API}/${data.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_INVENTORY_LOADING,
          updatingInventory: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_INVENTORY_LOADING,
          updatingInventory: false
        });
        console.log(e);
      }
  }
}
export default updateInventory;
