import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API
} from '../../../constants/API';
import {
  SEND_INVENTORY_INVITE
} from '../types';


function sendRemoteSaleDeclined(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: SEND_INVENTORY_INVITE,
        sendingRemoteSaleDeclined: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const inviteResponse = await axios.post(`${INVENTORY_API}/sendRemoteSaleDeclined`, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        if(inviteResponse.data.isError) {
          message.error('Error sending consigner and customer notifications');
        } else {
          message.success('Sent Customer + Consigner Notifications')
        }
        dispatch({
          type: SEND_INVENTORY_INVITE,
          sendingRemoteSaleDeclined: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: SEND_INVENTORY_INVITE,
          sendingRemoteSaleDeclined: false
        });
        console.log(e);
      }
  }
}
export default sendRemoteSaleDeclined;
