import React from 'react';
import {
  Form
} from 'antd';
import OrderCartList from '../../../OrderCartList';
class OrderViewProduct extends React.Component {
  state = { visible: false, selectedProduct: 0 };

  handleModal = (index) => {
    this.setState({
      visible: !this.state.visible,
      selectedProduct: index
    });
  };


  render() {
    const {
      values,
      updateState,
      updateStateArrayKey,
      refundedIds,
      defaultCurrency
    } = this.props;
    const {
      products
    } = values;
    return (
      <div>
          <Form.Item>
            <OrderCartList
              data={products}
              updateState={updateState}
              updateStateArrayKey={updateStateArrayKey}
              selectedProduct={this.state.selectedProduct}
              isView={true}
              refundedIds={refundedIds}
              defaultCurrency={defaultCurrency}

            />
          </Form.Item>

      </div>
    );
  }
}
export default OrderViewProduct;
