import React from 'react';
import { Empty, Form } from 'antd';
import LoadingSpinner from '../../../LoadingSpinner';
import Tag from '../../../Tag';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove,
  Edit
} from './styles';

class SettingsProductTemplates extends React.Component {
  render() {
    const {
      productTemplates,
      loadingProductTemplates,
      deleteProductTemplate,
      handleAddProductTemplate,
      handleEditProductTemplate,
      updateProductTemplateDataToState
    } = this.props;
    if(loadingProductTemplates) {
      return <LoadingSpinner />
    }
    if(!productTemplates || productTemplates.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddProductTemplate()}
            >
              Add Template
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        productTemplates.map((item) => {
          const {
            option1Switch,
            option2Switch,
            option3Switch,
            option1FixedValues,
            option2FixedValues,
            option3FixedValues,
            title
          } = item;
          return (
            <ItemContainer>
              <Title>
                {title}
              </Title>
              {
                option1Switch ?
                <Form.Item>
                  <Tag
                    value={option1FixedValues}
                    disabled={true}
                  />
                </Form.Item>
                  : null
              }
              {
                option2Switch ?
                <Form.Item>
                  <Tag
                    value={option2FixedValues}
                    disabled={true}
                  />
                </Form.Item> : null
              }
              {
                option3Switch ?
                <Form.Item>
                  <Tag
                    value={option3FixedValues}
                    disabled={true}
                  />
                </Form.Item> : null
              }
              <Edit
                onClick={async () => {
                  await updateProductTemplateDataToState(item)
                  await handleEditProductTemplate()
                }}
              >
                EDIT
              </Edit>

              <Remove
                onClick={() => {
                  deleteProductTemplate(item.id)
                }}
              >
                REMOVE
              </Remove>
            </ItemContainer>
          )
        })
      }

      <a
        onClick={() => handleAddProductTemplate()}
        data-test='SettingsAddLocationButton'

      >
        Add Template
      </a>
      </Container>
    );
  }
}
export default SettingsProductTemplates;
