import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import {
  withRouter,
  Link
} from 'react-router-dom';
import history from '../../navigation/history';
import BarcodeReader from 'react-barcode-reader';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  message,
  Modal
} from 'antd'
import IncomingInventoryList from '../../components/ui/IncomingInventoryList';
import InventorySearchFilter from '../../components/ui/InventorySearchFilter';
import ProductSortBy from '../../constants/ProductSortBy';

import SetLocation from '../../components/ui/modals/SetLocation';
import {
  DashboardContainer,
  PageTitle,
  FormCard,
  ScanButton
} from '../styles';


class Inventory extends React.Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    const {
      consignerName,
      consignerId,
      productId
    } = parsed;
    if(productId) {
      this.props.getProduct(productId);
    }
    this.state = {
      id: null || productId,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      inventoryFilterVisible: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: consignerName || '',
      filterConsignerId: consignerId || null,
      filterConsignerQuery: consignerName || '',
      productQuery: '',
      title: '',
      filterStatus: 'Active',
      filterShortCode: '',
      selectedInventory: [],
      bulkEditVisible: false,
      bulkEditKey: '',
      bulkEditKeyValue: '',
      filterReceivedAt: null,
      filterOrderDate: null,
      selectedSortBy: 0,
      filterTags: '',
      sortByOptions: [
        {
          label: 'Price (Low to High) and Consigner Priority',
          value: [['price', 'ASC'], ['isStoreInventory', 'ASC']]
        },
        {
          label: 'Price (Low to High)',
          value: [['price', 'ASC']]
        },
        {
          label: 'Consigner',
          value: [['consignerName', 'ASC']]
        },
        {
          label: 'Received At (Old to New)',
          value: [['receivedAt', 'ASC']]
        },
        {
          label: 'Received At (New to Old)',
          value: [['receivedAt', 'DESC']]
        },
        {
          label: 'Order Date (Old to New)',
          value: [['orderDate', 'ASC']]
        },
        {
          label: 'Order Date (New to Old)',
          value: [['orderDate', 'DESC']]
        }
      ],
      ...props.inventoryData.lastIncomingInventoryFilter
    }
  }
  componentDidMount() {
    this.loadInventory();
    this.props.getProducts();
  }
  componentDidUpdate(oldProps) {
    //New Product Item
    const { productData } = this.props;
    const newProductItem = productData.productItem;
    //Old Product Item
    const oldProductItem = oldProps.productData.productItem;
    if(newProductItem) {
      if(oldProductItem !== newProductItem) {
        this.setState({
          ...newProductItem,
          locationQuery: newProductItem.locationName,
          consignerQuery: newProductItem.consignerName,
          productQuery: newProductItem.title
        })
      }
    }
  }
  componentWillUnmount() {
    this.props.saveLastInvetoryFilter('lastIncomingInventoryFilter', this.state);
  }
  handleInventoryFilter() {
    this.setState({
      inventoryFilterVisible: !this.state.inventoryFilterVisible
    })
  }
  handleBulkEdit() {
    this.setState({
      bulkEditVisible: !this.state.bulkEditVisible
    })
  }
  updateState(key, value) {
    if(key === 'id') {
      this.props.getProduct(value);
    }
    this.setState({
      [key]: value
    })
  }
  loadInventory() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      filterShortCode,
      filterReceivedAt,
      filterOrderDate,
      selectedSortBy,
      filterTags,
      sortByOptions,
      isRemote,
      filterDateRange,
      filterDateRangeKey
    } = this.state;
    console.log(filterLocationQuery)
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      status: 'Pending',
      shortCode: filterShortCode !== '' ? filterShortCode : null,
      receivedAt: filterReceivedAt !== '' ? filterReceivedAt : null,
      orderDate: filterOrderDate !== '' ? filterOrderDate : null,
      sortBy: selectedSortBy !== '' &&  sortByOptions[selectedSortBy] ? sortByOptions[selectedSortBy].value : null,
      tags: filterTags !== '' ? filterTags.split(',') : null,
      isRemote: isRemote !== '' ? isRemote : null,
      dateRangeKey: filterDateRangeKey !== '' ? filterDateRangeKey : null,
      dateRange: filterDateRange !== '' ? filterDateRange : null,

    }
    this.props.getInventoryForFilter(data);
    this.setState({ inventoryFilterVisible: false });
    console.log(data)
  }
  clearProduct() {
    this.setState({
      id: null,
      productQuery: '',
      title: '',
      option1: null,
      option2: null,
      option3: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
    });
  }

  async acceptInventory(id, shortCode, item) {
    const { getProduct } = this.props;
    const draftProduct = item['Product.draftProduct'];
    const productTitle = item['Product.title'];
    const productId = item['Product.id'];
    console.log({ item })
    if(draftProduct) {
      Modal.confirm({
        title: 'Draft Product',
        content: (
          <div>
            <p>This product is a draft product, please make it live before accepting inventory.</p>
            <a
              href={`/products/${productId}`}
              target='_blank'
            >
              {productTitle}
            </a>
          </div>
        ),
        okText: 'Check Product',
        onOk: async() => {
          const hide = message.loading(`Checking if product is live....`);
          const product = await getProduct(item.productId);
          if(!product.draftProduct) {
            this.acceptModal(id, shortCode, item);
          } else {
            message.error(`Product is still a draft product...`);
            this.acceptInventory(id, shortCode, item)
          }
          hide();
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    } else {
      this.acceptModal(id, shortCode, item);
    }
  }
  handleLocationModal() {
    this.setState({
      showLocationModal: !this.state.showLocationModal
    })
  }
  acceptModal(id, shortCode, item) {
    this.setState({
      showLocationModal: true,
      selectedInventory: item,
      notes: item.notes
    })
  }
  async acceptInventoryOk() {
    const {
      selectedInventory,
      locationId,
      locationName,
      subLocationName,
      notes
    } = this.state;
    const { updateInventory } = this.props;
    const { isRemote, shortCode, id } = selectedInventory;
    const hide = message.loading(`Adding item ${shortCode}`)
    await updateInventory({
      id,
      status: 'Active',
      receivedAt: moment(),
      locationId: locationId,
      locationName: locationName,
      subLocationName,
      notes: notes
    })
    this.handleLocationModal()
    await this.loadInventory()
    hide();
  }

  async declineInventory(id, shortCode) {
    const { updateInventory } = this.props;
    Modal.confirm({
      title: 'Confirm',
      content: 'Are sure you want to set item to withdrawn?',
      onOk: async() => {
        const hide = message.loading(`Withdrawing item ${shortCode}`)
        await updateInventory({ id, status: 'Withdrawn' })
        await this.loadInventory()
        hide();
      },
      okType: 'danger',
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  async searchProducts(value, callback) {
    await this.props.searchProducts(value, 1, ProductSortBy[0], false)
    const { searchProducts } = this.props.productData;
    return searchProducts.map(obj => {return {
      value: obj.id,
      label: obj.title
    }})
  }

  render() {
    const {
      filteredInventory,
      filteringInventory,
      updatingInventory
    } = this.props.inventoryData;
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Incoming Inventory</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
          />
          <FormCard
          >
            <InventorySearchFilter
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              loadInventory={() => this.loadInventory()}
              query={this.state.filterLocationQuery}
              queryKey='filterLocationQuery'
              query2={this.state.filterConsignerQuery}
              queryKey2='filterConsignerQuery'
              query3={this.state.productQuery}
              queryKey3='productQuery'
              userId={this.props.auth.userData.id}
              canSelectProduct
              clearProduct={() => this.clearProduct()}
              disableStatus
              disableWithdrawalLocation
              userData={this.props.auth.userData}
              products={this.props.productData.searchProducts}
              loadingProducts={this.props.productData.loadingSearchProducts}
              onSearchProducts={(value) => this.searchProducts(value)}

            />
            <IncomingInventoryList
              userData={this.props.auth.userData}
              data={filteredInventory}
              loading={filteringInventory}
              updateState={(key, value) => this.updateState(key, value)}
              selectedInventory={this.state.selectedInventory}
              acceptInventory={(id, shortCode, item) => this.acceptInventory(id, shortCode, item)}
              declineInventory={(id, shortCode) => this.declineInventory(id, shortCode)}
              disableWithdrawalLocationName
              disableLocationName
              disablePayout
              disableOrderDateColumn
              disableOrderColumn
            />
          </FormCard>
          <BarcodeReader
            onScan={(data) => history.push(`inventory/${data}`)}
          />
          <SetLocation
            visible={this.state.showLocationModal}
            handleOk={() => this.acceptInventoryOk()}
            handleCancel={() => this.handleLocationModal()}
            loading={updatingInventory}
            title='Are you sure you want to set this item as active?'
            updateState={(key, value) => this.updateState(key, value)}
            userId={this.props.auth.userData.id}
            query={this.state.locationQuery}
            queryKey='locationQuery'
            dataKey='locationData'
            values={this.state}
            showInventory
          />

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
        productData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory));
