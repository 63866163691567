import axios from 'axios';
import {
  message
} from 'antd';
import {
  CONNECTIONS_API
} from '../../../constants/API';
import {
  CREATE_CONNECTION_LOADING
} from '../types';

function loadConnections(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CREATE_CONNECTION_LOADING,
      creatingConnection: true
    });
    console.log(data);
    try {
      const { userData } = getState().AuthReducer;
      const postData = {
        ...data,
        userId: userData.id
      };
      const accessToken = await localStorage.getItem('accessToken');
      await axios.post(
        CONNECTIONS_API,
        postData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: CREATE_CONNECTION_LOADING,
        creatingConnection: false
      });
      message.info('Please refresh screen in few minutes, your data will be synced after a few minutes.')
    } catch (e) {
      dispatch({
        type: CREATE_CONNECTION_LOADING,
        creatingConnection: false
      });
    }
  };
}

export default loadConnections;
