import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Button,
  Alert,
  Spin
} from 'antd';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import Avatar from '../Avatar';
import LoadingSpinner from '../LoadingSpinner';

import {
  Container,
  LogoContainer,
  AvatarTutorialContainer,
  
} from './styles';

TopBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  userData: PropTypes.shape({
    company: PropTypes.string
  })
};
TopBar.defaultProps = {
  isLoggedIn: false,
  userData: { company: 'Test Company'},
};

function TopBar(props) {

  const {
    isLoggedIn,
    userData,
    pathname,
    logout,
    csvLoading,
    csvUploading
  } = props;

  if(isLoggedIn) {

    const menu = (
      <Menu>
        <Menu.Item key="0">
        <Link to='/support' >
          Support
        </Link>
        </Menu.Item>
        <Menu.Item key="0">
          <Link to='/settings' >
            Settings
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <a onClick={logout}>
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
    return (

        <Container data-test="TopBarComponent">
          <LogoContainer data-test="TopBarLogoContainer">
            <Link
              to='/'
              style={{ display: 'flex', alignItems: 'center' }}
              data-test="TopBarLogoLink"
            >
              <Logo
                data-test="TopBarLogo"
                logo={userData.logo}
              />
            </Link>
          </LogoContainer>
          {!userData.didOnboard ?
            csvUploading.isLoading ?
                 <Alert 
                  message={`Uploading your data ${(Number(csvUploading.current / csvUploading.total) * 100).toFixed(2)}%`} 
                  banner 
                  type='info'
                  icon={<Spin size="small" />}
                />            
            :
            csvLoading.isLoading ?
                <Alert 
                  message={`Creating CSV ${(Number(csvLoading.current / csvLoading.total) * 100).toFixed(2)}% will be sent to ${userData.email} when completed`} 
                  banner 
                  icon={<Spin size="small" />}
                  
                />              
            :
            <Link to={'/onboarding'}>
              <Alert 
                message="Setup your account - click here" 
                banner 
                type='error'
              />
            </Link>
          : null}
          <LogoContainer>
          <Button
            data-canny-changelog
            shape="circle"
            icon="notification"
            theme="filled"

          />
          <Link to="/support">
            <Button
              shape="circle"
              icon="question-circle"
              theme="filled"
              style={{ marginLeft: 10 }}
            />
          </Link>
          <AvatarTutorialContainer overlay={menu}>

            <a>
              <Avatar
                name={`${userData.employee.name} ${userData.company}`}
                data-test="TopBarAvatar"
              />
            </a>
          </AvatarTutorialContainer>
          </LogoContainer>
        </Container>
    )
  }
  return (
    <Container data-test="TopBarLoggedOffContainer">
      <LogoContainer data-test="TopBarLogoContainer">
        <LoadingSpinner />
      </LogoContainer>
    </Container>
  );
}

export default TopBar;
