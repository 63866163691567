import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  BULK_UPSERT_PRODUCTS_LOADING
} from '../types';


function bulkUpsertProducts(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: BULK_UPSERT_PRODUCTS_LOADING,
        bulkUpsertingProducts: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const postData = {
          data
        };
        console.log(accessToken)
        console.log(`${PRODUCTS_API}/bulkUpsert`)
        const productResponse = await axios.post(`${PRODUCTS_API}/bulkUpsert`, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        console.log(productResponse)
        dispatch({
          type: BULK_UPSERT_PRODUCTS_LOADING,
          bulkUpsertingProducts: false
        });
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: BULK_UPSERT_PRODUCTS_LOADING,
          bulkUpsertingProducts: false
        });
        console.log(e);
      }
  }
}
export default bulkUpsertProducts;
