import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API,
} from '../../../constants/API';
import {
  CREATE_CONSIGNER_LOADING
} from '../types';


function createInventory(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_CONSIGNER_LOADING,
        creatingConsigner: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const consignerResponse = await axios.post(CONSIGNER_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        const { id } = consignerResponse.data

        dispatch({
          type: CREATE_CONSIGNER_LOADING,
          creatingConsigner: false
        });
        return id;
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_CONSIGNER_LOADING,
          creatingConsigner: false
        });
        console.log(e);
      }
  }
}
export default createInventory;
