import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import MembershipReducer from './MembershipReducer';
import InventoryReducer from './InventoryReducer';
import ConsignerReducer from './ConsignerReducer';
import CustomerReducer from './CustomerReducer';
import DiscountReducer from './DiscountReducer';
import EmployeeReducer from './EmployeeReducer';
import OrderReducer from './OrderReducer';
import TaxReducer from './TaxReducer';
import LocationReducer from './LocationReducer';
import AnalyticsReducer from './AnalyticsReducer';
import ConnectionReducer from './ConnectionReducer';
import ProductReducer from './ProductReducer';
import IntegrationReducer from './IntegrationReducer';
import PayoutReducer from './PayoutReducer';
import AppointmentReducer from './AppointmentReducer';
import FeeReducer from './FeeReducer';
import AppointmentRuleReducer from './AppointmentRuleReducer';
import AppointmentTimeReducer from './AppointmentTimeReducer';
import CatalogReducer from './CatalogReducer';
import ProductRestrictionReducer from './ProductRestrictionReducer';
import ProductTemplateReducer from './ProductTemplateReducer';
import LedgerReducer from './LedgerReducer';

export default combineReducers({
    AuthReducer,
    MembershipReducer,
    InventoryReducer,
    ConsignerReducer,
    CustomerReducer,
    DiscountReducer,
    EmployeeReducer,
    OrderReducer,
    TaxReducer,
    LocationReducer,
    AnalyticsReducer,
    ConnectionReducer,
    IntegrationReducer,
    ProductReducer,
    PayoutReducer,
    AppointmentReducer,
    FeeReducer,
    AppointmentRuleReducer,
    AppointmentTimeReducer,
    CatalogReducer,
    ProductRestrictionReducer,
    ProductTemplateReducer,
    LedgerReducer
});
