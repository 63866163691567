import React from 'react';
import {
  Redirect
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Button } from 'antd';
import * as actions from '../redux/actions';
import history from '../navigation/history';
import doesExistInArrayOfObj from '../helpers/data/doesExistInArrayOfObj';
import {
  SHOPIFY
} from '../constants/Integrations';
import LoadingSpinner from '../components/ui/LoadingSpinner';

import {
  DashboardContainer,
  Title,
  SubTitle
} from './styles';
class ShopifySignup extends React.Component {

  componentWillMount() {
    this.props.loadConnections();
  }
  login() {
    const parsed = queryString.parse(this.props.location.search);
    const { shop, accessToken } = parsed;
    this.props.login({
      shop,
      shopifyAccessToken: accessToken,
      isShopify: true
    });
  }
  async createConnection() {
    const parsed = queryString.parse(this.props.location.search);
    const { shop, accessToken } = parsed;
    await sessionStorage.setItem('shopifyConnecting', true);
    await this.props.createConnection({
      fieldValues: {
        shop,
        accessToken
      },
      integrationId: SHOPIFY
    });
    history.push('/');
  }

  render() {
    const {
      loadingConnection,
      connections,
      creatingConnection
    } = this.props.connections;
    const {
      isLoggedIn
    } = this.props.auth;

    if(!isLoggedIn) {
      return(
        <DashboardContainer>
          <Title>No Frontflip Account?</Title>
          <SubTitle>Looks like you don't have a Frontflip account yet. You need one before continuing to sync with Shopify. Click below to Login or Signup</SubTitle>
          <Button onClick={() => this.login()}> Signup or Login To Frontflip</Button>
        </DashboardContainer>
      );
    }
    if(loadingConnection) {
      return <LoadingSpinner />
    }
    const connection = doesExistInArrayOfObj(connections, SHOPIFY);
    if(connection) {
      return (
        <Redirect to='/' />
      )
    }
    return (
      <DashboardContainer>
        <Title>Almost there...</Title>
        <SubTitle>The last step is to connect your Shopify data with Frontflip to pull and push data into each platform. Don't worry we do this automatically for you, just click the button below.</SubTitle>
        <Button
          onClick={() => this.createConnection()}
          loading={creatingConnection}
        >
          Start Shopify Sync
        </Button>
      </DashboardContainer>
    )
  }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connections: state.ConnectionReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopifySignup);
