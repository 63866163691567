import axios from 'axios';
import { message } from 'antd';
import {
  APPOINTMENT_TIMES_API,
} from '../../../constants/API';
import {
  DELETE_APPOINTMENT_TIME_LOADING
} from '../types';
import getLocations from '../location/getLocations';

function deleteAppointmentTime(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: DELETE_APPOINTMENT_TIME_LOADING,
        deletingAppointmentTime: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        await axios.delete(`${APPOINTMENT_TIMES_API}/${id}`, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: DELETE_APPOINTMENT_TIME_LOADING,
          deletingAppointmentTime: false
        });
        const { locationItem } = getState().LocationReducer;

        await dispatch(getLocations());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: DELETE_APPOINTMENT_TIME_LOADING,
          deletingAppointmentTime: false
        });
        console.log(e);
      }
  }
}
export default deleteAppointmentTime;
