import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import {
  TimelineContainer,
  TimelineItem,
  TimelineTextContainer,
  Title,
  SubTitle
} from './styles'
class ConsignerTimeline extends React.Component {

  render() {
    const {
      data
    } = this.props;
    if(!data || data.length <= 0) {
      return null;
    }
    return (
      <TimelineContainer>
        {
          data.map((timeline) =>
              <TimelineItem>
                <TimelineTextContainer>
                  <div>
                    <Title> On {moment(timeline.createdAt).format('llll')} {timeline.model} {timeline.byName}</Title>
                    {
                      timeline.changed ? Object.keys(timeline.changed).map(changedKey => {
                        console.log(changedKey)
                        const changedValues = timeline.changed[changedKey];
                        return <SubTitle>Changed {changedKey.toUpperCase()} from {changedValues.previous} to {changedValues.new}</SubTitle>
                      }) : <SubTitle>Created {timeline.sourceModel} for {timeline.sourceName}</SubTitle>
                    }
                  </div>
                </TimelineTextContainer>

              </TimelineItem>
        )}
      </TimelineContainer>
    );
  }
}
export default ConsignerTimeline;
