import React from 'react';
import {
  Form,
  InputNumber
} from 'antd';
import Tag from '../../../Tag';
import DropdownSearch from '../../../../algolia/DropdownSearch';
import SelectDropdown from '../../../SelectDropdown';

class InventoryLocation extends React.Component {
  render() {
    const {
      updateState,
      values,
      query,
      queryKey,
      query2,
      queryKey2,
      userId,
      sourceConsignerName,
      viewOnly,
      onViewOnlyClick,
      quantityDisabled,
      disabled,
      dataKey
    } = this.props;
    const {
      productContents,
      locationName,
      quantity,
      subLocationName,
      locationData,
      grams
    } = values;
    return (
      <div>
          <Form.Item label='Product Contents'>
            <Tag
              title='New Content'
              updateState={updateState}
              stateKey='productContents'
              value={productContents}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item label='Location'>
            <DropdownSearch
              searchPlaceholder='Search Locations'
              index='locations'
              keyName='name'
              updateState={updateState}
              stateKey='locationId'
              stateValue='objectID'
              stateKey2='locationName'
              stateValue2='name'
              value={locationName}
              query={query}
              queryKey={queryKey}
              userId={userId}
              viewOnly={disabled}
              dataKey={dataKey}

            />
            <SelectDropdown
              data={locationData && locationData.subLocations ? locationData.subLocations : []}
              onChange={(value) => updateState('subLocationName', value)}
              value={subLocationName}
              disabled={disabled}
              placeholder='Select Sub Location'
            />
          </Form.Item>
          <Form.Item label='Weight (grams)'>
            <InputNumber
              defaultValue={0}
              onChange={(value) => updateState('grams', value)}
              value={grams}
              disabled={disabled}
            />
          </Form.Item><Form.Item label='Bulk Add Quantity'>
            <InputNumber
              defaultValue={1}
              onChange={(value) => updateState('quantity', value)}
              value={quantity}
              disabled={quantityDisabled}
            />
          </Form.Item>
          <DropdownSearch
            searchPlaceholder='Add Buyout source (optional)'
            index='consigners'
            keyName='name'
            updateState={updateState}
            stateKey='sourceConsignerId'
            stateValue='objectID'
            stateKey2='sourceConsignerName'
            stateValue2='name'
            value={sourceConsignerName}
            query={query2}
            queryKey={queryKey2}
            userId={userId}
            viewOnly={viewOnly}
            onViewOnlyClick={onViewOnlyClick}
          />
      </div>
    );
  }
}
export default InventoryLocation;
