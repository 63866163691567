import axios from 'axios';
import { message } from 'antd';
import {
  INVENTORY_API
} from '../../../constants/API';
import {
  GET_INVENTORY_ITEM_LOADING,
  GET_INVENTORY_ITEM
} from '../types';

function getInventoryItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INVENTORY_ITEM_LOADING,
      loadingInventoryItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const inventoryItemResponse = await axios.get(
        `${INVENTORY_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_INVENTORY_ITEM,
        loadingInventoryItem: false,
        inventoryItem: inventoryItemResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_INVENTORY_ITEM_LOADING,
        loadingInventoryItem: false
      });
    }
  };
}
export default getInventoryItem;
