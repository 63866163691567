import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from "react-helmet";
import {
  withRouter
} from 'react-router-dom';
import * as actions from '../redux/actions';
import { Typography, PageHeader, Layout, Button } from 'antd'
import {
  DashboardContainer,
  FormCard
} from './styles';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

class Support extends React.Component {

  render() {
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title='frontflip Support'
            style={{ background: 'transparent' }}
            breadcrumb={null}
            footer={null}
          />

          <FormCard style={{ padding: '0 50px' }}>

              <div style={{marginBottom: '2em'}}>
                <Title level={2}>Documentation</Title>
                <Paragraph>
                Looking for a "How To Guide" or just want to learn more?
                </Paragraph>
                <Button
                type="primary"
                onClick={ () => window.open('https://www.notion.so/frontflipco/Frontflip-Documentation-7f0f9840102048fd8cc52be63e5a75bf', '_blank') }>
                 View Documentation
                </Button>
              </div>

              <div style={{marginBottom: '2em'}}>
                <Title level={2}>FAQ's</Title>
                <Paragraph>
                Have a quick question? Browse our frequently asked questions and answers.
                </Paragraph>
                <Button
                type="primary"
                onClick={ () => window.open('https://www.notion.so/frontflipco/e486952fe6994785a779a4b42cb7d80f', '_blank') }>
                 View FAQ's
                </Button>
              </div>

              <div style={{marginBottom: '2em'}}>
                <Title level={2}>Support</Title>
                <Paragraph>
                Need support? Use the live chat or email us at support@frontflip.co
                </Paragraph>
              </div>
            </FormCard>

            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7152235.js"></script>
            </Helmet>
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        analyticsData: state.AnalyticsReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Support));
