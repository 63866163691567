import React from 'react';
import { Tag, Input, Tooltip, Icon } from 'antd';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    console.log(this.props)
    this.state = {
      tags: value,
      inputVisible: false,
      inputValue: '',
    }
  }

  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { value } = this.props;
    const oldValue = oldProps.value;
    if(value !== oldValue) {
      this.setState({ tags: value })
    }
  }

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    console.log(tags);
    this.props.updateState(this.props.stateKey, tags);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    console.log(tags);
    this.props.updateState(this.props.stateKey, tags);

    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  saveInputRef = input => (this.input = input);

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    const { title, disabled } = this.props;
    if(!tags) {
      return null;
    }
    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={tag} closable={!disabled} onClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
            name={this.props.inputName}
          />
        )}
        {(!inputVisible && !disabled) && (
          <Tag
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
            id={this.props.id}
          >
            <Icon type="plus" /> {title}
          </Tag>
        )}
      </div>
    );
  }
}
export default Tags;
