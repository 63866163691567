import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  PageHeader,
  Card
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import {
  DashboardContainer,
  Logo,
  PageTitle
} from '../styles';
import * as actions from '../../redux/actions';
import SelectDropdown from '../../components/ui/SelectDropdown';
import ConsignerStatus from '../../constants/ConsignerStatus';
import Currencies from '../../constants/Currencies';
const { Option } = Select;

class SettingsCustomDomain extends React.Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    this.state = {
      consignerSubDomain: userData ? userData.consignerSubDomain : '',
      consignerCNAME: userData ? userData.consignerCNAME : '',
      adminSubDomain: userData ? userData.adminSubDomain : '',
      adminCNAME: userData ? userData.adminCNAME : '',
    }
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  uploadImage(key) {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add images to state
            this.setState({
              [key]: secure_url
            })
        }
    });
  }
  render() {
    const {
      consignerSubDomain,
      consignerCNAME,
      adminCNAME,
      adminSubDomain
    } = this.state;

    const {
      updateUser
    } = this.props;
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Custom Domains</PageTitle>}
          style={{ background: 'transparent' }}
          extra={[
            <a
              href='https://frontflip.kwiki.io/docs/custom-domains'
              target='_blank'
            >
              How to Setup?
            </a>,
          ]}
        />
        <Card>
          <Form.Item label='Consignment Portal Url'>
            <Input.Search
              loading={this.props.auth.updatingUserSubdomain}
              placeholder='sell.frontflip.co'
              onChange={(e) => this.updateState('consignerSubDomain', e.target.value)}
              value={consignerSubDomain}
              enterButton={this.props.auth.userData.consignerSubDomain && this.props.auth.userData.consignerSubDomain !== '' ? 'Update' : 'Add'}
              size='large'
              onSearch={() => this.props.updateSubdomain('consigner', consignerSubDomain)}
            />
          </Form.Item>
          <Form.Item label='Consignment Portal CNAME'>
            <Input.TextArea
              placeholder='Your CNAME to add to your DNS will appear here'
              value={consignerCNAME}
              autoSize
            />
          </Form.Item>
        </Card>
        <Card>
          <Form.Item label='Admin Portal Url'>
            <Input.Search
              loading={this.props.auth.updatingUserSubdomain}
              placeholder='admin.frontflip.co'
              onChange={(e) => this.updateState('adminSubDomain', e.target.value)}
              value={adminSubDomain}
              enterButton={this.props.auth.userData.adminSubDomain && this.props.auth.userData.adminSubDomain !== '' ? 'Update' : 'Add'}
              size='large'
              onSearch={() => this.props.updateSubdomain('admin', adminSubDomain)}

            />
          </Form.Item>
          <Form.Item label='Admin Portal CNAME'>
            <Input.TextArea
              placeholder='Your CNAME to add to your DNS will appear here'
              value={adminCNAME}
              autoSize
            />
          </Form.Item>
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsCustomDomain));
