import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Statistic,
  Empty
} from 'antd';
import { Card } from 'antd';
import LoadingSpinner from '../LoadingSpinner';
AnalyticCard.propTypes = {
  loadingAnalytics: PropTypes.bool,
  analytics: PropTypes.shape({
    salesCount: PropTypes.number,
    inventoryCount: PropTypes.number,
    consignerCount: PropTypes.number,
    customerCount: PropTypes.number,
  }),
};
AnalyticCard.defaultProps = {
  loadingAnalytics: false,
  analytics: {
    salesCount: 0,
    inventoryCount: 0,
    consignerCount: 0,
    customerCount: 0
  }
};

function AnalyticCard(props) {
  const {
    loadingAnalytics,
    analytics,
    id,
    defaultCurrency
  } = props;
  if(loadingAnalytics) {
    return <LoadingSpinner />
  }
  if(!analytics) {
    return <Empty />;
  }
  const {
    salesCount,
    inventoryCount,
    consignerCount,
    customerCount,
    revenue
  } = analytics;
  const {
    symbol
  } = defaultCurrency;
  return (
    <Row
      gutter={16}
      style={{ marginTop: 20 }}
      data-test='AnalyticCardsContainer'
      id={id}
    >
     <Col
        xs={24}
        sm={24}
        md={24}
        lg={6}
        style={{ marginBottom: 16 }}
        data-test='AnalyticCardSalesCardContainer'
     >
      <Card
        data-test='AnalyticCardSalesCard'
      >
         <Statistic
          title='Sales'
          value={salesCount}
          prefix={symbol}
          data-test='AnalyticCardSalesCardStat'
        />
      </Card>
     </Col>
     <Col
        xs={24}
        sm={24}
        md={24}
        lg={6}
        data-test='AnalyticCardSalesCardContainer'
        style={{ marginBottom: 16 }}
     >
      <Card
        data-test='AnalyticCardConsignementRevenueCard'
      >
         <Statistic
          title='Gross margin'
          value={revenue}
          prefix={symbol}
          data-test='AnalyticCardConsignementRevenueCardStat'
        />
      </Card>
     </Col>
     <Col
      xs={24}
      sm={24}
      md={24}
      lg={6}
      data-test='AnalyticCardInventoryCardContainer'
      style={{ marginBottom: 16 }}
     >
      <Card
        data-test='AnalyticCardInventoryCard'
      >
         <Statistic
          title='Inventory'
          value={inventoryCount}
          data-test='AnalyticCardInventoryCardStat'

        />
      </Card>
     </Col>
     <Col
      xs={24}
      sm={24}
      md={24}
      lg={6}
      data-test='AnalyticCardCustomerCardContainer'
      style={{ marginBottom: 16 }}
    >
      <Card
        data-test='AnalyticCardCustomerCard'
      >
         <Statistic
          title='Customers'
          value={customerCount}
          data-test='AnalyticCardCustomerCardStat'
        />
      </Card>
     </Col>
     <Col
      xs={24}
      sm={24}
      md={24}
      lg={6}
      data-test='AnalyticCardConsignerCardContainer'
      style={{ marginBottom: 16 }}
    >
      <Card
        data-test='AnalyticCardConsignerCard'
      >
       <Statistic
        title='Consigners'
        value={consignerCount}
        data-test='AnalyticCardConsignerCardStat'
      />
      </Card>
     </Col>
 </Row>
  )
}

export default AnalyticCard;
