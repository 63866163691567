import styled from 'styled-components';
import {
  Table
} from 'antd';
export const Container = styled.div`
  margin: 20px;
  background: white;
  padding: 20px;
`

export default {
  Container
};
