import React from 'react';
import OrderList from '../../../OrderList';

class CustomerOrder extends React.Component {

  render() {
    const {
      data,
      defaultCurrency
    } = this.props;
    return (
      <OrderList
        data={data}
        defaultCurrency={defaultCurrency}
      />
    );
  }
}
export default CustomerOrder;
