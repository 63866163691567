import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import ProductTemplatesSettings from '../Settings/productTemplates';
import ProductTemplateInstructions from '../../constants/ProductTemplateInstructions';
import history from '../../navigation/history';
import {
  Card,
  List,
  Button
} from 'antd'


class ProductTemplates extends React.Component {

  render() {

    return (
      <div>
        <ProductTemplatesSettings
          header={
            <List
              grid={{ gutter: 16, column: 3 }}
              dataSource={ProductTemplateInstructions}
              renderItem={item => (
                <List.Item >
                  <Card
                    title={item.title}
                    hoverable
                  >
                    {item.desc}
                  </Card>
                </List.Item>
             )}
            />
          }
          button={
            <Button
              type='primary'
              onClick={() => {
                history.push('/onboarding/editProducts')

                window.location.reload()
              }}
            >
              Next
            </Button>
          }
        />

      </div>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductTemplates));
