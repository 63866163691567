import React from 'react';
import { Empty } from 'antd';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove
} from './styles';

class SettingsAppointment extends React.Component {

  render() {
    const {
      appointmentTimes,
      deleteAppointmentTime,
      handleAddAppointmentTimesModal
    } = this.props;
    if(!appointmentTimes || appointmentTimes.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddAppointmentTimesModal()}
            >
              Add Appointment Times
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        appointmentTimes.map((item) => {
          return (
            <ItemContainer>
              <Title>
              {item.open} to {item.close}
              </Title>
              <SubTitle>
                {item.day}
              </SubTitle>
              <Remove
                onClick={() => {
                  deleteAppointmentTime(item.id)
                }}
              >
                REMOVE
              </Remove>
            </ItemContainer>
          )
        })
      }
      <a
        onClick={() => handleAddAppointmentTimesModal()}

      >
        Add Appointment Time
      </a>
      </Container>
    );
  }
}
export default SettingsAppointment;
