import React from 'react';
import {
  Empty
} from 'antd';

function EmptyProductList() {
  return (
    <Empty
      description='No Products For Search'
    />
  )
}

export default EmptyProductList;
