import {
  GET_APPOINTMENT_RULES_LOADING,
  GET_APPOINTMENT_RULES,
  CREATE_APPOINTMENT_RULE_LOADING,
  DELETE_APPOINTMENT_RULE_LOADING
} from '../actions/types';

const defaultState = {
  loadingAppointmentRules: false,
  appointmentRules: [],
  creatingAppointmentRule: false,
  deletingAppointmentRule: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_APPOINTMENT_RULES_LOADING:
          return {
            ...state,
            loadingAppointmentRules: action.loadingAppointmentRules
          };
        case GET_APPOINTMENT_RULES:
          return {
            ...state,
            loadingAppointmentRules: action.loadingAppointmentRules,
            appointmentRules: action.appointmentRules
          };
        case CREATE_APPOINTMENT_RULE_LOADING:
          return {
            ...state,
            creatingAppointmentRule: action.creatingAppointmentRule
          }
        case DELETE_APPOINTMENT_RULE_LOADING:
          return {
            ...state,
            deletingAppointmentRule: action.deletingAppointmentRule
          }
        default:
            return state;
    }
};
