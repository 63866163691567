import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import history from './navigation/history';
import store from './redux/store'; //Import the store
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: process.env.REACT_APP_SENTRY_API_KEY });

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <Router history={history}>
        <App/>
      </Router>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);

serviceWorker.unregister();
