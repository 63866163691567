import axios from 'axios';
import {
  USERS_API
} from '../../../constants/API';

async function createUser(user, token) {
  try {
    const {
      email,
      picture,
      sub
    } = user;
    const usersData = await axios.get(`${USERS_API}/${sub}`, { headers: {"Authorization" : `Bearer ${token}`} });
    if(usersData.data.isError) { // Check if it is a new user
      const data = { email, image: picture, auth0_id: sub, tokens: 0 };
      await axios.post(USERS_API, data, { headers: {"Authorization" : `Bearer ${token}`} });
    }
  } catch (e) {
    alert('Error creating user');
    console.log(e);
  }
}

export default createUser;
