import React from 'react';
import {
  Menu,
  Icon
} from 'antd';

import {
  Link
} from 'react-router-dom';
import sideBarRoutes from '../../../navigation/sideBarRoutes'
import history from '../../../navigation/history';

import {
  SideMenu,
  SideBar,
  MenuItem,
} from './styles';

const SubMenu = Menu.SubMenu;
class SidebarMenu extends React.Component {
  state = {
    collapsed: true,
    remoteHisotryListener: () => {}
  }

  componentDidMount() {
    const remoteHisotryListener = history.listen(() => {
      // break point after menu is shown by default
      const isMobile = window.matchMedia("(max-width: 991px)").matches;
      isMobile && this.setState({ collapsed: true })
    });
    
    this.setState({ remoteHisotryListener });
  }

  componentWillUnmount() {
    this.state.remoteHisotryListener();
  } 

  render() {
    const {
      logout,
      changePassword,
      collapsed,
      userData
    } = this.props;

    let { restrictedRoutes } = userData.employee;
    restrictedRoutes = restrictedRoutes || []
    if(!userData.enableAppointments) {
      restrictedRoutes.push('/appointments');
    }
    return (
        <SideBar
          theme="light"
          width={250}
          id='sidemenu'
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={this.state.collapsed}
          onCollapse={collapsed => this.setState({ collapsed })}
        >

          <SideMenu theme="light" mode="inline">
          {
            sideBarRoutes(MenuItem, changePassword, logout, userData).map((secretRoute) => {
              const {
                key,
                path,
                icon,
                title,
                subMenu,
                subMenuItems
              } = secretRoute;
              if(restrictedRoutes.includes(path)) {
                return null;
              }
              if(!subMenu) {
                return (
                  <MenuItem key={key}>
                    <Link to={path}>
                      <Icon type={icon} />
                      <span>
                        {title}
                      </span>
                    </Link>
                  </MenuItem>
                )
              } return (
                <SubMenu
                  key={key}
                  title={
                    <span>
                      <Icon type={icon} />
                      <span>{title}</span>
                    </span>
                }>
                {subMenuItems.map(item => {
                  return item
                })}

                </SubMenu>
              )

            }
          )}
          </SideMenu>

        </SideBar>
    );
  }
}
export default SidebarMenu;
