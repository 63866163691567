import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API
} from '../../../constants/API';
import {
  SEARCH_CONSIGNERS_LOADING,
  SEARCH_CONSIGNERS
} from '../types';

function searchConsigners(query, page, order) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_CONSIGNERS_LOADING,
      loadingSearchConsigners: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const searchConsignerResponse = await axios.post(
        `${CONSIGNER_API}/search/${userData.id}`,
        { query, page, order },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(searchConsignerResponse)
      dispatch({
        type: SEARCH_CONSIGNERS,
        loadingSearchConsigners: false,
        searchConsigners: !searchConsignerResponse.data.isError ? searchConsignerResponse.data.results : [],
        searchConsignersCount: !searchConsignerResponse.data.isError ? searchConsignerResponse.data.count : 0,
      });
      return searchConsignerResponse.data;
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: SEARCH_CONSIGNERS_LOADING,
        loadingSearchConsigners: false
      });
    }
  };
}
export default searchConsigners;
