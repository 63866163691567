import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCT_TEMPLATES_API,
} from '../../../constants/API';
import {
  UPDATE_PRODUCT_TEMPLATE_LOADING
} from '../types';
import getProductTemplates from './getProductTemplates';

function updateProductTemplate(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_PRODUCT_TEMPLATE_LOADING,
        updatingProductTemplate: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data,
          userId: userData.id
        };
        const hide = message.loading('Updating Product Template', 0);
        await axios.put(`${PRODUCT_TEMPLATES_API}/${data.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_PRODUCT_TEMPLATE_LOADING,
          updatingProductTemplate: false
        });
        hide()
        await dispatch(getProductTemplates());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_PRODUCT_TEMPLATE_LOADING,
          updatingProductTemplate: false
        });
        console.log(e);
      }
  }
}
export default updateProductTemplate;
