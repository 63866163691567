import axios from 'axios';
import { message } from 'antd';
import {
  LOCATIONS_API,
} from '../../../constants/API';
import {
  DELETE_LOCATION_LOADING
} from '../types';
import getLocations from './getLocations';

function deleteLocation(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: DELETE_LOCATION_LOADING,
        deletingLocation: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const hide = message.loading('Deleting Location', 0);

        await axios.delete(`${LOCATIONS_API}/${id}`, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: DELETE_LOCATION_LOADING,
          deletingLocation: false
        });
        hide();
        await dispatch(getLocations());

      } catch (e) {
        message.error(String(e));
        dispatch({
          type: DELETE_LOCATION_LOADING,
          deletingLocation: false
        });
        console.log(e);
      }
  }
}
export default deleteLocation;
