import axios from 'axios';
import {
  USERS_API,
  CATALOG_API
} from '../../../constants/API';

async function getUser(userId, token) {
  try {
    const usersData = await axios.get(`${USERS_API}/${userId}`, { headers: {"Authorization" : `Bearer ${token}`} });
    if(usersData.data.isError) {
      return false;
    }

    usersData.data.subscribedCatalogNames = []
     if (usersData.data.subscribedCatalogIds !== null && usersData.data.subscribedCatalogIds.length > 0) {
        const urlEncodedCatalogIds = usersData.data.subscribedCatalogIds.join(",");
        const catalogResponse = await axios.get(
          `${CATALOG_API}?catalogIds=${urlEncodedCatalogIds}`,
          { headers: { Authorization: `Bearer ${token}` } }
      );
       usersData.data.subscribedCatalogNames = catalogResponse.data.map(catalog => catalog.title.toLowerCase());
     }

    console.log(usersData.data)
    return usersData.data;
  } catch (e) {
    alert('Error getting user');
    console.log(e);
    return false;
  }
}

export default getUser;
