import React from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker
} from 'antd';

class ConsignerContact extends React.Component {

  render() {
    const {
      updateState,
      values,
      disabled
    } = this.props;
    const {
      email,
      phone
    } = values;
    return (
      <div>
        <Form.Item label='Email'>
          <Input
            placeholder='Consigners email'
            onChange={(e) => updateState('email', e.target.value)}
            value={email}
            disabled

          />
        </Form.Item>
        <Form.Item label='Phone' >
          <Input
            placeholder='Consigners phone'
            onChange={(e) => updateState('phone', e.target.value)}
            value={phone}
            disabled={disabled}

          />
        </Form.Item>
      </div>
    );
  }
}
export default ConsignerContact;
