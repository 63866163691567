import axios from 'axios';
import {
  FEES_API
} from '../../../constants/API';
import {
  GET_FEES_LOADING,
  GET_FEES
} from '../types';

function getTaxRates() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_FEES_LOADING,
      loadingFees: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const feesResponse = await axios.get(
        `${FEES_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_FEES,
        loadingFees: false,
        fees: !feesResponse.data.isError ? feesResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_FEES_LOADING,
        loadingFees: false
      });
    }
  };
}
export default getTaxRates;
