import {
  GET_CONNECTION,
  GET_CONNECTION_LOADING,
  CREATE_CONNECTION_LOADING
} from "../actions/types"

let defaultState = {
  connections: [],
  loadingConnection: false,
  creatingConnection: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_CONNECTION:
          return {
            ...state,
            loadingConnection: action.loadingConnection,
            connections: action.connections
          };
        case GET_CONNECTION_LOADING:
          return {
            ...state,
            loadingConnection: action.loadingConnection,
          };
        case CREATE_CONNECTION_LOADING:
          return {
            ...state,
            creatingConnection: action.creatingConnection
          }
        default:
            return state;
    }
};
