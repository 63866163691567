import React from 'react';
import {
  Container,
  Spinner
} from './styles';

function LoadingSpinner(props) {
  return (
    <Container>
      <Spinner size='large' />
    </Container>
  );
}
export default LoadingSpinner;
