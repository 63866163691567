import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  Col,
  Empty,
  message,
  Icon,
  Switch
} from 'antd';
import moment from 'moment';
import {
  withRouter,
  Link
} from 'react-router-dom';

import history from '../../navigation/history';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import InventoryName from '../../components/ui/forms/inventory/InventoryName';
import InventoryConsigner from '../../components/ui/forms/inventory/InventoryConsigner';
import InventoryPricing from '../../components/ui/forms/inventory/InventoryPricing';
import InventoryDescription from '../../components/ui/forms/inventory/InventoryDescription';
import InventoryLocation from '../../components/ui/forms/inventory/InventoryLocation';
import InventoryStatus from '../../components/ui/forms/inventory/InventoryStatus';
import InventoryIntake from '../../components/ui/forms/inventory/InventoryIntake';
import PhotoUploader from '../../components/ui/PhotoUploader';
import Timeline from '../../components/ui/Timeline';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  FormCard,
  AddContainer,
  BarcodeContainer,
  MobileBar,
  MobileContainer,
  MobileBarText,
  QRCodeImage
} from '../styles';

class ViewInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      title: '',
      receivedAt: moment(),
      description: '',
      images: [],
      price: '1000',
      compareAt: '',
      lowestPrice: '1000',
      fee: '20',
      dollarfee: '',
      feeType: '%',
      taxable: false,
      productContents: [],
      locationId: null,
      quantity: 1,
      consignerId: null,
      option1: '',
      option2: '',
      option3: '',
      brand: '',
      category: '',
      tags: [],
      status: '',
      expirationDate: moment(),
      returnPolicy: '',
      consignerName: '',
      locationName: '',
      locationQuery: '',
      consignerQuery: '',
      options: [],
      sourceConsignerName: '',
      sourceConsignerQuery: '',
      sourceConsignerId: null,
      notes: '',
      editMode: false,
      locationData: {},
      isStoreInventory: false,
      grams: 0

    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getInventoryItem(id);
    this.handleStoreInventory(false);
  }
  componentDidUpdate(oldProps) {
    //New Inventory Item
    const { inventoryData } = this.props;
    const newInventoryItem = inventoryData.inventoryItem;
    //Old Inventory Item
    const oldInventoryItem = oldProps.inventoryData.inventoryItem;
    if(newInventoryItem) {
      if(oldInventoryItem !== newInventoryItem) {
        this.setState({
          ...newInventoryItem,
          locationQuery: newInventoryItem.locationName,
          consignerQuery: newInventoryItem.consignerName,
          sourceConsignerQuery: newInventoryItem.sourceConsignerName,
          productQuery: newInventoryItem.title,
          locationData: { subLocations: newInventoryItem['Location.subLocations']}

        })
      }
    }
  }
  uploadImage() {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add images to state
            this.setState({
              images: [...this.state.images, secure_url]
            })
        }
    });
  }
  deleteImage(index) {
    const images = this.state.images;
    images.splice(index, 1);
    this.setState({ images });
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

  async updateInventory() {
    const { updateInventory } = this.props;
    const { options } = this.state;
    let optionsSelected = true;
    options.forEach((option, index) => {
      if(option && option !== '') {
        if(this.state[`option${index + 1}`] === '') {
          optionsSelected = false;
        }
      }
    })
    if(optionsSelected) {
      await updateInventory(this.state);
      window.location.reload();
    } else {
      message.error('You must enter in the options for this inventory');
    }

  }
  cancelEdit() {
    const id = this.props.match.params.id
    this.props.getInventoryItem(id);
    this.setState({
      editMode: false
    })
  }

  handleStoreInventory(checked) {
    if(!checked) {
      const {
        consignerAccount
      } = this.props.auth.userData
      this.setState({
        consignerName: consignerAccount && consignerAccount.name ? consignerAccount.name : '',
        consignerQuery: consignerAccount && consignerAccount.name ? consignerAccount.name : '',
        consignerId: consignerAccount && consignerAccount.id ? consignerAccount.id : '',
        isStoreInventory: true
      })
    } else {
      this.setState({
        consignerName: '',
        consignerQuery: '',
        consignerId: null,
        isStoreInventory: false
      })
    }

  }
  getShopifyProductUrl() {
    const { inventoryData, auth } = this.props;
    const { connectionsData } = auth.userData;
    const { inventoryItem } = inventoryData;
    if(
      connectionsData && 
      connectionsData[0].fieldValues && 
      connectionsData[0].fieldValues.shop &&
      inventoryItem &&
      inventoryItem['Product.metadata'] &&
      inventoryItem['Product.metadata'].productId
    ) {
      const shop = connectionsData[0].fieldValues.shop;
      const productId = inventoryItem['Product.metadata'].productId;
      const shopifyProductUrl = `https://${shop}/admin/products/${productId}`
      return shopifyProductUrl;
    } 
    return '';

  }
  render() {
    const { images } = this.state;
    const { inventoryData, auth } = this.props;
    const {
      defaultCurrency,
      connectionsData
    } = auth.userData
    const {
      loadingInventoryItem,
      inventoryItem,
    } = inventoryData;
    if(loadingInventoryItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!inventoryItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const shopifyProductUrl = this.getShopifyProductUrl()
    return (
      <AddContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>{inventoryItem.title}</PageTitle>}
          footer={`${inventoryItem.locationName || ''} ${inventoryItem.subLocationName || ''}`}
          style={{ background: 'transparent' }}
          extra={[
            this.state.editMode ?
            <Button
              key="1"
              onClick={() => this.cancelEdit()}
              loading={inventoryData.updatingInventory}
            >
              Cancel
            </Button> : null,
              this.state.editMode ?
              <Button
                key="2"
                type="primary"
                onClick={() => this.updateInventory()}
                loading={inventoryData.updatingInventory}
              >
                Update
              </Button> : null,
              !this.state.editMode ?
              <Button
                key="3"
              >
                <a 
                  href={shopifyProductUrl}
                  target='_blank'
                >
                View Shopify Listing
                </a>
              </Button> : null,
              !this.state.editMode ?
              <Button
                key="4"
                type="primary"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button> : null
          ]}
        />
          {
            inventoryItem.remoteStatus === 'Pending' && inventoryItem.remoteShippingLabelUrl ?
            <FormCard
              title='Pending Order'
              content={null}
            >
              <p>Item pending to be shipped in to complete sale</p>
              <a href={inventoryItem.remoteShippingLabelUrl}>View Shipping Label</a>
            </FormCard> : null

          }
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
          >
            <FormCard >
              <InventoryName
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                query={this.state.productQuery}
                queryKey='productQuery'
                userId={this.props.auth.userData.id}
                onProductClick={() => history.push(`/products/${this.state.productId}`)}
              />
            </FormCard>
            <FormCard
              title="Description"
            >
              <InventoryDescription
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                disabled={!this.state.editMode}


              />
            </FormCard>
            <FormCard
              title="Pricing"
            >
              <InventoryPricing
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                defaultCurrency={this.props.auth.userData.defaultCurrency}
                disabled={!this.state.editMode}
                priceDisabled={auth.userData.employee.restrictedRoutes.includes('disableConsignerPriceControl')}
                feeDisabled={auth.userData.employee.restrictedRoutes.includes('disableConsignerFeeControl')}
              />
            </FormCard>

            <FormCard
              title="Timeline"
            >
              <Timeline
                updateState={(key, value) => this.updateState(key, value)}
                data={inventoryItem.timelineData}
              />
            </FormCard>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
          >
            <FormCard
              title="QR Code"
              extra={
                <ReactToPrint
                  trigger={() => <a>Print</a>}
                  content={() => this.componentRef}
                />
              }
            >
              <BarcodeContainer ref={el => (this.componentRef = el)}>
                <div>
                <p>{inventoryItem.title} {inventoryItem.option1} {inventoryItem.option2} {inventoryItem.option3}</p>
                <p> {defaultCurrency.symbol}{inventoryItem.price} </p>
                </div>
                <QRCodeImage
                  src={inventoryItem.code}
                  alt='qrcode'
                />
                <p> {inventoryItem.shortCode} </p>
                <p> {inventoryItem.consignerData ? inventoryItem.consignerData.shortCode : null} </p>

              </BarcodeContainer>
            </FormCard>
            {
            auth.userData.allowRemote
            && auth.userData.allowDropoff
            && inventoryItem.status !== 'Sold'
            && ((inventoryItem.isRemote || (!inventoryItem.isRemote && inventoryItem.status === 'Pending') ))
            ?
            <FormCard
              title="Sell Type"
            >
              <InventoryIntake
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                disabled={!this.state.editMode}
              />
            </FormCard>
            : null
          }

          <FormCard
            title="Consigner"
            extra={
              <Switch
                checked={!this.state.isStoreInventory}
                onChange={(checked) => this.handleStoreInventory(checked)}
                disabled={!this.state.editMode}

              />
            }

          >
            <InventoryConsigner
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              query={this.state.consignerQuery}
              queryKey='consignerQuery'
              userId={this.props.auth.userData.id}
              viewOnly={!this.state.editMode || this.state.isStoreInventory}
              onViewOnlyClick={() => history.push(`/consigners/${this.state.consignerId}`)}


            />
          </FormCard>



            <FormCard
              title="Status"
            >
              <InventoryStatus
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                disabled={!this.state.editMode}


              />
            </FormCard>
            <FormCard
              title="Location"
            >
              <InventoryLocation
                updateState={(key, value) => this.updateState(key, value)}
                values={this.state}
                query={this.state.locationQuery}
                queryKey='locationQuery'
                userId={this.props.auth.userData.id}
                query2={this.state.sourceConsignerQuery}
                queryKey2='sourceConsignerQuery'
                viewOnly
                onViewOnlyClick={() => history.push(`/consigners/${this.state.sourceConsignerId}`)}
                quantityDisabled
                disabled={!this.state.editMode}
                dataKey='locationData'

              />
            </FormCard>

          </Col>
          <MobileBar>
              <CopyToClipboard
              text={inventoryItem.shortCode}
              onCopy={() => message.success(`Copied Item Id ${inventoryItem.shortCode}`)}
            >
              <MobileContainer>
                <Icon type="qrcode" />
                <MobileBarText> Copy Item ID </MobileBarText>
              </MobileContainer>
            </CopyToClipboard>
            <CopyToClipboard
              text={inventoryItem.price}
              onCopy={() => message.success(`Copied Price ${inventoryItem.price}`)}
            >
              <MobileContainer>
                <Icon type="dollar" />
                <MobileBarText> Copy Price </MobileBarText>
              </MobileContainer>
            </CopyToClipboard>
          </MobileBar>
      </AddContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewInventory));
