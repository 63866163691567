import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from 'react-router-dom';
import history from '../../navigation/history';
import SearchBar from '../../components/ui/search/SearchBar';
import SearchTable from '../../components/ui/search/SearchTable';
import SearchSortTitle from '../../components/ui/search/SearchSortTitle';
import * as actions from '../../redux/actions';
import EmptyProductList from '../../components/ui/EmptyProductList';
import ProductSortBy from '../../constants/ProductSortBy';
import {
  PageHeader,
  Button,
} from 'antd'
import {
  DashboardContainer,
  PageTitle,
  ProductImage
} from '../styles';

class Products extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (!window._.isEqual(prevProps.localState, this.props.localState)) {
      this.setState(this.props.localState);
      this.props.searchStockXProducts(
        this.props.localState.query,
        this.props.localState.pagination.current,
        ProductSortBy[this.props.localState.selectedOrder]
      )
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      showCatalog: false,
      query: props.productsData.productsLastQuery,
      pagination: { current: 1 },
      selectedOrder: 0
    }
  }
  componentWillMount() {
    this.props.searchStockXProducts(this.state.query, 1, ProductSortBy[0])
  }

  componentWillUnmount() {
    this.props.saveProductsLastQuery(this.state.query);
  }

  selectTableColumns() {
    let columns = [
      {
        title: 'Image',
        dataIndex: '',
        key: '',
        render: (data) => <ProductImage src={data.images && data.images[0] ? data.images[0] : ''} />,
      },
      {
        title: () =>
        <SearchSortTitle
          title='Title'
          upIndex={0}
          downIndex={1}
          updateState={(key, value) => this.updateState(key, value)}
          selectedOrder={this.state.selectedOrder}
        />,
        dataIndex: '',
        key: '',
        render: (data) => {
          if(data.catalogId) return <Link
              to={{
                pathname: `/products/add`,
                state: { product: data }
              }}
            >
            {data.title}
            </Link>
          return <Link to={`/products/${data.id}`}>{data.title}</Link>
        }      },
    ];

    //Table columns
    if(window.outerWidth > 992) {
      columns = [
        {
          title: 'Image',
          dataIndex: '',
          key: '',
          render: (data) => <ProductImage src={data.images && data.images[0] ? data.images[0] : ''} />,
        },
        {
          title: () =>
          <SearchSortTitle
            title='Title'
            upIndex={0}
            downIndex={1}
            updateState={(key, value) => this.updateState(key, value)}
            selectedOrder={this.state.selectedOrder}
          />,
          dataIndex: '',
          key: '',
          render: (data) => {
            if(data.catalogId) return <Link
                to={{
                  pathname: `/products/add`,
                  state: { product: data }
                }}
              >
              {data.title}
              </Link>
            return <Link to={`/products/${data.id}`}>{data.title}</Link>
          }
        },
        {
          title:
          <SearchSortTitle
            title='Brand'
            updateState={(key, value) => this.updateState(key, value)}
            selectedOrder={this.state.selectedOrder}
            upIndex={2}
            downIndex={3}
          />,
          dataIndex: 'brand',
          key: 'brand',
        },
        {
          title: 'SKU',
          dataIndex: 'sku',
          key: 'sku',
        },
        {
          title: 'Type',
          dataIndex: '',
          key: '',
          render: (data) => {
            return data.catalogId ? "Catalog" : "Product";
          }
        },
      ];
    }
    return columns
  }

  updateState(key, value) {

    if(value === '') {
      this.props.searchStockXProducts(value, 1, ProductSortBy[this.state.selectedOrder])
    } else if(key === 'selectedOrder') {
      this.props.searchStockXProducts(this.state.query, this.state.pagination.current, ProductSortBy[value])
    } else if (key === 'query' && value !== this.state.query) {
      this.props.searchStockXProducts(value, 1, ProductSortBy[this.state.selectedOrder]);
    }
    this.setState({
      [key]: value,
      pagination: { current: 1 }
    })
  }

  onTableChange(pagination) {
    const { query } = this.state;
    this.setState({
      pagination
    })
    this.props.searchStockXProducts(query, pagination.current, ProductSortBy[this.state.selectedOrder])

  }
  render() {
    const columns =  this.selectTableColumns();
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Products</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
            extra={[
              <Button
                key="0"
                onClick={() => history.push('/catalogs')}
              >
                Catalogs
              </Button>,
              <Button
                key="1"
                type="primary"
                onClick={() => history.push('products/add')}
                id='addBtn'
              >
                Add
              </Button>,
            ]}
          />
          <SearchBar
            updateState={(key, value) => this.updateState(key, value)}
            query={this.state.query}
            searchPlaceholder='Search Products'
            onSearch={() => this.props.searchStockXProducts(this.state.query, this.state.pagination.current, ProductSortBy[this.state.selectedOrder])}
            loading={this.props.productsData.loadingSearchProducts}
          />
          <SearchTable
            data={this.props.productsData.searchProducts}
            columns={columns}
            loading={this.props.productsData.loadingSearchProducts}
            emptyText={() => <EmptyProductList />}
            pagination={{ total: this.props.productsData.searchProductsCount, defaultCurrent: 1, current: this.state.pagination.current }}
            onChange={(pagination) => this.onTableChange(pagination)}
          />
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
