import axios from 'axios';
import { message } from 'antd';
import {
  CATALOG_API
} from '../../../constants/API';
import {
  SUBSCRIBE_CATALOG_LOADING,
} from '../types';

function subscribeCatalog(catalogId) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBE_CATALOG_LOADING,
      subscribingCatalog: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const postData = {
        catalogId,
        userId: userData.id
      };
      await axios.post(
        `${CATALOG_API}/subscribe`,
        postData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: SUBSCRIBE_CATALOG_LOADING,
        subscribingCatalog: false
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: SUBSCRIBE_CATALOG_LOADING,
        subscribingCatalog: false
      });
    }
  };
}
export default subscribeCatalog;
