import axios from 'axios';
import { message } from 'antd';
import {
  ORDER_API,
} from '../../../constants/API';
import {
  CREATE_ORDER_LOADING
} from '../types';

function createOrder(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_ORDER_LOADING,
        creatingOrder: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        const orderResponse = await axios.post(ORDER_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        const { id } = orderResponse.data;

        dispatch({
          type: CREATE_ORDER_LOADING,
          creatingOrder: false
        });
        return id;
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_ORDER_LOADING,
          creatingOrder: false
        });
        console.log(e);
      }
  }
}
export default createOrder;
