import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`
const TextContainer = styled.div`
  margin-left: 20px;
`

const SubTitle = styled.p`

`
export {
  Container,
  Image,
  TextContainer,
  SubTitle
}
