import React from 'react';
import {
  Modal,
  Input,
  Form,
  Select,
  DatePicker
} from 'antd';
const { Option } = Select;

function AddAppointments(props) {
  const {
    creatingAppointmentRule,
    handleOk,
    handleCancel,
    visible,
    updateState,
    rule,
    ruleFormat
  } = props;
  return (
    <Modal
      title='Add Appointment Restriction'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingAppointmentRule}
    >
    <Form.Item label='Date Type'>
      <Select
        value={ruleFormat}
        onChange={(value) => updateState('ruleFormat', value)}
        style={{ width: '100%' }}
      >
        <Option value="dddd, hA">Hour of Day</Option>
        <Option value="YYYY/MM/DD">Date</Option>
      </Select>
    </Form.Item>
    <Form.Item label='Date'>
      <DatePicker
        defaultValue={rule}
        format={ruleFormat}
        onChange={(value) => updateState('rule', value)}
        showTime

      />
    </Form.Item>
    </Modal>
  );
}

export default AddAppointments;
