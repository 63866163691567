//Primary Color
export const PRIMARY_COLOR = 'blue';
export const LIGHT_GREY = '#778899'
export const SECONDARY_COLOR = '#33334f'
export const PRIMARY_COLOR_GREEN = '#00D9C0'
export const GRAPH_AREA_COLOR = 'rgba(0, 210, 211, 0.2)';
export const GRAPH_LINE_COLOR = 'rgba(0, 210, 211, 0.6)';
export const NEGATIVE_BACKGROUND = 'rgba(255, 107, 107, 0.2)';
export const NEGATIVE = '#ff6b6b';
export const POSITIVE = '#1dd1a1';
