import React from 'react';
import {
  Input,
  DatePicker
} from 'antd';
import DropdownSearch from '../../algolia/DropdownSearch';
import SelectDropdown from '../SelectDropdown';
import SortBy from '../SortBy';
import OrderStatus from '../../../constants/OrderStatus';
import ProductSearchInput from '../../ui/ProductSearchInput';

import {
  Container,
  SearchFilterContainer
} from './styles';
const { RangePicker } = DatePicker;

export default function OrderSearchFilter(props) {
  const {
    values,
    updateState,
    loadOrder,
    userId,
    query,
    queryKey,
    query2,
    queryKey2,
    query3,
    queryKey3,
    query4,
    queryKey4,
    clearProduct,
    products,
    loadingProducts,
    onSearchProducts
  } = props;
  const {
    title,
    id,
    option1,
    option2,
    option3,
    option1Switch,
    option2Switch,
    option3Switch,
    option1FixedValues,
    option2FixedValues,
    option3FixedValues,
    filterLocationName,
    filterConsignerName,
    filterStatus,
    sortByOptions,
    selectedSortBy,
    filterTags,
    filterDateRange,
    filterCustomerName
  } = values;
  const options = [option1, option2, option3];
  const optionSwitchs = [option1Switch, option2Switch, option3Switch];
  const optionFixedValues = {option1FixedValues, option2FixedValues, option3FixedValues};
  return (
    <SearchFilterContainer>
      <Container>
        <ProductSearchInput
          data={products}
          loading={loadingProducts}
          onSearch={onSearchProducts}
          onChange={async (obj) => {
            if(obj) {
              const { label, value } = obj;
              await updateState('id', value)
              await updateState('title', label)
              loadOrder()
            } else {
              await clearProduct()
              loadOrder()
            }

          }}
        />
          {
            options.map((option, index) => {
              if(option && option !== '') {
                const optionSwitch = optionSwitchs[index];
                const optionFixedValue = Object.values(optionFixedValues)[index];
                if(optionSwitch || optionSwitch === 'true' ) {
                  return (
                      <SelectDropdown
                        data={optionFixedValue}
                        placeholder={option}
                        onChange={async(value) => {
                          await updateState(`filterOption${index + 1}`, value)
                          loadOrder()
                        }}
                        value={values[`filterOption${index + 1}`]}
                      />
                  )
                } else {
                  return (
                      <Input
                        placeholder={option}
                        onChange={(e) => updateState(`filterOption${index + 1}`, e.target.value)}
                        value={values[`filterOption${index + 1}`]}
                        onPressEnter={() => loadOrder()}
                      />
                  )
                }

              } else return null
            })
          }
      </Container>
      <Container>
        <Input
          placeholder='Tags (Comma Seperated)'
          onChange={(e) => updateState('filterTags', e.target.value)}
          onPressEnter={() => loadOrder()}
          value={filterTags}
        />
        <SelectDropdown
          data={OrderStatus}
          onChange={async(value) => {
            await updateState('filterStatus', value)
            loadOrder()
          }}
          value={filterStatus}
        />
        <DropdownSearch
          searchPlaceholder='Search Locations'
          index='locations'
          keyName='name'
          updateState={async (queryKey, value) => {
            await updateState(queryKey, value)
            loadOrder()
          }}
          stateKey='filterLocationId'
          stateValue='objectID'
          stateKey2='filterLocationName'
          stateValue2='name'
          value={filterLocationName}
          query={query}
          queryKey={queryKey}
          userId={userId}
        />
        <DropdownSearch
          searchPlaceholder='Search Customers'
          index='customers'
          keyName='name'
          updateState={async (queryKey, value) => {
            await updateState(queryKey, value)
            loadOrder()
          }}
          stateKey='filterCustomerId'
          stateValue='objectID'
          stateKey2='filterCustomerName'
          stateValue2='name'
          value={filterCustomerName}
          query={query4}
          queryKey={queryKey4}
          userId={userId}
        />
        <DropdownSearch
          searchPlaceholder='Search Consigners'
          index='consigners'
          keyName='name'
          updateState={async (queryKey, value) => {
            await updateState(queryKey, value)
            loadOrder()
          }}
          stateKey='filterConsignerId'
          stateValue='objectID'
          stateKey2='filterConsignerName'
          stateValue2='name'
          value={filterConsignerName}
          query={query2}
          queryKey={queryKey2}
          userId={userId}
        />
      </Container>
      <Container>
        <RangePicker
          value={filterDateRange}
          onChange={async(value) => {
            if(value.length === 0) {
              await updateState('filterDateRange', null)
              loadOrder()
            } else {
              await updateState('filterDateRange', value)
              loadOrder()
            }
          }}

        />
        <SortBy
          data={sortByOptions}
          selectedSortBy={selectedSortBy}
          updateState={async(value) => {
            await updateState('selectedSortBy', value)
            loadOrder()
          }}
        />
      </Container>

    </SearchFilterContainer>
  );
}
