import React, {Component} from 'react';
import {
  Radio,
  Button,
  PageHeader,
  Card
} from 'antd';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  withRouter
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import history from '../../navigation/history';
import {
  DashboardContainer,
  PageTitle,
  SortableItemContainer,
  Label
} from '../styles';

const DragHandle = sortableHandle(() => <span>::</span>);

const SortableItem = SortableElement((props) => {
  const { item, itemIndex, setEditable } = props;
  return (
    <SortableItemContainer>
      <DragHandle />
      <Label> {item.label} </Label>
      {
        item.isEditable ?
        <Radio.Group
          value={item.value.join(',')}
          buttonStyle="solid"
          onChange={(e) => setEditable(itemIndex, e.target.value)}
        >
          <Radio value="isStoreInventory,ASC">Consigner</Radio>
          <Radio value="isStoreInventory,DESC">Store</Radio>
        </Radio.Group>
        : null
      }

    </SortableItemContainer>
  );
});

const SortableList = SortableContainer(({items, setEditable}) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.label}`}
          item={item}
          itemIndex={index}
          index={index}
          value={item.value}
          setEditable={setEditable}
        />
      ))}
    </div>
  );
});

class SettingsInventoryPriority extends Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.auth;
    this.state = {
      items: userData && userData.inventoryPriority ? userData.inventoryPriority : [
        {
          label: 'Lowest Price First',
          value: ['price', 'ASC'],
          isEditable: false
        },
        {
          label: 'Owner Priority:',
          value: ['isStoreInventory', 'ASC'],
          isEditable: true
        },
        {
          label: 'Accepted Date',
          value: ['receivedAt', 'DESC'],
          isEditable: false
        },
      ]
    }
  }

  setEditable(index, value) {
    const items = this.state.items;
    items[index].value = value.split(',');
    console.log(value)
    this.setState({
      items
    })
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  save() {
    const { items, } = this.state;
    let { userData } = this.props.auth;
    userData.inventoryPriority = items;
    userData.inventoryPriorityQuery = items.map(item => item.value);
    this.props.updateUser(userData);
  }

  render() {
    const { updatingUser } = this.props.auth;
    return (
      <DashboardContainer>
      <PageHeader
        onBack={() => history.goBack()}
        title={<PageTitle>Inventory Priority</PageTitle>}
        subTitle='Set rules on what order inventory sells'
        style={{ background: 'transparent' }}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => this.save()}
            loading={updatingUser}
          >
            Save
          </Button>,
        ]}
      />
        <Card>
          <SortableList
            items={this.state.items}
            onSortEnd={this.onSortEnd}
            setEditable={(index, value) => this.setEditable(index, value)}
          />
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsInventoryPriority));
