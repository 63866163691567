async function saveUserCredentials(credentials, user) {
  const {
    accessToken,
    expiresIn,
    idToken
  } = credentials
  await localStorage.setItem('userId', user.sub);
  await localStorage.setItem('accessToken', accessToken);
  await localStorage.setItem('accessTokenExpiresIn', String(expiresIn));
  await localStorage.setItem('idToken', idToken);
}

export default saveUserCredentials;
