import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  message,
  Form
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import arrayMove from 'array-move';
import history from '../../navigation/history';
import ProductName from '../../components/ui/forms/product/ProductName';
import ProductOption from '../../components/ui/forms/product/ProductOption';
import ProductTemplate from '../../components/ui/forms/product/ProductTemplate';
import PhotoUploader from '../../components/ui/PhotoUploader';

import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  FormCard,
  AddContainer
} from '../styles';

class AddProduct extends React.Component {
  componentWillMount() {
    this.props.getProductAttributes('brand');
  }
  constructor(props) {
    super(props);

    const { location } = this.props
    const product = location.state && location.state.product ? location.state.product  : {};
    const {
      title,
      sku,
      images,
      brand,
      category,
      tags,
      description,
      option1,
      option2,
      option3,
      option1FixedValues,
      option1Switch,
      option2FixedValues,
      option2Switch,
      option3FixedValues,
      option3Switch,
    } = product;
    this.state = {
      title: title || '',
      sku: sku || '',
      images: images ||  [],
      brand: brand || '',
      category: category || '',
      tags: tags || [],
      description: description || '',
      catalogId: null,
      option1: option1 || '',
      option2: option2 || '',
      option3: option3 || '',
      option1FixedValues: option1FixedValues || [],
      option1Switch: option1Switch ||false,
      option2FixedValues: option2FixedValues || [],
      option2Switch: option2Switch || false,
      option3FixedValues: option3FixedValues || [],
      option3Switch: option3Switch || false
    }
  }
  uploadImage() {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            console.log(result)
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add images to state
            this.setState({
              images: [...this.state.images, secure_url]
            })
        }
    });
  }
  deleteImage(index) {
    const images = this.state.images;
    images.splice(index, 1);
    this.setState({ images });
  }

  onImageSort = (oldIndex, newIndex) => {
    this.setState(({images}) => ({
      images: arrayMove(images, oldIndex, newIndex),
    }));
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }

  async createProduct(values) {
    const {
      productTemplateId
    } = this.state;
    if(!productTemplateId) {
      message.error('Please select a product template')
    } else {
      const { createProduct } = this.props;
      const id = await createProduct({...this.state, ...values});
      history.push(`/products/${id}`)
    }
  }

  onProductTemplateSelected(data) {
    const newData = { ...data };
    delete newData.title;
    delete newData.id;
    delete newData.createdAt;
    delete newData.updatedAt;
    this.setState({
      ...newData,
      productTemplateId: data.id
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.createProduct(values);
      }
    });
  };

  render() {
    const { images } = this.state;
    const { productData } = this.props;
    const { productAttributes } = productData;
    console.log(productAttributes)
      return (
        <Form onSubmit={this.handleSubmit}>
          <AddContainer>
            <PageHeader
              onBack={() => history.goBack()}
              title={<PageTitle>Add Product</PageTitle>}
              style={{ background: 'transparent' }}
              extra={[
                <Button
                  key="1"
                  type="primary"
                  htmlType="submit"
                  loading={productData.creatingProduct}
                >
                  Create
                </Button>,
              ]}
            />
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
              >
                <FormCard id='productName'>
                  <ProductName
                    form={this.props.form}
                    updateState={(key, value) => this.updateState(key, value)}
                    values={this.state}
                    query={this.state.productQuery}
                    queryKey='productQuery'
                    userId={this.props.auth.userData.id}

                  />
                </FormCard>
                <FormCard
                  title='Images'
                  extra={<a onClick={() => this.uploadImage()}>Add New</a>}
                  style={{ height: '100%' }}
                  id='productImages'
                >
                  <PhotoUploader
                    images={images}
                    onDelete={(index) => this.deleteImage(index)}
                    onImageSort={this.onImageSort}
                    title='Click add new to upload images'

                  />
                </FormCard>

              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
              >
                {
                  this.props.auth.userData.productTemplates ?
                  <FormCard
                    title='Templates'
                    style={{ height: '100%' }}
                  >
                    <ProductTemplate
                      selectedTemplate={this.state.productTemplateId}
                      productTemplates={this.props.auth.userData.productTemplates}
                      onProductTemplateSelected={(data) => this.onProductTemplateSelected(data)}
                    />
                  </FormCard> : null
                }


                <FormCard
                  title='Options'
                  style={{ height: '100%' }}
                  id='productImages'
                >
                  <ProductOption
                    updateState={(key, value) => this.updateState(key, value)}
                    values={this.state}
                    query={this.state.productQuery}
                    queryKey='productQuery'
                    userId={this.props.auth.userData.id}
                    disabled={true}
                  />
                </FormCard>

              </Col>
          </AddContainer>
        </Form>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        productData: state.ProductReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default Form.create({ name: 'AddProduct' })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProduct)));
