import {
  GET_TAXES_LOADING,
  GET_TAXES,
  CREATE_TAX_LOADING
} from '../actions/types';

const defaultState = {
  loadingTaxRates: false,
  taxRates: [],
  creatingTaxRate: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_TAXES_LOADING:
          return {
            ...state,
            loadingTaxRates: action.loadingTaxRates
          };
        case GET_TAXES:
          return {
            ...state,
            loadingTaxRates: action.loadingTaxRates,
            taxRates: action.taxRates
          };
        case CREATE_TAX_LOADING:
          return {
            ...state,
            creatingTaxRate: action.creatingTaxRate
          }
        default:
            return state;
    }
};
