import styled from 'styled-components';
import { Menu, Button, Layout } from 'antd';

const {
  Sider,
} = Layout;
const Container = styled.div`
  height: 100vh;
  width: 256px;
  display: flex;
  justify-content: center;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
`
const OrderButton = styled.h4`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100% !important;
  background: rgba(255,255,255,0.2);
  color: white;
`
const SideMenu = styled(Menu)`
  padding-top: 20px;
`
const MenuButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: 'center';
`
const SideBar = styled(Sider)`
  box-shadow:2px 0 6px rgba(0,21,41,.35)
  height: 100vh;
  margin-top: 70px;
`
const MenuItem = styled(Menu.Item)`
`
export {
  Container,
  SideMenu,
  MenuButton,
  SideBar,
  MenuItem,
  OrderButton
};
