export default [
  {
    title: '1) Products are merged',
    desc: 'Shopify Products get pulled into Frontflip. You will need to add product level skus (which you will do in the next steps)'
  },
  {
    title: '2) Variants are merged',
    desc: 'Shopify Variants and Inventory get turned into Frontflip Inventory (only variants with quantity over 0). Please clean up variants before connecting'
  },
  {
    title: '3) Ongoing Sync',
    desc: 'Shopify Products, Orders, and Customers will be updated into Frontflip. It is important to note variant level data is not sycned.'
  },
  {
    title: '4) Manage variants through Frontflip',
    desc: 'Frontflip automatically manages your Shopify Variant/Inventory level data, and all variant level updates should be made on Frontflip'
  },
]
