import React from 'react';
import {
  InstantSearch,
  Configure
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import IntegrationCard from './IntegrationCard';
import {
  Container,
  SearchInput
} from './styles';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ID, process.env.REACT_APP_ALGOLIA_KEY);

export default function AlgoliaSearch(props) {
  const {
    connections,
    onPress,
    filters
  } = props;
  return (
    <Container>
      <InstantSearch
        searchClient={searchClient}
        indexName='integrations'
      >
        <Configure filters={filters} />
        <SearchInput />
        <IntegrationCard
          connections={connections}
          onPress={onPress}
        />
      </InstantSearch>
    </Container>
  );
}
