import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { json2csv } from 'json-2-csv';
import fileDownload from 'js-file-download';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button,
  message,
  Modal
} from 'antd'
import OrderFilterList from '../../components/ui/OrderFilterList';
import OrderSearchFilter from '../../components/ui/OrderSearchFilter';
import SaveFilter from '../../components/ui/modals/SaveFilter';
import SavedFilterTabs from '../../components/ui/SavedFilterTabs';
import ProductSortBy from '../../constants/ProductSortBy';

import {
  DashboardContainer,
  PageTitle,
  FormCard,
} from '../styles';

class Order extends React.Component {
  getDefaultState() {
    return {
      id: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: '',
      filterConsignerId: null,
      filterConsignerQuery: '',
      filterCustomerName: '',
      filterCustomerId: null,
      filterCustomerQuery: '',
      productQuery: '',
      title: '',
      filterStatus: '',
      selectedOrder: [],
      filterReceivedAt: null,
      filterOrderDate: null,
      selectedSortBy: 0,
      filterTags: '',
      sortByOptions: [
        {
          label: 'Order Date (New to Old)',
          value: [['createdAt', 'DESC']]
        },
        {
          label: 'Order Date (Old to New)',
          value: [['createdAt', 'ASC']]
        }
      ],
      saveFilterModalVisible: false,
      saveFilterTitle: ''
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getDefaultState()
  }
  componentDidMount() {
    this.loadOrder();
  }
  componentDidUpdate(oldProps) {
    //New Product Item
    const { productData } = this.props;
    const newProductItem = productData.productItem;
    //Old Product Item
    const oldProductItem = oldProps.productData.productItem;
    if(newProductItem) {
      if(oldProductItem !== newProductItem) {
        this.setState({
          ...newProductItem,
          locationQuery: newProductItem.locationName,
          consignerQuery: newProductItem.consignerName,
          productQuery: newProductItem.title
        })
      }
    }
  }

  updateState(key, value) {
    if(key === 'id') {
      this.props.getProduct(value);
    }
    this.setState({
      [key]: value
    })
  }
  loadOrder() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      selectedSortBy,
      sortByOptions,
      filterTags,
      filterDateRange,
      filterCustomerQuery,
      filterCustomerId
    } = this.state;
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      customerId: filterCustomerQuery !== '' ? filterCustomerId : null,
      status: filterStatus !== '' ? filterStatus : null,
      sortBy: selectedSortBy !== '' &&  sortByOptions[selectedSortBy] ? sortByOptions[selectedSortBy].value : null,
      tags: filterTags !== '' ? filterTags.split(',') : null,
      dateRange: filterDateRange !== '' ? filterDateRange : null,
    }
    this.props.getOrderForFilter(data);
  }
  clearProduct() {
    this.setState({
      id: null,
      productQuery: '',
      title: '',
      option1: null,
      option2: null,
      option3: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
    });
  }



  exportCSV(data) {
    json2csv(data, (err, csv) => {
      if(!err) {
        fileDownload(csv, 'export.csv');
      } else {
        message.error('Error converting to CSV')
      }
    })
  }

  handleSaveFilterModal() {
    this.setState({
      saveFilterModalVisible: !this.state.saveFilterModalVisible
    })
  }
  async saveFilter() {

    if(this.state.saveFilterTitle === '') {
      message.error('Please enter a title')
    } else {
      const data = {
        data: this.state,
        filterKey: 'savedOrdersFilter'
      }
      delete data.data.saveFilterModalVisible
      await this.props.saveFilter(data);
      window.location.reload();
    }

  }
  async handleSavedFilter(index, action) {
    if(action === 'remove') {
      const { removeFilter } = this.props;
      const { savedOrdersFilter } = this.props.auth.userData;
      const selectedFilter = JSON.parse(savedOrdersFilter[index]);
      console.log(selectedFilter);
      const data = {
        data: selectedFilter,
        filterKey: 'savedOrdersFilter'
      }

      await Modal.confirm({
        content: `Are you sure you delete this saved filter?`,
        okText: `Delete ${selectedFilter.saveFilterTitle}`,
        onOk: async() => {
          await removeFilter(data);
          window.location.reload();
        }
      })
    } else if(action === 'add') {
      this.handleSaveFilterModal()
    }
  }

applySavedFilter(key) {
  if(key === 'Find') {
    const defaultState = this.getDefaultState();
    this.setState(defaultState, () => this.loadOrder())

  } else {
    const { savedOrdersFilter } = this.props.auth.userData;
    const selectedFilter = JSON.parse(savedOrdersFilter[key]);
    this.setState(selectedFilter, () => this.loadOrder())
  }

}
async searchProducts(value, callback) {
  await this.props.searchProducts(value, 1, ProductSortBy[0], false)
  const { searchProducts } = this.props.productData;
  return searchProducts.map(obj => {return {
    value: obj.id,
    label: obj.title
  }})
}

  render() {
    const {
      filteredOrder,
      filteringOrder,
    } = this.props.orderData;
    console.log(this.props)
      return (
        <DashboardContainer id='container'>
          <PageHeader
            title={<PageTitle>Orders</PageTitle>}
            breadcrumb={null}
            style={{ background: 'transparent' }}
            extra={[
              <Button
                key='1'
                onClick={() => this.exportCSV(filteredOrder)}
              >
                Export CSV
              </Button>,
            ]}
          />
          <FormCard
          >
            <SavedFilterTabs
              savedFilters={this.props.auth.userData.savedOrdersFilter}
              onTabSelect={(index) => this.applySavedFilter(index)}
              handleSavedFilter={(index, action) => this.handleSavedFilter(index, action)}
            />

            <OrderSearchFilter
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              loadOrder={() => this.loadOrder()}
              query={this.state.filterLocationQuery}
              queryKey='filterLocationQuery'
              query2={this.state.filterConsignerQuery}
              queryKey2='filterConsignerQuery'
              query3={this.state.productQuery}
              queryKey3='productQuery'
              query4={this.state.filterCustomerQuery}
              queryKey4='filterCustomerQuery'
              userId={this.props.auth.userData.id}
              canSelectProduct
              clearProduct={() => this.clearProduct()}
              userData={this.props.auth.userData}
              disableWithdrawalLocation
              products={this.props.productData.searchProducts}
              loadingProducts={this.props.productData.loadingSearchProducts}
              onSearchProducts={(value) => this.searchProducts(value)}

            />
            <OrderFilterList
              data={filteredOrder}
              loading={filteringOrder}
              updateState={(key, value) => this.updateState(key, value)}
              selectedOrder={this.state.selectedOrder}
              defaultCurrency={this.props.auth.userData.defaultCurrency}

            />
          </FormCard>
          <SaveFilter
            handleOk={() => this.saveFilter()}
            visible={this.state.saveFilterModalVisible}
            handleCancel={() => this.handleSaveFilterModal()}
            loading={this.props.auth.savingFilter}
            updateState={(key, value) => this.updateState(key, value)}
            saveFilterTitle={this.state.saveFilterTitle}
          />
        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        orderData: state.OrderReducer,
        productData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
