export const API = process.env.REACT_APP_API;
//export const API = 'https://frontflip-server.herokuapp.com/api';

export const USERS_API = `${API}/users`;
export const VALIDATE_API = `${API}/validate`;
export const CARDS_API = `${API}/cards`;
export const SUBSCRIPTION_API = `${API}/subscriptions`;
export const IMAGE_API = `${API}/users/image`;
export const INVENTORY_API = `${API}/inventory`;
export const CONSIGNER_API = `${API}/consigners`;
export const CUSTOMER_API = `${API}/customers`;
export const EMPLOYEE_API = `${API}/employees`;
export const ORDER_API = `${API}/orders`;
export const TAXES_API = `${API}/taxes`;
export const REFUND_API = `${API}/refunds`;
export const LOCATIONS_API = `${API}/locations`;
export const ANALYTICS_API = `${API}/analytics`;
export const CONNECTIONS_API = `${API}/connections`;
export const INTEGRATION_API = `${API}/integrations`;
export const PAYOUT_API = `${API}/payouts`;
export const PRODUCTS_API = `${API}/products`;
export const APPOINTMENTS_API = `${API}/appointments`;
export const FEES_API = `${API}/fees`
export const APPOINTMENT_RULES_API = `${API}/appointmentRules`
export const APPOINTMENT_TIMES_API = `${API}/appointmentTimes`
export const CATALOG_API = `${API}/catalogs`
export const PRODUCT_RESTRICTIONS_API = `${API}/productRestrictions`
export const PRODUCT_TEMPLATES_API = `${API}/productTemplates`
export const LEDGER_API = `${API}/ledgers`;