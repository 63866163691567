import axios from 'axios';
import {
  PRODUCT_TEMPLATES_API
} from '../../../constants/API';
import {
  GET_PRODUCT_TEMPLATES_LOADING,
  GET_PRODUCT_TEMPLATES
} from '../types';

function getProductTemplates() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT_TEMPLATES_LOADING,
      loadingProductTemplates: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const productTemplatesResponse = await axios.get(
        `${PRODUCT_TEMPLATES_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_PRODUCT_TEMPLATES,
        loadingProductTemplates: false,
        productTemplates: !productTemplatesResponse.data.isError ? productTemplatesResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_PRODUCT_TEMPLATES,
        loadingProductTemplates: false,
        productTemplates: []
      });
    }
  };
}
export default getProductTemplates;
