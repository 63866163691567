import axios from 'axios';
import setAuthState from '../auth/setAuthState';
import {
  SUBSCRIPTION_API
} from '../../../constants/API';
import {
  CANCEL_MEMBERSHIP_LOADING
} from '../types';

function cancelMembership(id, subscription_id) {
  return async (dispatch) => {
    dispatch({
      type: CANCEL_MEMBERSHIP_LOADING,
      cancellingMembership: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const data = { subscription_id };
      await axios.delete(
        `${SUBSCRIPTION_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` }, data }
      );
      await dispatch(setAuthState());
      dispatch({
        type: CANCEL_MEMBERSHIP_LOADING,
        cancellingMembership: false
      });
    } catch (e) {
      console.log(e);
      alert(String(e));
      dispatch({
        type: CANCEL_MEMBERSHIP_LOADING,
        cancellingMembership: false
      });
    }
  };
}
export default cancelMembership;
