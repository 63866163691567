import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { json2csv } from 'json-2-csv';
import fileDownload from 'js-file-download';
import ReactToPrint from 'react-to-print';

import {
  withRouter,
} from 'react-router-dom';
import _ from 'lodash';
import history from '../../navigation/history';
import BarcodeReader from 'react-barcode-reader';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button,
  message,
  Modal
} from 'antd'
import BulkEdit from '../../components/ui/modals/BulkEdit';
import ConsignerInventory from '../../components/ui/forms/consigners/ConsignerInventory';
import InventorySearchFilter from '../../components/ui/InventorySearchFilter';
import SavedFilterTabs from '../../components/ui/SavedFilterTabs';
import SaveFilter from '../../components/ui/modals/SaveFilter';
import ProductSortBy from '../../constants/ProductSortBy';

import {
  DashboardContainer,
  PageTitle,
  TitleContainer,
  FormCard,
  QRCodeImage,
  BarcodeContainer,
  ScanButton
} from '../styles';
import './styles.css'

class Inventory extends React.Component {
  getDefaultState() {
    const parsed = queryString.parse(this.props.location.search);
    const {
      consignerName,
      consignerId,
      productId
    } = parsed;
    if(productId) {
      this.props.getProduct(productId);
    }
    return {
      showBack: productId || consignerId ? true : null,
      id: null || productId,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      inventoryFilterVisible: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
      filterLocationName: '',
      filterLocationId: null,
      filterLocationQuery: '',
      filterConsignerName: consignerName || '',
      filterConsignerId: consignerId || null,
      filterConsignerQuery: consignerName || '',
      productQuery: '',
      title: '',
      filterStatus: 'Active',
      filterShortCode: '',
      selectedInventory: [],
      bulkEditVisible: false,
      bulkEditKey: '',
      bulkEditKeyValue: '',
      filterReceivedAt: null,
      filterOrderDate: null,
      selectedSortBy: 0,
      filterTags: '',
      sortByOptions: [
        {
          label: 'Default Inventory Priority',
          value: null
        },
        {
          label: 'Price (Low to High) and Consigner Priority',
          value: [['price', 'ASC'], ['isStoreInventory', 'ASC']]
        },
        {
          label: 'Price (Low to High)',
          value: [['price', 'ASC']]
        },
        {
          label: 'Consigner',
          value: [['consignerName', 'ASC']]
        },
        {
          label: 'Received At (Old to New)',
          value: [['receivedAt', 'ASC']]
        },
        {
          label: 'Received At (New to Old)',
          value: [['receivedAt', 'DESC']]
        },
        {
          label: 'Order Date (Old to New)',
          value: [['orderDate', 'ASC']]
        },
        {
          label: 'Order Date (New to Old)',
          value: [['orderDate', 'DESC']]
        },

      ],
      bulkEditData: []
    }
  }
  constructor(props) {
    super(props);

    this.state = {
      ...this.getDefaultState(),
      ...props.inventoryData.lastInvetoryFilter
    }

  }
  componentDidMount() {
    this.loadInventory();
    this.props.getConsigners()
    this.props.getProducts()
  }
  componentDidUpdate(oldProps) {
    //New Product Item
    const { inventoryData,  } = this.props;
    const newFilteredInventory = inventoryData.filteredInventory;
    //Old Product Item
    const oldFilteredInventory = oldProps.inventoryData.filteredInventory;
    if(newFilteredInventory) {
      if(oldFilteredInventory !== newFilteredInventory) {
        this.setState({
          bulkEditData: newFilteredInventory,
        })
      }
    }
    //New Product Item
   const { productData } = this.props;
   const newProductItem = productData.productItem;
   //Old Product Item
   const oldProductItem = oldProps.productData.productItem;
   if(newProductItem) {
     if(oldProductItem !== newProductItem) {
       this.setState({
         ...newProductItem,
         locationQuery: newProductItem.locationName,
         consignerQuery: newProductItem.consignerName,
         productQuery: newProductItem.title
       })
     }
   }
  }
  componentWillUnmount() {
    this.props.saveLastInvetoryFilter('lastInvetoryFilter', this.state);
  }
  handleInventoryFilter() {
    this.setState({
      inventoryFilterVisible: !this.state.inventoryFilterVisible
    })
  }
  handleBulkEdit() {
    this.setState({
      bulkEditVisible: !this.state.bulkEditVisible
    })
  }
  updateState(key, value) {
    if(key === 'id') {
      this.props.getProduct(value);
    }
    this.setState({
      [key]: value
    })
  }
  loadInventory() {
    const {
      filterOption1,
      filterOption2,
      filterOption3,
      filterLocationId,
      filterConsignerId,
      id,
      filterConsignerQuery,
      filterLocationQuery,
      filterStatus,
      filterShortCode,
      filterReceivedAt,
      filterOrderDate,
      selectedSortBy,
      sortByOptions,
      filterTags,
      isRemote,
      filterDateRangeKey,
      filterDateRange
    } = this.state;
    console.log(filterLocationQuery)
    const data = {
      productId: id,
      option1: filterOption1 !== '' ? filterOption1 : null,
      option2: filterOption2 !== '' ? filterOption2 : null,
      option3: filterOption3 !== '' ? filterOption3 : null,
      locationId: filterLocationQuery !== '' ? filterLocationId : null,
      consignerId: filterConsignerQuery !== '' ? filterConsignerId : null,
      status: filterStatus !== '' ? filterStatus : null,
      shortCode: filterShortCode !== '' ? filterShortCode : null,
      receivedAt: filterReceivedAt !== '' ? filterReceivedAt : null,
      orderDate: filterOrderDate !== '' ? filterOrderDate : null,
      sortBy: selectedSortBy !== '' &&  sortByOptions[selectedSortBy] ? sortByOptions[selectedSortBy].value : null,
      tags: filterTags !== '' ? filterTags.split(',') : null,
      isRemote: isRemote !== '' ? isRemote : null,
      dateRangeKey: filterDateRangeKey !== '' ? filterDateRangeKey : null,
      dateRange: filterDateRange !== '' ? filterDateRange : null,
    }
    this.props.getInventoryForFilter(data);
    this.setState({ inventoryFilterVisible: false });
    console.log(data)
  }
  clearProduct() {
    this.setState({
      id: null,
      productQuery: '',
      title: '',
      option1: null,
      option2: null,
      option3: null,
      option1FixedValues: [],
      option1Switch: false,
      option2FixedValues: [],
      option2Switch: false,
      option3FixedValues: [],
      option3Switch: false,
      filterOption1: null,
      filterOption2: null,
      filterOption3: null,
    });
  }

  async bulkUpdateInventory() {
    const { updateInventory } = this.props;
    const { selectedInventory, bulkEditKey, bulkEditValue } = this.state;

    if(selectedInventory.length > 0 && bulkEditKey !== '' && bulkEditValue !== '') {
      for (let i = 0; i < selectedInventory.length; i++) {
        const data = {
          id: selectedInventory[i],
          [bulkEditKey]: bulkEditValue
        }
        console.log(data)
        await updateInventory(data);
      }
      window.location.reload();
    } else {
      message.error('No Inventory Selected')
    }

  }
  async exportCSV(data) {
    if(data.length >= 1000) {
      await this.props.getInventoryForFilter({ limit: 100000 })
      data = this.props.inventoryData.filteredInventory;
    }
    json2csv(data, (err, csv) => {
      if(!err) {
        fileDownload(csv, 'export.csv');
      } else {
        console.log(err)
        message.error('Error converting to CSV')
      }
    })
  }
  async beforeBulkPrint() {
    const data = this.props.inventoryData.filteredInventory;
    if(data.length >= 1000) {
      const hide = message.loading('Loading Printing Job', 0)
      await this.props.getInventoryForFilter({ limit: 100000 });
      hide()
    }
  }
  handleSaveFilterModal() {
    this.setState({
      saveFilterModalVisible: !this.state.saveFilterModalVisible
    })
  }
  async saveFilter() {

    if(this.state.saveFilterTitle === '') {
      message.error('Please enter a title')
    } else {
      const data = {
        data: this.state,
        filterKey: 'savedInventoryFilter'
      }
      delete data.data.saveFilterModalVisible
      await this.props.saveFilter(data);
      window.location.reload();
    }

  }
  async handleSavedFilter(index, action) {
    if(action === 'remove') {
      const { removeFilter } = this.props;
      const { savedInventoryFilter } = this.props.auth.userData;
      const selectedFilter = JSON.parse(savedInventoryFilter[index]);
      console.log(selectedFilter);
      const data = {
        data: selectedFilter,
        filterKey: 'savedInventoryFilter'
      }

      await Modal.confirm({
        content: `Are you sure you delete this saved filter?`,
        okText: `Delete ${selectedFilter.saveFilterTitle}`,
        onOk: async() => {
          await removeFilter(data);
          window.location.reload();
        }
      })
    } else if(action === 'add') {
      this.handleSaveFilterModal()
    }
  }

applySavedFilter(key) {
  if(key === 'Find') {
    const defaultState = this.getDefaultState();
    this.setState(defaultState, () => this.loadInventory())

  } else {
    const { savedInventoryFilter } = this.props.auth.userData;
    const selectedFilter = JSON.parse(savedInventoryFilter[key]);
    this.setState(selectedFilter, () => this.loadInventory())
  }

}
async updateStateArrayKey(key, arrayKey, id, value) {
  //Updates a key of an array in the state

  console.log({
    key, arrayKey, id, value
  })
  const array = [ ...this.state[key] ];
  const index = array.findIndex(obj => id === obj.id);
  array[index] = { ...array[index], [arrayKey]: value };
  await this.setState({ [key]: array });
}

  saveBulkEdit() {
    const { bulkEditData } = this.state;
    const { filteredInventory } = this.props.inventoryData;
    const dataToUpdate = _.difference(bulkEditData, filteredInventory);
    console.log(dataToUpdate);
    this.props.bulkUpsertInventory(dataToUpdate)
  }

async searchProducts(value, callback) {
  await this.props.searchProducts(value, 1, ProductSortBy[0], false)
  const { searchProducts } = this.props.productData;
  return searchProducts.map(obj => {return {
    value: obj.id,
    label: obj.title
  }})
}

  render() {
    const {
      filteredInventory,
      filteringInventory,
      updatingInventory,
      bulkUpsertingInventory
    } = this.props.inventoryData;
    const {
      defaultCurrency
    } = this.props.auth.userData
    console.log(this.props);
    const { showBack } = this.state;
      return (
        <DashboardContainer id='container'>
          <PageHeader
            onBack={showBack ? () => history.goBack() : null}
            title={
              <TitleContainer>
                <PageTitle>Inventory</PageTitle>
                <ScanButton type="primary" onClick={() => history.push(`/scan`)}>Scan</ScanButton>
              </TitleContainer>
            }
            breadcrumb={null}
            style={{ background: 'transparent' }}
            extra={[
              !this.state.bulkEditMode ?
              <Button
                onClick={() => this.setState({ bulkEditMode: true })}
              >
                Bulk Edit
              </Button> : null,
              !this.state.bulkEditMode ?
              <Button
                onClick={() => this.exportCSV(filteredInventory)}
              >
                Export CSV
              </Button> : null,
              !this.state.bulkEditMode ?
                <ReactToPrint
                  trigger={() => <Button>Bulk Print</Button>}
                  content={() => this.componentRef}
                  onBeforeGetContent={() => this.beforeBulkPrint()}
                /> : null,
              this.state.bulkEditMode ?
              <Button
                onClick={() => this.setState({ bulkEditMode: false })}
              >
                View Inventory
              </Button> : null,
              this.state.bulkEditMode ?
              <Button
                type='primary'
                onClick={() => this.saveBulkEdit()}
                loading={bulkUpsertingInventory}
              >
                Save Edit
              </Button> : null

            ]}
          />
          <FormCard
          >
            <SavedFilterTabs
              savedFilters={this.props.auth.userData.savedInventoryFilter}
              onTabSelect={(index) => this.applySavedFilter(index)}
              handleSavedFilter={(index, action) => this.handleSavedFilter(index, action)}
            />
            <InventorySearchFilter
              updateState={(key, value) => this.updateState(key, value)}
              values={this.state}
              loadInventory={() => this.loadInventory()}
              query={this.state.filterLocationQuery}
              queryKey='filterLocationQuery'
              query2={this.state.filterConsignerQuery}
              queryKey2='filterConsignerQuery'
              query3={this.state.productQuery}
              queryKey3='productQuery'
              userId={this.props.auth.userData.id}
              canSelectProduct
              clearProduct={() => this.clearProduct()}
              userData={this.props.auth.userData}
              disableWithdrawalLocation
              products={this.props.productData.searchProducts}
              loadingProducts={this.props.productData.loadingSearchProducts}
              onSearchProducts={(value) => this.searchProducts(value)}

            />
            <ConsignerInventory
              data={filteredInventory}
              bulkEditData={this.state.bulkEditData}
              loading={filteringInventory}
              updateState={(key, value) => this.updateState(key, value)}
              selectedInventory={this.state.selectedInventory}
              bulkEditMode={this.state.bulkEditMode}
              defaultCurrency={this.props.auth.userData.defaultCurrency}
              updateStateArrayKey={(key, arrayKey, index, value) => this.updateStateArrayKey(key, arrayKey, index, value)}
              consigners={this.props.consignerData.consigners}
            />

            <BulkEdit
              visible={this.state.bulkEditVisible}
              handleCancel={() => this.handleBulkEdit()}
              values={this.state}
              updateState={(key, value) => this.updateState(key, value)}
              handleOk={() => this.bulkUpdateInventory()}
              loading={updatingInventory}

            />
          </FormCard>
          <BarcodeReader
            onScan={(data) => history.push(`inventory/${data}`)}
          />
          <SaveFilter
            handleOk={() => this.saveFilter()}
            visible={this.state.saveFilterModalVisible}
            handleCancel={() => this.handleSaveFilterModal()}
            loading={this.props.auth.savingFilter}
            updateState={(key, value) => this.updateState(key, value)}
            saveFilterTitle={this.state.saveFilterTitle}
          />

          <div
            ref={el => (this.componentRef = el)}
            className='print-source'
          >
            {
              filteredInventory.map(item => {
                return (
                  <BarcodeContainer>
                      <div>
                        <p>{item.title} {item.option1} {item.option2} {item.option3}</p>
                        <p>{defaultCurrency.symbol}{item.price} </p>
                      </div>
                      <QRCodeImage
                        src={item.code}
                        alt='qrcode'
                      />
                      <p> {item.shortCode} </p>
                      <p>{item.consignerData ? item.consignerData.shortCode : null} </p>


                  </BarcodeContainer>
                )
              }

              )
            }

          </div>

        </DashboardContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        inventoryData: state.InventoryReducer,
        productData: state.ProductReducer,
        consignerData: state.ConsignerReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory));
