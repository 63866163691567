import AuthCallback from '../screens/AuthCallback';
import Signup from '../screens/Signup';
import EmployeeSignup from '../screens/EmployeeSignup';
import ShopifySignup from '../screens/ShopifySignup';
import AddIntegration from '../screens/Settings/Integration/Add';
import ViewProduct from '../screens/Products/view';
import Membership from '../screens/Membership';

export default [
  {
    path: '/signup',
    component: Signup,
    breadcrumbName: 'Signup',
    exact: true
  },
  {
    path: '/employeeSignup',
    component: EmployeeSignup,
    breadcrumbName: 'EmployeeSignup',
    exact: true
  },
  {
    path: '/callback',
    component: AuthCallback,
    breadcrumbName: 'Call',
    exact: false
  },
  {
    path: '/shopify',
    component: ShopifySignup,
    exact: true
  },
  {
    path: '/membership',
    component: Membership,
    exact: true
  },
 
]
