import axios from 'axios';
import {
  PRODUCT_RESTRICTIONS_API
} from '../../../constants/API';
import {
  GET_PRODUCT_RESTRICTIONS_LOADING,
  GET_PRODUCT_RESTRICTIONS
} from '../types';

function getProductRestrictions() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT_RESTRICTIONS_LOADING,
      loadingProductRestrictions: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const productRestrictionsResponse = await axios.get(
        `${PRODUCT_RESTRICTIONS_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_PRODUCT_RESTRICTIONS,
        loadingProductRestrictions: false,
        productRestrictions: productRestrictionsResponse.data ? productRestrictionsResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_PRODUCT_RESTRICTIONS,
        loadingProductRestrictions: false,
        productRestrictions: []
      });
    }
  };
}
export default getProductRestrictions;
