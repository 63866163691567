import axios from 'axios';
import { message } from 'antd';
import {
  APPOINTMENTS_API
} from '../../../constants/API';
import {
  GET_APPOINTMENTS_LOADING,
  GET_APPOINTMENTS
} from '../types';

function getAppointments(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_APPOINTMENTS_LOADING,
      loadingAppointments: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const postData = {
        ...data,
        userId: userData.userId,
      };
      const appointmentsResponse = await axios.post(
        `${APPOINTMENTS_API}/filter`,
        postData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(appointmentsResponse)
      dispatch({
        type: GET_APPOINTMENTS,
        loadingAppointments: false,
        appointments: !appointmentsResponse.data.isError ? appointmentsResponse.data : []
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_APPOINTMENTS_LOADING,
        loadingAppointments: false
      });
    }
  };
}
export default getAppointments;
