import React from 'react';
import {
  Container,
  CardContainer,
  Title,
  Subtitle
} from './styles';
import InventoryIntakeTypes from '../../../../../constants/InventoryIntakeTypes';
function InventoryIntake(props) {
  const {
    values,
    updateState,
    disabled
  } = props
  const {
    isRemote,
    remoteStatus
  } = values;
  return (
    <Container>
      {
        InventoryIntakeTypes.map(type => {
          return (
            <CardContainer
              style={{ border: type.isRemote === isRemote && type.remoteStatus === remoteStatus ? '1px blue solid' : '1px #e7e7e7 solid' }}
              onClick={() => {
                if(disabled) {
                  return;
                }
                updateState('isRemote', type.isRemote)
                updateState('remoteStatus', type.remoteStatus)
              }}
            >
              <Title>{type.title}</Title>
              <Subtitle>{type.subtitle}</Subtitle>
            </CardContainer>
          )
        })
      }

    </Container>
  )
}
export default InventoryIntake;
