import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  message
} from 'antd';
import moment from 'moment';
import {
  withRouter
} from 'react-router-dom';
import EmployeeName from '../../components/ui/forms/employees/EmployeeName';
import EmployeeContact from '../../components/ui/forms/employees/EmployeeContact';
import EmployeeRestrictedRoutes from '../../components/ui/forms/employees/EmployeeRestrictedRoutes';
import EmployeeStatus from '../../components/ui/forms/employees/EmployeeStatus';
import RestrictedRoutes from '../../constants/RestrictedRoutes';

import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  AddContainer,
  FormCard
} from '../styles';

class AddEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      birthday: null,
      address: {},
      email: '',
      phone: '',
      fee: 20,
      feeType: '%',
      status: 'Active',
      notes: '',
      tags: [],
      locationId: null,
      locationName: '',
      locationQuery: '',
      pin: 0,
      sales: 0
    }
  }
  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }

  async createEmployee() {
    const {
      name,
      email
    } = this.state;
    if(name === '' || email === '') {
      message.error('Please enter a email and name')
    } else {
      const { createEmployee } = this.props;
      const id = await createEmployee(this.state);
      history.push(`/employees/${id}`);
    }
 }

  render() {
    const { employeeData } = this.props;
      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Add New Employee</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => this.createEmployee()}
                loading={employeeData.creatingEmployee}
              >
                Create
              </Button>,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard id='employeeName'>
                <EmployeeName
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}

                />

              </FormCard>
              <FormCard
                title='Contact'
                id='employeeContact'
              >
                <EmployeeContact
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                />

              </FormCard>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard title="Restricted Routes" >
                <EmployeeRestrictedRoutes
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  routes={RestrictedRoutes}

                />
              </FormCard>

              <FormCard
                title="Status"
                id='employeeStatus'

              >
                <EmployeeStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}

                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        employeeData: state.EmployeeReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEmployee));
