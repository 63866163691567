import styled from 'styled-components';
import { device } from '../../../constants/Device'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  & > input,
  & > span,
  & > div {
    margin-bottom: 7px;
  };
  @media ${device.mobileL} {
    flex-wrap: unset;
  }
`
export const SearchFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`
