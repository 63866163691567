import auth0 from 'auth0-js';

const Auth0 = new auth0.WebAuth({
  // the following three lines MUST be updated
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email app_metadata',
  grant_type:"client_credentials",
  theme: {
    logo: 'https://example.com/logo.png',
    primaryColor: '#000'
  }
});
function signUp() {
  return async(dispatch) => {
    try {
      await Auth0
      .authorize({
        scope: 'openid profile email app_metadata',
        audience: 'https',
        prompt: 'login',
        allowLogin: 'false',
        allowSignUp: 'true',
        fromWeb: true
      });
    } catch (e) {
      console.log(e)
    }
  };
}

export default signUp;
