import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API
} from '../../../constants/API';
import {
  GET_USERS_SHIPPING_CARRIERS_LOADING,
  GET_USERS_SHIPPING_CARRIERS
} from '../types';

function getInventory(id, subscription_id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_USERS_SHIPPING_CARRIERS_LOADING,
      loadingShippingCarriers: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;

      const shippingCarriersResponse = await axios.get(
        `${USERS_API}/shippingCarriers/${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_USERS_SHIPPING_CARRIERS,
        loadingShippingCarriers: false,
        shippingCarriers: !shippingCarriersResponse.data.isError ? shippingCarriersResponse.data : []
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_USERS_SHIPPING_CARRIERS_LOADING,
        loadingShippingCarriers: false
      });
    }
  };
}
export default getInventory;
