import React from 'react';
import {
  List
} from 'antd';
import card from './card';
import {
  Container
} from './styles';
function GridPicker(props) {
  const {
    data,
    cardComponent
  } = props;
  const CardComponent = card(cardComponent)
  return (
    <Container
      grid={{ column: 2 }}

      dataSource={data}
      renderItem={item => (
        <CardComponent data={item}/>
      )}
    />
  );
}
export default GridPicker;
