import axios from 'axios';
import { message } from 'antd';
import {
  PRODUCTS_API,
} from '../../../constants/API';
import {
  TURN_PRODUCT_CONSIGNMENT_LOADING
} from '../types';

function turnProductConsignment(id) {
  return async(dispatch, getState) => {
      dispatch({
        type: TURN_PRODUCT_CONSIGNMENT_LOADING,
        turningProductConsignment: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        await axios.post(`${PRODUCTS_API}/setProductAsConsignment/${id}/${userData.id}`, {}, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        dispatch({
          type: TURN_PRODUCT_CONSIGNMENT_LOADING,
          turningProductConsignment: false
        });
        message.loading('Loading Inventory in background - please check back in 1 minute. On avg this takes 5 seconds / variant');
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: TURN_PRODUCT_CONSIGNMENT_LOADING,
          turningProductConsignment: false
        });
        console.log(e);
      }
  }
}
export default turnProductConsignment;
