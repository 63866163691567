import React from 'react';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker
} from 'antd';
import PlacesInput from '../../../../algolia/PlacesInput';

class ConsignerName extends React.Component {

  render() {
    const {
      updateState,
      values,
      queryKey,
      query,
      updateStateObj,
      disabled
    } = this.props;
    const {
      name,
      birthday,
      firstName,
      verificationId,
      lastName,
      address
    } = values;

    return (
      <div>
        <Form.Item label='Display Name'>
          <Input
            placeholder='Nickname'
            onChange={(e) => updateState('name', e.target.value)}
            value={name}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label='First Name'>
          <Input
            placeholder='Legal name'
            onChange={(e) => updateState('firstName', e.target.value)}
            value={firstName}
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Last Name'>
          <Input
            placeholder='Legal name'
            onChange={(e) => updateState('lastName', e.target.value)}
            value={lastName}
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Verification Id'>
          <Input
            placeholder='Verification Id'
            onChange={(e) => updateState('verificationId', e.target.value)}
            value={verificationId}
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Birthday' >
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select Date"
            style={{ width: '100%'}}
            onChange={(date) => updateState('birthday', date)}
            value={moment(birthday)}
            disabled={disabled}

          />
        </Form.Item>
        <Form.Item label='Address'>
          <PlacesInput
            updateState={updateState}
            queryKey={queryKey}
            query={query}
            disabled={disabled}

          />
          <Input
            placeholder='Address'
            value={address.address}
            onChange={(e) => updateStateObj('address', 'address', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='City'
            value={address.city}
            onChange={(e) => updateStateObj('address', 'city', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='State/Province'
            value={address.province}
            onChange={(e) => updateStateObj('address', 'province', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='Country'
            value={address.country}
            onChange={(e) => updateStateObj('address', 'country', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='Country Code'
            value={address.countryCode}
            onChange={(e) => updateStateObj('address', 'countryCode', e.target.value)}
            disabled={disabled}

          />
          <Input
            placeholder='Postcode'
            value={address.postcode}
            onChange={(e) => updateStateObj('address', 'postcode', e.target.value)}
            disabled={disabled}

          />
        </Form.Item>
      </div>
    );
  }
}
export default ConsignerName;
