import React from 'react';
import { Button, Spin } from 'antd';
//REDUX IMPORTS
import {
    withRouter, 
    Redirect
  } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

import { 
    DashboardContainer,
    Title,
    SubTitle
} from './styles';

class ReAuth extends React.Component {
    componentWillMount() {
        this.props.checkReauth();
    }

    render() {
        const { 
            checkingReauth, 
            reauthData,
            reauthLoading,
            userData
        } = this.props.auth;
        if(userData.reauthNeeded) {
            return <Redirect to='/' />
        }
        return (
            <DashboardContainer>
                <Title>You need to reauthenicate your account.</Title>
                {
                    checkingReauth ? 
                        <Spin />
                        : 
                        <div>
                            <SubTitle>Your Shopify account needs to be reauthenticated!</SubTitle>
                            <Button 
                                onClick={() => window.location.replace(reauthData.url)}
                                loading={reauthLoading}
                            >
                                Reauthenticate
                            </Button>
                        </div>
                }
            </DashboardContainer>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        analyticsData: state.AnalyticsReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReAuth));
