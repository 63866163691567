import React from 'react';
import InventoryList from '../../../InventoryList';

class ConsignerInventory extends React.Component {

  render() {
    const {
      data,
      loading,
      updateState,
      selectedInventory,
      hideCheckbox,
      compact,
      bulkEditMode,
      bulkEditData,
      updateStateArrayKey,
      consigners,
      defaultCurrency

    } = this.props;
    return (
      <InventoryList
        data={data}
        loading={loading}
        updateState={updateState}
        selectedInventory={selectedInventory}
        hideCheckbox={hideCheckbox}
        compact={compact}
        bulkEditMode={bulkEditMode}
        bulkEditData={bulkEditData}
        updateStateArrayKey={updateStateArrayKey}
        consigners={consigners}
        defaultCurrency={defaultCurrency}
      />
    );
  }
}
export default ConsignerInventory;
