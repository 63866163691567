import {
  GET_INVENTORY_LOADING,
  GET_INVENTORY,
  CREATE_INVENTORY_LOADING,
  GET_INVENTORY_ITEM,
  GET_INVENTORY_ITEM_LOADING,
  UPDATE_INVENTORY_LOADING,
  GET_INVENTORY_FOR_FILTER,
  SEND_INVENTORY_INVITE,
  GET_INVENTORY_PAYOUT,
  GET_INVENTORY_PAYOUT_LOADING,
  SEND_WITHDRAWAL_TRANSFERED,
  BULK_UPSERTING_INVENTORY,
  SET_INVETORY_FILTER
} from '../actions/types';

const defaultState = {
  loadingInventory: false,
  inventory: [],
  creatingInventory: false,
  loadingInventoryItem: false,
  inventoryItem: null,
  updatingInventory: false,
  filteredInventory: [],
  filteringInventory: false,
  sendingRemoteSaleDeclined: false,
  inventoryPayout: null,
  loadingInventoryPayout: false,
  sendingWithdrawalTransfered: false,
  bulkUpsertingInventory: false,
  lastInvetoryFilter: {},
  lastIncomingInventoryFilter: {},
  lastWithdrawalsInventoryFilter: {},
  lastWithdrawalsTransferInventoryFilter: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_INVENTORY_LOADING:
          return {
            ...state,
            loadingInventory: action.loadingInventory
          };
        case GET_INVENTORY:
          return {
            ...state,
            loadingInventory: action.loadingInventory,
            inventory: action.inventory
          };
        case CREATE_INVENTORY_LOADING:
          return {
            ...state,
            creatingInventory: action.creatingInventory
          }
        case GET_INVENTORY_ITEM:
          return {
            ...state,
            loadingInventoryItem: action.loadingInventoryItem,
            inventoryItem: action.inventoryItem
          }
        case GET_INVENTORY_ITEM_LOADING:
          return {
            ...state,
            loadingInventoryItem: action.loadingInventoryItem,
          }
        case UPDATE_INVENTORY_LOADING:
          return {
            ...state,
            updatingInventory: action.updatingInventory
          }
        case GET_INVENTORY_FOR_FILTER:
          return {
            ...state,
            filteringInventory: action.filteringInventory,
            filteredInventory: action.filteredInventory
          }
        case SEND_INVENTORY_INVITE:
          return {
            ...state,
            sendingRemoteSaleDeclined: action.sendingRemoteSaleDeclined
          }
        case GET_INVENTORY_PAYOUT_LOADING:
          return {
            ...state,
            loadingInventoryPayout: action.loadingInventoryPayout
          }
        case GET_INVENTORY_PAYOUT:
          return {
            ...state,
            loadingInventoryPayout: action.loadingInventoryPayout,
            inventoryPayout: action.inventoryPayout
          }
        case SEND_WITHDRAWAL_TRANSFERED:
          return {
            ...state,
            sendingWithdrawalTransfered: action.sendingWithdrawalTransfered
          }
        case BULK_UPSERTING_INVENTORY:
          return {
            ...state,
            bulkUpsertingInventory: action.bulkUpsertingInventory
          }
        case SET_INVETORY_FILTER:
          return {
            ...state,
            [action.invetoryType]: action.filterOptions
          }

        default:
            return state;
    }
};
