import axios from 'axios'
import setAuthState from '../auth/setAuthState';

import {
  SUBSCRIPTION_API
} from '../../../constants/API';
import {
  UPDATE_MEMBERSHIP_LOADING
} from '../types';

function updateMembership(id, subscription_id, quantity) {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_MEMBERSHIP_LOADING,
      updatingMembership: true
    })
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const data = { subscription_id, quantity };
      await axios.put(`${SUBSCRIPTION_API}/${id}`, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
      await dispatch(setAuthState())
      dispatch({
        type: UPDATE_MEMBERSHIP_LOADING,
        updatingMembership: false
      })

    } catch(e) {
      console.log(e);
      alert('Error - please contact support at hello@gethabit.co')
      dispatch({
        type: UPDATE_MEMBERSHIP_LOADING,
        updatingMembership: false
      })
    }

  }
}
export default updateMembership;
