import axios from 'axios';
import { message } from 'antd';
import {
  USERS_API,
} from '../../../constants/API';
import {
  UPDATE_USER_LOADING
} from '../types';


function updateUser(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: UPDATE_USER_LOADING,
        updatingUser: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const putData = {
          ...data
        };
        console.log(putData)
        await axios.put(`${USERS_API}/${userData.id}`, putData, { headers: {"Authorization" : `Bearer ${accessToken}`} })
        dispatch({
          type: UPDATE_USER_LOADING,
          updatingUser: false
        });
        window.location.reload();
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: UPDATE_USER_LOADING,
          updatingUser: false
        });
        console.log(e);
      }
  }
}
export default updateUser;
