import styled from 'styled-components';
import {
  Timeline
} from 'antd';

const TimelineContainer = styled(Timeline)`
`
const TimelineItem = styled(Timeline.Item)`
`
const TimelineTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h2`
  font-size: 14px;
`
const SubTitle = styled.p`

`
export {
  TimelineContainer,
  TimelineItem,
  TimelineTextContainer,
  Title,
  SubTitle
};
