import React from 'react';
import {
  Form,
  Input
} from 'antd';
import Tag from '../../../Tag';
import SelectDropdown from '../../../SelectDropdown';

class InventoryDescription extends React.Component {
  render() {
    const {
      updateState,
      values,
      disabled
    } = this.props;
    const {
      brand,
      category,
      tags,
      options,
      optionSwitchs,
      optionFixedValues,
      sku
    } = values;
    console.log({optionFixedValues})
    return (
      <div>
        {
          options.map((option, index) => {
            if(option && option !== '') {
              const optionSwitch = optionSwitchs[index];
              const optionFixedValue = Object.values(optionFixedValues)[index];
              if(optionSwitch !== 'false' && (optionSwitch || optionSwitch === 'true') ) {
                return (
                  <Form.Item label={option}>
                    <SelectDropdown
                      placeholder={option}
                      data={optionFixedValue}
                      onChange={(value) => updateState(`option${index + 1}`, value)}
                      value={values[`option${index + 1}`]}
                      disabled={disabled}
                    />
                  </Form.Item>
                )
              } else {
                return (
                  <Form.Item label={option}>
                    <Input
                      placeholder={option}
                      onChange={(e) => updateState(`option${index + 1}`, e.target.value)}
                      value={values[`option${index + 1}`]}
                      disabled={disabled}
                    />
                  </Form.Item>
                )
              }

            } else return null
          })
        }
          <Form.Item label='Inventory SKU'>
            <Input
              placeholder='Inventory SKU'
              onChange={(e) => updateState('sku', e.target.value)}
              value={sku}
              disabled={disabled}

            />
          </Form.Item>
          <Form.Item label='Tags'>
            <Tag
              title='New Tag'
              updateState={updateState}
              stateKey='tags'
              value={tags}
              disabled={disabled}

            />
          </Form.Item>
      </div>
    );
  }
}
export default InventoryDescription;
