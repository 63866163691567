import {
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_LOADING,
} from "../actions/types"

let defaultState = {
  loadingAppointments: false,
  appointments: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_APPOINTMENTS:
          return {
            ...state,
            loadingAppointments: action.loadingAppointments,
            appointments: action.appointments
          };
        case GET_APPOINTMENTS_LOADING:
          return {
            ...state,
            loadingAppointments: action.loadingAppointments,
          };
        default:
            return state;
    }
};
