import React from 'react';
import {bindActionCreators} from 'redux'
import {
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
  PageHeader,
  Card
} from 'antd';
import LoadingSpinner from '../../../components/ui/LoadingSpinner';
import DynamicForm from '../../../components/ui/DynamicForm';

import {
  DashboardContainer,
} from '../../styles';

class AddIntegration extends React.Component {
  componentWillMount() {
    const {
      integrationId
    } = this.props.match.params;
    this.props.getIntegration(integrationId);
  }
  handleSubmit = (e, form) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const {
          id,
          is_url,
          url
        } = this.props.integration.integration;
        if(is_url) {
          const installUrl = `${url}${values["Shop Url"] || ''}`;
          window.open(installUrl, '_blank');
        } else {
          this.props.createIntegration(values, id, is_url);
        }
      }
    });
  }
  render() {
    const {
      integration,
      loadingIntegration,
    } = this.props.integration;
    const {
      creatingConnection
    } = this.props.connection
    console.log(integration)

    if(loadingIntegration && !integration) {
      return <LoadingSpinner />
    }
    const {
      name,
      fields
    } = integration;
    console.log(creatingConnection)

    return (
      <DashboardContainer>
        <PageHeader
          title={`Add ${name}`}
          onBack={() => window.history.back()}
        />
        <Card>
          <DynamicForm
            fields={fields}
            handleSubmit={this.handleSubmit}
            isLoading={creatingConnection}
          />
        </Card>

      </DashboardContainer>
    );
}
}

function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        integration: state.IntegrationReducer,
        connection: state.ConnectionReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddIntegration));
