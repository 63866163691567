import { SET_INVETORY_FILTER } from '../types';

function saveLastInvetoryFilter(invetoryType, filterOptions) {
  return dispatch => {
    dispatch({
      type: SET_INVETORY_FILTER,
      invetoryType,
      filterOptions
    });
  }
}

export default saveLastInvetoryFilter;
