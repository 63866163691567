import axios from 'axios';
import { message } from 'antd';
import {
  EMPLOYEE_API
} from '../../../constants/API';
import {
  GET_EMPLOYEE_ITEM_LOADING,
  GET_EMPLOYEE_ITEM
} from '../types';

function getEmployeeItem(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_EMPLOYEE_ITEM_LOADING,
      loadingEmployeeItem: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const employeeItemResponse = await axios.get(
        `${EMPLOYEE_API}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      dispatch({
        type: GET_EMPLOYEE_ITEM,
        loadingEmployeeItem: false,
        employeeItem: employeeItemResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_EMPLOYEE_ITEM_LOADING,
        loadingEmployeeItem: false
      });
    }
  };
}
export default getEmployeeItem;
