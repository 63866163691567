import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import * as actions from '../../redux/actions';
import history from '../../navigation/history';

import {
  PageHeader,
  Empty,
  Button,
  Card
} from 'antd'
import {
  DashboardContainer,
  PageTitle
} from '../styles';


class FinalStep extends React.Component {

  async finish() {
    await this.props.updateUser({ didOnboard: true });
    window.location = '/'
  }

  render() {
    return (
      <DashboardContainer>
        <PageHeader
          onBack={() => history.goBack()}
          title={<PageTitle>Final Step</PageTitle>}
          style={{ background: 'transparent', marginBottom: 10 }}
        />
        <Card>
          <Empty
            image={require('../../images/success.gif')}
            imageStyle={{
               height: 200,
             }}
            description=''
          >
            <h2>Congrats!</h2>
            <p>You are all setup, check out <a href='https://frontflip.kwiki.io' target='_blank'> our  docs </a> to better configure frontflip to your needs.</p>
            <Button
              type='primary'
              onClick={() => this.finish()}
              loading={this.props.auth.updatingUser}
            >
              Finish
            </Button>
          </Empty>
        </Card>
      </DashboardContainer>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        connection: state.ConnectionReducer,
        productsData: state.ProductReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalStep));
