import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';
import {
  ANALYTICS_API
} from '../../../constants/API';
import {
  GET_ANALYTICS_LOADING,
  GET_ANALYTICS
} from '../types';

function getAnalytics(dateRange) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ANALYTICS_LOADING,
      loadingAnalytics: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const start = moment(dateRange[0]).format()
      const end = moment(dateRange[1]).format()
      const analyticsResponse = await axios.get(
        `${ANALYTICS_API}?userId=${userData.id}&start=${start}&end=${end}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(analyticsResponse.data);
      dispatch({
        type: GET_ANALYTICS,
        loadingAnalytics: false,
        analytics: analyticsResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_ANALYTICS_LOADING,
        loadingAnalytics: false
      });
    }
  };
}
export default getAnalytics;
