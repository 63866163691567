import React from 'react';
import { Divider } from 'antd';
import _ from 'lodash';

import {
  Container,
  ItemContainer,
  Title,
  SubTitle
} from './styles';

function OrderTotal(props) {
  const {
    totals,
    isRefunded,
    defaultCurrency
  } = props;
  const {
    totalPrice,
    discount,
    totalTax,
    subTotalPrice,
    refundAmount
  } = totals;
  const { symbol } = defaultCurrency;
  return (
      <Container>
        <Divider />
        <ItemContainer>
          <Title>
            Discount
          </Title>}
          <SubTitle>
            {symbol}{discount}
          </SubTitle>
        </ItemContainer>

        <ItemContainer>
          <Title>
            Subtotal
          </Title>
          <SubTitle>
            {symbol}{subTotalPrice}
          </SubTitle>
        </ItemContainer>
        <ItemContainer>
          <Title>
            Tax
          </Title>
          <SubTitle>
            {symbol}{totalTax}
          </SubTitle>
        </ItemContainer>
        <ItemContainer>
          <Title>
            Total
          </Title>
          <SubTitle>
            {symbol}{totalPrice}
          </SubTitle>
        </ItemContainer>
        {
          isRefunded ?
            <ItemContainer>
              <Title>
                Refund
              </Title>
              <SubTitle>
                -{symbol}{refundAmount}
              </SubTitle>
            </ItemContainer>
            : null
        }
        {
          isRefunded ?
            <ItemContainer>
              <Title>
                Net Payment
              </Title>
              <SubTitle>
                {symbol}{totalPrice - refundAmount}
              </SubTitle>
            </ItemContainer>
            : null
        }



      </Container>
  )
}

export default OrderTotal;
