import axios from 'axios';
import {
  VALIDATE_API
} from '../../../constants/API';

async function checkToken(token) {
  try {
    const tokenValid = await axios.get(VALIDATE_API, { headers: {"Authorization" : `Bearer ${token}`} });
    return tokenValid.data;
  } catch (e) {
    console.log(e)
    return false;
  }
}

export default checkToken;
