import React from 'react';
import { Empty } from 'antd';
import moment from 'moment';
import LoadingSpinner from '../../../LoadingSpinner';
import {
  Container,
  ItemContainer,
  Title,
  SubTitle,
  Remove
} from './styles';

class SettingsAppointment extends React.Component {

  render() {
    const {
      appointmentRules,
      deleteAppointmentRule,
      handleAddAppointmentRulesModal
    } = this.props;
    if(!appointmentRules || appointmentRules.length <= 0) {
      return (
        <Empty
          description={
            <a
              onClick={() => handleAddAppointmentRulesModal()}
            >
              Add Appointments Restriction
            </a>
          }
        />
      );
    }

    return (
      <Container>
      {
        appointmentRules.map((item) => {
          return (
            <ItemContainer>
              <Title>
              {item.rule}
              </Title>
              <SubTitle>
                Restricted From Appointment Bookings
              </SubTitle>
              <Remove
                onClick={() => {
                  deleteAppointmentRule(item.id)
                }}
              >
                REMOVE
              </Remove>
            </ItemContainer>
          )
        })
      }
      <a
        onClick={() => handleAddAppointmentRulesModal()}
        data-test='SettingsAddAppointmentButton'

      >
        Add Appointment Restriction
      </a>
      </Container>
    );
  }
}
export default SettingsAppointment;
