import axios from 'axios';
import setAuthState from '../auth/setAuthState';
import login from '../auth/login';

import {
  CARDS_API,
} from '../../../constants/API';
import {
  EDITING_CARD_LOADING
} from '../types';

function updateCard(cardData, billingName, billingPostal, stripe) {
  return async (dispatch, getState) => {
    if (!cardData || !cardData.valid) {
      alert('Please check card details');
    } else if(billingName == '') {
      alert('Please check billing name');
    } else if(billingPostal == '') {
      alert('Please check billing postal');
    } else {
      dispatch({
        type: EDITING_CARD_LOADING,
        editingCard: true
      });
      try {
        const params = {...cardData, name: billingName, addressZip: billingPostal};
        const token = await stripe.createTokenWithCard(params);
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        if(accessToken && userData) {
          const data = {
            customer_id: userData.customer_id,
            token: token.tokenId,
            userId: userData.id
          }
          const addCardsResponse = await axios.post(CARDS_API, data, { headers: {"Authorization" : `Bearer ${accessToken}`} });
          if(addCardsResponse.data.isError) {
            dispatch({
              type: EDITING_CARD_LOADING,
              editingCard: false
            });
            alert(addCardsResponse.data.errorMessage)
          } else {
            await dispatch(setAuthState(false));
            dispatch({
              type: EDITING_CARD_LOADING,
              editingCard: false
            });
          }
        } else {
          dispatch(login());
        }
      } catch (e) {
        alert(String(e))
        console.log(e);
      }
    }
  }
}
export default updateCard;
