import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
const SubTitle = styled.p`

`
const Title = styled.h2`
  font-size: 14px;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export {
  Container,
  SubTitle,
  Title,
  TitleContainer
}
