import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import moment from 'moment';
import ConsignerName from '../../components/ui/forms/consigners/ConsignerName';
import ConsignerContact from '../../components/ui/forms/consigners/ConsignerContact';
import ConsignerFee from '../../components/ui/forms/consigners/ConsignerFee';
import ConsignerStatus from '../../components/ui/forms/consigners/ConsignerStatus';
import ConsignerCustomerAccount from '../../components/ui/forms/consigners/ConsignerCustomerAccount';
import FileUploader from '../../components/ui/FileUploader';

import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd'
import {
  PageTitle,
  AddContainer,
  FormCard
} from '../styles';

class AddConsigners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      notes: '',
      locationName: '',
      locationId: null,
      status: '',
      name: '',
      birthday: moment(),
      fee: '20',
      feeType: '%',
      email: '',
      phone: '',
      contracts: [],
      customerId: null,
      customerQuery: '',
      customerName: '',
      locationQuery: '',
      addressQuery: ''

    }
  }
  uploadImage() {
    window.cloudinary.openUploadWidget({
      cloudName: "frontflipco", uploadPreset: "kovxlqub"}, (error, result) => {
        if (result && result.event === "success") {
            console.log(result)
            //Get Image uploaded
            const { secure_url } = result.info;
            //Add contracts to state
            this.setState({
              contracts: [...this.state.contracts, {url: secure_url}]
            })
        }
    });
  }
  deleteImage(index) {
    //State data
    const contracts = this.state.contracts;
    //Remove index
    contracts.splice(index, 1);
    this.setState({ contracts });
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    })
  }
  updateStateObj(key, objKey, value) {
    this.setState(prevState => ({
      [key]: {                   // object that we want to update
        ...prevState[key],    // keep all other key-value pairs
        [objKey]: value       // update the value of specific key
      }
    }))
  }

  async createConsigner() {
    const { createConsigner } = this.props;
    const id = await createConsigner(this.state);
    history.push(`/consigners/${id}`);
  }

  render() {
    const { contracts } = this.state;
    const { consignerData } = this.props;
      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Add New Consigner</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => this.createConsigner()}
                loading={consignerData.creatingConsigner}
              >
                Create
              </Button>,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard id='consignerName'>
                <ConsignerName
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  updateStateObj={(key, objKey, value ) => this.updateStateObj(key, objKey, value)}
                  query={this.state.addressQuery}
                  queryKey='addressQuery'
                />
              </FormCard>
              <FormCard
                title="Contact"
                id='consignerContact'
              >
                <ConsignerContact
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                />
              </FormCard>
              <FormCard
                  title="Consignment Contract"
                  extra={this.state.editMode ? <a onClick={() => this.uploadImage()}>Add New</a> : null}
                >
                  <FileUploader
                    data={contracts}
                    onDelete={(index) => this.deleteImage(index)}
                    title='Click edit and add new to upload consigners'
                    disabled={!this.state.editMode}
                  />
              </FormCard>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard
                title="Consignment Fee"
                id='consignerFee'
              >
                <ConsignerFee
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                />
              </FormCard>
              <FormCard
                title="Status"
                id='consignerStatus'
              >
                <ConsignerStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  userId={this.props.auth.userData.id}


                />
              </FormCard>
              <FormCard
                title="Connect Customer Account"
                id='consignerCustomer'
              >
                <ConsignerCustomerAccount
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.customerQuery}
                  queryKey='customerQuery'
                  userId={this.props.auth.userData.id}

                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        consignerData: state.ConsignerReducer,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddConsigners));
