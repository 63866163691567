import axios from 'axios';
import { message } from 'antd';
import {
  APPOINTMENT_RULES_API,
} from '../../../constants/API';
import {
  CREATE_APPOINTMENT_RULE_LOADING
} from '../types';
import getLocations from '../location/getLocations';

function createLocation(data) {
  return async(dispatch, getState) => {
      dispatch({
        type: CREATE_APPOINTMENT_RULE_LOADING,
        creatingAppointmentRule: true
      });
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const { userData } = getState().AuthReducer;
        const postData = {
          ...data,
          userId: userData.id
        };
        await axios.post(APPOINTMENT_RULES_API, postData, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        dispatch({
          type: CREATE_APPOINTMENT_RULE_LOADING,
          creatingAppointmentRule: false
        });

        await dispatch(getLocations());
      } catch (e) {
        message.error(String(e));
        dispatch({
          type: CREATE_APPOINTMENT_RULE_LOADING,
          creatingAppointmentRule: false
        });
        console.log(e);
      }
  }
}
export default createLocation;
