export default [
  {
    title: 'What are product templates',
    desc: 'Product Templates let you quickly add product options to products. Product options are comprised of 3 things option name, fixed value, and fixed value options. Option name are editable in the option1, option2, option3 input. The fixed values switch determines if it the option has fixed values or is free for input.'
  },
  {
    title: 'How does it work',
    desc: 'Once you create a template, you can add them to products which will take the templates product options. Product Options can be fixed (dropdown consigners choose from) or a input(consigners type in).'
  },
  {
    title: 'Want an example?',
    desc: 'For a product template with a title "Sneakers", we can setup Option1 to be "Size" and switch on fixed values. In the input below we will add in all sneaker sizes such as 5, 5.5, 6, 6.5.... 10, 10.5. This will create a drop down of sizes consigners can choose from for products that we connect this template too.'
  }
]
