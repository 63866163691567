import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  Empty,
  Tag
} from 'antd';
import {
  withRouter
} from 'react-router-dom';
import history from '../../navigation/history';
import * as actions from '../../redux/actions';
import {
  PageHeader,
  Button
} from 'antd';
import OrderStatus from '../../components/ui/forms/orders/OrderStatus';
import OrderViewCustomer from '../../components/ui/forms/orders/OrderViewCustomer';
import OrderViewProduct from '../../components/ui/forms/orders/OrderViewProduct';
import OrderTotal from '../../components/ui/forms/orders/OrderTotal';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

import {
  PageTitle,
  FormCard,
  AddContainer
} from '../styles';

class ViewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      status: '',
      tags: [],
      locationName: '',
      locationQuery: '',
      locationId: null,
      customerId: null,
      customerQuery: '',
      customerName: '',
      employeeQuery: '',
      employeeName: '',
      employeeId: null,
      orderFees: [],
      products: [],
      discount: {
        discount: 0,
        discountType: '$'
      },
      paymentMethod: []
    }
  }

  componentWillMount() {
    const id = this.props.match.params.id
    this.props.getOrderItem(id);
  }
  componentDidUpdate(oldProps) {
    //New Order Item
    const { orderData } = this.props;
    const newOrderItem = orderData.orderItem;
    //Old Order Item
    const oldOrderItem = oldProps.orderData.orderItem;
    if(newOrderItem) {
      if(oldOrderItem !== newOrderItem) {
        console.log(newOrderItem)
        this.setState({
          ...newOrderItem,
          locationQuery: newOrderItem.locationName,
          customerQuery: newOrderItem.customerName,
          employeeQuery: newOrderItem.employeeName,
          paymentMethod: newOrderItem.paymentMethod || [],
          discount: newOrderItem.discountData ?
            newOrderItem.discountData : { discount: newOrderItem.discount, discountType: '$' }
        })
      }
    }
  }

  updateStateArray(key, value) {
    //Updates a Array in the state
    this.setState({
      [key]: [...this.state[key], value]
    })
  }
  deleteStateArray(key, index) {
    //Deletes a array index in the state
    const array = this.state[key];
    array.splice(index, 1);
    this.setState({ [key]: array });
  }

  updateState(key, value) {
    //updates a state value that is not a array
    this.setState({
      [key]: value
    })
  }

  updateStateArrayKey(key, arrayKey, index, value) {
    //Updates a key of an array in the state
    const array = [ ...this.state[key] ];
    array[index] = { ...array[index], [arrayKey]: value };
    this.setState({ [key]: array });
  }
  async updateOrder() {
    const orderData = {
      notes: this.state.notes,
      tags: this.state.tags,
      status: this.state.status,
      locationName: this.state.locationName,
      locationId: this.state.locationId,
      id: this.state.id
    }
    const { updateOrder } = this.props;
    await updateOrder(orderData);
    window.location.reload();
  }
  getShopifyOrderUrl() {
    const { orderData, auth } = this.props;
    const { connectionsData } = auth.userData;
    const { orderItem } = orderData;
    if(
      connectionsData && 
      connectionsData[0].fieldValues && 
      connectionsData[0].fieldValues.shop &&
      orderItem &&
      orderItem.metadata &&
      orderItem.metadata.id
    ) {
      const shop = connectionsData[0].fieldValues.shop;
      const orderId = orderItem.metadata.id;
      const shopifyOrderUrl = `https://${shop}/admin/orders/${orderId}`
      return shopifyOrderUrl;
    } 
    return '';

  }
  goToShopifyOrder() {
    const shopifyOrderUrl = this.getShopifyOrderUrl()
    window.open(shopifyOrderUrl,'_blank');
  }

  render() {
    const { orderData, taxData } = this.props;

    const {
      loadingOrderItem,
      orderItem
    } = orderData;
    if(loadingOrderItem) {
      return <AddContainer><LoadingSpinner /></AddContainer>
    }
    if(!orderItem) {
      return <AddContainer><Empty /></AddContainer>
    }
    const {
      totalPrice,
      discount,
      totalTax,
      subTotalPrice,
      refundAmount
    } = orderItem
    const totals = {
      totalPrice,
      discount,
      totalTax,
      subTotalPrice,
      refundAmount
    }

      return (
        <AddContainer>
          <PageHeader
            onBack={() => history.goBack()}
            title={<PageTitle>Order #{orderItem.orderNumber}</PageTitle>}
            style={{ background: 'transparent' }}
            extra={[
               !orderItem.isRefunded ?
                 <Tag onClick={() => this.goToShopifyOrder('test')}>Use {orderItem.integrationName} to refund</Tag>  : <Tag color="red">{orderItem.status}</Tag>,
              <Button
                key="1"
                type="primary"
                onClick={() => this.updateOrder()}
                loading={orderData.updatingOrder}
              >
                Update
              </Button>,
            ]}
          />
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
            >
              <FormCard
                title='Order Details'
              >
                <OrderViewProduct
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  addProduct={(key, value) => this.updateStateArray(key, value)}
                  updateStateArrayKey={(key, arrayKey, index, value) => this.updateStateArrayKey(key, arrayKey, index, value)}
                  products={this.state.products}
                  deleteStateArray={(key, index) => this.deleteStateArray(key, index)}
                  taxRates={taxData.taxRates}
                  loadingTaxRates={taxData.loadingTaxRates}
                  creatingTaxRate={taxData.creatingTaxRate}
                  createTaxRate={(rate) => this.props.createTaxRate(rate)}
                  userId={this.props.auth.userData.id}
                  refundedIds={orderItem.refundedIds || []}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}

                />
                <OrderTotal
                  isRefunded={orderItem.isRefunded}
                  isView
                  totals={totals}
                  updateState={(key, value) => this.updateState(key, value)}
                  defaultCurrency={this.props.auth.userData.defaultCurrency}
                />
              </FormCard>

            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
            >
              <FormCard
                title="Customer"
              >
                <OrderViewCustomer
                  values={this.state}
                />

              </FormCard>
              <FormCard
                title="Status"
              >
                <OrderStatus
                  values={this.state}
                  updateState={(key, value) => this.updateState(key, value)}
                  query={this.state.locationQuery}
                  queryKey='locationQuery'
                  isRefunded={orderItem.isRefunded}
                  userId={this.props.auth.userData.id}
                />
              </FormCard>
            </Col>
        </AddContainer>
      );
  }
}
function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer,
        membership: state.MembershipReducer,
        orderData: state.OrderReducer,
        taxData: state.TaxReducer
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewOrder));
