import axios from 'axios';
import { message } from 'antd';
import {
  LOCATIONS_API
} from '../../../constants/API';
import {
  GET_LOCATIONS_LOADING,
  GET_LOCATIONS
} from '../types';

function getLocations() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LOCATIONS_LOADING,
      loadingLocations: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const locationsResponse = await axios.get(
        `${LOCATIONS_API}?userId=${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(locationsResponse)
      dispatch({
        type: GET_LOCATIONS,
        loadingLocations: false,
        locations: locationsResponse && locationsResponse.data ? locationsResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_LOCATIONS,
        loadingLocations: false,
        locations: []
      });
    }
  };
}
export default getLocations;
