import React from 'react';
import {
  Statistic
} from 'antd';
import { Container } from './styles';
class CustomerStats extends React.Component {

  render() {
    const {
      values,
      defaultCurrency
    } = this.props;
    const {
      sales,
      orders,
    } = values;
    const { symbol } = defaultCurrency;
    return (
      <Container>
        <Statistic
          title='Sales'
          value={sales ? sales : 0}
          precision={2}
          prefix={symbol}
          valueStyle={{ textAlign: 'center' }}
        />
        <Statistic
          title='Amount of Orders'
          value={orders ? orders : 0}
          precision={2}
          valueStyle={{ textAlign: 'center' }}

        />
      </Container>
    );
  }
}
export default CustomerStats;
