import {
  SET_CURRENT_LOCATION
} from '../types';
function setCurrentLocation(location){
 return async(dispatch) => {
   try {
     await localStorage.setItem('currentLocation', location.id);
     await localStorage.setItem('currentLocationName', location.name);
     dispatch({
       type: SET_CURRENT_LOCATION,
       currentLocation: location
     })
   } catch (e) {
     console.log(e)
   }
 };
}
export default setCurrentLocation;
