import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

class Signup extends React.Component {
  componentWillMount() {
    this.props.login();
  }

  render() {
    return <div />;
  }
}


function mapStateToProps(state, props) {
    return {
        auth: state.AuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
