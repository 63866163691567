import moment from 'moment-timezone';
import initHelpHero from 'helphero';
import axios from 'axios';
import firebase from 'firebase'
import { message } from 'antd';
import checkToken from '../token/checkToken';
import getUser from '../user/getUser';
import logout from './logout';
import login from './login';
import createUser from '../user/createUser';
import setPageName from '../../../helpers/setPageName';
import setFavicon from '../../../helpers/setFavicon';
import {
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_CSV_LOADING,
  SET_CSV_UPLOADING
} from '../types';

async function setOnboardingLoading(userData, dispatch) {
  if (!userData.didOnboard) {
    //user did not onboard
    //firebase init
    var firebaseConfig = {
      apiKey: "AIzaSyCWBweBlm5UA7k6pus2AZyyGveaMcZtymI",
      authDomain: "frontflip.firebaseapp.com",
      databaseURL: "https://frontflip.firebaseio.com",
      projectId: "frontflip",
      storageBucket: "frontflip.appspot.com",
      messagingSenderId: "911372376365",
      appId: "1:911372376365:web:c8700da8d30ea607"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig)
    // Get a reference to the database service
    var database = firebase.database();
    //user id
    const userId = userData.id;
    //create CSV Ref
    var createCSVRef = database.ref(`createCSV/${userId}`);
    createCSVRef.on('value', function (snapshot) {
      if (snapshot && snapshot.val()) {
        dispatch({
          type: SET_CSV_LOADING,
          csvLoading: snapshot.val()
        })
      }

    });
    //import CSV Ref
    var importCSVRef = database.ref(`importCSV/${userId}`);
    importCSVRef.on('value', function (snapshot) {
      if (snapshot && snapshot.val()) {
        dispatch({
          type: SET_CSV_UPLOADING,
          csvUploading: snapshot.val()
        })
      }
    });
  }
}

async function getDemoAccessToken() {
  const tokenUrl = 'https://frontflip.auth0.com/oauth/token';
  const postData = {
    "client_id": "i2Ncosd5FXJnrgDJekpSiyp2VSPiwM02",
    "client_secret": "RwO4NPoUpzssuw64hPDvz6heSj1LZIWu3eeL5rZ6I5OuUYrXCn-ZLx005k3GeDw-",
    "audience": "https",
    "grant_type": "client_credentials"
  }
  const tokenResponse = await axios.post(tokenUrl, postData);
  console.log(tokenResponse)
  await localStorage.setItem('accessToken', tokenResponse.data.access_token);
  return tokenResponse.data.access_token; 
}

function setAuthState(user = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH_LOADING,
      isLoadingAuthState: true
    });
    try {
      await getDemoAccessToken()
      const accessToken = await localStorage.getItem('accessToken');
      const userId = 'auth0|5db89925c0d37f0dff907955';

      if (accessToken !== null) {
        const tokenValid = await checkToken(accessToken);
        if (tokenValid) {

          const userData = await getUser(userId, accessToken);
          //CHECK IF THERE IS A USER, IF THERE ISNT CREATE USRE
          console.log(userData)
          if (userData) {
            //There is user data
            if (
              userData.subscriptionData &&
              userData.subscriptionData.id &&
              (userData.subscriptionData.status !== 'cancelled') &&
              (userData.subscriptionData.status !== 'unpaid') &&
              (userData.subscriptionData.status !== 'past_due')
            ) {
              const { employee } = userData;
              if (employee.status === 'Disabled') {
                message.error('Account Disabled')
                await dispatch(logout());

              } else {
                moment.tz.setDefault(userData.timezone)
                setPageName(userData.company || '')
                setFavicon(userData.favicon)
                window.Canny('identify', {
                  appID: '5da90b3424827d3c797cc428',
                  user: {
                    // Replace these values with the current user's data
                    companies: [{
                      created: new Date(userData.createdAt).toISOString(), // optional
                      id: userData.id,
                      name: userData.company,
                    }],
                    email: userData.employee.email,
                    name: userData.employee.name,
                    id: userData.employee.id,
                    // These fields are optional, but recommended:
                    avatarURL: userData.logo,
                    created: new Date(userData.employee.createdAt).toISOString(),
                  },
                });
                // hlp.identify(userData.id, {
                //   ...userData
                // });
                await setOnboardingLoading(userData, dispatch)
                dispatch({
                  type: SET_AUTH,
                  isLoggedIn: true,
                  isMember: true,
                  isLoadingAuthState: false,
                  userData
                });
              }
            } else {
              //User does not have active membership
              dispatch({
                type: SET_AUTH,
                isLoggedIn: true,
                isLoadingAuthState: false,
                userData,
                isMember: false,
              });
            }
          } else {
            // No user data

            if (user) {
              await createUser(user, accessToken);
              console.log('no user data')
              dispatch(setAuthState());
            } else {
              await dispatch(logout());

            }
          }
        } else {
          //NO VALID ACCESS TOKEN
          dispatch({
            type: SET_AUTH,
            isLoggedIn: false,
            isLoadingAuthState: false,
            userData: {}
          });
          await dispatch(logout());
          dispatch(login());
        }
      } else {
        //ACCESS TOKEN
        dispatch({
          type: SET_AUTH,
          isLoggedIn: false,
          isLoadingAuthState: false,
          userData: {}
        });
      }
    } catch (error) {
      console.log(error);
      //NO USER
      dispatch({
        type: SET_AUTH,
        isLoggedIn: false,
        isLoadingAuthState: false,
        userData: {}
      });
    }
  };
}
export default setAuthState;
