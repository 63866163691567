import axios from 'axios';
import { message } from 'antd';
import {
  CONSIGNER_API
} from '../../../constants/API';
import {
  GET_CONSIGNER_LEDGER_LOADING,
  GET_CONSIGNER_LEDGER
} from '../types';

function getConsignerLedgerData(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CONSIGNER_LEDGER_LOADING,
      loadingConsignerLedger: true
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const consignerLedgerDataResponse = await axios.get(
        `${CONSIGNER_API}/ledger/${id}/${userData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(consignerLedgerDataResponse)
      dispatch({
        type: GET_CONSIGNER_LEDGER,
        loadingConsignerLedger: false,
        consignerLedgerData: consignerLedgerDataResponse.data
      });
    } catch (e) {
      console.log(e);
      message.error(String(e));
      dispatch({
        type: GET_CONSIGNER_LEDGER_LOADING,
        loadingConsignerLedger: false
      });
    }
  };
}
export default getConsignerLedgerData;
