import React from 'react';
import {
  List,
} from 'antd';
import {
  Link
} from 'react-router-dom';
import moment from 'moment';
import {
  Container,
  SubTitle,
  Title,
  TitleContainer
} from './styles';

function PayoutList({
  data,
  defaultCurrency
}) {
  const { symbol } = defaultCurrency;
  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        pageSize: 8,
      }}
      dataSource={data}
      renderItem={item => (
        <Container>
            <div>
              {
                item.payoutData && item.payoutData.inventory ?
                <TitleContainer>
                  <Link
                    to={`/inventory/${item.payoutData.inventory.id}`}
                  >
                  {item.payoutData.inventory.title} {item.payoutData.inventory.option1} {item.payoutData.inventory.option2} {item.payoutData.inventory.option3} {item.payoutData.inventory.shortCode} 
                  </Link>
                  <Link
                    to={`/orders/${item.payoutData.inventory.orderId}`}
                  >
                  Order #{item.payoutData.inventory.orderNumber}
                  </Link>
                </TitleContainer>
                : null
              }

              <SubTitle>{moment(item.createdAt).format('llll')}</SubTitle>
            </div>
            <SubTitle>{symbol}{item.amount}</SubTitle>

        </Container>
      )}
    />
  )
}

export default PayoutList;
