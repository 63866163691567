import {
  CREATE_EMPLOYEE_LOADING,
  GET_EMPLOYEE_ITEM_LOADING,
  GET_EMPLOYEE_ITEM,
  UPDATE_EMPLOYEE_LOADING,
  SEND_EMPLOYEE_INVITE,
  SEARCH_EMPLOYEES,
  SEARCH_EMPLOYEES_LOADING
} from "../actions/types"

let defaultState = {
  creatingEmployee: false,
  loadingEmployeeItem: false,
  employeeItem: null,
  updatingEmployee: false,
  sendingEmployeeInvite: false,
  loadingSearchEmployees: false,
  searchEmployees: [],
  searchEmployeesCount: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_EMPLOYEE_LOADING:
          return {
            ...state,
            creatingEmployee: action.creatingEmployee
          };
        case GET_EMPLOYEE_ITEM_LOADING:
          return {
            ...state,
            loadingEmployeeItem: action.loadingEmployeeItem
          }
        case GET_EMPLOYEE_ITEM:
          return {
            ...state,
            loadingEmployeeItem: action.loadingEmployeeItem,
            employeeItem: action.employeeItem
          }
        case UPDATE_EMPLOYEE_LOADING:
          return {
            ...state,
            updatingEmployee: action.updatingEmployee
          }
        case SEND_EMPLOYEE_INVITE:
          return {
            ...state,
            sendingEmployeeInvite: action.sendingEmployeeInvite
          }
        case SEARCH_EMPLOYEES:
          return {
            ...state,
            loadingSearchEmployees: action.loadingSearchEmployees,
            searchEmployees: action.searchEmployees,
            searchEmployeesCount: action.searchEmployeesCount
          }
        case SEARCH_EMPLOYEES_LOADING:
          return {
            ...state,
            loadingSearchEmployees: action.loadingSearchEmployees,
          }
        default:
            return state;
    }
};
