import React from 'react';
import { Input } from 'antd';
import { Container } from './styles';
const SearchBar = ({ query, searchPlaceholder, updateState, onSearch,loading }) => (
  <Container>
  <Input.Search
    placeholder={searchPlaceholder}
    value={query}
    onChange={e => updateState('query', e.target.value)}
    allowClear
    size='large'
    onPressEnter={onSearch}
    onSearch={onSearch}
    loading={loading}
    enterButton
  />
  </Container>
);

export default SearchBar;
