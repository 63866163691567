import React from 'react';
import PayoutList from '../../../PayoutList';

class ConsignerPayouts extends React.Component {

  render() {
    const {
      data,
      defaultCurrency 
    } = this.props;
    return (
      <PayoutList
        data={data}
        defaultCurrency={defaultCurrency}
      />
    );
  }
}
export default ConsignerPayouts;
