import {
  GET_APPOINTMENT_TIMES_LOADING,
  GET_APPOINTMENT_TIMES,
  CREATE_APPOINTMENT_TIME_LOADING,
  DELETE_APPOINTMENT_TIME_LOADING
} from '../actions/types';

const defaultState = {
  loadingAppointmentTimes: false,
  appointmentTimes: [],
  creatingAppointmentTime: false,
  deletingAppointmentTime: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_APPOINTMENT_TIMES_LOADING:
          return {
            ...state,
            loadingAppointmentTimes: action.loadingAppointmentTimes
          };
        case GET_APPOINTMENT_TIMES:
          return {
            ...state,
            loadingAppointmentTimes: action.loadingAppointmentTimes,
            appointmentTimes: action.appointmentTimes
          };
        case CREATE_APPOINTMENT_TIME_LOADING:
          return {
            ...state,
            creatingAppointmentTime: action.creatingAppointmentTime
          }
        case DELETE_APPOINTMENT_TIME_LOADING:
          return {
            ...state,
            deletingAppointmentTime: action.deletingAppointmentTime
          }
        default:
            return state;
    }
};
