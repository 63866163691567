import axios from 'axios';
import {
  APPOINTMENT_TIMES_API
} from '../../../constants/API';
import {
  GET_APPOINTMENT_TIMES_LOADING,
  GET_APPOINTMENT_TIMES
} from '../types';

function getAppointmentTimes(locationId) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_APPOINTMENT_TIMES,
      loadingAppointmentTimes: true,
      appointmentTimes: []
    });
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const { userData } = getState().AuthReducer;
      const appointmentTimesResponse = await axios.get(
        `${APPOINTMENT_TIMES_API}?userId=${userData.id}&locationId=${locationId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(appointmentTimesResponse)
      dispatch({
        type: GET_APPOINTMENT_TIMES,
        loadingAppointmentTimes: false,
        appointmentTimes: appointmentTimesResponse.data ? appointmentTimesResponse.data : []
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: GET_APPOINTMENT_TIMES,
        loadingAppointmentTimes: false,
        appointmentTimes: []

      });
    }
  };
}
export default getAppointmentTimes;
