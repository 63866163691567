import React from 'react';
import {
  Modal,
  Input,
  Form,
  Switch
} from 'antd';

import ProductOption from '../../forms/product/ProductOption';


function EditProductTemplate(props) {
  const {
    updatingProductTemplate,
    handleOk,
    handleCancel,
    visible,
    updateState,
    values,
  } = props;

  return (
    <Modal
      title='Edit Product Template'
      visible={visible}
      onOk={() => handleOk()}
      okText='Update'
      onCancel={() => handleCancel()}
      confirmLoading={updatingProductTemplate}
    >
      <Form.Item label='Title'>
        <Input
          placeholder='Title'
          onChange={(e) => updateState('title', e.target.value)}
          value={values.title}
        />
      </Form.Item>
      <Form.Item label='Set as default template'>
        <Switch
          onChange={(checked) => updateState('isDefault', checked)}
          checked={values.isDefault}
        />
      </Form.Item>
      <ProductOption
        updateState={updateState}
        values={values}
        disabled={false}
      />
    </Modal>
  );
}

export default EditProductTemplate;
