import React from 'react';
import {
  Modal,
  Input,
  Form,
  Switch,
  Select
} from 'antd';
import PlacesInput from '../../../algolia/PlacesInput';
import Tag from '../../Tag';
const { Option } = Select;
function AddProductRestriction(props) {
  const {
    creatingLocation,
    handleOk,
    handleCancel,
    visible,
    updateState,
    updateStateObj,
    values
  } = props;
  const {
    type,
    trigger,
    restrictionType,
    restrictionValue

  } = values;
  return (
    <Modal
      title='Add Product Restriction'
      visible={visible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      confirmLoading={creatingLocation}
    >
      <Form.Item label='Trigger'>
        <Select
          defaultValue="consignerTag"
          value={type}
          style={{ width: '100%' }}
          onChange={(value) => updateState('type', value)}
        >
          <Option value="consignerTag">Consigner Tag</Option>
        </Select>
        <Input
          placeholder='Equals'
          onChange={(e) => updateState('trigger', e.target.value)}
          value={trigger}
        />
      </Form.Item>

      <Form.Item label='Restriction'>
        <Select
          defaultValue="option1FixedValues"
          value={restrictionType}
          style={{ width: '100%' }}
          onChange={(value) => updateState('restrictionType', value)}
        >
          <Option value="option1FixedValues">Option 1</Option>
          <Option value="option2FixedValues">Option 2</Option>
          <Option value="option3FixedValues">Option 3</Option>
        </Select>
        <Input
          placeholder='Equals'
          onChange={(e) => updateState('restrictionValue', e.target.value)}
          value={restrictionValue}
        />
      </Form.Item>
    </Modal>
  );
}

export default AddProductRestriction;
